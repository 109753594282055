module.exports={
"hr-HR": {		
	"changePasswordButton": "Promjeni lozinku",
	"changePasswordType": "'internal'",
	"checkdRequestMsg": "Provjera u tijeku...",
	"confirmNewPasswordLabel": "Potvrda nove lozinke",
	"confirmPasswordError": "Lozinke moraju biti identične",
	"dataLoadingText": "  Prijava u tijeku...",
	"gotoLoginLink": "Povratak na stranicu za prijavu",
	"gotoLoginText": "Povratak na",
	"invalidRequestMsg": "Neispravan zahtjev",
	"loginButton": "Prijava",
	"loginErrorMsg": "Neuspješan pokušaj prijave",
	"loginMsg": "Prijava u tijeku...",
	"logoutMsg1": "Prijava",
	"logoutMsg2": "Prijava",
	"logoutPageLoginLink": "Prijava",
	"newChangePassError": "Obavezan unos lozinke",
	"newPasswordLabel": "Nova lozinka",
	"pageHeader": "Administracija korisnika",
	"loginPageHeader": "Prijava u sustav",
	"password": "Lozinka",
	"passwordError": "Obavezan unos lozinke",
	"processingLabel": "Obrada u tijeku...",
	"resetPasswordLabel": "Promjena lozinke",
	"upsMsg": "Ups!",
	"username": "Korisničko ime",
	"usernameError": "Obavezan unos korisničkog imena",
    "cancelButtonlabel": "Odustani",
    "changePasswordButton": "Promjeni lozinku",
    "changePasswordHeader": "Promjena lozinke",
    "confirmNewPasswordLabel": "Potvrda nove lozinke",
    "confirmPassError": "Lozinke moraju biti iste",
    "copyButtonlabel": "Kopiraj poveznicu za reset lozinke",
    "copyMsg": "Kopirano!",
    "currentPassError": "Unos trenutne lozinke je obavezan",
    "currentPasswordLabel": "Trenutna lozinka",
    "editButtonLabel": "Izmjeni",
    "editUserHeader": "Podaci o korisniku",
    "email": "E-mail",
    "emailError": "Upišite e-mail",
    "firstNameError": "Upišite ime",
    "first_name": "Ime",
    "groups": "Grupe",
    "lastLoginLabel": "Datum zadnjeg logiranja",
    "lastNameError": "Upišite prezime",
    "last_name": "Prezime",
    "loadUsersMsg": "Učitavanje korisnika u tijeku...",
    "myDetailsPageHeader": "Moji korisnički podaci",
    "newPassError": "Unesite ispravnu lozinku (minimalno 8 znakova od kojih barem 1 broj, 1 malo, 1 veliko slovo i 1 specijalni znak)",
    "newPasswordLabel": "Nova lozinka",
    "newUserBtnLabel": "Novi korisnik",
    "newUserHeader": "Novi korisnik",
    "password": "Lozinka",
    "passwordError": "Upišite lozinku",
    "resetButtonlabel": "Reset lozinke",
    "saveButtonlabel": "Snimi promjene",
    "searchGroupsText": "Odaberite grupe...",
    "searchLabel": "Traži korisnike...",
    "superUserLabel": "Administrator",
    "userListGroupsHeader": "Grupe",
    "userListUsernameHeader": "Korisničko ime",
    "username": "Korisničko ime",
    "usernameError": "Upišite korisničko ime",
	"userAdvancedSettings": "Korisničke postavke",
	"save" : "Snimi",
	"addDataset": "Dodaj",
	"popover": {
			"newresource-page": "You don't have any pages! Create your 1st page!",
			"newresource-dataset": "You shoud create dataset if you want to store data in your application! ",
			"app.navbar.resources.new": "Click here to create new resource!",
			"widget-toolbox": "Drag and drop components from toolbox below",
			"add-action-button": "Click here to add action"
		}
	},
"en-GB": {
		"cancelButtonlabel": "Cancel",
		"changePasswordButton": "Change Password",
		"changePasswordHeader": "Change Password",
		"changePasswordType": "'internal'",
		"checkdRequestMsg": "Check in progress...",
		"confirmNewPasswordLabel": "Confirm New Password",
		"confirmPassError": "Passwords must match",
		"confirmPasswordError": "Passwords must be identical",
		"copyButtonlabel": "Copy Reset Password Link",
		"copyMsg": "Copied!",
		"currentPassError": "Current password is required",
		"currentPasswordLabel": "Current Password",
		"dataLoadingText": "Data loading, login in progress...",
		"editButtonLabel": "Edit",
		"editUserHeader": "User Information",
		"email": "E-mail",
		"emailError": "Enter an e-mail",
		"firstNameError": "Enter a first name",
		"first_name": "First Name",
		"gotoLoginLink": "Back to the login page",
		"gotoLoginText": "Back to",
		"groups": "Groups",
		"invalidRequestMsg": "Invalid request",
		"lastLoginLabel": "Last Login Date",
		"lastNameError": "Enter a last name",
		"last_name": "Last Name",
		"loadUsersMsg": "Loading users in progress...",
		"loadUsersMsg": "Loading users...",
		"loginButton": "Login",
		"loginErrorMsg": "Unsuccessful login attempt",
		"loginMsg": "Login in progress...",
		"logoutMsg1": "You are logged out. To log in, click ",
		"logoutMsg2": "Login",
		"logoutPageLoginLink": "Login",
		"myDetailsPageHeader": "My User Data",
		"newChangePassError": "Required entry of password",
		"newPassError": "Enter a valid password (at least 8 characters, including 1 number, 1 lowercase letter, 1 uppercase letter, and 1 special character)",
		"newPasswordLabel": "New Password",
		"newUserBtnLabel": "New User",
		"newUserHeader": "New User",
		"loginPageHeader": "Login to the system",
		"pageHeader": "User Administration",
		"password": "Password",
		"passwordError": "Required entry of password",
		"processingLabel": "Processing in progress...",
		"resetButtonlabel": "Reset Password",
		"resetPasswordLabel": "Change password",
		"saveButtonlabel": "Save Changes",
		"searchGroupsText": "Select groups...",
		"searchLabel": "Search users...",
		"searchResource": "Search resource...",
		"superUserLabel": "Administrator",
		"superUserLabel": "Super User",
		"upsMsg": "Oops!",
		"userListGroupsHeader": "Groups",
		"userListUsernameHeader": "Username",
		"username": "Username",
		"usernameError": "Required entry of username",
		"userAdvancedSettings": "Additional user properties",
		"save" : "Save",
		"applicationMenu": "Application menu",
		"addDataset": "Assign dataset",
		"popover": {
			"newresource-page": "You dont have any pages! Create your 1st page!",
			"newresource-dataset": "You shoud create dataset if you want to store data in your application! ",
			"app.navbar.resources.new": "Click here to create new resource!",
			"widget-toolbox": "Drag and drop components from toolbox below",
			"select-menu": "Select application menu by clicking here!",
			"select-default-page": "Default page is not selected. Please select it by clicking here!",
			"add-action-button": "Click here to add action"
		}
},
"config": {
		"homepagetemplate": "/0001_index/angularTemplates/app_home.html",
		"deploydir": "/builder",
		"ktlyo_logo_img":"/img/Katalyo_final_aqua.png",
		"community_img":"/img/katalyo_community.png",
		"menu_logo_img":"/img/Katalyo_final_aqua_white.png",
		"app_url":"?app_ref&tab&env"
	}
}