//**************************************************************************************************
//*************************************** utilityService - ES6 module ****************************
//**************************************************************************************************

'use strict';

class UtilityService {
   
    
  
   constructor ($window) {
    this.w = $window
    }

   uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
   }
   
   uuidNow() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16)+'-'+Date.now();
      });
   }
   
   
  clone(obj) {  // javascript object cloning (replacement for angular.copy)
    let instance = this
    if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj)
        return obj;

    if (obj instanceof Date)
        var temp = new obj.constructor(); //or new Date(obj);
    else
        var temp = obj.constructor();

    for (var key in obj) {
        if (key=='gsrvc') continue // skipping angular objects!!
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj['isActiveClone'] = null;
            temp[key] = instance.clone(obj[key]);
            delete obj['isActiveClone'];
        }
    }
    return temp;
   }

    subscribe_2_events(resource_id,me,events,skop,ksm) {  // called by the widgets that have events for processing
    
        for (let i=0;i<events.length;i++)
        {
            //subscribe to event
            ksm.subscribeWithParams(resource_id+'-'+events[i].EventWidgetId,me,events[i].EventName,skop[events[i].EventFn],events[i].actions,events[i].InputParams)
        }
    }
	
	
    check_condition(operator,val,res){

        let condition = false

        if (operator=='=' && res==val) condition=true 
        else if (operator=='>=' && res>=val) condition=true
        else if (operator=='<=' && res<=val) condition=true
        else if (operator=='>' && res>val) condition=true
        else if (operator=='<' && res<val) condition=true

        return condition
    }


     process_events = function(page_state_id,valueState,data,events,ksm)
     {
       for (let i=0;i<events.length;i++) {          
           for (let j=0;j<events[i].actions.length;j++) {   //execute actions
                    if (!events[i].actions[j].completed) {
						if (events[i].actions[j].DependsOn>0){
							//check if dependendent action is completed
							if (events[i].actions[events[i].actions[j].DependsOn-1].completed){

								let condition = JSON.parse(events[i].actions[j].Condition)

								let res,execute_action
								for (let k=0;k<condition.length;k++){
									res = events[i].actions[condition[k].action-1].result
									execute_action = this.check_condition(condition[k].operator,condition[k].result,res)
								}
								
								if (execute_action) ksm.executeAction(page_state_id+'-'+events[i].actions[j].WidgetId,events[i].actions[j].ActionName,{event_index:i,action_index:j,in_data:events[i].actions[j].InputParams})
							}

						} else {
							ksm.executeAction(page_state_id+'-'+events[i].actions[j].WidgetId,events[i].actions[j].ActionName,{event_index:i,action_index:j,in_data:events[i].actions[j].InputParams})
						}
                }
            }
        }
       
          valueState?.subject?.notifyObservers("processEvents") //notify
    }
	
	
	    getSortMethod = function() //universal sort - usage: array.sort( getSortMethod(['aprice', 'dpriority', 'dname']))  - begining with a(scending) or d(escending)
		{    
              var _args = arguments[0]
			  var _langCode = arguments?.[1] || 'en-GB'
              
			  return function(a, b){
                  for(var x of _args){
                       try {    
                          var ax,bx, cx ;
                          ax = a[x.substring(1)]      
                          bx = b[x.substring(1)]
                          if (ax==null || ax == undefined)    return 1  
                          if (bx==null || bx == undefined)    return -1
                          if (ax == bx) continue 	
			  }   catch (error) {
					 console.error(error)
				}
                      
                    if (x.substring(0,1) == "d") {cx = ax; ax = bx; bx = cx;} //switch when 'descending' sort
                            
                    if (typeof ax == "string") {
                         let res= ax.localeCompare(bx, _langCode, {sensitivity: 'base'})
						 //var langCode = $rootScope.globals?.currentUser?.langObj?.Code ?? 'en-GB'
                         return res     
                     }                                       
                     else return  ax < bx ? -1 : 1
                  }
              }  
         }
	
	
}//UTILITY

export default UtilityService;