'use strict';


export default function importDataCtrl ($cookies,uiUploader,$element,$scope, $rootScope, $location, $timeout,AuthenticationService,ProcessingService,MessagingService,GridsterService,ResourcesDataService,CodesService,$stateParams) {
            
		$scope.CTRLNAME='importDataCtrl';
        $scope.resourceId=$stateParams.resource_id;
        $scope.showGrid=true;
        
        uiUploader.createUploader(1);//MIGOR TODO  - ovo je bilo kod mene, ne znam da li si to izbacio ili ne treba više
        $scope.gsrvc = GridsterService
        $scope.gsrvc.resetBodyBgColor()   
    
        $scope.gridOptions = {
            enableGridMenu: true,
            showGridFooter: true,
            //showColumnFooter: true,
            enableRowSelection: true,
                multiSelect: false,
                enableHorizontalScrollbar : 0, 
                enableVerticalScrollbar : 2,
                //enableFiltering: true,
                enableRowHeaderSelection: true,
                enableColumnResizing: true,
            useExternalPagination: true,
            paginationPageSizes: [5, 10, 50,100,200,500,1000],
            enablePaginationControls: false,
        }
  /*   $scope.gridOptions.columnDefs = [    { name:'id', width:50 },    { name:'name' },  ];*/
  
     $scope.gridOptions.onRegisterApi = function (gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
          gridApi.selection.on.rowSelectionChanged($scope,function(row){
          
            $scope.isSelected=row.isSelected;
            //$scope.showGrid=false;
            $scope.selectedId = row.entity.id;
            //$scope.selectedTaskObject = row.entity;
          });
     }
     
	
		$scope.animationsEnabled = true;	 
		var lang = $rootScope.globals.currentUser.lang;
		var type="file"; 
		 var out_fields = ['resourceId'];
         var element = $element[0].getElementsByClassName('fileElement');
		 $scope.files = [];
	
		 uiUploader.removeAll(1); //MIGOR TEMP- TREBA nekako obrisati inicijalne fajlove kod reigstracija ali ovdje ne radi !!!

		  //GET MAAPINGLIST         
          ResourcesDataService.getMappingsList($scope.resourceId).then(function(data) {
                   //MessagingService.addMessage(data.msg,'success');
                    $scope.gridOptions.data = data.data;
                                      },function(error){
                                      MessagingService.addMessage(error.msg,'error');  
          });
            
            
          element[0].addEventListener('change', function(e) {
			$timeout(function() {
				  var files = e.target.files;
                  uiUploader.addFiles(files,1);				
                  $scope.files = uiUploader.getFiles(1);  
				  });
            });
				
            var fileCounter = 0;
			          
			$scope.btn_remove = function(index,file) {	
                    $log.info('deleting=' + file);
					var s=$scope;
					var f=formCtrl;
                     uiUploader.removeFile(file,1);
					 var err=$scope.testRules();
					
             };

             $scope.btn_clean = function() {
                    uiUploader.removeAll(1);
             };

			
			// FUNCTION - ON_SELECT_FILETYPE 
			// NAKON ŠTO SE ZA POJEDINAČNI FILE ODABERE FILE-TYPE  --> $scope.resourceId
					
			$scope.onSelectFileType = function (item,fileIndex){
						var selectedFileTypeId=item.id;
						$scope.resourceId=selectedFileTypeId;
						 $scope.isResourceSelected=true;
			             if ($scope.files[fileIndex].fileResourceID!=selectedFileTypeId) {
			              $scope.files[fileIndex].fileResourceID = selectedFileTypeId;
			              $scope.files[fileIndex].formLoaded = false;
			             }
					
						console.log("onSelectFileType :" + selectedFileTypeId);
		
						$scope.fileIndex=fileIndex;
								
				
			}//onSelectFileType
			
			
			$scope.importData =function (fileServerId) {
				
				var i=fileServerId;
						
			}


            //FAKE API - ovaj importresource dolje je samo za testiranje importa iz filea (vezan za TEST IMPORT button)
            $scope.importTest = function() {
                //debugger
                ResourcesDataService.importResource(117,     257,    17).then( // argumenti su file.serverId, $stateParams.resource_id, $scope.selectedId
                                                function(data) {
                                                                 $scope.listOfFileTypes = data.data;
                                                                 MessagingService.addMessage('Imported data frome file '+ data.data.fileId + " using mapping " + $scope.selectedId,'success');
                                                                     
                                                    },function(error){
                                                                MessagingService.addMessage(error.msg,'error');
                                                    
                                                });
            
            }
            
			//*******************************************************************************************************
			// 											UPLOAD AND IMPORT FILE
		$scope.btn_upload = function() {				
								
			var csrf_token = $cookies.get('csrftoken'); 
					
			// ZA svaki FAJL pojedinačno !
			uiUploader.startUpload(1,{  
						url:'api/file-upload/', 
						headers: {
									'X-CSRFToken' : csrf_token ,
                                    'Authorization': this.$root.globals.currentUser.authdata
								},
						concurrency: 3,
								       // u data punimo podatke vezane za fajl - fileType , ID resursa
						data: {resource_def_id:$scope.resourceId,resource_id:0}, //HARDCODIRAN RESOURCE_ID = 0 
						onProgress: function(file) {
									//$log.info(file.name + '=' + file.humanSize);
									 $timeout(function() { file.status='Uploading...'; });
								},
						
						onCompleted: function(file, response,status) {
								//$log.info(file + 'response' + response);
                                //debugger
								response=JSON.parse(response); //convertiram string u JSON objekt
                                
											
                                if (status==201) {         
                                            // NAKON ŠTO SMO USPJESNO UPLOADALI FILE POZIVAMO IMPORTRESOURCE ...
											file.serverId=response;
                                            MessagingService.addMessage('Uploaded file '+file.serverId,'success');
											$timeout(function() { file.uploadSuccess=true;  });														
											file.resourceData={};
                                            
                                            //import resource 
                                            ResourcesDataService.importResource(file.serverId,$stateParams.resource_id,$scope.selectedId).then(
                                                function(data) {
                                                                 $scope.listOfFileTypes = data.data;
                                                                 MessagingService.addMessage('Imported data frome file '+file.serverId + " using mapping " + $scope.selectedId,'success');
                                                                     
                                                    },function(error){
                                                                MessagingService.addMessage(error.msg,'error');
                                                    
                                                });
                                               	
											// PRIPREMA $scope.resourceData  ZA UPLOAD 
											if (file.dashboard!=undefined) {
												  var fieldId="";
												  var sc=$scope;
												 fileCounter++;
												// if ($scope.files.length == fileCounter){
												 //  $rootScope.$broadcast('InitiateTaskFilesUploaded',{'resource_id':$scope.linkedResourceId,'resource_def_id': $scope.linkedResourceDefId})
												 //}
											}
												

								}//if
								else { 
										//if file upload error
										$timeout(function() {
													 file.uploadError=true;
													 file.status=response;
													});					 
											}
								
							} //on_completed
						}) //startUpload
					
		}; // FUNCTION btn_upload
					 		 
		ResourcesDataService.getResourceDefByType(lang,type).then(
							function(data) {
											 $scope.listOfFileTypes = data.data;
												 
								},function(error){
											MessagingService.addMessage(error.msg,'error');
								
							});
							
	 /* ResourcesDataService.getResourceDef(lang).then(function(data) {
								 $scope.listOfResourceDefs = data.data;  
                                },function(error){
                                 MessagingService.addMessage(error.msg,'error');
                                
                             });*/
     
        
	  var lang = $rootScope.globals.currentUser.lang;
        CodesService.getCodesByName('resourcetype',lang).then(function (data) {
                                //debugger;
                                $scope.resourceTypeList = data.data;
                             });
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
        CodesService.getCodesByName('_RESOURCE_GROUP_',lang).then(function (data) {
                                $scope.resourceGroupList = data.data;
                             });
      
      $scope.newResource = function (isValid) {
           if (isValid) {
                $scope.SaveResourceDef();
            } else {
               MessagingService.addMessage("Save failed - please enter all required data!",'error');
                  
            }
        }
 
       
      
}//...importDataCtrl

export function datasetTransformCtrl ($scope, $rootScope, $location, $timeout,AuthenticationService,ProcessingService,MessagingService,GridsterService,ResourcesDataService,CodesService,UserService,dragularService) {
    
    $scope.CTRL='datasetTransformCtrl';
    $scope.datasetDict={}
	$scope.publishedForms={}
    
    $scope.gridOptions1 = {
        columnDefs: [
         { name: 'id', displayName: 'ID', type: 'number', width:'7%',enableHiding: false, visible: true },
         { name: 'name', displayName: 'Mapping name'},
         { name: 'dataset1name', displayName: 'Dataset 1' },
         { name: 'dataset2name', displayName: 'Dataset 2' },
         { name: 'mappingtype', displayName: 'Mapping type' },
         ],
          enableGridMenu: true,
            showGridFooter: false,
            showColumnFooter: true,
            enableRowSelection: true,
           multiSelect: false,
           enableHorizontalScrollbar : 2, 
          enableVerticalScrollbar : 2,
           enableFiltering: true,
           enableRowHeaderSelection: true,
           enableColumnResizing: true,
         /*   paginationPageSizes: [5, 10, 20, 50, 100],   paginationPageSize: 5,   enableSelectAll: false,   enableCellEdit: false*/  
       }
   
    $scope.gridOptions2 = {
        columnDefs: [
         { name: 'id', displayName: 'ID', type: 'number', width:'7%',enableHiding: false, visible: true },
         { name: 'transform_name', displayName: 'Transform name'},
         { name: 'name', displayName: 'Transform label'},
         { name: 'field_type1.name', displayName: 'Field 1 type' },
         { name: 'field_type2.name', displayName: 'Field 2 type ' },
         ],
        enableGridMenu: true,
        showGridFooter: false,
        showColumnFooter: true,
        enableRowSelection: true,
       multiSelect: false,
       enableHorizontalScrollbar : 2, 
      enableVerticalScrollbar : 2,
       enableFiltering: true,
       enableRowHeaderSelection: true,
       enableColumnResizing: true,
     /*  paginationPageSizes: [5, 10, 20, 50, 100],         paginationPageSize: 5,       enableSelectAll: false,       enableCellEdit: false*/  
   }
   
   $scope.gsrvc = GridsterService
   $scope.gsrvc.resetBodyBgColor()   
    
   $scope.removeItem=  (pindex,index) => $scope.dataset2Fields[pindex].fields.splice(index,1);
   $scope.removeItemF2=  (pindex,index) => $scope.dataset2Fields[pindex].fields2.splice(index,1);
       
   var drake;
   
     $scope.setupDragular = function(dragularContainerModels,dragularContainerClasses,copy,namespaces){
                                      $timeout(function(){
                                          var containers=[];
                                          
                                         for (var i =0;i<dragularContainerClasses.length;i++){
                                            var container = document.getElementsByClassName(dragularContainerClasses[i]);
                                            containers.push(container[0]);
                                         }
                                         
                                         drake = dragularService(containers,
                                           {
                                              
                                               namespace:namespaces,
                                               revertOnSpill: false,
                                               copy: copy,
                                               containersModel: dragularContainerModels,                                            
                                               moves: function (el, container, handle) {
                                                   return handle.classList.contains("element-drag-handle");
                                                 }
                                            });
                                          });               
                            }
                            
    $scope.setupResourcesDragular = function (resourceid,type) {
		// get FIELDS for dataset1
		ResourcesDataService.GetPublishedResourceById(resourceid).then(
				function (data) {
					//debugger
					 if (type==1){ // ==1 (DATASET1)
                                  $scope.dataset1Fields = data.data.Definition;
                                  $scope.setupDragular([$scope.dataset1Fields],['dataset1Container'],true,'dataset1');    
                     }  else {  //  <>1 ( DATASET2)
                                  $scope.dataset2Fields = data.data.Definition;
                                  var dragularContainerClasses=[];
                                  var dragularContainerModels = [];    
                                  for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                      if ($scope.dataset2Fields[i].fields==undefined) $scope.dataset2Fields[i]['fields'] = [];
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);         
                                  }
                                    $scope.setupDragular(dragularContainerModels,dragularContainerClasses,false,'dataset2');
             
                        } 
                             
                },function(error){    
                            MessagingService.addMessage(error.msg,'error');
         })
					
        } 
        
		
		$scope.GetPublishedResourceById = function(dataset1or2Fields, res) 
		{
			var res
			if (dataset1or2Fields == 'dataset1Fields')  res = $scope.mappingFields['dataset1']?.version
			if (dataset1or2Fields == 'dataset2Fields')  res = $scope.mappingFields['dataset2']?.version
			
         if (res && res.length>0) 
			 ResourcesDataService.GetPublishedResourceById(res.id).then(
				function (data) {
					if (dataset1or2Fields=='dataset1Fields') {
						 $scope['dataset1Fields'] = data.data.Definition; 
						 $scope.setupDragular([$scope['dataset1Fields']],['dataset1Container'],true,'dataset1'); 
					}
					if (dataset1or2Fields=='dataset2Fields') {
						  $scope['dataset2Fields'] = data.data.Definition;
                          var dragularContainerClasses = [];
                          var dragularContainerModels = [];    
                           for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                      if ($scope.dataset2Fields[i].fields==undefined) $scope.dataset2Fields[i]['fields'] = [];
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);         
                           }
                          $scope.setupDragular(dragularContainerModels,dragularContainerClasses,false,'dataset2');
						  //$scope.setupDragular([$scope['dataset2Fields']],['dataset2Container'],true,'dataset2');
					 }	 
                },function(error){    
                            MessagingService.addMessage(error.msg,'error');
                    });
		}	
    $scope.getTransformFields = function (transformId,field1,field2) {
		//get FIELDS for transform
         ResourcesDataService.getTransformFields(transformId,field1,field2).then(function(data) {
			 //debugger
                               if (type==1){
                                  $scope.dataset1Fields = data.data;
                                  $scope.setupDragular([$scope.dataset1Fields],['dataset1Container']);  
                              } else{
                                  $scope.dataset2Fields = data.data;
                                  var dragularContainerClasses=[];
                                  var dragularContainerModels = [];    
                                  for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);         
                                  }
                                  $scope.setupDragular(dragularContainerModels,dragularContainerClasses);
                                } 
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };
        
    $scope.gridOptions1.onRegisterApi = function (gridApi) {
        $scope.gridApi = gridApi;
		
        gridApi.selection.on.rowSelectionChanged($scope,function(row)
		{
            $scope.resourceSelected=row.isSelected;
            $scope.selectedResource = row.entity.id;
            if (drake!=undefined)  drake.destroy();
            if ($scope.dataset1Fields!=undefined) $scope.dataset1Fields.length=0;
            if ($scope.dataset2Fields!=undefined) $scope.dataset2Fields.length=0;
            
            if ($scope.resourceSelected)  {
				
				   ResourcesDataService.GetPublishedResourcesByTypeId(row.entity.dataset1uid ,'form')
				   .then(function (data) {       
						$scope.publishedForms['dataset1'] = data.data;
						return ResourcesDataService.GetPublishedResourcesByTypeId(row.entity.dataset2uid ,'form')
					})
				   .then(function (data) { //  Puni mapping strukturu za API poziv
					   
					   
					   $scope.tmpMappingFields={}
					   $scope.publishedForms['dataset2'] = data.data;
					   
					   $scope.setupResourcesDragular(row.entity.dataset1,1);
					   $scope.getDatasetMappings(row.entity.id)

					   $scope.tmpMappingFields.mappingName= row.entity.name;  
					   $scope.tmpMappingFields.mappingDisplayName = row.entity.displayname
					   $scope.tmpMappingFields.dataset1= {id:row.entity.dataset1uid,  name: row.entity.dataset1name};
					   $scope.tmpMappingFields.dataset1.version = { id: row.entity.dataset1 , ResourceDefUid: row.entity.dataset1uid  }
					    
					   $scope.tmpMappingFields.dataset2= {id:row.entity.dataset2uid, name: row.entity.dataset2name};
					   $scope.tmpMappingFields.dataset2.version = { id:row.entity.dataset2 , ResourceDefUid: row.entity.dataset2uid }
					   
					   $scope.tmpMappingFields.mappingType = { id: $scope.map2codeDict[row.entity.mappingtype] }
					   $scope.mappingFields =  $scope.tmpMappingFields
						
				   }).catch( function(error) {
					    MessagingService.addMessage(error.msg,'error');
				   })
            }
            else  {
              $scope.selectedDataset1 = { };
              $scope.selectedDataset2 = { }; 
            }
  
          });
     }
     
   $scope.gridOptions2.onRegisterApi = function (gridApi) {

        $scope.gridApi2 = gridApi;
        
          gridApi.selection.on.rowSelectionChanged(
			
			$scope,function(row) {

				
			
				 $scope.transformSelected=row.isSelected;
				$scope.selectedTransform = row.entity.id;
				
				if ($scope.transformPackage!=undefined) $scope.transformPackage.length=0;
				
				if ($scope.transformSelected) {                
				  $scope.getTransformFields(row.entity.id); 
					//fix this with correct fields ToDo
					//debugger
				  $scope.selectedDataset1 = {id:row.entity.field_type1,name: row.entity.field_type1};
				  $scope.selectedDataset2 = {id:row.entity.field_type2,name: row.entity.field_type2};
					
				  $scope.tmpTransformPackage={}
				  $scope.tmpTransformPackage.fieldType1=$scope.selectedDataset1;
				  $scope.tmpTransformPackage.fieldType2=$scope.selectedDataset2;
				  $scope.tmpTransformPackage.transformDisplayName=row.entity.name
				  $scope.tmpTransformPackage.transformName=row.entity.transform_name; 
				 console.log($scope.tmpTransformPackage); 


				 
				 }
          });
     }
   
   $scope.getMappingPackages = function () {
    
         var lang = $rootScope.globals.currentUser.lang;        
         ResourcesDataService.getMappingPackages(0,0,lang).then(function(data) { 
                             
                for (let mapItem of data.data) {   //append DatasetNames to mappings data        
                       if ($scope.datasetDict[mapItem.dataset1])   mapItem.dataset1name = $scope.datasetDict[mapItem.dataset1]        
                       if ($scope.datasetDict[mapItem.dataset2])  mapItem.dataset2name = $scope.datasetDict[mapItem.dataset2] 
                 }
            
                   $scope.gridOptions1.data = data.data;
                               
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };  
    
    $scope.getDatasetMappings = function (datasetId) {
    
         var lang = $rootScope.globals.currentUser.lang;
         
         ResourcesDataService.getDatasetMappings(datasetId,lang).then(
					function(data) {  
                             $scope.dataset2Fields = data.data;
                             //treba dragulirati
                              var dragularContainerClasses=[];
                              var dragularContainerModels = [];    
                              for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields2);
                                     dragularContainerClasses.push('exampleRowFields2'+i);  
                               }
                                  
                              $scope.setupDragular(dragularContainerModels,dragularContainerClasses);
               
                   },function(error){ 
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };  
    
    $scope.getTransformFields = function (transformId) {
    
         var lang = $rootScope.globals.currentUser.lang;
         ResourcesDataService.getTransformFields(transformId).then(
				function(data) {      
                             $scope.transformFields = data.data;       
                },function(error){
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };  
    
    
    // POPUNI LISTU DEFINICIJA OD RESURSA (RESOURCES LIST)
	$scope.getDatasets = function()  {
			var lang=$rootScope.globals.currentUser.lang;
            ResourcesDataService.getResourceDefListByType(lang,'dataset').then(
					function(data) {			
							$scope.datasetList = data.data;
                            for (let item of $scope.datasetList)  
                                        $scope.datasetDict[item.id] = item.name // create dictionary from datasetList
					},function(error){    
                                MessagingService.addMessage(error.msg,'error');
										 });
	}
    
     $scope.showMappingData = function (selectedResource) {
         if (selectedResource==false || selectedResource==undefined) 
          {
             $scope.mappingFields={};
             $scope.saveDatasetMappingHeadStatus='createMapping'
          } else   {
              $scope.saveDatasetMappingHeadStatus='updateMapping'
              $scope.mappingFields= $scope.tmpMappingFields;
          }
          
     };
          
     $scope.showTransformData = function (selectedResource) {
         
         if (selectedResource)  {
              $scope.saveDatasetTransformHeadStatus='updateTransform'
              $scope.transformPackage= $scope.tmpTransformPackage;
          }  else 
          {  //create new
             $scope.transformPackage={};
             $scope.saveDatasetTransformHeadStatus='createTransform'
          }
         
          
        // $scope.getDatasets();//puni datasetList
         CodesService.getCodesByName('euiu',lang).then(function (data) {
                                $scope.transformList = data.data;           
                             }); 
     };				  
    
    
     $scope.addTransformField = function () {
      
       $scope.transformFields.push({id:null,TransformationHeadId:{FieldType1:{Name:'text'},FieldType2:{Name:'user'}}})
     };
     
     
       $scope.saveTransformFields = function () { 
			ResourcesDataService.saveTransformFields($scope.transformFields,$scope.selectedTransform).then(
				function(data) {
                         $scope.transformFields = data.data.data;      
                         MessagingService.addMessage(data.data.msg,'success');     
                  },function(error){   
                              MessagingService.addMessage(error.data.msg,'error');  
            });
        };
        
      $scope.saveDatasetMappingDetails = function () {
         
         ResourcesDataService.saveDatasetMappingDetails($scope.dataset2Fields,$scope.selectedResource).then(
				function(data) {       
                         MessagingService.addMessage(data.data.msg,'success');  
                },function(error){
                              MessagingService.addMessage(error.data.msg,'error');  
                            });
        };  
        
        
       $scope.saveDatasetMappingHead = function (selectedResource) {//ulaz: $scope.mappingFields,$scope.selectedResource)
       
         ResourcesDataService.saveDatasetMappingHead($scope.mappingFields,selectedResource,lang).then(
				function(data) {  
						  $scope.getMappingPackages();
                         MessagingService.addMessage(data.data.msg,'success');
						 //debugger
						 $scope.selectedResource=data.data.data
						 //$scope.selectedResource=null
						 $scope.resourceSelected=false
                         $scope.saveDatasetMappingHeadStatus='CancelOrInit'
                 },function(error){
                              MessagingService.addMessage(error.msg,'error');  
                              $scope.saveDatasetMappingHeadStatus='CancelOrInit'
                            });
                            
          $scope.getMappingPackages();
        };  
        
        $scope.cancelMapping = function () {
            $scope.saveDatasetMappingHeadStatus='CancelOrInit';
            console.log($scope.saveDatasetMappingHeadStatus);
         }
         
        $scope.cancelTransform  = function () {
            $scope.saveDatasetTransformHeadStatus='CancelOrInit';
            console.log($scope.saveDatasetTransformHeadStatus);
         } 

         
        $scope.saveDatasetTransformHead = function (selectedResource) {
         
			ResourcesDataService.saveDatasetTransformHead($scope.transformPackage,selectedResource,lang).then(
					function(data) {                      
                         MessagingService.addMessage(data.data.msg,'success');  
                         $scope.saveDatasetTransformHeadStatus='CancelOrInit'
                   },function(error){
                              MessagingService.addMessage(error.msg,'error');  
                              $scope.saveDatasetTransformHeadStatus='CancelOrInit'
                            });
                            
          $scope.getTransformPackages();
        };  
        

         
    
        $scope.getTransformPackages = function () {
    
         var lang = $rootScope.globals.currentUser.lang;
         
         ResourcesDataService.getTransformPackages(lang).then(
				function(data) {
                              $scope.gridOptions2.data = data.data;  
                  },function(error){      
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };  
        
        
       $scope.getUsers = function () {
              UserService.getUsersGroups('users').then(function(data){
                  $scope.userList = data.data;                 
                 });
        }
         
         
         $scope.getCodesDetails = function(codeId)// TODO zamjeniti ostale getCodesDetails sa ovim jednostavnim
		 {
              $scope.codesDetails=[]; 
               CodesService.getCodesDetails(codeId,lang).then(function(data) {
                                   $scope.codesDetails = data.data;  
                              },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                            });
          }
          
		  // MIGOR - Added versioning
	    $scope.onNewMappingSelectCallback=function(item, selectedDataset)
        {
		   ResourcesDataService.GetPublishedResourcesByTypeId(item.id,'form').then(
					function (data) {       
						$scope.publishedForms[selectedDataset] = data.data;
               },function(error){    
                    MessagingService.addMessage(error.msg,'error');
                });
		}
		

          //  SELECT FIELDS CALLBACKS
          $scope.onSelectField1 = function (item){
              
                    $scope.transformPackage.dataset1=null
                    $scope.transformPackage.dataset1Field=null
                  	$scope.selectedField1=item      
              }

          $scope.onSelectField2 = function (item){
             
                    $scope.transformPackage.dataset2=null
                    $scope.transformPackage.dataset2Field=null;
                  	$scope.selectedField2=item   

              }
                 
          //   TRANSFORM CALLBACKS
          $scope.onSelectDataset1= function (item){
                    ResourcesDataService.getResourceFields(item.id,lang).then(function(data) {               
                               $scope.field1resources = data.data;
                                },function(error){
                              MessagingService.addMessage(error.msg,'error'); 
                    });                              
          }

          $scope.onSelectDataset2 = function (item){
                ResourcesDataService.getResourceFields(item.id,lang).then(function(data) {               
                             $scope.field2resources = data.data;
                                },function(error){ 
                              MessagingService.addMessage(error.msg,'error');  
                            });              
           }
      
      //****************  inicijalizacija ********************
      
   
            
      var lang=$rootScope.globals.currentUser.lang;
      if ($scope.mappingFields===undefined) 
				$scope.mappingFields={ dataset1: {}, dataset2: {} };
      if ($scope.transformPackage===undefined) 
			$scope.transformPackage={ Params:{} };
      
      $scope.getDatasets();//puni datasetList
     
      $scope.getUsers();
	  
	  $scope.map2codeDict ={}
      CodesService.getCodesByName('dmt',lang).then(function (data) {
             $scope.codeList = data.data;
			 data.data.forEach( (item,index) =>
				 $scope.map2codeDict[item.value] = item.id
				 
			 )
      }); 
      $scope.getCodesDetails(69);//69 je id od data_transform kodova - ne može ovako, jer id ovisi o serveru. Treba by code name

      $scope.saveDatasetMappingHeadStatus='CancelOrInit';
      $scope.saveDatasetTransformHeadStatus='CancelOrInit';
      $scope.getMappingPackages();
      $scope.getTransformPackages();
      
    }
    
    
 //////// KRAJ DATASETTRANSFORM CTRL ///////////


 export function mappingDefinitionCtrl ($scope, $rootScope, $location, $state,$timeout,AuthenticationService,ProcessingService,MessagingService,GridsterService,ResourcesDataService,CodesService,UserService,dragularService) {
    
    $scope.CTRL='mappingDefinitionCtrl';
    $scope.datasetDict={}
    $scope.publishedForms={}
    
   $scope.gsrvc = GridsterService
   $scope.gsrvc.resetBodyBgColor()   
    
   $scope.removeItem=  (pindex,index) => $scope.dataset2Fields[pindex].fields.splice(index,1);
   $scope.removeItemF2=  (pindex,index) => $scope.dataset2Fields[pindex].fields2.splice(index,1);
       
   var drake;
   $scope.resourceId = $state.params?.id
    $scope.getResourceAll = function () {
            
            $scope.loadingResource=true
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                             $scope.loadingResource=false 
                             // MessagingService.addMessage(data.msg,'success');
                             $scope.resource = data.data;
                             $scope.resource.resourceDefDownloaded = true
                             $scope.gsrvc.setResourceFormVersion($scope.resource.uid,$scope.resource) 
                             $scope.mappHead = $scope.resource.ResourceExtended?.MappingHead
                             if ($scope.resource.ResourceExtended?.MappingId !=undefined) $scope.getResourceFields()
                               
                                },function(error){
                               $scope.loadingResource=false 
                              MessagingService.addMessage(error.msg,'error');  
            });
    };      
     $scope.setResourceVersion = function(type){
        if (type==1)
        {
            let pos = $scope.publishedForms.dataset1.map(function(e) { return e.id.toString(); }).indexOf($scope.mappHead?.Dataset1.toString());
            if (pos>=0) {
                    $scope.mappingFields.dataset1.version = $scope.publishedForms.dataset1[pos]
            }
                     
          
        }else{

            let pos2 = $scope.publishedForms.dataset2.map(function(e) { return e.id.toString(); }).indexOf($scope.mappHead?.Dataset2.toString());
            if (pos2>=0) {
                    $scope.mappingFields.dataset2.version = $scope.publishedForms.dataset2[pos2]
            }
        }
    }          
    $scope.getResourceFields=function(){

        $scope.mappingFields.dataset1.version={}
        $scope.mappingFields.dataset2.version={}
    
    ResourcesDataService.GetPublishedResourcesByTypeId($scope.mappHead.Dataset1uid ,'form')
                   .then(function (data) {       
                        $scope.publishedForms['dataset1'] = data.data;
                        return ResourcesDataService.GetPublishedResourcesByTypeId($scope.mappHead.Dataset2uid ,'form')
                    })
                   .then(function (data) { //  Puni mapping strukturu za API poziv
                       
                       
                       $scope.tmpMappingFields={}
                       $scope.publishedForms['dataset2'] = data.data;
                       
                       $scope.setupResourcesDragular($scope.mappHead.Dataset1,1);
                       $scope.getDatasetMappings($scope.mappHead.id)
                      
                       $scope.tmpMappingFields.dataset1= {id:$scope.mappHead.Dataset1uid,  name: $scope.mappHead.Dataset1Name};
                       $scope.tmpMappingFields.dataset2= {id:$scope.mappHead.Dataset2uid, name: $scope.mappHead.Dataset2Name};
                       $scope.mappingFields =  $scope.tmpMappingFields
                       $scope.setResourceVersion(1)
                       $scope.setResourceVersion(2)
    
                       
                   }).catch( function(error) {
                        MessagingService.addMessage(error.msg,'error');
                   })
    
    }
    $scope.setupDragular = function(dragularContainerModels,dragularContainerClasses,copy,namespaces){
                                      $timeout(function(){
                                          var containers=[];
                                          
                                         for (var i =0;i<dragularContainerClasses.length;i++){
                                            var container = document.getElementsByClassName(dragularContainerClasses[i]);
                                            containers.push(container[0]);
                                         }
                                         
                                         drake = dragularService(containers,
                                           {
                                              
                                               namespace:namespaces,
                                               revertOnSpill: false,
                                               copy: copy,
                                               containersModel: dragularContainerModels,                                            
                                               moves: function (el, container, handle) {
                                                   return handle.classList.contains("element-drag-handle");
                                                 }
                                            });
                                          });               
                            }
    
                 
    $scope.setupResourcesDragular = function (resourceid,type) {
        // get FIELDS for dataset1
        ResourcesDataService.GetPublishedResourceById(resourceid).then(
                function (data) {
                    //debugger
                     if (type==1){ // ==1 (DATASET1)
                                  $scope.dataset1Fields = data.data.Definition;
                                   
                                  $scope.setupDragular([$scope.dataset1Fields],['dataset1Container'],true,'dataset1');    
                     }  else {  //  <>1 ( DATASET2)
                                  $scope.dataset2Fields = data.data.Definition;
                                  var dragularContainerClasses=[];
                                  var dragularContainerModels = [];    
                                  for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                      if ($scope.dataset2Fields[i].fields==undefined) $scope.dataset2Fields[i]['fields'] = [];
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);         
                                  }
                                  
                                   
                                  $scope.setupDragular(dragularContainerModels,dragularContainerClasses,false,'dataset2');
             
                        } 
                             
                },function(error){    
                            MessagingService.addMessage(error.msg,'error');
         })
                    
        } 
        
        
        $scope.GetPublishedResourceById = function(dataset1or2Fields, res) 
        {
            var res
            if (dataset1or2Fields == 'dataset1Fields')  res = $scope.mappingFields['dataset1']?.version
            if (dataset1or2Fields == 'dataset2Fields')  res = $scope.mappingFields['dataset2']?.version
            
         if (res.id && $scope.mappHead.id) 
             ResourcesDataService.GetPublishedResourceById(res.id).then(
                function (data) {
                    if (dataset1or2Fields=='dataset1Fields') {
                         $scope['dataset1Fields'] = data.data.Definition; 
                         $scope.setupDragular([$scope['dataset1Fields']],['dataset1Container'],true,'dataset1'); 
                    }
                    if (dataset1or2Fields=='dataset2Fields') {
                          $scope['dataset2Fields'] = data.data.Definition;
                          var dragularContainerClasses = [];
                          var dragularContainerModels = [];    
                           for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                      if ($scope.dataset2Fields[i].fields==undefined) $scope.dataset2Fields[i]['fields'] = [];
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);         
                           }
                          $scope.setupDragular(dragularContainerModels,dragularContainerClasses,false,'dataset2');
                          //$scope.setupDragular([$scope['dataset2Fields']],['dataset2Container'],true,'dataset2');
                     }   
                },function(error){    
                            MessagingService.addMessage(error.msg,'error');
                    });
        }   
    $scope.onChangeResourceVersion = function(dataset1or2) 
    {
        if ($scope[dataset1or2]) $scope[dataset1or2].length=0
    }   
    $scope.getDatasetMappings = function (datasetId) {
    
         var lang = $rootScope.globals.currentUser.lang;
         
         ResourcesDataService.getDatasetMappings(datasetId,lang).then(
                    function(data) {  
                             $scope.dataset2Fields = data.data;
                             //treba dragulirati
                              var dragularContainerClasses=[];
                              var dragularContainerModels = [];    
                              for(var i=0;i<$scope.dataset2Fields.length;i++) {
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields);
                                     dragularContainerClasses.push('exampleRow'+i);
                                     dragularContainerModels.push($scope.dataset2Fields[i].fields2);
                                     dragularContainerClasses.push('exampleRowFields2'+i);  
                               }
                                  
                              $scope.setupDragular(dragularContainerModels,dragularContainerClasses);
               
                   },function(error){ 
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };  
    
    // POPUNI LISTU DEFINICIJA OD RESURSA (RESOURCES LIST)
    $scope.getDatasets = function()  {
            var lang=$rootScope.globals.currentUser.lang;
            ResourcesDataService.getResourceDefListByType(lang,'dataset').then(
                    function(data) {            
                            $scope.datasetList = data.data;
                            for (let item of $scope.datasetList)  
                                        $scope.datasetDict[item.id] = item.name // create dictionary from datasetList
                    },function(error){    
                                MessagingService.addMessage(error.msg,'error');
                                         });
    }
    
    $scope.saveDatasetMappingHead = function () {//ulaz: $scope.mappingFields,$scope.selectedResource)
        let mappId
        if ($scope.mappHead?.id==undefined) mappId = 0
        else mappId = $scope.mappHead.id
            $scope.mappingFields.id=$scope.resourceId
        $scope.savingHead = true
        ResourcesDataService.saveDatasetMappingHead($scope.mappingFields,mappId,lang).then(
                function(data) {  
                        $scope.savingHead=false 
                        MessagingService.addMessage(data.data.msg,'success');
                          
                        $scope.resource.ResourceExtended = data.data.data
                        if ($scope.resource.ResourceExtended.Version!=undefined) 
                        {
                            $scope.resource.Version = $scope.resource.ResourceExtended.Version
                            if ($scope.resource.ResourceExtended.Published!=undefined) $scope.resource.Published = $scope.resource.ResourceExtended.Published
                            $scope.gsrvc.setResourceFormVersion($scope.resource.uid,$scope.resource)
                        }
                        $scope.mappHead = $scope.resource.ResourceExtended?.MappingHead 
                        $scope.getResourceFields()
                        
                         
                 },function(error){
                            $scope.savingHead=false
                            MessagingService.addMessage(error.msg,'error');  
                            
                            });
                            
    };  

    $scope.saveDatasetMappingDetails = function () {
         
         ResourcesDataService.saveDatasetMappingDetails($scope.dataset2Fields,$scope.mappHead?.id).then(
                function(data) {       
                         MessagingService.addMessage(data.data.msg,'success');  
                },function(error){
                              MessagingService.addMessage(error.data.msg,'error');  
                            });
        };  
        
    
        
        $scope.cancelMapping = function () {
            $scope.saveDatasetMappingHeadStatus='CancelOrInit';
            console.log($scope.saveDatasetMappingHeadStatus);
         }
 
          
          // MIGOR - Added versioning
        $scope.onNewMappingSelectCallback=function(item, selectedDataset)
        {
            $scope.getPublishedResources(item.id,selectedDataset)
            if ($scope.dataset1Fields!=undefined) $scope.dataset1Fields.length=0;
            if ($scope.dataset2Fields!=undefined) $scope.dataset2Fields.length=0;
        }
        
        $scope.getPublishedResources=function(itemId,selectedDataset){

             ResourcesDataService.GetPublishedResourcesByTypeId(itemId,'form').then(
                    function (data) {       
                        $scope.publishedForms[selectedDataset] = data.data;
                        if (selectedDataset=='dataset1') $scope.setResourceVersion(1)
                        else $scope.setResourceVersion(2)

               },function(error){    
                    MessagingService.addMessage(error.msg,'error');
                });
        }
          //  SELECT FIELDS CALLBACKS
          $scope.onSelectField1 = function (item){
              
                    $scope.transformPackage.dataset1=null
                    $scope.transformPackage.dataset1Field=null
                    $scope.selectedField1=item      
              }

          $scope.onSelectField2 = function (item){
             
                    $scope.transformPackage.dataset2=null
                    $scope.transformPackage.dataset2Field=null;
                    $scope.selectedField2=item   

              }
                 
          //   TRANSFORM CALLBACKS
          $scope.onSelectDataset1= function (item){
                    ResourcesDataService.getResourceFields(item.id,lang).then(function(data) {               
                               $scope.field1resources = data.data;
                                },function(error){
                              MessagingService.addMessage(error.msg,'error'); 
                    });                              
          }

          $scope.onSelectDataset2 = function (item){
                ResourcesDataService.getResourceFields(item.id,lang).then(function(data) {               
                             $scope.field2resources = data.data;
                                },function(error){ 
                              MessagingService.addMessage(error.msg,'error');  
                            });              
           }
      
      //****************  inicijalizacija ********************
      
   
            
      var lang=$rootScope.globals.currentUser.lang;
      if ($scope.mappingFields===undefined) 
                $scope.mappingFields={ dataset1: {}, dataset2: {} };
      if ($scope.transformPackage===undefined) 
            $scope.transformPackage={ Params:{} };
      
      $scope.getDatasets();//puni datasetList

      
      $scope.map2codeDict ={}
      CodesService.getCodesByName('dmt',lang).then(function (data) {
             $scope.codeList = data.data;
             data.data.forEach( (item,index) =>
                 $scope.map2codeDict[item.value] = item.id
                 
             )
      }); 
      $scope.returnToPreviousPage = function()
      {
                let prev_state="";
                if ($state.params.prev_state!="" && $state.params.prev_state!=undefined) prev_state = $state.params.prev_state;
                else prev_state = "app.navbar.home";
                
                $state.go(prev_state);
      }
      //$scope.getCodesDetails(69);//69 je id od data_transform kodova - ne može ovako, jer id ovisi o serveru. Treba by code name

      $scope.saveDatasetMappingHeadStatus='CancelOrInit';
      $scope.saveDatasetTransformHeadStatus='CancelOrInit';
     // $scope.getMappingPackages();
     $scope.getResourceAll()
      
    }
    
    
 //////// END mapping definition CTRL ///////////
    
export function newResourceCtrl ($scope, $rootScope, $location, $timeout,AuthenticationService,ProcessingService,MessagingService,GridsterService,ResourcesDataService,CodesService,MenuService,$stateParams) {
 
      $scope.CTRL='newResourceCtrl';
      $scope.selectedItems=[];
      $scope.resource={};
      $scope.resource_type_filter=$stateParams.resource_type;
      $scope.saveSuccess2 = false
	  $scope.rootscope = $rootScope
      
        var lang = $rootScope.globals.currentUser.lang;
        $scope.selectedResourceType={Value:false, HideBack:false};
        
        $scope.gsrvc = GridsterService
        $scope.menu_service = MenuService
        $scope.menu_service.clearSideMenu()
        $scope.gsrvc.resetBodyBgColor()   
       
        $scope.saveResourceDef = function (isValid) 
        { 
          if (isValid) {
             ResourcesDataService.SaveResourceDef2($scope.resource)
            .then(          
                function(data) {           
                    if (data.status===200  || data.status===201 ) {
                        MessagingService.addMessage("Resource saved successfuly",'success'); 
                        $scope.saveSuccess2 = true
                    }                        
                }
               ,function(error){
                    MessagingService.addMessage(error.msg,'error'); 
                    $scope.saveSuccess2   = false                 
           });    
          } else {          
            MessagingService.addMessage("Please enter required data",'warning'); 
          }
        };
        
        
     
        ResourcesDataService.GetResourceParams().then(function (data) {               
                                $scope.resourceTypeList = data.data;
                                if ($scope.resourceStatusList!=undefined) $scope.filterResources();
        });
        
        
        CodesService.getCodesByName('codestatus',lang)
            .then(function (data) {
                     $scope.resourceStatusList = data.data;
                     // Setting resource to ACTIVE state ==	{id: 17, value: 'a', name: 'Active', active: true, params: null}
                     var pos = $scope.resourceStatusList.map(function(e) { return e.value; }).indexOf("a");
                     if (pos>=0) {
                     
                          $scope.resource.Status = $scope.resourceStatusList[pos];
                       }
                     if ($scope.resourceTypeList!=undefined) $scope.filterResources();
           });
        
         CodesService.getCodesByName('_RESOURCE_GROUP_',lang).then(function (data) {
                                $scope.resourceGroupList = data.data;
            });
                             

      
        $scope.resourceTypeSelected = function (resourceType) {
          
            var pos = $scope.resourceTypeList.map(function(e) { return e.ResourceCode; }).indexOf(resourceType);
            if (pos>=0) {
              //debugger //newResourceCtrl
              $scope.resource.ResourceType= $scope.resourceTypeList[pos];
              $scope.resource.Parameters={};
              $scope.selectedResourceType.Value=true;
              
           
              if ($scope.resource.ResourceType.ResourceCode == 'page')
                    $scope.resource.Parameters.navbar= true //show Application menu on pages
              
              if (resourceType != 'dataset') 
                  $scope.subTypeSelected = true;// skip submenu for other types (not datasets)
              else   {
				 // $scope.subTypeSelected = false;
				   $scope.subTypeSelected = true;// TODO - temporary skip dataset submenu - allowing only 1 type of datasets
				   $scope.resourcesubTypeSelected('standard');// TODO - temporary skip dataset submenu - allowing only 1 type of datasets
			  }
            }
           
        };
      
      
      
         $scope.resourcesubTypeSelected = function (resourceSubType) {
 
           $scope.subTypeSelected = resourceSubType
           $scope.resource.ResourceSubType = resourceSubType
           if (resourceSubType=='standard') {
                $scope.resource.Parameters.extDataSourceType = {id:1,name: "Database Table"}
           }
              
        };
        
        
        
        if ($scope.resource.Parameters ==undefined ||  $scope.resource.Parameters==null)
                {
                 $scope.resource.Parameters = {extDataSourceParams:{formFieldsList:[]} };      
                } else
                {
                   if ($scope.resource.Parameters.extDataSourceParams == undefined || value == false)  { 
                        $scope.resource.Parameters.extDataSourceParams={ formFieldsList:[] };                 
                  } else  $scope.resource.Parameters.extDataSourceParams.formFieldsList.length=0;      
                }  
        
        
      $scope.newResource = function (isValid) {
          
           if (isValid) {
                $scope.SaveResourceDef();
            } else {
               MessagingService.addMessage("Save failed - please enter all required data!",'error');     
            }
        };
        
         $scope.setImmutable = function () {
          
             if (!$scope.saveSuccess) $scope.resource.IsBlockchain = !$scope.resource.IsBlockchain;
        };

 

        $scope.SaveResourceDef = function () {
        //call new resource from service
        //debugger
            $scope.savingResource=true
            $scope.activity.activityMessage = ProcessingService.setActivityMsg('Saving resource '+$scope.resource.Name);
         
            ResourcesDataService.SaveResourceDef2($scope.resource).then(function(data) {
                               
                              MessagingService.addMessage(data.msg,'success');
                              $scope.saveSuccess=true;
                              $scope.resource = data.data
							  
							  $rootScope.globals.envinfo[$scope.resource.ResourceCode]=$rootScope.globals.envinfo[ $scope.resource.ResourceCode]+1
                               $scope.savingResource=false 
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');
                              $scope.saveSuccess=false;
                               $scope.savingResource=false
                            });
        };  
     
        $scope.filterResources = function()
        {
                if ($scope.resource_type_filter!="")
                {
                        $scope.selectedResourceType.Value=true;
                        $scope.selectedResourceType.HideBack=true;
                        $scope.resourceTypeSelected($scope.resource_type_filter);
                }
        }
      
    }
    
    

export function viewResourceCtrl ($scope, $rootScope, $state, $timeout,MessagingService,GridsterService,ResourcesDataService,CodesService,MenuService) {
  
       
        $scope.resourceId=$state.params.id;
        $scope.resource_type=$state.params.resource_type;
        $scope.CTRL='viewResourceCtrl';
        $scope.selectedItems=[];
        $scope.menu_service = MenuService
        $scope.menu_service.clearSideMenu()
        $scope.gsrvc = GridsterService
        $scope.gsrvc.resetBodyBgColor()   
    
        //$scope.showReturnLink = $state.params.prev_state.params.showReturnLink;      
        var lang = $rootScope.globals.currentUser.lang;
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
        
        $scope.getResourceAll = function () {
            

            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resource = data.data;
                               
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
            });
        };      
        
        $scope.getResourceInfo = function () {
            

            ResourcesDataService.getExternalResourceInfo($scope.resourceId).then(function(data) {
                               
                           
                              $scope.resource = data.data;
                               
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
            });
        };      
        

        $scope.getResourceInfo($scope.resourceId);
        
        
        $scope.returnToPreviousPage = function()
        {
                let prev_state="";
                if ($state.params.prev_state!="" && $state.params.prev_state!=undefined) prev_state = $state.params.prev_state;
                else prev_state = "app.navbar.home";
                
                $state.go(prev_state);
        }
        
}


export function editResourceCtrl ($scope, $rootScope, $state, $timeout,MessagingService,GridsterService,ResourcesDataService,CodesService,MenuService) {
  
       
        $scope.resourceId=$state.params.id;
        $scope.CTRL='editResourceCtrl';
        $scope.selectedItems=[];
      //$scope.selectedItem={};
        $scope.menu_service = MenuService
        $scope.menu_service.clearSideMenu()
        $scope.gsrvc = GridsterService
        $scope.gsrvc.resetBodyBgColor()   
    
        $scope.showReturnLink = $state.params.prev_state.params?.showReturnLink;      
        var lang = $rootScope.globals.currentUser.lang;
            
        
        CodesService.getCodesByName('resourcetype',lang).then(function (data) {
                            
                                $scope.resourceTypeList = data.data;
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
         CodesService.getCodesByName('_APPS_',lang).then(function (data) {
                               
                                $scope.resourceTagsList = data.data;
                             });
        
         $scope.getResourceAll = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resource = data.data;
                               
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };      
        
       $scope.getResourceAll();
       
         $scope.setImmutable = function () {
          
           
            if (!$scope.saveSuccess)  $scope.resource.IsBlockchain = !$scope.resource.IsBlockchain;
        };

        $scope.returnToPreviousPage = function()
        {
                $state.go($state.params.prev_state.state,$state.params.prev_state.params);
        }
         
         
         $scope.saveResourceDef = function (isValid) {
          
          if (isValid)
          {
            ResourcesDataService.saveResourceDefAll($scope.resource).then(function(data) {
                               
                               if (data.status===200 || data.status===201)
                                {
                                MessagingService.addMessage("Resource saved successfuly",'success');  
                                }
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
         
                            });
            
          }else
          {
            
            MessagingService.addMessage("Please enter required data",'warning'); 
          }
        };      
        
       
      
    }
    
    

export function applicationDefinitionCtrl ($scope, $rootScope, $location, $timeout,AuthenticationService,ProcessingService,MessagingService,ResourcesDataService,UtilityService,GridsterService,KatalyoStateManager,MenuService,$stateParams,$state) {
       
    $scope.resourceId=$stateParams.id;
    $scope.CTRLNAME='applicationDefinitionCtrl';
    $scope.pageState={ selectedPage: {}, selectedMenu: {} };
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()   
    $scope.selectedTab=1
    $scope.ksm = KatalyoStateManager;
    $scope.localVars={}
	$scope.pageLabels = $rootScope?.globals?.pageLabels
	var lang = $rootScope.globals.currentUser.lang;
	 
	
	$scope.openSelectMenu = function(a) {
		$scope.pageState.selectMenu = true
	}

	
	$scope.oncheck = function() {
	//debugger
		if ($scope.pageState.selectedMenu.SelectVersionDirect==false) 
				$scope.pageState.selectedMenu.selectedPublishedResource=null
	}
	
	
    if ($location.search().page_state!=undefined) {
        var page_state = $location.search().page_state;
    } else {
        var uuid = UtilityService.uuidv4(); //generate guid
    }
       
      
    $scope.existingMenu = function () 
	{
        $scope.pageState.selectedMenuOption = "existing";
    }
       
      
    //get menus
    ResourcesDataService.getResourceDefListByType(lang,'menu').then(
		function(data) {								
            $scope.menuList = data.data;
            $scope.getResourceAll();
			$scope.localVars.menusdownloaded=true					 
        },function(error){    
            MessagingService.addMessage(error.msg,'error');
    });
        
    
	//get pages
    ResourcesDataService.getResourceDefListByType(lang,'page').then(
	function(data) {									
                $scope.pageList = data.data;
				$scope.localVars.pagesdownloaded=true								 
        },function(error){    
                MessagingService.addMessage(error.msg,'error');
	});


    $scope.get_published_apps = function()
	{             
			
        ResourcesDataService.GetPublishedResources($scope.resourceId, 'application').then(
			function (data) {  		
			   $scope.publishedResources = data.data;
			   $scope.localVars.publishresourcesdownloaded=true
			});
			
		/*	
		ResourcesDataService.GetPublishedResourcesByTypeId($scope.resourceId,'application').then(
                    function (data) { 					
						$scope.publishedResources = data.data;
						$scope.localVars.publishresourcesdownloaded=true
                    },function(error){    
                        MessagingService.addMessage(error.msg,'error');   
        })*/
    }
    
    $scope.get_published_apps()
    
    $scope.selectMenu = function (menu) {
        $scope.pageState.selectedMenu = menu;
        $scope.pageState.selectedMenuOption = undefined;
        $scope.pageState.saveEnabled = true;
        $scope.pageState.selectMenu =false;
        if ($scope.pageState.selectedMenu?.SelectVersionDirect){
            $scope.getPublishedResources($scope.pageState.selectedMenu,'app-menu')
        }   
    }


    $scope.PreparePublish2Prod = function() //TODO - vidjeti cemu ovo sluzi , da li se $scope.publishedResource koristi negdje ??
    {
            ResourcesDataService.preparePublish2Prod($scope.resourceId,$scope.resource.ResourceCode,$scope.resource.Version).then(
				function(data) {                     
					$scope.publishedResource = data.data;
					$scope.localVars.preparePublish2Prod = true                                                     
				},function(error){                       
					MessagingService.addMessage(error.msg,'error');  
            });
    }


    $scope.Publish2Prod = function() //APP controler
    {
            $scope.publishingApp=true
            let resources = [{uid:$scope.resourceId,itemSubType:$scope.resource.ResourceCode,version:  $scope.gsrvc.resourceFormVersion[$scope.resource.uid]?.Version }]
            ResourcesDataService.publish2Prod(resources).then(function(data) {
                                   
                MessagingService.addMessage(data.data.msg,data.data.msg_type);  
                $scope.publishingApp=false
                if ($scope.publishedResource==undefined) $scope.publishedResource={}
                $scope.publishedResource.Published = data?.data?.Published
                $scope.publishedResource.PublishedBy = data?.data?.PublishedBy
                $scope.publishedResource.Version =  data?.data?.Version
                $scope.gsrvc.resourceFormVersion[$scope.resource.uid].Version = $scope.publishedResource.Version
                $scope.publishedResource.PublishedVersion =  data?.data?.PublishedVersion
                $scope.publishedResource.DateTime =  data?.data?.DateTime
                $scope.publishedResource.ChangedDateTime = data?.data?.ChangedDateTime
                $scope.publishedResource.ChangedBy = data?.data?.ChangedBy
				$('#manifest').modal('hide')
				$scope.showPublish2Market($scope.resource) 
                                                                   
            },function(error){
                $scope.publishingApp=false                
                MessagingService.addMessage(error.msg,'error');  
            });
    }

    $scope.selectedResources = []
    $scope.pageState.selectAllResources = false
        

    $scope.selectResource2Publish = function (resource,isAll) {
            
            let pos = $scope.selectedResources.map(function(e) { return e.id; }).indexOf(resource.id)
            if (!isAll)  {  
                if (pos>=0) {
                    $scope.selectedResources.splice(pos,1)
                    resource.selected = false
                } else  {
                        resource.selected = true
                        $scope.selectedResources.push(resource)
                }
            } else {
                resource.selected = !$scope.pageState.selectAllResources
                if (!$scope.pageState.selectAllResources) {
                  if (pos<0) $scope.selectedResources.push(resource)  
                } 
                else 
					if (pos>=0) $scope.selectedResources.splice(pos,1)
            }      

        }

         $scope.selectAll4Publish = function (itemType) 
		 {
         
           for (let i=0;i<$scope.appManifest.length;i++){ 
				if ($scope.appManifest[i].itemType==itemType) 
					$scope.selectResource2Publish($scope.appManifest[i],true)
                
           }

        }


        $scope.selectPage = function (page) {
            
            $scope.pageState.selectedPage = page;
            $scope.pageState.selectDefaultPage = false;
            if ($scope.pageState.selectedPage?.SelectVersionDirect)
            {
                $scope.getPublishedResources($scope.pageState.selectedPage,'form')
            }
               
        
        }   
         $scope.getResourceAll = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resource = data.data;
                              $scope.resource.resourceDefDownloaded = true
							  $scope.localVars.resourcedefdownloaded=true

                              $scope.resource.adminHeaderLabel = 'App permissions'
                              let menu = $scope.findLink("app-menu",$scope.menuList);
                              $scope.selectMenu(menu);
                              let defaultPage = $scope.findLink("default-page",$scope.pageList);
                              $scope.selectPage(defaultPage);
                              $scope.gsrvc.setResourceFormVersion($scope.resource.uid,$scope.resource);
                              $scope.PreparePublish2Prod()
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };
		
		
        $scope.findLink = function(link_type,resourceList)
        {
                let resource2,r_item;
                for (let i=0;i<$scope.resource.ResourceExtended?.length;i++) 
                {
                        if ($scope.resource.ResourceExtended[i].LinkType==link_type) 
                            {
                                r_item = $scope.resource.ResourceExtended[i]
                                resource2=$scope.resource.ResourceExtended[i].Resource2;

                            }
                }
                let pos = resourceList.map(function(e) { return e.id; }).indexOf(resource2);

                if (pos>=0) return { ...r_item ,...resourceList[pos] }
                else return null;
     
        }
        
		$scope.newMenu = function () 
		{
			$state.go("app.navbar.resources.new",{resource_type:'menu',return_page:{'id':$scope.resource.uid,'page':'app.navbar.resources.application-definition'}})        
		};
	   
	   
        $scope.findLinkIndex = function(link_type)
        {
            for (let i=0;i<$scope.resource.ResourceExtended.length;i++)  {
                        if ($scope.resource.ResourceExtended[i].LinkType==link_type) return i;
            }
            return -1;
        }
		
		$scope.findLinkId = function( link_type, wheretolook)
        {
            for (let i=0;i<wheretolook.length;i++) {
                        if (wheretolook[i].LinkType==link_type) return wheretolook[i]?.id;
            }
            return null;
        }
      
    
      $scope.saveApplication = function (isFormValid) 
	  {
		let link_id1 = $scope.findLinkId("app-menu", $scope.resource.ResourceExtended)
		let link_id2 = $scope.findLinkId("default-page", $scope.resource.ResourceExtended)

		$scope.pageState.selectedMenu = $scope.pageState.selectedMenu ?? { id: null }
		$scope.pageState.selectedPage = $scope.pageState.selectedPage ?? { id: null }
		
        $scope.rLinks = []
        if (!$scope.pageState.selectedMenu?.SelectVersionDirect) $scope.pageState.selectedMenu.SelectVersionDirect = false
        if (!$scope.pageState.selectedPage?.SelectVersionDirect) $scope.pageState.selectedPage.SelectVersionDirect = false
        if ($scope.pageState.selectedMenu.id) 
                $scope.rLinks.push({'id':link_id1,'Resource1':$scope.resourceId,'Resource2':$scope.pageState.selectedMenu.id,'LinkType':'app-menu','SelectVersionDirect':$scope.pageState.selectedMenu?.SelectVersionDirect,'publishedResource':$scope.pageState.selectedMenu?.selectedPublishedResource,'Version':$scope.pageState.selectedMenu?.selectedPublishedResource?.Version})
        
		if ($scope.pageState.selectedPage.id)
            $scope.rLinks.push({'id':link_id2,'Resource1':$scope.resourceId,'Resource2':$scope.pageState.selectedPage.id,'LinkType':'default-page','SelectVersionDirect':$scope.pageState.selectedPage?.SelectVersionDirect,'publishedResource':$scope.pageState.selectedPage?.selectedPublishedResource,'Version':$scope.pageState.selectedPage?.selectedPublishedResource?.Version})

        let app_data = {appId:$scope.resource.uid,version:$scope.resource.NewVersion,desc:$scope.localVars.newDescription,rLinks:$scope.rLinks}
        if (isFormValid)
        {
            if ($scope.rLinks.length){
                $scope.savingApp = true
                ResourcesDataService.saveApplication(app_data).then(
					function(data) {             
						$scope.savingApp = false  
						$('#appSaveModal').modal('hide')						
						MessagingService.addMessage(data.data.msg,'success');
						
						$scope.gsrvc.resourceFormVersion[$scope.resource.uid] = data.data.data
						$scope.resource=data.data.data
					},
					function(error){
						$scope.savingApp = false
						MessagingService.addMessage(error.msg,'error');
					})
            } else {
                MessagingService.addMessage('Please select application menu or default page to save!','error');
                $scope.savingApp = false
            }
        } else MessagingService.addMessage('App description is mandatory!','error');        

      };
	  
      
	  $scope.newVersion = function()
       {
           $scope.creatingNewVersion=true

            ResourcesDataService.SetNewResourceVersion($scope.resource.uid)
                .then(function(data) {
                    if (data.status==200) {
                        $scope.resource.NewVersion = data.data
                        $scope.resource.Published=false
                        $scope.gsrvc.setResourceFormVersion($scope.resource.uid,$scope.resource.NewVersion);
                    } else MessagingService.addMessage(data.data,'error');
                    
					$scope.creatingNewVersion=false
                    $scope.hasChanges=false
         
                   },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.creatingNewVersion=false
                                 
                             });

       }

    $scope.showModal = function (p_type) {
            $('#appSaveModal').modal('show')
            $scope.modalAction=p_type
            $scope.newPublishVersion = $scope.resource.NewVersion

    }
	
	
	$scope.showManifest = function (p_type, element= {}) {
		//debugger
            $('#manifest').modal('show')
			if (element) $scope.modal = {resource: element}
            $scope.modalAction=p_type
			$scope.buildAppManifest()
    }
	
	
	$scope.showPublish2Market = function (element) {
            $('#publish2market').modal('show')
            $scope.modalAction='publish2market'
			$scope.modal = {resource : element }
			$scope.buildAppManifest()
    }
	
	 $scope.hideModal = function (which) {
            $(which).modal('hide')
    }
	
    $scope.getPublishedResources = function(resource, publishType){
        
	if (! resource?.id) return	

	ResourcesDataService.GetPublishedResourcesByTypeId(resource.id,publishType).then(
                    function (data) { 					
                        if ($scope.selectedResourcesPublishedList==undefined) $scope.selectedResourcesPublishedList={}
                        $scope.selectedResourcesPublishedList[resource.id] = data.data;
                        $scope.localVars.publishedresourcesdownloaded=true
                        // fill 'select version' options to have both id and name (for already saved data)
                        if (resource.SelectVersionDirect){ 
                              let publishedList = $scope.selectedResourcesPublishedList[resource.id] 
                              publishedList.forEach( (item)=> {
                                  if (item.Version == resource?.Version) 
                                      resource.selectedPublishedResource = item
                              } ) 
                        }
                    },function(error){    
                        MessagingService.addMessage(error.msg,'error');   
        })

    }

    $scope.publishApplication = function () {
        
            $scope.publishingApp = true;
            //debugger
            ResourcesDataService.PublishResource($scope.resourceId,$scope.resource.NewVersion,'publish to PROD','application')
                    .then(function(data) {
                                    MessagingService.addMessage(data.msg,'success');
                                    //$('#datasetPublish').modal('hide'); 
                                    $scope.publishingApp = false;                
                    } ,function(error){ 
                        $scope.publishingApp = false;
                        MessagingService.addMessage(error.msg,'error');           
            })
     }

    $scope.appManifest=[]  
	
    $scope.buildAppManifest = function () 
	{
		//debugger
        $scope.buildingApp = true
        $scope.appManifest.length=0
        ResourcesDataService.publishApplication($scope.resourceId,$scope.resource.NewVersion).then(
				function(data) {   
                                $scope.buildingApp = false
                                $scope.appManifest=data.data?.manifest
                                $scope.readyToPublish = data.data?.ready_to_publish
                                     
               },function(error){
                                $scope.buildingApp = false
                                MessagingService.addMessage(error.msg,'error');     
        })
      
    }


     $scope.publishPublicResource = function (modalResource) 
	 {
        $scope.savingManifest = true
		let version = $scope.resource.NewVersion
		if (modalResource?.Version) 	version = modalResource.Version	
        let publish = {publishtype:'app-manifest',envtype:'ALL',install:true,definition:$scope.appManifest}
	
        ResourcesDataService.publishPublicResource($scope.resourceId,$scope.resource.ResourceCode, version ,publish).then(
				function(data) {
                               MessagingService.addMessage(data.data.msg,'success');
                                $scope.savingManifest = false
                 },function(error){
                                    $scope.savingManifest = false
                                    MessagingService.addMessage(error.msg,'error');     
                 }).finally( () => {
					 $('#publish2market').modal('hide')
					  $('#manifest').modal('hide')
				 })
      
    };
        
        $scope.returnToPreviousPage = function()
        {
                let page = "app.navbar.resources.list";
                //let navbar = MenuService.getPageNavbarByName(page);
                $state.go(page,{});
             
        }
      
    }

export function appMenuDefinitionCtrl ($scope, $rootScope, $location, $timeout,AuthenticationService,ProcessingService,MessagingService,ResourcesDataService,CodesService,GridsterService,MenuService,$stateParams,$state) {
  
       
    $scope.resourceId=$stateParams.id;
    $scope.CTRLNAME='appMenuDefinitionCtrl';
    $scope.selectedItems=[];
    $scope.menuLoaded = false;
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()
    
    $scope.localVars = {taskList:[]};
    $scope.localVars.hideBtns=true;
    $scope.localVars.showDelete=true;
    var lang = $rootScope.globals.currentUser.lang;
    $scope.gsrvc = GridsterService
     $scope.gsrvc.resetBodyBgColor()   
      /*  CodesService.getCodesByName('resourcetype',lang).then(function (data) {
            
                                $scope.resourceTypeList = data.data;
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
        
        */
		
         $scope.getResourceAll = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                            $scope.resource = data.data;
                            $scope.resource.resourceDefDownloaded = true
                            $scope.resource.adminHeaderLabel = 'App menu permissions'
							$scope.gsrvc.setResourceFormVersion($scope.resource.uid,{Version:$scope.resource.Version,Published:$scope.resource.Published,NewVersion:$scope.resource.NewVersion})


                            $scope.PreparePublish2Prod()

                            $scope.menuLoaded = true;
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };      
       
       
       
        $scope.returnToPreviousPage = function()
        {
                let page = "app.navbar.resources.list";
                $state.go(page,{});
             
        }
        
        $scope.showModal = function (p_type) {
      
                    $('#menuPublish').modal('show'); 
                    //$scope.modalAction=p_type
                    //$scope.newPublishVersion = $scope.resource.NewVersion
        }

        $scope.PreparePublish2Prod = function()
        {
            ResourcesDataService.preparePublish2Prod($scope.resourceId,$scope.resource.ResourceCode,$scope.resource.Version).then(function(data) {
                                   
                $scope.publishedResource = data.data;
                                                                   
            },function(error){
                                  
                MessagingService.addMessage(error.msg,'error');  
            });
        }

         $scope.Publish2Prod = function()
        {
            $scope.publishingDataset=true
            //if (!$scope.publishedResource?.Version) $scope.publishedResource.PublishedVersion = $scope.gsrvc.resourceFormVersion[$scope.resource.uid].Version
            let resources = [{uid:$scope.resourceId,itemSubType:$scope.resource.ResourceCode,version: $scope.gsrvc.resourceFormVersion[$scope.resource.uid].Version  }] //$scope.gsrvc.resourceFormVersion[$scope.resource.uid]?.Version
            ResourcesDataService.publish2Prod(resources).then(function(data) {
                               
                MessagingService.addMessage(data.data.msg,'success'); 
                $scope.publishedResource.Published = data.data.Published
                $scope.publishedResource.Deployed = data.data.Deployed
                $scope.publishedResource.Version = data.data.Version
                $scope.gsrvc.resourceFormVersion[$scope.resource.uid].Version = $scope.publishedResource.Version
                $scope.publishedResource.PublishedVersion =  data?.data?.PublishedVersion
                $scope.publishedResource.DateTime = data.data.DateTime
                $scope.publishedResource.PublishedBy = data.data.PublishedBy
                $scope.publishedResource.ChangedDateTime = data.data.ChangedDateTime
                $scope.publishedResource.ChangedBy = data.data.ChangedBy
                $scope.publishedResource.count = 0 
               // $scope.publishedResource.rmd = data.data.rmd
                $scope.publishingDataset=false
                                                               
            },function(error){
                $scope.publishingDataset=false                
                MessagingService.addMessage(error.msg,'error');  
            });
        }

       $scope.getResourceAll();
        
        $scope.localVars.updateResource = function(resource)
        {
            $scope.resource=resource
        }
        $scope.saveResourceDef = function (forma) {
          
                  if (forma.$valid)
                  {
                    ResourcesDataService.SaveResourceDef2($scope.resource).then(function(data) {
                                       
                                       if (data.status===200 || data.status==201)
                                        {
                                        MessagingService.addMessage(data.msg,'success');  
                                        }
                                        },function(error){
                                      
                                      MessagingService.addMessage(error.msg,'error');  
                 
                                    });
                    
                  }else
                  {
                    
                    MessagingService.addMessage("Please enter required data",'warning'); 
                  }
        }; 

    
   
    
    
      
    }
export function integrationDefinitionCtrl ($scope, $rootScope, $location, $state, $timeout,AuthenticationService,ProcessingService,MessagingService,ResourcesDataService,CodesService,GridsterService,MenuService,$stateParams) {
  
       
    $scope.resourceId=$stateParams.id;
    $scope.CTRLNAME='integrationDefinitionCtrl';
    $scope.selectedItems=[];
    $scope.dashboard = {};
    $scope.dashboard.widgetsRight={};
    $scope.localVars = {taskList:[]};
    $scope.gsrvc=GridsterService
    $scope.gsrvc.resetBodyBgColor()
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()   
    
    var lang = $rootScope.globals.currentUser.lang;
        
        CodesService.getCodesByName('resourcetype',lang).then(function (data) {
            //debugger;
                                $scope.resourceTypeList = data.data;
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
        
        
         $scope.getResourceAll = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resource = data.data;
                               
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };      
        
       $scope.getResourceAll();
        
        
         $scope.getResourceDefinition = function () {
          
                ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                        $scope.resource = data.data;
                        
                        if ( $scope.resource.Parameters ==null ||  angular.equals( {}, $scope.resource.Parameters) )
                        {                    
                                $scope.resource.Parameters = {navbar:true,sideMenu:true,pageGridItems:[{id:1}],grid:{NoOfRows:null,NoOfColumns:null,RowGap:null,ColumnGap:null}}       
                        }
                        $scope.pageDownloaded = true;
                        $scope.BuildGrid(true);
                              
                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                });
        };
        
        
        ResourcesDataService.getResourceDefListByType(lang,'dataset').then(function(data) {
                                                    
                        $scope.resourceList = data.data;
                        $scope.resourcesLoaded = true;
                },function(error){
                                
                        MessagingService.addMessage(error.msg,'error');
        });        
        
        
         $scope.saveResourceDef = function (isValid) {
          
          if (isValid)
          {
            ResourcesDataService.saveResourceDefAllNew($scope.resource).then(function(data) {
                
                               
                               if (data.status===200  || data.status===201)
                                {
                                MessagingService.addMessage("Integration saved successfuly",'success');  
                                }
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
         
                            });
            
          }else
          {
            
            MessagingService.addMessage("Please enter required data",'warning'); 
          }
        };      
      
        
        
        $scope.returnToPreviousPage = function()
        {
                let page = "app.navbar.resources.list";
                //let navbar = MenuService.getPageNavbarByName(page);
                $state.go(page,{});
             
        }
        
       
       $scope.getIntegration = function () {
        $scope.integrationLoaded=false;
        //debugger
      ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(function(data) {
                                if (data.data.widgets.length>0)
                                {
                                     $scope.dashboard.widgetsRight={layout:data.data.widgets};
                                }else $scope.dashboard.widgetsRight.layout = GridsterService.getEmptyApplicationMenu();
                                
                                //datasetFormState.subject.setState=$scope.dashboard.widgets;
                                
                                $scope.resourceDefinition= data.data.resourceDefinition;
                                                                                              
                                   $timeout(function() {
                                    $scope.integrationLoaded=true;
                                    });
                                   
                                },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                             });
      
      };
       
    $scope.getIntegration();
      
}
export function smartContractDefinitionCtrl ($scope, $rootScope, $location, $timeout,MessagingService,ResourcesDataService,CodesService,GridsterService,MenuService,$stateParams,$state) {
  
       
    $scope.resourceId=$stateParams.id;
    $scope.CTRL='smartContractDefinitionCtrl';
    $scope.selectedItems=[];
    
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()   
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()
    var lang = $rootScope.globals.currentUser.lang;
        
        
        ResourcesDataService.getResourceDefListByType($rootScope.globals.currentUser.lang,'dataset').then(
                        function(data) {			
							$scope.datasetList = data.data;
                            //debugger
                         }
                        ,function(error){   MessagingService.addMessage(error.msg,'error')	}
            );
        
        /*
        CodesService.getCodesByName('usertags',lang).then(function (data) {
                                $scope.tagList = data.data;
                                $scope.tagList1 = angular.copy($scope.tagList);
                                $scope.tagList2 = angular.copy($scope.tagList);
                                
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
        
        */
         $scope.newFunction = function(){
            
            if ($scope.resource.Parameters.Functions==undefined) $scope.resource.Parameters.Functions = [];
            $scope.resource.Parameters.Functions.push({});
                          
        };
        
         $scope.newBlockchainDataset = function(){
            
            if ($scope.resource.Parameters.Datasets==undefined) $scope.resource.Parameters.Datasets = [];
            $scope.resource.Parameters.Datasets.push({});
                          
        };
        
        $scope.getResourceAll = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resource = data.data;
                              $scope.resourceLoaded = true;
                              
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };      
        
       $scope.getResourceAll();
       
         $scope.saveResourceParameters = function () {
          
           ResourcesDataService.saveResourceDefParameters($scope.resourceId,$scope.resource.Parameters).then(function(data) {
                               
                               if (data.status===200  || data.status===201)
                                {
                                MessagingService.addMessage(data.data,'success');  
                                }
                                },function(error){
                              
                              MessagingService.addMessage(error.data,'error');  
         
                            });
            
          
        };
        
       
}


export function tokenDefinitionCtrl ($scope, $rootScope, $location, $timeout,MessagingService,ResourcesDataService,CodesService,GridsterService,MenuService,$stateParams,$state) {
  
       
    $scope.resourceId=$stateParams.id;
    $scope.CTRL='tokenDefinitionCtrl';
    $scope.selectedItems=[];
    let lang = $rootScope.globals.currentUser.lang;

    let tokenDef = Vue.createApp(TokenDefinition);
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()

    tokenDef.mount("#tokenDefinitionComponent");
       
}
export function notificationDefinitionCtrl ($scope, $rootScope, $location, $timeout,MessagingService,ResourcesDataService,CodesService,GridsterService,MenuService,$stateParams,$state) {
  
       
    $scope.resourceId=$stateParams.id;
    $scope.CTRL='notificationDefinitionCtrl';
    $scope.selectedItems=[];
    
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()
    $scope.returnToPreviousPage = function() {
           $state.go("app.navbar.resources.list",{});
     }
        
 
   
       //$state.go("app.navbar.codes.new",{id:$scope.resourceId,returnState:{Label:'Return to notification definition',state:$state.current,params:{id:$scope.resourceId}}});
     
        
       
    var lang = $rootScope.globals.currentUser.lang;
        
        CodesService.getCodesByName('usertags',lang).then(function (data) {
                                $scope.tagList = data.data;
                                $scope.tagListUsers = angular.copy($scope.tagList);
                                $scope.tagListGroups = angular.copy($scope.tagList);
                                $scope.tagList1 = angular.copy($scope.tagList);
                                $scope.tagList2 = angular.copy($scope.tagList);
                                
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
        
        
        $scope.setEmailParams = function()
        {
               var pos = $scope.tagList1.map(function(e) { return e.id; }).indexOf($scope.resource.Parameters.EmailSubjectField.id);

                if (pos>=0){
                
                $scope.resource.Parameters.EmailSubjectField=$scope.tagList1[pos];
                                      
                }
                pos = $scope.tagList2.map(function(e) { return e.id; }).indexOf($scope.resource.Parameters.EmailMessageField.id);

                if (pos>=0){
                
                $scope.resource.Parameters.EmailMessageField=$scope.tagList2[pos];
                                      
                } 
        }
        $scope.getResourceAll = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resource = data.data;
                              $scope.resourceLoaded = true;
                              if ($scope.resource.Parameters===null || $scope.resource.Parameters===undefined) $scope.resource.Parameters={};
                              if ($scope.resource.Parameters.SendEmail) $scope.setEmailParams();
                               
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };      
        
       $scope.getResourceAll();
       
        $scope.GotoMessageDef = function()
        {
           $state.go("app.navbar.resources.form",{id:$scope.resourceId,returnState:{Label:'Return to notification definition',state:$state.current,params:{id:$scope.resourceId}}});
        }
      
         $scope.saveResourceParameters = function () {
          
           ResourcesDataService.saveResourceDefParameters($scope.resourceId,$scope.resource.Parameters).then(function(data) {
                               
                               if (data.status===200  || data.status===201)
                                {
                                MessagingService.addMessage(data.msg,'success');  
                                }
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
         
                            });
            
          
        };
        
        
        /*
      
       $scope.getNotificationForm= function () {
        $scope.menuLoaded=false;
      ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(function(data) {
                                if (data.data.widgets.length>0)
                                {
                                     $scope.dashboard.widgetsRight={layout:data.data.widgets};
                                }else $scope.dashboard.widgetsRight.layout = GridsterService.getEmptyApplicationMenu();
                                
                                //datasetFormState.subject.setState=$scope.dashboard.widgets;
                                
                                $scope.resourceDefinition= data.data.resourceDefinition;
                                                                                              
                                   $timeout(function() {
                                    $scope.menuLoaded=true;
                                    });
                                   
                                },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                             });
      
      };
     
        $scope.getNotificationForm();
    */
    }

//import kt_rd from '../../../0000_common/0001_components/vue/kt-resource-definition.vue';

export function notificationDefinitionComponent (ResourcesDataService,MessagingService,$stateParams) {
  
    
        var resource;
        var resourceId=$stateParams.id;
        // get notification details here
        
        ResourcesDataService.getResourceDefAll(resourceId).then(function(data) {
                               
                resource = data.data;
                 let vueNotificationDef =  new Vue({
                                                el: '#notificationDefinitionComponent',
                                                data: {
                                                  resource: resource,
                                                },
                                              
                                                methods: {
                                                 
                                                  saveNotification:function() {
                                                   
                                                  }
                                                 
                                                },
                                                mounted(){
                                              
                                                },
                                                components: {
  //                                                      'kt-resource-definition': kt_rd
                                                }
                                              
                                          
                                            });
                               
        },function(error){
                              
                MessagingService.addMessage(error.msg,'error');  
        });
 
    }
	
	
export function resourceListCtrl ($scope, $rootScope, AuthenticationService,CodesService,ResourcesDataService,UserService,KatalyoStateManager,uiGridConstants,MessagingService,MenuService,$timeout,$state,$stateParams,$q) {

    $scope.CTRL='resourceListCtrl';

    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()

    $scope.paginationOptions = {
        pageNumber: 1,
        pageSize: 10,
        sort: null
    };
	
	$scope.testfn = function(a,b){
		console.log(a)
		//debugger
		
	}
	
	$scope.showEnvInfoCount = function() {
		let cc=0
		if ($rootScope.globals.envinfo) {
				for (const [key, value] of Object.entries($rootScope.globals.envinfo 	)) {
						  console.log(`${key}: ${value}`);
						  cc=cc+value
				}
				
		} else return 0
		return cc
	}
	
    $scope.pageState = {};
    $scope.results = {};
    $scope.filter = {'resource_type':$stateParams.resource_type,'name':$stateParams.resource_name};
    $scope.actionDef= [{id:1,btnLabel:'Edit resource',btnClass:'btn-outline-primary',icon:'fa fa-edit'}] 
    $scope.smParams = {UuidRef:1}
    $scope.resourceTableColumns =   [
        { name: 'id', displayName: 'ID', aggregationType: uiGridConstants.aggregationTypes.max,type: 'number', width:'1%',enableHiding: false, visible: true },
        { name: "ResourceType.ResourceName", width:'1%',displayName: 'Type' },
        { name: "resourcedeflang_set[0].Name", displayName: 'Resource name'},
        { name: "resourcedeflang_set[0].Subject", displayName: 'Resource subject' },
        { name: "resourcedeflang_set[0].Description", displayName: 'Resource description' },
       // { name: "resourcedeflang_set[0].Lang.name", displayName: 'Language'},
        { name: "Active", displayName: 'Active',width:'1%',type:'bool'},
        /* { name: "act", displayName: ''}, //special actions column*/
         ];
   
        $scope.ksm = KatalyoStateManager;
    
        let resourceListState= $scope.ksm.addSubject($scope.CTRL,$scope.resource);
        let resourceListStateName = resourceListState.subject.name;
        
        if (resourceListState.subject.getState!=undefined) $scope.pageState =resourceListState.subject.getState;
        if ($scope.pageState?.rememberSearch)
        {
                $scope.resource = $scope.pageState.resource;
                $scope.currentPage=$scope.pageState.currentPage;
                $scope.results.pageSize = $scope.pageState.pageSize;
                
        }
        if ($scope.resource==undefined) $scope.resource={};
        
        let search_saved = function(data)
        {
           resourceListState.subject.setStateChanges = data;
           resourceListState.subject.notifyObservers();     
                
        }
        
         let action_search_saved = $scope.ksm.addAction(resourceListStateName,"resourceListSearch",search_saved);
         
    $scope.resourceSelected = false;
    
    $scope.updateState = function()
    {
       // $scope.pageState.rememberSearch=!$scope.pageState.rememberSearch;
        $scope.pageState.resource = $scope.resource;
        $scope.pageState.currentPage = $scope.currentPage;
        $scope.pageState.pageSize = $scope.results.pageSize;
        $scope.ksm.executeAction(resourceListStateName,"resourceListSearch",$scope.pageState);
        
        
    }  
   
    $scope.rowSelectionChanged = function(item){
        //debugger //rowSelectionChanged
        
        $scope.resourceSelected=item.isSelected;
        $scope.selectedResource = item.id;
        $scope.selectedResourceType = item.ResourceType.ResourceName;
        $scope.resourceIconSelected=item.ResourceType.Parameters.Icon;
        $scope.resourceDetailsState=item.ResourceType.Parameters.DefinitionPage;
        $scope.resourceDetailsParams={id:$scope.selectedResource};      
    };
          
     $scope.resourceIconSelected = "globe";
     $scope.selectedResourceType="Resource";
     $scope.resourceDetailsLink="app.navbar.resources.list";
     $scope.advancedSearch=false;
     
     var lang=$rootScope.globals.currentUser.lang;
    
     ResourcesDataService.GetResourceParams().then(function (data) {
                   $scope.resourceTypeList = data.data;
                   if ($scope.resourceStatusList!=undefined) $scope.filterResources();
                   $scope.ResourceSearch();     
     });
        
        
    CodesService.getCodesByName('_APPS_',lang).then(function (data) {
                                $scope.resourceTagsList = data.data;
                             }); 
 

     $scope.gotoState = function(){
        $state.go($scope.resourceDetailsState,$scope.resourceDetailsParams);
     };
 
 
        $scope.resourceLoading=false;
        $scope.showTableActions=true;
 
        $scope.processTableAction = function(item,actionId){
                $scope.resourceDetailsState=item.ResourceType.Parameters.DefinitionPage;
                $scope.resourceDetailsParams={id:item.uid};
                $scope.gotoState();
        };
        
        
        
    $scope.getResourceDef = function(pageNumber,pageSize){//Called by smart table
       
        let deferred = $q.defer();    
        $scope.resourceLoading=true;
        //debugger //getResourceDef
   
        var filter=$scope.resource;
        
        if (pageNumber==undefined) pageNumber = $scope.paginationOptions.pageNumber;
        
        if (pageSize==undefined) pageSize = $scope.paginationOptions.pageSize;
         
        if ($scope.totalCount>0){
            if (Math.ceil($scope.totalCount/pageSize)<newPage)
            {
              newPage = Math.ceil($scope.totalCount/pageSize);
            }
         }
         $scope.pageState.resource = $scope.resource;
         
         if ($scope.firstQuery)
         {
          $scope.pageState.currentPage = pageNumber;
          $scope.pageState.pageSize = pageSize;           
         }else
         {
                if ($scope.pageState.rememberSearch)
                {
                        if ($scope.currentPage == 1)
                        {
                                $scope.pageState.currentPage = $scope.currentPage;
                        }
                        pageNumber = $scope.pageState.currentPage;
                        pageSize = $scope.pageState.pageSize;
                }
         }
        
        $scope.ksm.executeAction(resourceListStateName,"resourceListSearch",$scope.pageState);
        
    ResourcesDataService.getResourceDefFiltered(pageNumber,pageSize,filter,lang).then(function(data) {
                                $scope.firstQuery=true;
                                $scope.resourceTableData = data.data.results;
                                //$scope.gridOptions.data = data.data.results;
                                $scope.results = data.data;
                                $scope.results.columns=$scope.resourceTableColumns;
                                $scope.nextPage = data.data.next;
                                $scope.previousPage = data.data.previous;
                                $scope.paginationOptions.totalItemCount=data.data.count;
                                $scope.currentPage=pageNumber;
                                $scope.paginationOptions.numberOfPages = Math.ceil($scope.paginationOptions.totalItemCount/pageSize);
                                $scope.results.pageSize = pageSize;
                                $scope.results.numberOfPages = $scope.paginationOptions.numberOfPages;
                               // $scope.gridOptions.totalItems=$scope.totalCount;
                               
                               deferred.resolve({data: $scope.results});
                              
                                $scope.resourceLoading=false;
                                
                                },function(error){
       
                                 MessagingService.addMessage(error.msg,'error');
                                 $scope.resourceLoading=false;
                             });
      return deferred.promise;
      };
        
    
    //fill grid with resource def
    $scope.getResourceList = function(newPage,pageSize){
        $scope.getResourceDef(newPage,pageSize);
    };
    
     $scope.ResourceSearch = function(page){
         //debugger
        $scope.searchInitiated=false;
        $scope.paginationOptions.totalItemCount=0;
        if (page!=undefined) $scope.currentPage=page;
        $timeout(function(){
            $scope.searchInitiated=true;
        });
     };
     
     $scope.pageChanged = function(){
            $scope.getResourceDef($scope.nextPage,$scope.previousPage,$scope.currentPage,$scope.pageSize.pageSize);
    };
    
    
    
    }

//	**********************************************************
//           RESOURCE DATA CONTROLER 
//	**********************************************************

export function resourceDataCtrl ($scope, $rootScope, uiGridConstants,CodesService,AuthenticationService,ResourcesDataService,ProcessingService,MessagingService,UserService,$stateParams,$timeout) {
	   
       $scope.CTRL='resourceDataCtrl';
       var lang = $rootScope.globals.currentUser.lang;
  
    $scope.gridOptions = {
      //  columnDefs: colDefs,
       enableGridMenu: true,
       showGridFooter: true,
       showColumnFooter: true,
       enableRowSelection: true,
       multiSelect: false,
       enableHorizontalScrollbar : 2, 
       nableVerticalScrollbar : 2,
       enableFiltering: true,
       enableRowHeaderSelection: true,
       enableColumnResizing: true,
       useExternalPagination: true,
       paginationPageSizes: [10, 25, 50,100,200,500,1000],
      enablePaginationControls: false,
      //paginationPageSize: 10,
      //useExternalSorting : true,
      //useExternalFiltering: true,
      // minRowsToShow : 3
    }

  var paginationOptions = {
    pageNumber: 1,
    pageSize: 10,
    sort: null
    };
 
   
    $scope.gridOptionsRelated = {
      //  columnDefs: colDefs,
       enableGridMenu: true,
       showGridFooter: true,
       showColumnFooter: true,
       enableRowSelection: true,
       multiSelect: false,
       enableHorizontalScrollbar : 2, 
       nableVerticalScrollbar : 2,
       enableFiltering: true,
       enableRowHeaderSelection: true,
       enableColumnResizing: true,
      //exporterCsvFilename: 'myFile.csv',
     /* 
        paginationPageSizes: [5, 10, 20, 50, 100],
         paginationPageSize: 5,
       enableSelectAll: false,
       enableCellEdit: false*/
      
   }
   
   
   $scope.resourceId=$stateParams.id;
   $scope.data=[];
   $scope.gridOptions.data = $scope.data;   
        
   $scope.getCodeById= function (input,entity) {
    
    CodesService.getCodesValue(entity,lang,input,1).then(function (data){
    
            return data;
          });
   };
  //**********************************************************
	//           GET RESOURCE DATA
	//**********************************************************      
  $scope.getResourceData = function (urlNext,urlPrev,newPage,pageSize) {
    
         $scope.activity.activityMessage = ProcessingService.setActivityMsg('Downloading resource data for '+$scope.resourceId);
      
         
        
        if (Math.ceil($scope.totalCount/pageSize)<newPage)
        {
          newPage = Math.ceil($scope.totalCount/pageSize);
        }
         
        // ResourcesDataService.getResourceDataPaginated($scope.resourceId,urlNext,urlPrev,newPage,pageSize).then(function(data) {
        ResourcesDataService.getResourceDataPaginatedFilter($scope.resourceId,urlNext,urlPrev,newPage,pageSize,lang,[], []).then(function(data) {
                                    
                                    $scope.data =  data.data;
                                    $scope.gridOptions.data = $scope.data;
                                    $scope.nextPage = data.data.next;
                                    $scope.previousPage = data.data.previous;
                                    $scope.totalCount=data.data.count;
                                    $scope.currentPage=newPage;
                                    $scope.numPages = Math.ceil($scope.totalCount/pageSize);
                                    $scope.pageSize = pageSize;
                                    
                                    if ($scope.totalCount>0 && $scope.currentPage==0) $scope.currentPage=1;
                                    $scope.numPages = Math.ceil($scope.totalCount/pageSize);
                                    $scope.pageSize = pageSize;
                                    $scope.totalItems=$scope.totalCount;         
                                   
         //                            MessagingService.addMessage(data.msg,'success');
                                  },function(error){
                                    MessagingService.addMessage(error.msg,'error');               
                             });
        };


 $scope.pageChanged = function(){
        $scope.getResourceData($scope.nextPage,$scope.previousPage,$scope.currentPage,$scope.pageSize);
         }
         
    $scope.gridOptions.onRegisterApi = function (gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
         gridApi.selection.on.rowSelectionChanged($scope,function(row){
       
          $scope.resourceRecordSelected=row.isSelected;
       
           if(row.isSelected){
              $scope.selectedRecordId=row.entity.id;
            }
     
     
        });
        //external pagination
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
            paginationOptions.pageNumber = newPage;
            paginationOptions.pageSize = pageSize;
            $scope.getResourceData($scope.nextPage,$scope.previousPage,newPage,pageSize);
        });
     };
 

        
  //**********************************************************
	//           GET RESOURCE COLUMNS
	//**********************************************************      
  $scope.getResourceColumns = function () {
    var lang = $rootScope.globals.currentUser.lang;
     ResourcesDataService.getResourceColumns($scope.resourceId,0,"i",lang).then(function(data) {
                                
                $scope.gridOptions.columnDefs = data.data;
                //debugger  //getResourceColumns //smarttable
                $scope.getResourceData($scope.nextPage,$scope.previousPage,paginationOptions.pageNumber,paginationOptions.pageSize);               
         //                            MessagingService.addMessage(data.msg,'success');
                                  },function(error){
                                    MessagingService.addMessage(error.msg,'error');               
                             });
  }
 //**********************************************************
	//           GET DATASET RELATED RECORDS
	//**********************************************************      
  $scope.getDatasetRelatedRecords = function (relatedDatasetId,recordId) {
  
  
         var lang = $rootScope.globals.currentUser.lang;
        
         ResourcesDataService.getResourceDataRelated($scope.resourceId,recordId,relatedDatasetId,0,lang).then(function(data) {
                                
                                     $scope.gridOptionsRelated.data = data.data;
                                     //debugger
                                     $scope.gridOptionsRelated.columnDefs = data.columnsDef;
                               
         //                            MessagingService.addMessage(data.msg,'success');
                                  },function(error){
                                    MessagingService.addMessage(error.msg,'error');               
                             });
        };
        
        
 //**********************************************************
	//           GET DATASET SINGLE RECORD
	//**********************************************************      
  $scope.getDatasetSingleRecord = function (relatedDatasetId,recordId) {
  
  
         var lang = $rootScope.globals.currentUser.lang;
        
         ResourcesDataService.getResourceDataDirectFilter(relatedDatasetId,0,lang,{id: recordId}).then(function(data) {
                                
                                     $scope.gridOptionsRelated.data = data.data;
                                     $scope.gridOptionsRelated.columnDefs = data.columnsDef;
                                   //debugger
         //                            MessagingService.addMessage(data.msg,'success');
                                  },function(error){
                                    MessagingService.addMessage(error.msg,'error');               
                             });
        };
        
        
          if ($scope.resourceId != undefined)
          {
              UserService.getUsersGroups('users').then(function (data){
                 $scope.getResourceColumns();
                
              },function(error){
                 MessagingService.addMessage(error.msg,'error');
              });
          }
// OVO JE KOD MENE BILO a nemas ti - VIDJETI MIGOR TODO - vidjeti treba li
    $scope.gridOptions.onRegisterApi = function (gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
         gridApi.selection.on.rowSelectionChanged($scope,function(row){
       
          $scope.resourceRecordSelected=row.isSelected
       
           if(row.isSelected){
              $scope.selectedRecordId=row.entity.id
            }
     
     
        });

            
          }

     $scope.gridOptions.data = $scope.data;
	 
	 
	 
	 $scope.openSettings = function(widget) {
		 console.log("opensettings!");
			
		};
     
    }

//**********************************************************
//          resourceMAPPINGS ctrl 
//**********************************************************
export function mappingsFormCtrl ($scope, $rootScope, AuthenticationService,ResourcesDataService,ProcessingService,MessagingService,$stateParams,$timeout,GridsterService,$element,dragularService) {
      $scope.CTRL='mappingsFormCtrl';
      $scope.resourceId=$stateParams.id;
      $scope.formLoaded = false;
      $scope.gridsterType ="dataset";
      $scope.dashboard= {widgets:{layout:[]}};
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()
      var lang=$rootScope.globals.currentUser.lang;
      $scope.dashboard.widgets = GridsterService.getEmptyDatasetForm();
      
      // SELECTED RESOURCE CALLBACKS
        $scope.onSelectDataset= function (item){

                    ResourcesDataService.getResourceFields(item.id,lang).then(function(data) {               
                               $scope.fieldResources = [];
                               //debugger
                                
                               data.data.forEach( dfield => {
                                  $scope.fieldResources.push(  { 'id': dfield.id, 'dbtype':dfield.Dbtype, 'FieldName': dfield.FieldName, 'FieldType': dfield.FieldType,'ResourceDefId':dfield.ResourceDefId })
                               })

                                },function(error){
                              MessagingService.addMessage(error.msg,'error'); 
                    });                              
        }
     
     //Dodao funkciju getTransformFields jer ova druga sa 3 argumenta ne radi($scope.getTransformFields = function (transformId,field1,field2) )
	 $scope.getTransformFields = function (transformId) {
    
         var lang = $rootScope.globals.currentUser.lang;
         
         ResourcesDataService.getTransformFields(transformId).then(function(data) {
                               
                             $scope.transformFields = data.data;
                                   
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };  
        
     var fileel= document.getElementById('myfile');
     //debugger
      fileel.addEventListener('change', function(e) {
   
          //after file is picked we process its content
          var files = e.target.files;
          if (files.length) {
                  var r = new FileReader();
                  r.onload = function(e) {
                      //debugger
                      var contents = e.target.result;
                      $scope.fileContent = contents;
                     
                      $timeout(function() { $scope.extractFieldsFromFile(); });                      
                  };
                  
                  r.readAsText(files[0]);
                }
            // apply changes to angular with $timeout
			$timeout(function() { });
      });
            
            
            
        
        $scope.showGrid=false;
        $scope.gridOptions = {
            enableGridMenu: true,
            showGridFooter: true,
            //showColumnFooter: true,
            enableRowSelection: true,
                multiSelect: false,
                enableHorizontalScrollbar : 0, 
                enableVerticalScrollbar : 2,
                //enableFiltering: true,
                enableRowHeaderSelection: true,
                enableColumnResizing: true,
           // useExternalPagination: true,
            paginationPageSizes: [5,10, 25, 50,100,200,500],
            enablePaginationControls: true,
    }
    
     $scope.gridOptions.columnDefs = [
    { name:'id', width:50 },
    { name:'name' },
  ];

 /* kOD MENE JE OVDJE VELIKI KOMAD KOJI PUNI GRIDOPTIONS2 - NE ZNAM DA LI to treba evo ga komentiranog*/
      // TRANSFROM GRID
      $scope.gridOptions2 = {
            columnDefs: [
             { name: 'id', displayName: 'Transform ID', type: 'number', width:'15%',enableHiding: false, visible: true },
             { name: 'transform_name', displayName: 'Transform unique name'},
             { name: 'name', displayName: 'Transform display name'},
             { name: 'field_type1.name', displayName: 'Field 1 type' },
             { name: 'field_type2.name', displayName: 'Field 2 type ' },
             ],
          enableGridMenu: true,
            showGridFooter: true,
        showColumnFooter: true,
            enableRowSelection: true,
           multiSelect: false,
           enableHorizontalScrollbar : 2, 
          enableVerticalScrollbar : 2,
           enableFiltering: true,
           enableRowHeaderSelection: true,
           enableColumnResizing: true,
       }
       
       $scope.gridOptions2.onRegisterApi = function (gridApi) {
        $scope.gridApi2 = gridApi;
          gridApi.selection.on.rowSelectionChanged($scope,function(row){
          
            $scope.transformSelected=row.isSelected;
            $scope.selectedTransform = row.entity.id;
           
            if ($scope.transformFields!=undefined) $scope.transformFields.length=0;
            
            if ($scope.transformSelected)
            {
             $scope.getTransformFields(row.entity.id);
            }
          });
     }

     //kad se selektira mapping na gridu 
     $scope.gridOptions.onRegisterApi = function (gridApi) {
         $scope.gridApi = gridApi;
     
         gridApi.selection.on.rowSelectionChanged(
		 
		 $scope,function(row){
             $scope.isSelected=row.isSelected;
             $scope.selectedId = row.entity.id;
             $scope.mappingName = row.entity.name;
             $scope.items.length=0; //vidjeti da li treba
             $scope.items=JSON.parse(row.entity.fileFields.replace(/'/g,"\""));
             $scope.items2.length=0;//vidjeti da li treba
             $scope.items2=JSON.parse(row.entity.resourceMapping.replace(/'/g,"\""));
           
        
               // dragular items2
                  var dragularContainerClasses=[];
                  var dragularContainerModels = [];
                  for(var i=0;i<$scope.items2.length;i++) {
                              dragularContainerModels.push($scope.items2[i].cells);
                              dragularContainerClasses.push('exampleRows'+i);         
                 }
                 $scope.setupDragular4(dragularContainerModels,dragularContainerClasses, false);
                 
               //dragular items
               var dragularContainerClasses=[];
               var dragularContainerModels = [];    
               for(var i=0;i<$scope.items.length;i++) {
                            dragularContainerModels.push($scope.items[i].cells);
                            dragularContainerClasses.push('exampleRow'+i);         
               }
                $scope.setupDragular4(dragularContainerModels,dragularContainerClasses, false);
             
         
          });//on.rowSelectionChanged
     }
     
     
     
         $scope.recursiveTraverse  = function (obj,  patchData)      { //  recursively patching dashboard object
               
                var o = {};
                
                if  (obj && obj.hasOwnProperty('FieldType')) 
                                   patchData.push({cells:[],name:obj.Name,itemID:obj.ItemId,presentationId:obj.PresentationId,placeholder:obj.Placeholder});
                
                if (obj)  Object.keys(obj).forEach(
                    function(key) {
                       
                        if ( (typeof(obj[key]) === 'object') && obj[key]   ) {  //container level
                            //if (  (key === 'codesDefinitionList') || (key ==='publishedResourceList') || (key==='resourceList') || (key==='datasetListParent') || (key=='localVars') || (key=='tagList')  ) {
                           
                         $scope.recursiveTraverse(obj[key],  patchData)
                        } 
                        else {   // leaf level
                                if (key == 'nesto' ) {
                                   // o['id']= obj[key];
                                }    
                                
                            }
                        }) ; 
                
                //if (Object.keys(o).length > 0) ..do somoething with o   
            };
            
            
        $scope.fillItems2 = function (sourceDashboard) {
           // Prepare Resource fields for drag&drop  
           
           var res=[]
           
            $scope.recursiveTraverse(sourceDashboard,  res)
            
            $scope.items2 = res
            
          /* var tmp=[];
           for (var w = 0 ;w <  $scope.dashboard.widgets.length;  w++) 
              for (var l = 0;  l< $scope.dashboard.widgets[w].layout.length; l++) 
              for (var ll = 0;  ll< $scope.dashboard.widgets[w].layout[l].layout.length; ll++) {
                  //console.log($scope.dashboard2.widgets[w].layout[l].layout[ll]);
                  tmp.push($scope.dashboard.widgets[w].layout[l].layout[ll]);
              }
                                                                 
              for(var i=0;i<tmp.length;i++) {
                 $scope.items2.push({cells:[],name:tmp[i].Name,itemID:tmp[i].ItemId,presentationId:tmp[i].PresentationId,placeholder:tmp[i].Placeholder});        
               } 
*/


               // dragular items2
               var dragularContainerClasses=[];
               var dragularContainerModels = [];
              for(var i=0;i<$scope.items2.length;i++) {
                          dragularContainerModels.push($scope.items2[i].cells);
                          dragularContainerClasses.push('exampleRows'+i);         
             }
             $scope.setupDragular4(dragularContainerModels,dragularContainerClasses, false);
             
        } //end_funtion
      
        $scope.setupDragular4 = function(dragularContainerModels,dragularContainerClasses, isCopyOn){
                                      $timeout(function(){
                                          var containers=[];
                                          
                                         for (var i =0;i<dragularContainerClasses.length;i++){
                                            var container = document.getElementsByClassName(dragularContainerClasses[i]);
                                            containers.push(container[0]);
                                         }
                                         
                                         dragularService(containers,
                                           {
                                               classes: { },
                                               namespace:'shared',
                                               revertOnSpill: false,
                                               copy: isCopyOn,
                                               containersModel: dragularContainerModels,                                            
                                               moves: function (el, container, handle) {
                                                   return handle.classList.contains("element-drag-handle");
                                                 }
                                            });
                                          });               
                            }
       
   
                             

            ResourcesDataService.getResourceDefListByType($rootScope.globals.currentUser.lang,'dataset').then(
                        function(data) {			
							$scope.datasetList = data.data;
                            //debugger
                         }
                        ,function(error){   MessagingService.addMessage(error.msg,'error')	}
            );

                             
                             
             ResourcesDataService.getMappingsList($scope.resourceId).then(function(data) {
                    //MessagingService.addMessage(data.msg,'success');
                    $scope.gridOptions.data = data.data;
                                      },function(error){
                                      MessagingService.addMessage(error.msg,'error');  
            });
            
      
        $scope.items = [
            { cells: [],
              name:'FILE_ITEMS' 
            }
       ];
       
       
       
        $scope.items2 = [
       ];
      
        $scope.transformItems = [
            { cells: [],
              name:'TRANSFORMS' 
            }
       ];
       
      $scope.fileFields=[];
    
      // U NULTI stavi polja od fajla (fields)
      for(var i=0;i<$scope.fileFields.length;i++) {
                $scope.items[0].cells.push({name:$scope.fileFields[i]});
      }
      
       //dragular items
       var dragularContainerClasses=[];
       var dragularContainerModels = [];    
       for(var i=0;i<$scope.items.length;i++) {
                    dragularContainerModels.push($scope.items[i].cells);
                    dragularContainerClasses.push('exampleRow'+i);         
       }
        $scope.setupDragular4(dragularContainerModels,dragularContainerClasses, false);
      
                                         
       
      $scope.getResourceForm = function () {//in mappingsFormCtrl
          //debugger
        ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(function(data) {
                                //debugger
                                if (data.data.widgets.length>0)
                                {
                                     $scope.dashboard.widgets=data.data.widgets;
                                }           
                                $scope.resourceDefinition= data.data.resourceDefinition;
                                $scope.fillItems2($scope.dashboard.widgets);  
                                              
                                //**********************************************************
                                //          Drag And Drop dragular
                                //**********************************************************
                                  $timeout(function(){
                                    var container = $element.children().eq(0).children().eq(2).children(), widgets = $scope.dashboard.widgets;
                                    // scope.widgetHandle = scope.setHandle('outer');                  
                                    dragularService(container,
                                      {
                                      nameSpace: 'row-layout',
                                      //copy: true,
                                      classes: {
                                      //mirror: 'show-border'
                                          },
                                      revertOnSpill: true,
                                      containersModel: widgets,
                                      //direction:'vertical',
                                        moves: function (el, container, handle) {
                                          return handle.classList.contains("row-"+$scope.gridsterType+"-handle");
                                        }
                                 
                                       }
                                       );
                                     }
                                   );
                                
                                
                                   $timeout(function() {$scope.formLoaded=true;},1000);
                                },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                             });
      };
  
      if ($scope.resourceId != undefined)
      {
        $scope.getResourceForm();
        //MIGOR - kod dolje ne popunjava sva polja već samo resourceID,treba ga valjda negdje drugdje ubaciti  
      }
      

        $scope.saveMappings = function (mapId) {
           if (mapId==undefined) MessagingService.addMessage('Mapping not selected!','error');  
           else
           if (mapId=='New') {            
             ResourcesDataService.putMappings($scope.mappingName,$scope.resourceId,$scope.items2,$scope.items).then(function(data) {
                            MessagingService.addMessage(data.msg,'success');
                            $scope.showDetails=false;
                            $scope.refreshMappingsList();
               },function(error){
                                      MessagingService.addMessage(error.msg,'error');  
                                      })
         }//if 
         else  ResourcesDataService.putMappingsWithId($scope.mappingName,$scope.resourceId, mapId, $scope.items2,$scope.items).then(function(data) {
                   MessagingService.addMessage(data.msg,'success');
                   $scope.showDetails=false;
                   $scope.refreshMappingsList();
                                      },function(error){
                                      MessagingService.addMessage(error.msg,'error');  
            })
            
       //  $scope.refreshMappingsList();
         
        } //saveMappings_END
        
  
    $scope.refreshMappingsList = function() {
          //refresh MAPINGLIST       
          ResourcesDataService.getMappingsList($scope.resourceId).then(function(data) {
                    $scope.gridOptions.data = data.data;
                                      },function(error){
                                      MessagingService.addMessage(error.msg,'error');  
          }); 
    }
      
    $scope.extractFieldsFromFile = function () {
             
           console.log($scope.fileContent);  
            $scope.items[0].cells.length=0;

            var lines = $scope.fileContent.split('\n');
            $scope.fileFields = lines[0].split(/[;,]+/) //Currently splitting on ; and ,
            
             for(var i=0;i< $scope.fileFields.length;i++) {
                   var tmp=$scope.fileFields[i];
                   if (tmp.charCodeAt(tmp.length-1)==10)  tmp=tmp.substring(0,tmp.length-1) //makni CR ili LF
                   if (tmp.charCodeAt(tmp.length-1)==13)  tmp=tmp.substring(0,tmp.length-1) //makni CR ili LF
                   if  ((tmp[tmp.length-1]=='"') && (tmp[0]=='"')) tmp=tmp.substring(1,tmp.length-1);//makni navodnike ako ih ima
                   $scope.items[0].cells.push({name: tmp});     
            }
            
            
            console.log($scope.fileFields);
        
        }    
     
    
    $scope.mapping = function (mappedResource) {
    
           //$scope.activity.activityMessage = ProcessingService.setActivityMsg('Syncing database for '+$scope.resourceId);
         console.log(mappedResource);
         
         //ovo dolje je obsolete jer će se puniti na početku prikaza forme
         //debugger
         ResourcesDataService.getResourceForm(mappedResource,'i',lang).then(function(data) {
                                $scope.dashboard2=[];
                                //debugger
                                if (data.data.widgets.length>0){
                                     $scope.dashboard2.widgets=data.data.widgets;
                                                     var tmp=[];
                                                     for (var w = 0 ;w <  $scope.dashboard2.widgets.length;  w++) 
                                                        for (var l = 0;  l< $scope.dashboard2.widgets[w].layout.length; l++) 
                                                        for (var ll = 0;  ll< $scope.dashboard2.widgets[w].layout[l].layout.length; ll++) {
                                                            //console.log($scope.dashboard2.widgets[w].layout[l].layout[ll]);
                                                            tmp.push($scope.dashboard2.widgets[w].layout[l].layout[ll]);
                                                        }
                                                                                                            
                                                        //kreirati items2 iz tmp 
                                                        for(var i=0;i<tmp.length;i++) {
                                                           $scope.items2.push({cells:[],name:tmp[i].name,itemID:tmp[i].itemID,presentationId:tmp[i].presentationId});        
                                                         } 
                                                         //pozvati dragular
                                                             var dragularContainerClasses=[];
                                                            var dragularContainerModels = [];
         
                                                        for(var i=0;i<$scope.items2.length;i++) {
                                                                    dragularContainerModels.push($scope.items2[i].cells);
                                                                    dragularContainerClasses.push('exampleRows'+i);         
                                                       }
                                                      
                                                       $scope.setupDragular4(dragularContainerModels,dragularContainerClasses, false);  
                               

                                } 

                                 //MIGOR - ovo ispod nisam siguran da treba!!!! VIDJETI!!
                                $scope.resourceDefinition2= data.data.resourceDefinition;
                                                         
                                  $timeout(function(){
                                    var container = $element.children().eq(0).children().eq(2).children(), widgets = $scope.dashboard2.widgets;
                                    // scope.widgetHandle = scope.setHandle('outer');                  
                                    dragularService(container,
                                      {
                                      nameSpace: 'row-layout',
                                      //copy: true,
                                      classes: {
                                      //mirror: 'show-border'
                                          },
                                      revertOnSpill: true,
                                      containersModel: widgets,
                                      //direction:'vertical',
                                   
                                        moves: function (el, container, handle) {
                                          return handle.classList.contains("row-"+$scope.gridsterType+"-handle");
                                        }
                                 
                                       }
                                       );
                                     }
                                   );
                                
                                   $timeout(function() {$scope.formLoaded=true;},1000);
                                },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                             });                      
      
        
        ResourcesDataService.putMappings($scope.mappingName, $scope.resourceId,$scope.items2,$scope.items).then(function(data) {
               MessagingService.addMessage(data.msg,'success');
                                  },function(error){
                                  MessagingService.addMessage(error.msg,'error');  
        });
        
       }; //$scope.mapping()_END    

        
    //  Get transform packages from server and prepare drag&drop (transformItems)
     $scope.getTransformPackages = function () {
             var lang = $rootScope.globals.currentUser.lang;

             ResourcesDataService.getTransformPackages(lang).then(function(data) {
                                              
                                  $scope.gridOptions2.data = data.data;
                                  $scope.transformItems[0].cells.length=0;
  
                                  //debugger                                       
                                  for(var i=0;i< data.data.length;i++) {
                                            var params2JSON={} 
                                            if (data.data[i].Params) { 
                                                params2JSON=JSON.parse(data.data[i].Params.replace(/'/g,"\""));   
                                            }
                                            
                                            $scope.transformItems[0].cells.push({name: data.data[i].TransformName,type:'TRANSFORM', params: params2JSON});     
                                        }
                                        
                                  var dragularContainerClasses=[];
                                  var dragularContainerModels = [];    
                                  for(var i=0;i<$scope.transformItems.length;i++) {
                                     dragularContainerModels.push($scope.transformItems[i].cells);
                                     dragularContainerClasses.push('exampleRowz'+i);         
                                  }
                                    $scope.setupDragular4(dragularContainerModels,dragularContainerClasses,true);
     
                                    },function(error){
                                  MessagingService.addMessage(error.msg,'error');  
                                });
        };  
    
     
    
      $scope.getTransformPackages(); //MIGOR TODO - vidjeti da li ovdje ili negdje druge staviti

         
}//resourceMAPPINGScontroler
 
 

//import ktToolbox from '../form/components/kt-toolbox.vue';
 
export function toolboxComponent () {
        
    let vueToolbox =  new Vue({
        el: '#toolbox',
             
        components: {
               // 'kt-toolbox': ktToolbox,
                },
        
        created: function(){
      
      
       
        }
  });
        
}

	 //**********************************************************
	//          resourceFormComponent - vuejs version
	//**********************************************************


import GridsterService from '../../../0000_common/0004_modules/gridsterModule.js';

export function resourceFormComponent ($rootScope, KatalyoStateManager,ResourcesDataService,MessagingService,$stateParams)
{
        var resourceId=$stateParams.id;
        var lang=$rootScope.globals.currentUser.lang;
        
        let vueResourceForm =  new Vue({
        el: '#resourceForm',
        data: {
          resourceId: resourceId,
          resource: {},
          resourceLoaded :false,
          lang: lang,
          formLoaded: false,
          dashboard: {widgets:{layout:[]}},
          resourceDefinition: {formParams:{formViewType:null}},
          currentStateIndex:0,
          datasetDeploy:{},
        },
      methods: {
                getResourceForm : function () {
                        var vm = this;
                        this.formLoaded=false;
                        //debugger
                        ResourcesDataService.getResourceForm(this.resourceId,'i',this.lang).then(function(data) {
                              //
                                //if (data.data.widgets.top_rows.length>0)
                                if (data.data.widgets.length>0)
                                {
                                   //  $scope.dashboard.widgets={layout:data.data.widgets.top_rows};
                                   vm.dashboard.widgets={layout:data.data.widgets};
                                   
                                }else vm.dashboard.widgets={layout : GridsterService.getEmptyDatasetForm()};
                                
                                
                                //datasetFormState.subject.setState=$scope.dashboard.widgets;
                                
                                //set state here
                                
                                vm.resourceDefinition= data.data.resourceDefinition;
                                
                                 vm.resourceDefinition.formParams = {formViewType:1,formLocked:true};
                                                  
                                //GridsterModule.setFormViewType($scope.resourceDefinition.formParams.formViewType);
                                   vm.formLoaded=true;
                                   
                                },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                             });
      
                },
                
                undo:function() {
                let vm = this;
          
                },
                
                redo:function() {
                let vm = this;
          
                },
                showDatasetDeploy:function() {
                let vm = this;
          
                },
                deployToDb:function() {
                let vm = this;
          
                },
                ShowFormView:function() {
                let vm = this;
          
                },
                saveResourceForm:function() {
                let vm = this;
          
                }
      
    },
    computed: {
        
        getTopForSticky:function(){
               
         return GridsterService.getTopForSticky(0);
        
        
        }
    },
    components: {
               // 'kt-resource-definition': kt_rd,
                },
    created: function(){
      
      
        var vm = this;
        if (this.resourceId != undefined)
        {
        
        ResourcesDataService.getResourceDefAll(this.resourceId).then(function(data) {
                               
                vm.resource = data.data;
                vm.resourceLoaded = true;
                
        },function(error){
                              
                MessagingService.addMessage(error.msg,'error');  
        });
        this.getResourceForm();
        
      }
      
      //import {AuthenticationClass,Login} from './authModule';
      
    }
    
  });
      
}


	 //**********************************************************
     
    //   searching dashboard for "relatedrmd" parents (files, resources)
    
	//**********************************************************

   export function getParents (obj, outputList)  //find recursively
   {
        let o = {};
        var parentWidget = {};
        Object.keys(obj).forEach(function(key) {
            if ( (typeof(obj[key]) === 'object') && obj[key]  && key != 'NameField' && key != 'Parameters' && key != ' GridState')  { //Do NOT parse into 'NameField' and "Parameters' 
                    getParents(obj[key], outputList)
            }   else {          
                   if ( ['RelatedRmd', 'DirectiveName', 'Label', 'FieldName' , 'ResourceModelDefinitionId' ,'ElementType','ItemId', 'ResourceDefId' , 'PresentationId'].includes(key)  ) {  
                        o[key]= obj[key];
                        parentWidget=obj
                        }   
                }
            }) ; 
        
        if (Object.keys(o).length > 0 
            && o['ElementType']=='resource')  {
                o['widget']=parentWidget
                outputList.push(parentWidget) 
        }
    }; 
    
	 //**********************************************************
	//          getFieldNamesFromObject
    //
    // find FieldNames in given Dashboard object recursively 
     // fieldsList  is return variable 
	//**********************************************************

   export function getFieldNamesFromObject (obj, fieldsList)   // find FieldNames in given Dashboard object recursively  
   {
        let o = {};
        var parentWidget = {};
        Object.keys(obj).forEach(function(key) {
            if ( (typeof(obj[key]) === 'object') && obj[key]  
                    && key != 'NameField')   //Do NOT parse into 'NameField'  (we need it as it is)
            { 
               getFieldNamesFromObject(obj[key], fieldsList)
            } 
            else {          
                    if ( ['DirectiveName', 'Label', 'FieldName' , 'ResourceModelDefinitionId' ,'ElementType','ItemId', 'ResourceDefId' , 'PresentationId'].includes(key)  ) {  
                        o[key]= obj[key];
                        parentWidget=obj
                        }
                    if ( key == 'NameField' ) {
                       //debugger
                        if (Array.isArray(obj[key]) ) o[key]= obj[key][0];
                        else o[key]= obj[key];
                    }      
                     
                }
            }) ; 
        
        if (Object.keys(o).length > 0 && !(['ktGridRow','ktGridColumn'].includes(o['DirectiveName'] ) )) 
        {
                o['widget']=parentWidget
                fieldsList.push(o) 
        }
    }; 


	 //**********************************************************
	//          resourceFormCtrl - angularJs version
	//**********************************************************

export function resourceFormCtrl ($scope, $rootScope, AuthenticationService,KatalyoStateManager,ResourcesDataService,CodesService,ProcessingService,MessagingService,WidgetRegisterService,$stateParams,$timeout,GridsterService,MenuService,$element,dragulaService,$state)
{      
    
    $scope.gsrvc = GridsterService;  
    $scope.ksm = KatalyoStateManager;
    $scope.localVars = {   gsrvc:$scope.gsrvc, PropertiesSize:'standard'};
    $scope.formVars = {  };
    $scope.resource = {}
    $scope.parentResources=[]
    $scope.CTRL='resourceFormCtrl';
    $scope.formLoaded = false;
    $scope.gridsterType ="dataset";
    var lang=$rootScope.globals.currentUser.lang;
    $scope.resourceId=$stateParams.id;
    $scope.SavedButNotDeployed = false
    $scope.isCollapsedVerHistory = true
    $scope.localVars.publishedResources = []
    $scope.returnState=$stateParams.returnState
    $scope.datasetDeploy=[]
    $scope.datasetDeployCols=[
		{label:'ID',name:'id',len:1,type:'id'},
		{label:'Field name',name:'label',len:3},
		{label:'Field type',name:'dbtype',len:2},
		{label:'Table column',name:'field',len:4},
		{label:'Max len',name:'maxLength',len:1},
		{label:'Required',name:'required',type:'bool',len:2},
		{label:'Deployed',name:'deployed',type:'bool',len:2},
		{label:'Status',name:'status',len:2},
		{label:'Action',name:'deployAction',len:1,type:'mapping'},
		{label:'Version',name:'version',len:2},
		{label:'Deployed version',name:'deployedVersion',len:2}
    ]

    $scope.deployActionMappings={'A':'Add','C':'Change','D':'Delete','N':'No change'}

    $scope.gsrvc.resetBodyBgColor()
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()

    if ($scope.returnState===undefined || $scope.returnState==='')
    {

       $scope.returnState={state:{name:'app.navbar.resources.list'},Label:'Return to resource list',params:{}} 
    }
    $scope.resourceDef = {ResourceDefId_id: $scope.resourceId,form_definition : true}
      
    let massUpdateState= $scope.ksm.addSubject('massUpdateState',null);
    let massUpdateStateName = massUpdateState.subject.name;
    $scope.ksm.executeAction(massUpdateStateName,"massUpdateChanged", {Active:false,Item:null}); 
                    
    $scope.get_published_resource_forms=function(){               
        ResourcesDataService.GetPublishedResources($scope.resourceId).then(function (data) {       
           $scope.localVars.publishedResources = data.data;
        });
    }
    $scope.get_published_resource_forms();
    $timeout(function() {   $scope.definitionType = 'form';         });
    
    /*
     $scope.$watch('dashboard.widgets.layout',function(newValue,oldValue){ 
        console.log("promjena!")
        console.log(newValue)
     },true)
         
    */

    $scope.localVars.ChangePropertiesSize = function()
    {
       let propElement = document.getElementsByClassName("propertiesContainer");
        
       $scope.propertiesTop = "";
       
      if ($scope.localVars.PropertiesSize=='standard')
      {
        $scope.localVars.PropertiesSize = 'large';
       $scope.propertiesTop = $scope.gsrvc.calcTopForExtendedProperties(propElement[0]); 
      
      }
      else $scope.localVars.PropertiesSize='standard';
    }
   
   
   
    $scope.PreparePublish2Prod = function()
    {
        ResourcesDataService.preparePublish2Prod($scope.resourceId,$scope.resource.ResourceCode,$scope.resource.Version).then(
			function(data) {               
				$scope.publishedResource = data.data;
				/*
				if ($scope.publishedResource?.rmd) { 
					$scope.publishedResource.rmd.forEach( item => {	
					})
				}*/                                                      
			},function(error){			  
				MessagingService.addMessage(error.msg,'error');  
			});
    }

    $scope.Publish2Prod = function()
    {
        $scope.publishingDataset=true
        let resources = [{uid:$scope.resourceId,itemSubType:$scope.resource.ResourceCode,version:$scope.resource.Version }]
        ResourcesDataService.publish2Prod(resources).then(function(data) {
                               
            MessagingService.addMessage(data.data.msg,'success'); 
            $scope.publishedResource.Published = data.data.Published
            $scope.publishedResource.Deployed = data.data.Deployed
            $scope.publishedResource.Version = data.data.Version
            $scope.resource.Version=$scope.publishedResource.Version
            $scope.publishedResource.PublishedVersion =  data?.data?.PublishedVersion
            $scope.publishedResource.DateTime = data.data.DateTime
            $scope.publishedResource.PublishedBy = data.data.PublishedBy
            $scope.publishedResource.ChangedDateTime = data.data.ChangedDateTime
            $scope.publishedResource.ChangedBy = data.data.ChangedBy
            $scope.publishedResource.count = 0 
            $scope.publishedResource.rmd = data.data.rmd
            $scope.publishingDataset=false
                                                               
        },function(error){
            $scope.publishingDataset=false                
            MessagingService.addMessage(error.msg,'error');  
        });
    }
    

    let datasetform_changed = function(data)
    {
        datasetFormState.subject.setStateChanges = data;
        
        currentIndex = datasetFormState.subject.getCurrentStateIndex;
        
        if (currentIndex<0) undo=false;
        else undo = true;
        
        if (currentIndex==datasetFormState.subject.stateChanges.length-1) redo=false;
        else redo=true;
        
        $scope.currentStateIndex ={'undo':undo ,'redo':redo}; 
        
        datasetFormState.subject.notifyObservers();
        
    };
    
    let datasetform_undo = function(data)
    {
                undoIndex = datasetFormState.subject.getCurrentStateIndex;
                
                undoIndex--;
                if (undoIndex<0) undo=false;
                else undo = true;
                
                if (undoIndex==datasetFormState.subject.stateChanges.length-1) redo=false;
                else redo=true;
                $scope.currentStateIndex ={'undo':undo ,'redo':redo};
                
                datasetFormState.subject.notifyObservers("undoDatasetFormChange");
                
    };
    
      let datasetform_undo_done = function(data)
    {
 
                currentIndex = datasetFormState.subject.getCurrentStateIndex;
                currentIndex--;
                datasetFormState.subject.setCurrentStateIndex = currentIndex;
               
    };
    
    var datasetform_redo = function(data)
    {

                currentIndex = datasetFormState.subject.getCurrentStateIndex;
                currentIndex++;
                datasetFormState.subject.setCurrentStateIndex = currentIndex;
                
                if (currentIndex<0) undo=false;
                else undo = true;
                
                if (currentIndex==datasetFormState.subject.stateChanges.length-1) redo=false;
                else redo=true;
                
                $scope.currentStateIndex ={'undo':undo ,'redo':redo};
                datasetFormState.subject.notifyObservers("redoDatasetFormChange");
    };
      

    
    $scope.undoRedoChanges = function(actionType)
    {
        
        $scope.ksm.executeAction(datasetFormStateName,actionType+"DatasetFormChange");
        $scope.showHideProperties(false);
        
    };
    
      
    $scope.showHideProperties = function(value)
                {
                  
                  
                  $scope.gsrvc.setFieldPropertiesOpened(value);
                  $scope.showFieldProperties=$scope.gsrvc.getFieldPropertiesOpened();
                 // if (value) $scope.gsrvc.setPropertiesPosition();
                };


   
       $scope.ShowFormView = function(viewId,locked)
      {
       
        $scope.resource.formParams.formViewType=viewId;
        $scope.resource.formParams.formLocked = locked;
        $scope.gsrvc.setFormViewType($scope.resource.formParams.formViewType);
        
      };
      
       CodesService.getCodesByName('resourcetype',lang).then(function (data) {
          //debugger;
                                $scope.resourceTypeList = data.data;
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                                $scope.statusesDownloaded= true;
                             });
  
  
  
    $scope.getResourceDefinition = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                            // MessagingService.addMessage(data.msg,'success');
                            $scope.resource = data.data;
                             
                            $scope.resourceDefDownloaded=true

                            //get resource published info (from PROD instance)
                            $scope.PreparePublish2Prod()

                            ResourcesDataService.GetWidgetToolbox($scope.resource.ResourceCode).then(function (data) {
                                    $scope.gsrvc.setWidgetToolboxItems(data.data,$scope.gridsterType);
                                    $scope.getResourceForm();
                            },function(error){
                              
                                     MessagingService.addMessage(error.msg,'error');  
                            });  
                                                               
            },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
        });
    };      
        
        
        //$scope.getResourceDefinition();
        
        $scope.$on('CtrlPBPressed',function(event,data){
                  
                let key=data;
                if (key=='b' || key=='B')
                {
                    if ($scope.resource.formParams.formViewType!=1) $scope.ShowFormView(1);
            
                }
                if (key=='p' || key=='P')
                {
                    if ($scope.resource.formParams.formViewType!=2) $scope.ShowFormView(2);
                }
                  
        });     
       
        $scope.getInnerHtml = function()
                
        {
                //$scope.innerHtml  = document.querySelector('div[kt-grid]').innerHTML;
                $scope.resource.FormDef = document.querySelector('div[kt-grid]').innerHTML;
                //console.log(document.querySelector('div[kt-grid]').innerHTML);
                //$scope.innerHtml = elem;
                        
        }
        
        $scope.setImmutable = function () {
          
           if (!$scope.saveSuccess) $scope.resource.IsBlockchain = !$scope.resource.IsBlockchain;
        };
        
        $scope.DatasetData = function () {
          
                let params={id:$scope.resourceId};
                $state.go("app.navbar.resources.data",params)
        };

         $scope.existingRelationships = []
         $scope.existingRelationshipsCopy=[]
        /*********************************************************************************************/
        /************************ GET CHILD RELATIONSHIPS *************************/
        /*********************************************************************************************/
       $scope.getExistingResources = function()  {

            
            $scope.existingRelationships.length=0
            $scope.RelatedLoaded=false
             
          
            
            ResourcesDataService.getExistingResourceRelationships($scope.resourceId).then(function(data) {
                                        
                            $scope.existingRelationships = data.data;
                            $scope.existingRelationshipsCopy = angular.copy($scope.existingRelationships)
                            $scope.RelatedLoaded=true;
               
                     },function(error){    
                                MessagingService.addMessage(error.msg,'error');
                            })
  
       };    
          
        $scope.getExistingResources()

        $scope.checkDroppedChildren = function(child)
        {
                  
            let item = $scope.existingRelationships.find(element => element.id == child.id)

            if (item == undefined) return true
            else return false
        };
      
      
      
        $scope.saveResourceDef = function (isValid)
        {
              if (isValid)  {
                    ResourcesDataService.SaveResourceDef2 ($scope.resource).then(
                        function(data) {      
                                   if (data.status===200 || data.status===201 )     MessagingService.addMessage("Resource saved successfuly",'success');  
                         },function(error){     
                                  MessagingService.addMessage(error.msg,'error');  
                                });
                
              } else {  
                MessagingService.addMessage("Please enter required data",'warning'); 
              }
        };      
        
      
       $scope.SetDefinitionType = function(value) //TODO
        {
            $scope.definitionType=value;
            if (value=='datasource') $scope.EditDataset();
        }
        
        
        $scope.clearResourceParameters = function() {
         
            if ($scope.resource.Parameters ==undefined ||  $scope.resource.Parameters==null)
                {
                 $scope.resource.Parameters = {extDataSourceParams:{formFieldsList:[]} };      
                } else
                {
                   if ($scope.resource.Parameters.extDataSourceParams == undefined || value == false)  { 
                        $scope.resource.Parameters.extDataSourceParams={ formFieldsList:[] };                 
                  } else  $scope.resource.Parameters.extDataSourceParams.formFieldsList.length=0;      
                }  
        }
        
        
        
      $scope.EditDataset = function(value) //TODO
        {
                var localFieldsList=[] 
                 if ( $scope.resource.Parameters?.extDataSourceParams?.formFieldsList) 
                        getFieldNamesFromObject($scope.dashboard,   $scope.resource.Parameters.extDataSourceParams.formFieldsList);            
       };
       
       
                
      $scope.$watch('gsrvc.getFieldPropertiesOpened()',function(newValue,oldValue){
              
              if (newValue!=oldValue)
              {
               $scope.selectedField=$scope.gsrvc.getFieldProperties();
               
               $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
              }
              if ($scope.refreshProperties && $scope.showFieldProperties)
              {
                $scope.selectedField=$scope.gsrvc.getFieldProperties();
                $scope.refreshProperties=false;
               }
            });
      
       $scope.$watch('gsrvc.getFieldProperties()',function(newValue,oldValue){
              
              if (newValue!=oldValue)
              {
               $scope.selectedField=$scope.gsrvc.getFieldProperties();
               $scope.localVars.PropertiesSize ='standard';
               $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
              }
               if ($scope.refreshProperties && $scope.showFieldProperties)
               {
                $scope.selectedField=$scope.gsrvc.getFieldProperties();
                $scope.refreshProperties=false;
               }
            });
      
      
           
      $scope.getResourceForm = function () //in resourceFormCtrl
      {
          $scope.formLoaded=false;
          
          ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(
                function(data)    {
                   
                     
                     $scope.resource= data.data.resourceDefinition
                     if (data?.data?.widgets && data.data.widgets.length>0) {
                         $scope.dashboard.widgets={layout:data.data.widgets};
                     } else 
                         $scope.dashboard.widgets={layout : $scope.resource.ResourceExtended.DefaultForm};
                    
                    
                    //get resource published info (from PROD instance)
                            $scope.PreparePublish2Prod()

                            ResourcesDataService.GetWidgetToolbox($scope.resource.ResourceCode).then(function (data) {
                                    $scope.gsrvc.setWidgetToolboxItems(data.data,$scope.gridsterType);
                                },function(error){
                              
                                     MessagingService.addMessage(error.msg,'error');  
                            });  

                    getParents(  $scope.dashboard.widgets ,  $scope.parentResources)
                    
                    datasetFormState.subject.setState=$scope.dashboard.widgets;
                    $scope.resource.formParams = {formViewType:1,formLocked:true};                 
                    $scope.adminHeaderLabel="Select "+$scope.resource.ResourceCode+" administrators";                                                                                
                    $scope.gsrvc.setFormViewType($scope.resource.formParams.formViewType);

                    $timeout( function() {
                         $scope.formLoaded=true;
                        //if (!$scope.resource.Published && $scope.resource.Version==$scope.resource.NewVersion)    $scope.showPublish(false)
                    });
              },function(error){
                       MessagingService.addMessage(error.msg,'error');
                  });
      
      };
  
    $scope.getResourceForm();

  $scope.getDeployAction = function(action)
  
    {
    var deployActionMappings={'A':'Add','C':'Change','D':'Delete','N':'No change'};
    return deployActionMappings[action];
    };

    $scope.newVersion = function()
  
    {
        $scope.creatingNewVersion=true

        ResourcesDataService.SetNewResourceVersion($scope.resourceId)
                .then(function(data) {
                    if (data.status==200)
                    {
                    $scope.resource.NewVersion = data.data
                    $scope.resource.Published=false
                    }else MessagingService.addMessage(data.data,'error');
                    $scope.creatingNewVersion=false
                    $scope.hasChanges=false
         
                   },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.creatingNewVersion=false
                                 
                             });

    };


     //**********************************************************
	//           SAVE RESOURCE FORM DEFINITION
	//**********************************************************      
  $scope.saveResourceForm = function (val) {
     $scope.savingForm = true; //disable save button
     $scope.formLoaded=false; 
     $scope.showHideProperties(false);
     // First save the form

      ResourcesDataService.saveResourceForm($scope.dashboard.widgets.layout,$scope.resourceId,'i',$scope.localVars.newDescription)
                .then(function(data) {
                            if (data.data.formData.length>0)  {
                                $scope.dashboard.widgets={layout:data.data.formData};
                                $scope.resource.FormVersion = data.data.form_version;
                                $scope.resource.Version = data.data.version;
                                $scope.hasChanges=data.data.has_changes
                            }
                            $scope.definitionType = val;
                            var lang=$rootScope.globals.currentUser.lang;
                            MessagingService.addMessage(data.msg,data.msgType);
                            $scope.savingForm = false;
                                                         
                            $scope.formLoaded=true;
                            $scope.showPublish()
                   },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.savingForm = false;
                                  $scope.formLoaded=true;
                             });

  };

     $scope.showModal = function (p_type) {
       //POST publish-resource , resource_id, version i description
        //$scope.saveModal=true;
        $('#datasetPublish').modal('show'); 
        $scope.modalAction=p_type
        $scope.newPublishVersion = $scope.resource.Version
    }
   
   
   
   $scope.showPublish = function (val) {
       //POST publish-resource , resource_id, version i description
       $scope.modalAction='publish'
       $scope.datasetDeploy.length=0
       if (val==undefined) val = true
         ResourcesDataService.getResourceModelForDeployment($scope.resourceId,$scope.resource.Version,lang)
                                        .then(function(data) {
                                                $scope.datasetDeploy = data.data;
												$scope.datasetDeploy.forEach( (e) => e.dbtype = e.dbtype.replace('definition',''))	

                                                //debugger //getResourceModelForDeployment
                                                if ($scope.datasetDeploy.length==0 && $scope.hasChanges) 
													$scope.resource.Published = true
                                                else if ($scope.datasetDeploy.length>0) 
													$scope.resource.Published = false
                                                $scope.newPublishVersion = $scope.resource.NewVersion
                                                if (val) $('#datasetPublish').modal('show');     

          
                                          },function(error){
                                              if (error.status=904) MessagingService.addMessage('Nothing to deploy','info'); 
                                              else MessagingService.addMessage(error.msg,'error'); 
            });
                                              
   }
   
  
   //**********************************************************
    //           Publish resource
    //**********************************************************      
  $scope.save_resource = function (formValid) {
        
        let publish_type='form'
        if (formValid) {
            if ($scope.modalAction=='save')
            {
                $scope.saveResourceForm(publish_type)
            }else{
            $scope.publishingForm = true;
            //debugger
            ResourcesDataService.PublishResource($scope.resourceId,$scope.resource.Version,$scope.localVars.newDescription,publish_type)
                    .then(function(data) {
                                    MessagingService.addMessage(data.msg,'success');
                                    //$('#datasetPublish').modal('hide'); 
                                    $scope.publishingForm = false;
                                    $scope.resource.Published = true
                                    $scope.get_published_resource_forms()
                                    $scope.PreparePublish2Prod()
                                    $scope.datasetDeploy = []
                                    $('#datasetPublish').modal('hide');         
                                     //TODO - SKINUTI PONOVNO PUBLISHED RESOURCES
                    } ,function(error){
                                      MessagingService.addMessage(error.msg,'error');
                                      $scope.publishingForm = false;
                                    
                                 });
           }
            $scope.activity.activityMessage = ProcessingService.setActivityMsg("");
        }
  };
  
      //**********************************************************
	//           SAVE RESOURCE FORM DEFINITION
	//**********************************************************      
    /*
  $scope.saveResourceForm = function (isValid) {
     
         $scope.activity.activityMessage = ProcessingService.setActivityMsg('Saving resource form for '+$scope.resourceId);
         $scope.savingForm = true; //disable save button
         
         ResourcesDataService.saveResourceForm($scope.dashboard.widgets.layout,$scope.resourceId,'i')
                .then(function(data) {
                                //debugger      //ResourcesDataService.saveResourceForm(
                                if (data.data.formData.length>0) 
                                {
                                    $scope.dashboard.widgets={layout:data.data.formData};
                                    $scope.resource.Version = data.version;
                                    $scope.resource.FormVersion = data.form_version;
                                    $scope.resource.Published = true;
                                }
                                MessagingService.addMessage(data.msg,'success');
                                $scope.savingForm = false;
                                $scope.showHideProperties(false);
         
                   },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.savingForm = false;
                             });
         $scope.activity.activityMessage = ProcessingService.setActivityMsg("");
        };
        */
        
      $scope.closeDatasetDeploy = function (isvalid) {
         $scope.SavedButNotDeployed = true
         $('#datasetDeploy').modal('hide'); 
        //$scope.saveResourceForm(isvalid);
              
   
   }
   
       $scope.compiledb=function(){
             $('#datasetDeploy').modal('show'); 

       } 
    //**********************************************************
	//           SYNC DATABASE - CREATE OR ALTER TABLE
	//**********************************************************      
  $scope.syncdb = function () {
        //debugger //syncdb
        $scope.activity.activityMessage = ProcessingService.setActivityMsg('Syncing database for '+$scope.resourceId);
        if ($scope.resource.FormVersion===undefined || $scope.resource.FormVersion===null) $scope.resource.FormVersion=1
        ResourcesDataService.syncdb($scope.resourceId,$scope.resource.FormVersion)
                .then(function(data) {
                                 $scope.SavedButNotDeployed = false
                                 MessagingService.addMessage(data.msg,'success');
                                 $('#datasetDeploy').modal('hide'); 
                } ,function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                
                             });
        $scope.activity.activityMessage = ProcessingService.setActivityMsg("");
  };


   $scope.returnToPreviousPage = function()
        {
                let page = $scope.returnState.state.name
                //let navbar = MenuService.getPageNavbarByName(page);
                $state.go(page,$scope.returnState.params);
             
        }
  
   // ***********   ktFormResource INIT ******************
   

      $scope.extDataSourceTypeList = [{id:1,name: 'Database Table'},{id:2, name: 'API'}, {id:3, name: 'SmartContract'}]
      $scope.clearResourceParameters()
      
      if ($stateParams.subTypeSelected == 'standard') {
          //something
      }
     
      $scope.dataForGridReady = false;
      $scope.editDataset=false;
      $scope.dashboard={};
     
      $scope.gsrvc.destroyDragula('bag-dataset');
      $scope.gsrvc.destroyDragula('bag-columns');
      $scope.gsrvc.destroyDragula('bag-rows-'+$scope.gridsterType);
     //WidgetRegisterService.RegisterDatasetWidgets();
        
      $scope.showFieldProperties=false;
      
      //register to state manager
      
    
    let datasetFormState= $scope.ksm.addSubject('datasetFormState'+$scope.resourceId,$scope.dashboard.widgets);
    let datasetFormStateName = datasetFormState.subject.name;
    //let datasetFormChangesLog = $scope.ksm.addSubject('datasetFormChangesLog'+$scope.resourceId,"");
    let undo,redo,currentIndex,undoIndex;  
    
    currentIndex =datasetFormState.subject.getCurrentStateIndex;
        
    if (currentIndex<0) undo=false;
    else undo = true;
    if (currentIndex==datasetFormState.subject.stateChanges.length-1) redo=false;
    else redo=true;
    
    
    let action_dataseform_changed = $scope.ksm.addAction(datasetFormStateName,"datasetFormChanged",datasetform_changed);
    let action_undo = $scope.ksm.addAction(datasetFormStateName,"undoDatasetFormChange",datasetform_undo);   
    let action_redo = $scope.ksm.addAction(datasetFormStateName,"redoDatasetFormChange",datasetform_redo);
    let action_undoDone = $scope.ksm.addAction(datasetFormStateName,"undoDatasetFormChangeDone",datasetform_undo_done);
    //let action_redoDone = $scope.ksm.addAction(datasetFormStateName,"redoDatasetFormChangeDone",datasetform_redo_done);
    
    
     
      
        $scope.showGrid=false;
        $scope.gridOptions = {
            enableGridMenu: true,
            showGridFooter: true,
            //showColumnFooter: true,
            enableRowSelection: true,
                multiSelect: false,
                enableHorizontalScrollbar : 0, 
                enableVerticalScrollbar : 2,
                //enableFiltering: true,
                enableRowHeaderSelection: true,
                enableColumnResizing: true,
           // useExternalPagination: true,
            paginationPageSizes: [5,10, 25, 50,100,200,500],
            enablePaginationControls: true,
    }
    
     $scope.gridOptions.columnDefs = [
            { name:'id', width:50 },
            { name:'name' },
        ];
        
     $scope.currentStateIndex ={'undo':undo ,'redo':redo};
    
}

export function formDefinitionCtrl ($scope, $rootScope, AuthenticationService,KatalyoStateManager,ResourcesDataService,CodesService,ProcessingService,MessagingService,WidgetRegisterService,$stateParams,$timeout,GridsterService,MenuService,$element,dragulaService,$state)
{      
    
    $scope.gsrvc = GridsterService;  
    $scope.ksm = KatalyoStateManager;
    $scope.localVars = {   gsrvc:$scope.gsrvc, PropertiesSize:'standard'};
    $scope.formVars = {  };
    $scope.resource = {}
    $scope.parentResources=[]
    $scope.CTRL='formDefinitionCtrl';
    $scope.formLoaded = false;
    $scope.gridsterType ="form"; //dataset
    var lang=$rootScope.globals.currentUser.lang;
    $scope.resourceId=$stateParams.id;
    $scope.SavedButNotDeployed = false
    $scope.isCollapsedVerHistory = true
    $scope.localVars.publishedResources = []
    $scope.returnState=$stateParams.returnState
  
    $scope.gsrvc.resetBodyBgColor()
    $scope.menu_service = MenuService
    $scope.menu_service.clearSideMenu()

    if ($scope.returnState===undefined || $scope.returnState==='')
    {

       $scope.returnState={state:{name:'app.navbar.resources.list'},Label:'Return to resource list',params:{}} 
    }
    $scope.resourceDef = {ResourceDefId_id: $scope.resourceId,form_definition : true}
      
    let massUpdateState= $scope.ksm.addSubject('massUpdateState',null);
    let massUpdateStateName = massUpdateState.subject.name;
    $scope.ksm.executeAction(massUpdateStateName,"massUpdateChanged", {Active:false,Item:null}); 
                    
    $scope.get_published_resource_forms=function(){               
        ResourcesDataService.GetPublishedResources($scope.resourceId).then(function (data) {       
           $scope.localVars.publishedResources = data.data;
        });
    }
    $scope.get_published_resource_forms();
    $timeout(function() {   $scope.definitionType = 'form';         });
    

    $scope.widgetPropertyHideMap={general:true,dataValidation:true}
   
    $scope.localVars.ChangePropertiesSize = function()
    {
       let propElement = document.getElementsByClassName("propertiesContainer");
        
       $scope.propertiesTop = "";
       
      if ($scope.localVars.PropertiesSize=='standard')
      {
        $scope.localVars.PropertiesSize = 'large';
       $scope.propertiesTop = $scope.gsrvc.calcTopForExtendedProperties(propElement[0]); 
      
      }
      else $scope.localVars.PropertiesSize='standard';
    }
   
   
   
    $scope.PreparePublish2Prod = function()
    {
        ResourcesDataService.preparePublish2Prod($scope.resourceId,$scope.resource.ResourceCode,$scope.resource.Version).then(
            function(data) {               
                $scope.publishedResource = data.data;
                /*
                if ($scope.publishedResource?.rmd) { 
                    $scope.publishedResource.rmd.forEach( item => { 
                    })
                }*/                                                      
            },function(error){            
                MessagingService.addMessage(error.msg,'error');  
            });
    }

    $scope.Publish2Prod = function()
    {
        $scope.publishingDataset=true
        let resources = [{uid:$scope.resourceId,itemSubType:$scope.resource.ResourceCode,version:$scope.resource.Version }]
        ResourcesDataService.publish2Prod(resources).then(function(data) {
                               
            MessagingService.addMessage(data.data.msg,'success'); 
            $scope.publishedResource.Published = data.data.Published
            $scope.publishedResource.Deployed = data.data.Deployed
            $scope.publishedResource.Version = data.data.Version
            $scope.resource.Version=$scope.publishedResource.Version
            $scope.publishedResource.PublishedVersion =  data?.data?.PublishedVersion
            $scope.publishedResource.DateTime = data.data.DateTime
            $scope.publishedResource.PublishedBy = data.data.PublishedBy
            $scope.publishedResource.ChangedDateTime = data.data.ChangedDateTime
            $scope.publishedResource.ChangedBy = data.data.ChangedBy
            $scope.publishedResource.count = 0 
            $scope.publishedResource.rmd = data.data.rmd
            $scope.publishingDataset=false
                                                               
        },function(error){
            $scope.publishingDataset=false                
            MessagingService.addMessage(error.msg,'error');  
        });
    }
    

         
    $scope.showHideProperties = function(value)
                {
                  
                  
                  $scope.gsrvc.setFieldPropertiesOpened(value);
                  $scope.showFieldProperties=$scope.gsrvc.getFieldPropertiesOpened();
                 // if (value) $scope.gsrvc.setPropertiesPosition();
                };


   
       $scope.ShowFormView = function(viewId,locked)
      {
       
        $scope.resource.formParams.formViewType=viewId;
        $scope.resource.formParams.formLocked = locked;
        $scope.gsrvc.setFormViewType($scope.resource.formParams.formViewType);
        
      };
      
       CodesService.getCodesByName('resourcetype',lang).then(function (data) {
          //debugger;
                                $scope.resourceTypeList = data.data;
                             });
        
        CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                                $scope.statusesDownloaded= true;
                             });
  
  
  
    $scope.getResourceDefinition = function () {
          
            ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                            // MessagingService.addMessage(data.msg,'success');
                            $scope.resource = data.data;
                            $scope.resource.widgetPropertyHideMap = $scope.widgetPropertyHideMap
                            $scope.resourceDefDownloaded=true

                            //get resource published info (from PROD instance)
                            $scope.PreparePublish2Prod()

                            ResourcesDataService.GetWidgetToolbox($scope.resource.ResourceCode).then(function (data) {
                                    $scope.gsrvc.setWidgetToolboxItems(data.data,$scope.gridsterType);
                                    $scope.getResourceForm();
                            },function(error){
                              
                                     MessagingService.addMessage(error.msg,'error');  
                            });  
                                                               
            },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
        });
    };      
        
        
        //$scope.getResourceDefinition();
        
        $scope.$on('CtrlPBPressed',function(event,data){
                  
                let key=data;
                if (key=='b' || key=='B')
                {
                    if ($scope.resource.formParams.formViewType!=1) $scope.ShowFormView(1);
            
                }
                if (key=='p' || key=='P')
                {
                    if ($scope.resource.formParams.formViewType!=2) $scope.ShowFormView(2);
                }
                  
        });     
       
        $scope.getInnerHtml = function()
                
        {
                //$scope.innerHtml  = document.querySelector('div[kt-grid]').innerHTML;
                $scope.resource.FormDef = document.querySelector('div[kt-grid]').innerHTML;
                //console.log(document.querySelector('div[kt-grid]').innerHTML);
                //$scope.innerHtml = elem;
                        
        }
        
     
        
     

         $scope.existingRelationships = []
         $scope.existingRelationshipsCopy=[]
        /*********************************************************************************************/
        /************************ GET CHILD RELATIONSHIPS *************************/
        /*********************************************************************************************/
       $scope.getExistingResources = function()  {

            
            $scope.existingRelationships.length=0
            $scope.RelatedLoaded=false
             
          
            
            ResourcesDataService.getExistingResourceRelationships($scope.resourceId).then(function(data) {
                                        
                            $scope.existingRelationships = data.data;
                            $scope.existingRelationshipsCopy = angular.copy($scope.existingRelationships)
                            $scope.RelatedLoaded=true;
               
                     },function(error){    
                                MessagingService.addMessage(error.msg,'error');
                            })
  
       };    
          
   
      
        $scope.saveResourceDef = function (isValid)
        {
              if (isValid)  {
                    ResourcesDataService.SaveResourceDef2 ($scope.resource).then(
                        function(data) {      
                                   if (data.status===200 || data.status===201 )     MessagingService.addMessage("Resource saved successfuly",'success');  
                         },function(error){     
                                  MessagingService.addMessage(error.msg,'error');  
                                });
                
              } else {  
                MessagingService.addMessage("Please enter required data",'warning'); 
              }
        };      
        
      
        
        $scope.clearResourceParameters = function() {
         
            if ($scope.resource.Parameters ==undefined ||  $scope.resource.Parameters==null)
                {
                 $scope.resource.Parameters = {extDataSourceParams:{formFieldsList:[]} };      
                } else
                {
                   if ($scope.resource.Parameters.extDataSourceParams == undefined || value == false)  { 
                        $scope.resource.Parameters.extDataSourceParams={ formFieldsList:[] };                 
                  } else  $scope.resource.Parameters.extDataSourceParams.formFieldsList.length=0;      
                }  
        }
        
        
        
      $scope.EditDataset = function(value) //TODO
        {
                var localFieldsList=[] 
                 if ( $scope.resource.Parameters?.extDataSourceParams?.formFieldsList) 
                        getFieldNamesFromObject($scope.dashboard,   $scope.resource.Parameters.extDataSourceParams.formFieldsList);            
       };
       
       
                
      $scope.$watch('gsrvc.getFieldPropertiesOpened()',function(newValue,oldValue){
              
              if (newValue!=oldValue)
              {
               $scope.selectedField=$scope.gsrvc.getFieldProperties();
               
               $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
              }
              if ($scope.refreshProperties && $scope.showFieldProperties)
              {
                $scope.selectedField=$scope.gsrvc.getFieldProperties();
                $scope.refreshProperties=false;
               }
            });
      
       $scope.$watch('gsrvc.getFieldProperties()',function(newValue,oldValue){
              
              if (newValue!=oldValue)
              {
               $scope.selectedField=$scope.gsrvc.getFieldProperties();
               $scope.localVars.PropertiesSize ='standard';
               $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
              }
               if ($scope.refreshProperties && $scope.showFieldProperties)
               {
                $scope.selectedField=$scope.gsrvc.getFieldProperties();
                $scope.refreshProperties=false;
               }
            });
      
      
           
      $scope.getResourceForm = function () //in resourceFormCtrl
      {
          $scope.formLoaded=false;
          
          ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(
                function(data)    {
                   
                     
                    $scope.resource= data.data.resourceDefinition
                    $scope.resource.widgetPropertyHideMap = $scope.widgetPropertyHideMap
                    if (data?.data?.widgets && data.data.widgets.length>0) {
                         $scope.dashboard.widgets={layout:data.data.widgets};
                     } else 
                         $scope.dashboard.widgets={layout : $scope.resource.ResourceExtended.DefaultForm};
                    
                    
                    //get resource published info (from PROD instance)
                            $scope.PreparePublish2Prod()

                            ResourcesDataService.GetWidgetToolbox($scope.resource.ResourceCode).then(function (data) {
                                    $scope.gsrvc.setWidgetToolboxItems(data.data,$scope.gridsterType);
                                },function(error){
                              
                                     MessagingService.addMessage(error.msg,'error');  
                            });  

                    
 
                    $scope.resource.formParams = {formViewType:1,formLocked:true};                 
                    $scope.adminHeaderLabel="Select "+$scope.resource.ResourceCode+" administrators";                                                                                
                    $scope.gsrvc.setFormViewType($scope.resource.formParams.formViewType);

                    $timeout( function() {
                         $scope.formLoaded=true;
                        //if (!$scope.resource.Published && $scope.resource.Version==$scope.resource.NewVersion)    $scope.showPublish(false)
                    });
              },function(error){
                       MessagingService.addMessage(error.msg,'error');
                  });
      
      };
  
    $scope.getResourceForm();



     //**********************************************************
    //           SAVE RESOURCE FORM DEFINITION
    //**********************************************************      
  $scope.saveResourceForm = function (val) {
     $scope.savingForm = true; //disable save button
     $scope.formLoaded=false; 
     $scope.showHideProperties(false);
     // First save the form

      ResourcesDataService.saveResourceForm($scope.dashboard.widgets.layout,$scope.resourceId,'i',$scope.localVars.newDescription)
                .then(function(data) {
                            if (data.data.formData.length>0)  {
                                $scope.dashboard.widgets={layout:data.data.formData};
                                $scope.resource.FormVersion = data.data.form_version;
                                $scope.resource.Version = data.data.version;
                                $scope.hasChanges=data.data.has_changes
                            }
                            $scope.definitionType = val;
                            var lang=$rootScope.globals.currentUser.lang;
                            MessagingService.addMessage(data.msg,data.msgType);
                            $scope.savingForm = false;
                                                         
                            $scope.formLoaded=true;
                            $('#datasetPublish').modal('hide'); 
                            
                   },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.savingForm = false;
                                  $scope.formLoaded=true;
                             });

  };

     $scope.showModal = function (p_type) {
       //POST publish-resource , resource_id, version i description
        //$scope.saveModal=true;
        $('#datasetPublish').modal('show'); 
        $scope.modalAction=p_type
        $scope.newPublishVersion = $scope.resource.Version
    }
   
   
   
  
   //**********************************************************
    //           Publish resource
    //**********************************************************      
  $scope.save_resource = function (formValid) {
        
        let publish_type='form'
        if (formValid) {
            if ($scope.modalAction=='save')
            {
                $scope.saveResourceForm(publish_type)
            }else{
            $scope.publishingForm = true;
            //debugger
            ResourcesDataService.PublishResource($scope.resourceId,$scope.resource.Version,$scope.localVars.newDescription,publish_type)
                    .then(function(data) {
                                    MessagingService.addMessage(data.msg,'success');
                                    //$('#datasetPublish').modal('hide'); 
                                    $scope.publishingForm = false;
                                    $scope.resource.Published = true
                                    $scope.get_published_resource_forms()
                                    $scope.PreparePublish2Prod()
                                    $scope.datasetDeploy = []
                                    $('#datasetPublish').modal('hide');         
                                     //TODO - SKINUTI PONOVNO PUBLISHED RESOURCES
                    } ,function(error){
                                      MessagingService.addMessage(error.msg,'error');
                                      $scope.publishingForm = false;
                                    
                                 });
           }
            $scope.activity.activityMessage = ProcessingService.setActivityMsg("");
        }
  };
  
  

   $scope.returnToPreviousPage = function()
        {
                let page = $scope.returnState.state.name
                //let navbar = MenuService.getPageNavbarByName(page);
                $state.go(page,$scope.returnState.params);
             
        }
  
   // ***********   ktFormResource INIT ******************
   

     
      $scope.dataForGridReady = false;
      $scope.editDataset=false;
      $scope.dashboard={};
     
      $scope.gsrvc.destroyDragula('bag-form');
      $scope.gsrvc.destroyDragula('bag-columns');
      $scope.gsrvc.destroyDragula('bag-rows-'+$scope.gridsterType);
     
        
      $scope.showFieldProperties=false;
   
    
}
        
    
    
export function resourceDataRecordCtrl ($scope,$timeout ,$rootScope, AuthenticationService,ResourcesDataService,ProcessingService,MessagingService,$stateParams,GridsterService) {
    
      $scope.resourceId=$stateParams.resource_id;
      $scope.record_id=$stateParams.id;
      $scope.CTRL='resourceDataRecordCtrl';
      $scope.resourceDefinition = {'ResourceDefId_id':$scope.resourceId};
      $scope.formData=[{}];
      $scope.gridsterType="resource";

      $scope.dashboard = {
				id: '1',
				name: 'Home',
				widgets: GridsterService.getEmptyDatasetForm()
				} ;
 
      var lang=$rootScope.globals.currentUser.lang;
  
      $scope.getResourceForm = function () {// in resourceDataRecordCtrl
      //debugger
      ResourcesDataService.getResourceForm($scope.resourceId,'i',lang,$scope.resourceDefiniton.resourceType).then(function(data) {
                                
                                $scope.dashboard.widgets.layout=data.data.widgets;
                                $scope.resourceDefinition= data.data.resourceDefinition;
                                //debugger;
                                $scope.resource.formParams = {formViewType:1};
                                $scope.formLoaded=true;
        },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                                
                                
                             });
      };

  $scope.getResourceFormWithData = function () {
      ResourcesDataService.getResourceFormWithData($scope.resourceId,$scope.record_id,'i',lang).then(function(data) {
                                
                                $scope.dashboard.widgets.layout=data.data.widgets;
                                $scope.formData = data.data.form_data;
                                $scope.resourceDefinition= data.data.resourceDefinition;
                                //debugger;
                                $scope.resource.formParams = {formViewType:1};
                                $scope.formLoaded=true;
                                },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                                
                                
                             });
      };
      
  if ($scope.record_id=="")
    {
      $scope.action = "Create";
      
    }
    else
    {
      
      $scope.action = "Update";
      
    }
      if ($scope.resourceId != undefined && $scope.action == "Create")
      {
        $scope.getResourceForm();
      }
      else if ($scope.record_id != undefined)
      {
      $scope.getResourceFormWithData();
        
        
      }
    
      $scope.saveResourceData = function () {
            
          
          
           $scope.activity.activityMessage = ProcessingService.setActivityMsg("Saving record for "+$scope.resourceId);
            
           //$scope.resourceData = ResourcesDataService.prepareResourceWidgetData($scope.dashboard1.widgets);
          

          ResourcesDataService.saveResourceData($scope.resourceId,$scope.formData).then(function(data) {
                                    MessagingService.addMessage(data.msg,'success');
                                      $scope.activity.activityMessage = ProcessingService.setActivityMsg("");
                                 },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                
                             });
        };
        
        
      $scope.deleteSelectedRecord = function () {
    
          ResourcesDataService.deleteDatasetRecord($scope.resourceId,$scope.record_id).then(function(data) {
                                    MessagingService.addMessage(data.msg,'success');
                                 },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                
                             });
        };  
    }
    
    
export function pagesCtrl ($scope, $rootScope,$state,ResourcesDataService,UserService,uiGridConstants,MessagingService,$timeout) {
    
 $scope.CTRL='pagesCtrl';
 $scope.navbar_id=$state.params.navbar_id;
        $scope.previousState = {state:$state.current.name,params:{navbar_id:$scope.navbar_id,sidemenu_id:$state.params.sidemenu_id}};
        //Get data from server
    $scope.gridOptions = {
        columnDefs: [
         { name: 'id', displayName: 'ID', aggregationType: uiGridConstants.aggregationTypes.maX,type: 'number', width:'5%',enableHiding: false, visible: true },
         { name: 'resourcedeflang_set[0].Name', displayName: 'Page name'},
         { name: 'resourcedeflang_set[0].Subject', displayName: 'Page subject' },
         { name: 'resourcedeflang_set[0].Description', displayName: 'Page description' },
       //  { name: 'resourcedeflang_set[0].Lang.name', displayName: 'Language'},
        // { name: 'ResourceType.CodesDetailLang[0].Value', displayName: 'Task type' },
         { name: 'CreatedDateTime', displayName: 'Date created',enableHiding: false,type: 'date', cellFilter: 'date:\'dd.MM.yyyy HH:MM:ss:sss\'' },
         { name: 'createdBy.name', displayName: 'Created by'},
         { name: 'Status.CodesDetailLang[0].Value', displayName: 'Status'},
         ],
      enableGridMenu: true,
        showGridFooter: true,
    showColumnFooter: true,
        enableRowSelection: true,
       multiSelect: false,
       enableHorizontalScrollbar : 0, 
      enableVerticalScrollbar : 2,
       enableFiltering: true,
       enableRowHeaderSelection: true,
       enableColumnResizing: true,
     /* 
        paginationPageSizes: [5, 10, 20, 50, 100],
         paginationPageSize: 5,
       enableSelectAll: false,
       
       
       enableCellEdit: false*/
      
   }
   
   

    $scope.gridOptions.onRegisterApi = function (gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
        
          gridApi.selection.on.rowSelectionChanged($scope,function(row){
          
            $scope.pageSelected=row.isSelected;
            $scope.selectedPage = row.entity.id;
            $scope.selectedPageObject = row.entity;
          });
     }
   
   
   
 $scope.getPageDef = function(){
   
   
    var lang=$rootScope.globals.currentUser.lang;
    ResourcesDataService.getResourceDefByType(lang,'page').then(function(data) {
                               
                                  $scope.gridOptions.data = data.data;
                                 
                                 },function(error){
                                  
                                 MessagingService.addMessage(error.msg,'error');
                                
                             });
      };
        
    
    //napuni grid s definicijama resursa
    $scope.getPageDef();
           
         
    
    }

export function pagesDefinitionCtrl ($scope, $rootScope,$state,$location,CodesService,ResourcesDataService,UserService,MessagingService,MenuService,GridsterService,$timeout) {

        $scope.CTRL='pagesDefinitionCtrl';
        $scope.gsrvc = GridsterService
        $scope.dashboard = {widgets:$scope.gsrvc.getEmptyTaskForm()}
        $scope.resourceId=$state.params.id;
        $scope.grid={};
        $scope.navbar_id=$state.params.navbar_id;
        var lang=$rootScope.globals.currentUser.lang;
        $scope.user_is_admin = $rootScope.globals.currentUser.is_superuser;
        $scope.selectedTab=1;
        $scope.previousState = {state:$state.current.name,params:{id:$scope.pageid,navbar_id:$scope.navbar_id,showReturnLink:true}};
        $scope.resourceDefinition={form_definition:true}
        $scope.gridsterType='page'
        $scope.localVars={};
        //$scope.organisation_id =  $rootScope.globals.currentUser.uep.Organisation
		$scope.organisation_id =  $rootScope.globals.currentUser?.organisation?.id
        $scope.gsrvc.resetBodyBgColor()
        
        $scope.menu_service = MenuService
        $scope.menu_service.clearSideMenu()
        $scope.env_id = $rootScope.globals.currentEnv
        /*
		$timeout(function(){
            $('[data-toggle="tooltipm"]').popover('show')
        });
 
        
        $timeout(function(){
            $('[data-toggle="tooltipm"]').popover('hide')
        },5000);
		*/
		if ($scope.localVars.selectedPageMenu) {
		}
       
	         
        $scope.findLinkIndex = function( link_type, wheretolook)
        {
            for (let i=0;i<wheretolook.length;i++) {
                        if (wheretolook[i].LinkType==link_type) return i;
            }
            return -1;
        }
		
		$scope.findLinkId = function( link_type, wheretolook)
        {
            for (let i=0;i<wheretolook.length;i++) {
                        if (wheretolook[i].LinkType==link_type) return wheretolook[i]?.id;
            }
            return null;
        }
		
         $scope.openPreviewPage = function()
        {   
            let page_id
            if ($scope.resource.Parameters.PublicPage) page_id = $scope.resource.ResourceExtended.UniqueUrl
            else page_id = $scope.resource.uid

           let env_from_url = $location.search().env 
           
           let url = "/vpage/"+page_id+'/'+$scope.gsrvc.resourceFormVersion[$scope.resource.uid].NewVersion+'?rinstance=0&env='+env_from_url

           window.open(url,'_blank')

        }

	    $scope.previewPageLink = function()
        {   
            let page_id
            if ($scope.resource.Parameters.PublicPage) page_id = $scope.resource.ResourceExtended.UniqueUrl
            else page_id = $scope.resource.uid

           let env_from_url = $location.search().env 
           
           return "/vpage/"+page_id+'/'+$scope.gsrvc.resourceFormVersion[$scope.resource.uid].NewVersion+'?rinstance=0&env='+env_from_url
        }

        $scope.previewPageParams = function()
        {   
            let page_id
            if ($scope.resource.Parameters.PublicPage) page_id = $scope.resource.ResourceExtended.UniqueUrl
            else page_id = $scope.resource.uid

           // $scope.previewPageParams = {pageid: page_id,app_ref:undefined,orgid:$scope.organisation_id,version:$scope.gsrvc.resourceFormVersion[$scope.resource.uid].NewVersion,rinstance:0}
            return {pageid: page_id,app_ref:undefined,orgid:$scope.organisation_id,version:$scope.gsrvc.resourceFormVersion[$scope.resource.uid].NewVersion,rinstance:0}
        }
        

	   $scope.saveApplication2 = function (isFormValid) 
	   {
	
		let link_id1 = $scope.findLinkId("page-menu", $scope.resource.ResourceExtended.rlinks);
		let link_id2 = $scope.findLinkId("default-page", $scope.resource.ResourceExtended.rlinks);

		$scope.localVars.selectedPageMenu = $scope.localVars.selectedPageMenu ?? { id: null }
		$scope.localVars.selectedPage = $scope.localVars.selectedPage ?? { id: null }
        
		$scope.rLinks = []
        if ($scope.localVars.selectedPageMenu.id) 
            $scope.rLinks.push({'id':link_id1,
								'Resource1':$scope.resourceId,
								'Resource2':$scope.localVars.selectedPageMenu.id,
								'LinkType':'page-menu',
								'SelectVersionDirect':$scope.localVars.selectedPageMenu?.SelectVersionDirect,
								'publishedResource':$scope.localVars.selectedPageMenu?.selectedPublishedResource,
								'Version':$scope.localVars.selectedPageMenu?.selectedPublishedResource?.Version})
			
        if ($scope.localVars.selectedPage.id)
            $scope.rLinks.push({'id':link_id2,
								'Resource1':$scope.resourceId,
								'Resource2':$scope.localVars.selectedPage.id,
								'LinkType':'default-page',
								'SelectVersionDirect':$scope.localVars.selectedPage?.SelectVersionDirect,
								'publishedResource':$scope.localVars.selectedPage?.selectedPublishedResource,
								'Version':$scope.localVars.selectedPage?.selectedPublishedResource?.Version})
        //debugger
        let app_data = {appId:$scope.resource.uid, version:$scope.resource.NewVersion, desc:$scope.localVars.newDescription, rLinks:$scope.rLinks}
        if (isFormValid){
            if ($scope.rLinks.length){
                $scope.savingApp = true
                ResourcesDataService.saveApplication2(app_data).then(
					function(data) {       
						$scope.savingApp = false   
						MessagingService.addMessage(data.data.msg,'success');
						$scope.gsrvc.resourceFormVersion[$scope.resource.uid] = data.data.data
						$scope.resource=data.data.data 
					},function(error){
						$scope.savingApp = false
						MessagingService.addMessage(error.msg,'error');
                })
            }  else {
                MessagingService.addMessage('Please select application menu or default page to save!','error');
                $scope.savingApp = false
            }
        } else MessagingService.addMessage('App description is mandatory!','error');
            
      };

    $scope.getPublishedResources = function(resource,publishType){
	
        ResourcesDataService.GetPublishedResourcesByTypeId(resource.id,publishType).then(
                    function (data) {       
                        if ($scope.selectedResourcesPublishedList==undefined) $scope.selectedResourcesPublishedList={}
                        $scope.selectedResourcesPublishedList[resource.id] = data.data;
                        
                        // fill 'select version' options to have both id and name (for already saved data)
                        if (resource.SelectVersionDirect){ 
                              let publishedList = $scope.selectedResourcesPublishedList[resource.id] 
                              publishedList.forEach( (item)=> {
                                  if (item.Version == resource?.Version) 
										resource.selectedPublishedResource = item
										$scope.localVars.selectedPageMenu.selectedPublishedResource = item //TODO  - popraviti da radi i bez toga
                              } ) 
                        }
                    },function(error){    
                        MessagingService.addMessage(error.msg,'error');   
            });
	}

        $scope.pageTypeList = [{id:1,name:'System page',value:'system'},{id:2,name:'User page',value:'user'}]

        
        
        /*
        CodesService.getCodesByName('pagetypes',lang).then(function (data) {
                $scope.pageTypeList = data.data;
				  //napuni grid s definicijama resursa
				
        });
        */
        
         ResourcesDataService.getResourceDefListByType(lang,'pagemenu').then(
						function(data) {	 
                            $scope.localVars.pageMenuList = [{id:0, name: 'No menu selected'}].concat(data.data)
				 
							},function(error){    
                                MessagingService.addMessage(error.msg,'error');
		 });
        
         ResourcesDataService.GetResourceParams().then(function (data) {
             //debugger
                                $scope.resourceTypeList = data.data;
                                $scope.resourceTypesLoaded = true;
        });
        
        let form_saved = function(data) {
                           formDefinitionState.subject.setStateChanges = data;
                           formDefinitionState.subject.notifyObservers("formSaved");

       };
                  
                   
        let formDefinitionState,formDefinitionStateName;   
        formDefinitionState= $scope.ksm.addSubject('formDefinitionState'+$scope.resourceId+'i',null);
        formDefinitionStateName = formDefinitionState.subject.name;
            
         let action_value_changed = $scope.ksm.addAction(formDefinitionStateName,"formSaved",form_saved);

                        
        
        $scope.PreparePublish2Prod = function()
        {
            ResourcesDataService.preparePublish2Prod($scope.resourceId,$scope.resource.ResourceCode,$scope.resource.Version).then(function(data) {
                                   
                $scope.publishedResource = data.data;
                                                                   
            },function(error){
                                  
                MessagingService.addMessage(error.msg,'error');  
            });
        }

        $scope.Publish2Prod = function()
        {
            $scope.publishingDataset=true
            let resources = [{uid:$scope.resourceId,itemSubType:$scope.resource.ResourceCode,version: $scope.gsrvc.resourceFormVersion[$scope.resource.uid]?.Version }]
            if ($scope.publishedResource==undefined) $scope.publishedResource={}
            ResourcesDataService.publish2Prod(resources).then(function(data) {
                
                MessagingService.addMessage(data.data.msg,'success'); 
                $scope.publishedResource.Published = data.data.Published
                $scope.publishedResource.Deployed = data.data.Deployed
                $scope.publishedResource.Version = data.data.Version
                $scope.publishedResource.PublishedVersion =  data?.data?.PublishedVersion
                $scope.publishedResource.DateTime = data.data.DateTime
                $scope.publishedResource.PublishedBy = data.data.PublishedBy
                $scope.publishedResource.ChangedDateTime = data.data.ChangedDateTime
                $scope.publishedResource.ChangedBy = data.data.ChangedBy
                $scope.publishedResource.count = 0 
                $scope.publishedResource.rmd = data.data.rmd
                $scope.publishingDataset=false
                $scope.gsrvc.resourceFormVersion[$scope.resource.uid] = data.data
                                                               
            },function(error){
                $scope.publishingDataset=false                
                MessagingService.addMessage(error.msg,'error');  
            });
        }
        

       $scope.getResourceForm = function () {// in pagesDefinitionCtrl

       
        $scope.pageDownloaded=true;
          //debugger
          /*
          ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(function(data) {
          
                     if (data?.data?.widgets && data.data.widgets.length>0)  {
                        $scope.dashboard.widgets={layout:data.data.widgets};
                     } else 
                         $scope.dashboard.widgets={layout : GridsterService.getEmptyTaskForm()};
   
                     $scope.resourceDefinition= data.data.resourceDefinition;  
                     $scope.resource.formParams = {formViewType:1,formLocked:true};
                     $scope.resourceDefinition.form_definition = true
                     $scope.resourceDefinition.form_type='i'
                     $scope.resourceDefinition.ResourceDefId_id = $scope.resourceId
                                     
                     $scope.adminHeaderLabel="Select "+$scope.resourceDefinition.ResourceCode+" administrators";                
                                                                                 
                     $scope.gsrvc.setFormViewType($scope.resourceDefinition.formParams.formViewType);

                    $timeout(function() {
                          $scope.pageDownloaded = true
                    });
                        
                     },function(error){
                       MessagingService.addMessage(error.msg,'error');
                  });
                  */
      
      };

       $scope.savePageForm = function () {

               $scope.savingForm = true;
               ResourcesDataService.saveResourceForm($scope.dashboard.widgets.layout,$scope.resourceId,'i').then(function(data) {
                                       
                                        MessagingService.addMessage(data.msg,data.msgType);
                                        $scope.FormVersion = data.version;

                                        if (data.data.formData.length>0) {
                                            $scope.dashboard.widgets={layout:data.data.formData}
                                        }   

                                        $scope.ksm.executeAction(formDefinitionStateName,"formSaved",data.new_items);
                                        $scope.savingForm = false;
   
                                   },function(error){
                                     
                                       $scope.savingForm = false;
                                       MessagingService.addMessage(error.msg,'error');
                                      
                                    });
                                      
                                  
              };
        $scope.setPageDef = function(){
   
                
                $scope.resource.headerLabel="Page permissions";
                $scope.resource.usersLabel="Users";
                $scope.resource.groupsLabel="Groups";
                
                if ($scope.resource.ResourceExtended.PageType!= undefined)
                {
                        if ($scope.resource.ResourceExtended.PageType.value=="user")
                        {
                                $scope.resource.ResourceExtended.Target = "app.navbar.vpage";
                                if ($scope.resource.Parameters.PublicPage) $scope.resource.ResourceExtended.Target = "app.navbar.public-vpage"
                                $scope.resource.disabledTarget = true;
                        }else
                        {
                                //$scope.page.Target = "app.navbar.vpage";
                                $scope.resource.disabledTarget = false;
          
                        }
                }else
                {
                        var pos = $scope.pageTypeList.map(function(e) { return e.value; }).indexOf("user");
                        if (pos>=0) {
                                $scope.resource.ResourceExtended.PageType = $scope.pageTypeList[pos];
                                $scope.resource.ResourceExtended.Target = "app.navbar.vpage";
                                if ($scope.resource.Parameters.PublicPage) $scope.resource.ResourceExtended.Target = "app.navbar.public-vpage"
                                $scope.resource.disabledTarget = true;            
                        }
                        $scope.savePageDef($scope.resource,'info')
                }


    
        };
        
         $scope.getResourceDefinition = function () {
          
                ResourcesDataService.getResourceDefAll($scope.resourceId).then(function(data) {
                               
                        $scope.resource = data.data;
                        
                        $scope.resource.resourceDefDownloaded = true
                        $scope.resource.adminHeaderLabel = 'Page permissions'
                        if ($scope.resource.Parameters?.AccessUsers==undefined) $scope.resource.Parameters.AccessUsers=[]

                        if ($scope.resource.Parameters?.AccessGroups==undefined) $scope.resource.Parameters.AccessGroups=[]

                        $scope.gsrvc.setResourceFormVersion($scope.resource.uid,{Version:$scope.resource.Version,Published:$scope.resource.Published,NewVersion:$scope.resource.NewVersion})
                        $scope.PreparePublish2Prod()  
                        ResourcesDataService.GetWidgetToolbox($scope.resource.ResourceCode).then(function (data) {
                                    $scope.gsrvc.setWidgetToolboxItems(data.data,$scope.gridsterType);
                                    $scope.getResourceForm();
                            },function(error){
                              
                                     MessagingService.addMessage(error.msg,'error');  
                            });  
                        $scope.setPageDef();
                       
                              
                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                });
        };
        
        
        /*
        ResourcesDataService.getResourceDefListByType(lang,'task').then(function(data) {
                                                    
                        $scope.resourceList = data.data;
                        $scope.resourcesLoaded = true;
                        //debugger
                },function(error){
                                
                        MessagingService.addMessage(error.msg,'error');
        });        
        */
        $scope.getResourceDefinition();
        
         $scope.saveResourceDef = function (isValid) {
          
          if (isValid)
          {
            ResourcesDataService.saveResourceDefAllNew($scope.resource).then(function(data) {
                               
                               if (data.status===200  || data.status===201)
                                {
                                MessagingService.addMessage("Page saved successfuly",'success');  
                                }
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
         
                            });
            
          }else
          {
            
            MessagingService.addMessage("Please enter required data",'warning'); 
          }
        };      
      
        $scope.showHideProperties = function(value)
        {
                  $scope.gsrvc.setFieldPropertiesOpened(value);
                  $scope.showFieldProperties=$scope.gsrvc.getFieldPropertiesOpened();
       
        };

        $scope.showHideProperties(false)

        $scope.$watch('gsrvc.getFieldProperties()',function(newValue,oldValue){
                    
                    if (newValue!=oldValue)
                    {
                     $scope.selectedField=$scope.gsrvc.getFieldProperties();
                     $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
                    }
        });
        $scope.returnToPreviousPage = function()
        {
                let page = "app.navbar.resources.list";
                //let navbar = MenuService.getPageNavbarByName(page);
                $state.go(page,{});
             
        }
        
       
        $scope.savePageDef = function(valid,save_type){
                //debugger
                if (valid)
                {
                        ResourcesDataService.savePageDef($scope.resource,lang,save_type).then(function(data) {
                                       
                                MessagingService.addMessage(data.data,'success');
                                         
                        },function(error){
                                          
                                MessagingService.addMessage(error.msg,'error');
                                        
                        });
                }
        };
        
        
        $scope.savePageMenu = function () {
         
            let link_id1=null
            if ($scope.resource.ResourceExtended.rlinks[0] && $scope.resource.ResourceExtended.rlinks[0].id )  link_id1 = $scope.resource.ResourceExtended.rlinks[0].id;
            
            $scope.rLinks=[{'id':link_id1,
						'Resource1':$scope.resource.uid,
						'Resource2':$scope.localVars.selectedPageMenu.id,
						'LinkType':'page-menu',
						'SelectVersionDirect':$scope.localVars.selectedPageMenu?.SelectVersionDirect,
						}];
						
			if ($scope.localVars.selectedPageMenu?.SelectVersionDirect) {
					$scope.rLinks[0].publishedResource = $scope.localVars.selectedPageMenu?.selectedPublishedResource
					$scope.rLinks[0].Version = $scope.localVars.selectedPageMenu?.selectedPublishedResource?.Version	
			}
			
			
			
           ResourcesDataService.saveResourceLinks($scope.rLinks).then(function(data) {
                                   MessagingService.addMessage(data.data.msg,'success');  
                                    },function(error){
                                      MessagingService.addMessage(error.data.msg,'error');
                                 });
      
      };
      
	  
	  
    $scope.newVersion = function()
    {
        $scope.creatingNewVersion=true

        ResourcesDataService.SetNewResourceVersion($scope.resource.id)
                .then(function(data) {
                    if (data.status==200)
                    {
                    $scope.resource.NewVersion = data.data
                    $scope.resource.Published=false
                    }else MessagingService.addMessage(data.data,'error');
                    $scope.creatingNewVersion=false
                    $scope.hasChanges=false
         
                   },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.creatingNewVersion=false
                                 
                             });

    };

        $scope.showModal = function (p_type) {
            $('#pagePublish').modal('show')
            $scope.modalAction=p_type
            $scope.newPublishVersion = $scope.resourceDefinition.NewVersion

        }

      $scope.pageGridItemsPreview = [];
      
      $scope.calcRowSize = function(rebuildGrid)
      {
        $scope.rowSizes="";
        for (let i=0;i<$scope.resource.Parameters.grid.NoOfRows;i++) {
         //$scope.rowSizes = $scope.rowSizes+" auto";
          $scope.rowSizes = $scope.rowSizes+ "minmax(0,1fr)"; //TODO - zbog overflowa tablice u gridu, vidjeti negativne efekte
        }
        
        if (rebuildGrid) $scope.rebuildGrid();
      }
      
      
      
      $scope.calcColumnSize = function(rebuildGrid)
      {
        $scope.columnSizes="";
        for (let i=0;i<$scope.resource.Parameters.grid.NoOfColumns;i++)
        {
          // $scope.columnSizes = $scope.columnSizes+" auto";
          $scope.columnSizes = $scope.columnSizes+"minmax(0,1fr)";//TODO - zbog overflowa tablice u gridu, vidjeti negativne efekte
        }    
        //debugger
         if (rebuildGrid) $scope.rebuildGrid(); 
      }
      
      
       $scope.BuildGrid = function(loadFromDB)
       {
         //debugger
        /* $scope.pageGridItemsPreview.length=0;
         let itemLength = $scope.resource.Parameters.grid.NoOfRows*$scope.resource.Parameters.grid.NoOfColumns;
             
             for (let j=0;j<itemLength;j++) {
                $scope.pageGridItemsPreview.push({id:j+1});
             }
             */
         if (loadFromDB == false) 
                $scope.BuildGrid2();
       }
        
        
        $scope.rebuildGrid = function()
        {
            //debugger
            if ($scope.resource.Parameters.pageGridItems==undefined) $scope.resource.Parameters.pageGridItems=[];
            $scope.resource.Parameters.pageGridItems.length=0;
            let itemLength = $scope.resource.Parameters.grid.NoOfRows*$scope.resource.Parameters.grid.NoOfColumns;
            if ($scope.resource.Parameters.pageGridItems.length> itemLength) {
               MessagingService.addMessage('Data loss error ','error'); 
            }
            else  {
                for (let j=0;j<itemLength;j++) {
                   //if (j==0) 
                     //  $scope.resource.Parameters.pageGridItems.push({id:j+1,layout:true}); 
                  // else 
                       $scope.resource.Parameters.pageGridItems.push({id:j+1,layout:false});
                    //$scope.resource.Parameters.pageGridItems.push({id:j+1,layout:false}); //MIGOR TODO 
                    
                }
            }
        }
      
        $scope.CreateDeleteLayoutBox = function(action,index)
        {
        $scope.resource.Parameters.pageGridItems[index].layout=action;
        }
      
      
       $scope.PageTypeChanged = function(item,model){
       
            if (item.value=="user")  {
              $scope.resource.ResourceExtended.Target = "app.navbar.vpage";
              $scope.resource.disabledTarget = true;
            }else {
              //$scope.page.Target = "app.navbar.vpage";
              $scope.resource.disabledTarget = false; 
            }
      };  
      
      
     $scope.loadSelectedPage = function(){
         //when pageMenu & rlinks are downloaded from server
	
         $scope.localVars.loadedSelectedPage = true

		   
		   //selectedResourcesPublishedList[localVars.selectedPageMenu.id]
        
		//debugger
         $scope.localVars.selectedPageMenu = $scope.localVars.pageMenuList.filter( item => item.id == $scope.resource.ResourceExtended.rlinks[0].Resource2 )[0];
		 $scope.localVars.selectedPageMenu.SelectVersionDirect = $scope.resource.ResourceExtended.rlinks[0].Resource2_Version ? true : false
		  
		 if ($scope.localVars.selectedPageMenu.id) {
			 $scope.getPublishedResources($scope.localVars.selectedPageMenu,'all')
		 }
		 //scope().localVars.selectedPageMenu.selectedPublishedResource
     }
     
  
         
    
    }




export function pagesFormCtrl ($scope, $rootScope, dragularService,ResourcesDataService,UserService,GridsterService,ProcessingService,MessagingService,$timeout,$stateParams,$element) {
    
        $scope.CTRL='pagesFormCtrl';
        $scope.gridsterType ="page";

        $scope.resourceId=$stateParams.id;
        var lang=$rootScope.globals.currentUser.lang;
        $scope.dashboard.widgets = GridsterService.getEmptyTaskForm();
        $scope.formDefinition=true;
        $scope.gsrvc=GridsterService
        $scope.gsrvc.resetBodyBgColor()
        
        
        
        $scope.getResourceForm = function () {// in pagesFormCtrl
              //debugger
              ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(function(data) {
                       if (data.data.widgets.length>0) {
                            $scope.dashboard.widgets=data.data.widgets;
                       }           
                       $scope.resourceDefinition= data.data.resourceDefinition;
                       
                    
                       //**********************************************************
                       //          Drag And Drop dragular
                       //**********************************************************
               
                         $timeout(function(){
                               var container = $element.children().eq(0).children().eq(2).children().eq(0), widgets = $scope.dashboard.widgets;                 
                               dragularService(container[0],
                                 {
                                 nameSpace: 'row-layout',
                                 classes: { },
                                 revertOnSpill: true,
                                 containersModel: widgets,
                                   moves: function (el, container, handle) {
                                     return handle.classList.contains("row-"+$scope.gridsterType+"-handle");
                                   }
                            
                                  }  );
                            });
                         
                          $timeout(function() {$scope.formLoaded=true;},1000);
                          
                       },function(error){
                         MessagingService.addMessage(error.msg,'error');
                    });
      };
  
      if ($scope.resourceId != undefined) {
        $scope.getResourceForm();  
      }
      
      
      //**********************************************************
      //           SAVE PAGE FORM DEFINITION
      //**********************************************************      
    $scope.savePageForm = function (isValid) {
     //debugger
      //code
    
         $scope.activity.activityMessage = ProcessingService.setActivityMsg('Saving form for page'+$scope.resourceId);
         
         ResourcesDataService.saveResourceForm($scope.dashboard.widgets,$scope.resourceId,'i').then(function(data) {
                                 
                                  
                               
                                   $scope.dashboard.widgets = data.data;
                                   MessagingService.addMessage(data.msg,'success');
                                
                                 },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                
                             });
         $scope.activity.activityMessage = ProcessingService.setActivityMsg("");
      //   }
        };
      
    
    }
    
export function menusDefinitionCtrl ($scope, $rootScope,$state, dragularService,ResourcesDataService,MenuService,CodesService,GridsterService,ProcessingService,MessagingService,$timeout,$stateParams,$element) {
  
    
      
      $scope.CTRL='menusDefinitionCtrl';
      $scope.menu_service=MenuService;
      $scope.menus= {editMode: $scope.menu_service.getMenuEditMode(),showNavbar:$scope.menu_service.navbarSelected,showSideMenu:$scope.menu_service.sideMenuSelected};
      var lang=$rootScope.globals.currentUser.lang;
      
      $scope.menu_service.clearSideMenu()

      $scope.localVars={manualPageSelect:true};
      $scope.changeEditMode = function(){
    
    
         $scope.menus.editMode = !$scope.menus.editMode;
         $scope.menu_service.setMenuEditMode($scope.menus.editMode);
         if ($scope.menus.editMode) $scope.menu_service.setCurrentNavbarId(null,lang);
         else $scope.menu_service.refreshMenus(lang,0).then(function(data){
          
          });
        // $scope.showNavbar=!$scope.showNavbar;
      }
      $scope.initMenu = function(){
        $scope.menus.editMode=  $scope.menu_service.getMenuEditMode();
  
      }
        
 
      //two-way binding with the service - not working like that
     
      $scope.selectedNavbar=$scope.menu_service.selectedNavbar;
      //make sure the navbars are loaded               
      $scope.menu_service.getMenus(lang,1).then(function (data) {
                
        });
     
        $scope.$watch('menu_service.loadingMenu',function(newValue,oldValue){
                  if (newValue!=oldValue)
                  {
                        $scope.loadingMenu=$scope.menu_service.getLoadingMenu();
                  }
               });     
     

    $scope.PreparePublish2Prod = function()
    {
        ResourcesDataService.preparePublish2Prod($scope.resourceId,$scope.resource.Version).then(
			function(data) {                    
				$scope.publishedResource = data.data;                                                    
			},function(error){                     
				MessagingService.addMessage(error.msg,'error');  
        });
    }
    
    $scope.PreparePublish2Prod()

    $scope.newChildNavbar = function() {
      if ($scope.cMenus==undefined) $scope.cMenus=[];
      $scope.selectedNavbar = {Users:[],Groups:[],Target:null,ParentItem:null,Params: {pageid:  $scope.selectedPageId},Order:$scope.cMenus[$scope.currentNavbarId].length+1,MenuType:CodesService.getCodesDetailObjectByName('menutypes','navbarrightc',lang),Navbar:$scope.currentNavbarId};
       $scope.cMenus[$scope.currentNavbarId].push($scope.selectedNavbar);
      
   }  
    
}


