//**************************************************************************************************
//*************************************** SettingsService - ES6 module ****************************
//**************************************************************************************************

'use strict';

class SettingsService{
   
    
   constructor ($q,$rootScope,RestApiService) {
     
     
      this.RestApiService = RestApiService;
      this.$q=$q;
      this.$rootScope =$rootScope; 

    }

   static $inject = ['$q','$rootScope','RestApiService'];
     
   getOrganisationInfo (organisationId){
     
      if (organisationId != undefined) {
         var deferred = this.$q.defer();                   

         var instance=this;
         
         let url = '../api/organisations/'+organisationId+'/';
         this.RestApiService.get(url).then(response =>{
   
                           if (response.status===200) deferred.resolve({data: response.data , status:response.status});
                           else deferred.reject({data: data , status:response.status});
                });   
      }
      else {
             
             deferred.resolve({data: this.listOfCodesByName[code_name+language].codesList,status:200});
      }
      return deferred.promise;
   }
     
	 
   saveOrganisationInfo (organisation, extended={} )
   {
      
	   var deferred = this.$q.defer(); 
      if (organisation.id != undefined) {    
		let url = '../api/organisations/'+organisation.id+'/';
		this.RestApiService.post(url,organisation).then(response =>{	
				if (response.status===200) deferred.resolve({msg: response?.data?.msg , status:response?.status});
				 else deferred.reject({data: response?.data , status:response?.status});				  		
		});
          
      } else {   
            deferred.reject({msg: 'Error = > Missing organisation id', status:400});
      }
      return deferred.promise;
   } 

   //**********************************************************
    //           getCredits
    //**********************************************************      
    getCredits (balanceType){
     
        if (balanceType == undefined)  balanceType='user'
        var deferred = this.$q.defer();                   

        var instance=this;
         
        let url = '../api/get-credits/'+balanceType+'/';
        this.RestApiService.get(url).then(response =>{
                        
                       
                           if (response.status===200) deferred.resolve({data: response.data , status:response.status});
                           else deferred.reject({data: data , status:response.status});
                      
                        
        });
          
      
     
      return deferred.promise;
    }

     GenerateApiKeys (api_user) {
        
                     var deferred = this.$q.defer();   
                     var instance = this;
                     let url = '../api/generate-api-keys/';
                     let post_data = {'api_id':api_user};
                     this.RestApiService.post(url,post_data).then(response =>{
                               if (response.status===200) deferred.resolve({data:response.data,status:response.status}) 
                                   else deferred.reject({data: response.data,status:response.status});
              
                     });
         
               
                return deferred.promise;
 
        };
        
        GetKtyloBalance (address) {
        
                     var deferred = this.$q.defer();   
                        
                     let url = '../api/get-ktlyo-balance/';
                     let post_data = {address};
                     this.RestApiService.post(url,post_data).then(response =>{
                           
                         if (response.status===200) deferred.resolve({data:response.data,status:response.status})
                                else deferred.reject({data:response.data,status:response.status});
                              
                     });
                     
                       
                return deferred.promise;
 
        };
        
        SaveBlockchainAccount (address) {
        
                     var deferred = this.$q.defer();   
                     let url = '../api/save-eth-account/';
                     let post_data = {address};
                     this.RestApiService.post(url,post_data).then(response =>{
                         
                               if (response.status===200) deferred.resolve({data:response.data,status:response.status})  
                                    else deferred.reject({data:response.data,status:response.status});
                             
                     });
                     
                      
                return deferred.promise;
 
        };
}

export default SettingsService;