//**************************************************************************************************
//*************************************** ResourcesDataService ****************************
//**************************************************************************************************

'use strict';

//import {ktAdjustContentToNavbar} from '../0001_directives/angularJS/build/ktdirectives.js';

class ResourcesDataService{

  constructor($http,$q,$rootScope,MessagingService,RestApiService)
  {
   this.cache = {}; //this should go into state manager
    this.$http =$http;
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.MessagingService = MessagingService;
    this.RestApiService =RestApiService;
  }
 
   static $inject = ['$http','$q','$rootScope','MessagingService','RestApiService'];
 
    
	SaveResourceDef  (resource) {
       
          var http_method="";
          var url='../api/save-resource-def/';  
          if (resource.ResourceDefId!==undefined)
          {
              resource.ResourceDefId.Organisation= 1;
              resource.ResourceDefId.Private= false;
              resource.Lang = this.$rootScope.globals.currentUser.langObj;
              resource.Version= 1;
              resource.ResourceDefId.TableCreated = false;
              resource.ResourceDefId.FormDefined = false;
            }
            
          http_method="POST";
          var deferred = this.$q.defer();
            
          this.RestApiService.post(url,resource).then(response =>{
               
                    if (response.status === 200 || response.status === 201) {  // if successful, bind success message to message 
                                 deferred.resolve({data: response.data, msg:'Resource definition saved successfuly !',status:response.status});            
                    } else {
                            deferred.reject({msg:'Save resource definition error (fn SaveResourceDef) --> status = '+response.status+' statusText = '+response.statusText+' '+response.data,status:response.status});       
                        } 
            });
          
          
          return deferred.promise;
        };  
 
 
    SaveResourceDef2  (resource) { //Removing from client post all references to  'ResourceDefId' 
              
          var http_method="";
          var url='../api/save-resource-def2/';  
            
          http_method="POST";
          var deferred = this.$q.defer();
            
          this.RestApiService.post(url,resource).then(response =>{
                    if (response.status === 200 || response.status === 201) {     
                              deferred.resolve({data: response.data,msg:'Resource definition saved successfuly !',status:response.status});           
                    } else {  // error
                        if (response.status==403) deferred.reject({msg: response.data+' => Save resource definition failed ! => SaveResourceDef2',status:response.status});     
                        else deferred.reject({msg:'Save resource def failed ! --> SaveResourceDef2',status:response.status});     
                    }  
            });
            
          return deferred.promise;
        };  
 
 
 // NEW MAPPINGS 
     putMappings (name,resourceDefId,mappingsData,fileFields) {
    
          var http_method="POST";                                           
          var url='../api/put-mappings-new/'+resourceDefId+'/';
            var deferred = this.$q.defer();
            
             let post_data= {'resourceDefId':resourceDefId,'mappingsData':mappingsData,'name':name,'fileFields':fileFields};
            this.RestApiService.post(url,post_data).then(response =>{
               
                    if (response.status === 201) {
                        // if successful, bind success message to message
                              
                                  deferred.resolve({data: response.data,status:response.status});  
                                   
                    } else {
     
                            deferred.reject({msg:'Save mapping failed ! --> putMappings',status:response.status});
                       
                    }
               
            });
            
          
                
            return deferred.promise;
        };

      // UPDATE MAPPINGS 
     putMappingsWithId (name,resourceDefId,id,mappingsData,fileFields) {
    
          var http_method="POST";                                           
          var url='../api/put-mappings/'+resourceDefId+'/'+id+'/';
            var deferred = this.$q.defer();
            
             let post_data= {'mappingsId':id,'resourceDefId':resourceDefId,'mappingsData':mappingsData,'name':name,'fileFields':fileFields};
            this.RestApiService.post(url,post_data).then(response =>{

                    if (response.status === 201) { // if successful, bind success message to message
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Save mapping failed ! --> putMappingsWithId',status:response.status});     
                    }   
            });
            
        
                
           return deferred.promise;
    };       
        
        
     // GET-MAPPINGS LIST
     getMappingsList (resourceDefId) {
    
          var http_method="GET";                                           
          var url='../api/get-mappings-list/'+resourceDefId+'/';
            var deferred = this.$q.defer();
            
               this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables 
                        deferred.reject({msg:'Get mapping failed ! --> getMappingsList',status:response.status});     
                    }
               // });    
            });
               
          
                
            return deferred.promise;
    };        
        
        
         // GET-MAPPINGS 
     getMappings (id) {
    
          var http_method="GET";                                           
          var url='../api/get-mappings/'+id+'/';
            var deferred = this.$q.defer();
            
                this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: response.data,msg:data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Get mapping failed ! --> getMappings',status:response.status});     
                    }
                //});    
            });
                
                
            
            return deferred.promise;
    };        
// MIGOR END
 //********************************************************** http localhost:8080/api/save-resource-def/",
	//           GET RESOURCES DEF  ---------  MIGOR
	
	//**********************************************************      
  getResources  () {
    
          var http_method="GET";
          var url='../api/get-resource-def/';
          var deferred = this.$q.defer(); 
		   this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: response.data , msg: response.data, status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Get resource def failed ! --> getResources',status:response.status});     
                    }
                //});    
            });
           
           
           return deferred.promise;
    };

    //********************************************************** http localhost:8080/api/save-resource-def/",
    //           GET RESOURCES DEF  ---------  MIGOR
    
    //**********************************************************      
  getExistingResourceRelationships  (resourceId) {
    
          var http_method="GET";
          var url='../api/get-existing-resource-relationships/'+resourceId+'/';
          var deferred = this.$q.defer(); 
           this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: response.data , msg: response.data, status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Get existing resource relationships failed ! --> getExistingParentForResource',status:response.status});     
                    }
                //});    
            });
           
           
           return deferred.promise;
    };
		
	//**********************************************************
	//           SAVE PAGE DEF
	
	//**********************************************************      
  savePageDef  (page,lang,stype) {
    
          var http_method="POST";
          var url='../api/save-page-def/'+lang+'/';
          var deferred = this.$q.defer(); 
		  
           let post_data= {resource:page,save_type:stype};
            this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201) {
                        // if successful, bind success message to message    
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Save paged def failed ! --> savePageDef',status:response.status});     
                    }  
            });
            
           
           return deferred.promise;
        };
	
  //**********************************************************
	//           SAVE RESOURCES DEF ALL
	
	//**********************************************************      
  saveResourceDefAll  (resource) {
    
          var http_method="PUT";
          var url='../api/resource-def-all/'+resource.id+'/';
          var deferred = this.$q.defer(); 
		  
           this.RestApiService.put(url,resource).then(response =>{
                response.json().then(data=>{

                    if (response.status === 200 || response.status === 200  ||  response.status === 201) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Save resource def failed ! --> saveResourceDefAll',status:response.status});     
                    }
                });    
            });
           
           
           return deferred.promise;
        };
		
    //**********************************************************
	//           SAVE RESOURCES DEF ALL - NEW !!!
	
	//**********************************************************     
          saveResourceDefAllNew  (resource) {
    
          var http_method="PUT";
          var url='../api/resource-def-all-new/'+resource.id+'/';
          var deferred = this.$q.defer(); 
          //debugger
		  
          this.RestApiService.put(url,resource).then(response =>{
                response.json().then(data=>{

                    if (response.status === 200 || response.status === 201) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Save resource def new failed ! --> saveResourceDefAllNew',status:response.status});     
                    }
                });    
            });
          
          
            return deferred.promise;
    };
    //**********************************************************
	//           SAVE RESOURCES DEF PARAMETERS
	
	//**********************************************************      
  saveResourceDefParameters  (resource_id,parameters) {
    
          var http_method="POST";
          var url='../api/save-resource-def-parameters/'+resource_id+'/';
          var deferred = this.$q.defer(); 
		  
          this.RestApiService.post(url,parameters).then(response =>{

                    if (response.status === 200 || response.status === 200  ||response.status === 201 ) {
                        // if successful, bind success message to message
                                deferred.resolve({msg: response.data,status:response.status});
                         // });         
                    } else {
                                deferred.reject({msg:'Save error (fn saveResourceDefParameters) --> status = '+response.status+' statusText = '+response.statusText+' '+data,status:response.status});     
                               
                    }


            });
          
          
            return deferred.promise;
    };
		
    //**********************************************************
	//           SEND NOTIFICATION
	//**********************************************************      
    sendNotification  (notification) {
    
          var http_method="POST";
          var url='../api/send-notification/'+notification.id+'/';
          var deferred = this.$q.defer(); 
		  
          this.RestApiService.post(url,notification).then(response =>{
                    if (response.status === 200 || response.status === 201) {
                        // if successful, bind success message to message       
                                  deferred.resolve({data: data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                        deferred.reject({msg:'Save notificationfailed ! --> sendNotification',status:response.status});     
                    }   
            });
            
          
           return deferred.promise;
    };
				
	//********************************************************** 
	//           GET RESOURCES DEF ALL
	
	//**********************************************************      
  getResourceDefAll  (resourceId) {
    
          var http_method="GET";
          var url='../api/resource-def-all/'+resourceId+'/';
          var deferred = this.$q.defer(); 
		  
          this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201) {   
                        deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        // if not successful, bind errors to error variables
                        deferred.reject({msg:'Get resource def failed ! --> getResourceDefAll',status:response.status});     
                    }  
            });
            
           
            return deferred.promise;
    };
		
 
   
     getResourceForm  (resourceId,formType,lang,version) {
          
         var http_method="GET";
         if (version===undefined) version=0
         var url='../api/get-resource-form/'+ resourceId +'/'+formType+'/'+lang+'/'+version+'/';
         var index = resourceId + '_' + formType + '_' + lang 
         var deferred = this.$q.defer();
        
         this.RestApiService.get(url).then(response =>{
              
                    if (response.status === 200 || response.status === 200   || response.status === 201) {
                        // if successful, bind success message to message     
                           deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        // if not successful, bind errors to error variables
                        deferred.reject({msg:'Get resource form failed ! --> getResourceForm'+response.msg,status:response.status});     
                    }
                
            });
          
          
            return deferred.promise;
         
     }
     
       
   
  //**********************************************************
	//           GET RESOURCE MODEL FOR DEPLOYMENT 
	//**********************************************************      
  getResourceModelForDeployment  (resourceId,version,lang) {
    
          var http_method="GET";
          var url='../api/get-resource-model-for-deployment/'+ resourceId +'/'+version +'/'+lang+'/';
          var deferred = this.$q.defer();
           this.RestApiService.get(url).then(
               response =>{
                       if (response.data.length==0) response.status === 404
                        if (response.status === 200 || response.status === 200  ) { // if successful, bind success message to message       
                            deferred.resolve({data: response.data,status:response.status});            
                        } else {
                            deferred.reject({msg:'Get resource for deployment failed ! --> getResourceModelForDeployment',status:response.status});     
                        }   
                }, 
                err => {
                     deferred.reject({msg:'Get resource for deployment failed ! --> getResourceModelForDeployment',status:err.status});     
                }
            );
            
            return deferred.promise;
    };
  //**********************************************************
	//           GET FORM FIELDS
	//**********************************************************      
  getFormFields  (resourceId,lang,version) {
    
      var http_method="GET";
       if (version === undefined) version = 0
      var url='../api/get-form-fields/'+ resourceId +'/'+lang+'/'+version+'/';
      var deferred = this.$q.defer();
      var gotoServer=false;
        resourceId = resourceId.toString()
      if (this.cache['getFormFields'] !==undefined)
      {
         if(this.cache.getFormFields[resourceId+lang] !==undefined)
         {
            deferred.resolve({data:  this.cache.getFormFields[resourceId+lang],status:200});
         }
         else
         {
           this.cache['getFormFields'][resourceId+lang] = {};
           gotoServer=true; 
         }
      } else
      {
         this.cache['getFormFields'] = {};
         this.cache['getFormFields'][resourceId+lang] = {};
         gotoServer=true;
      }  
       if (gotoServer)
       {
          var instance = this;
          this.RestApiService.get(url).then(response =>{
                
                    if (response.status === 200 || response.status === 200   || response.status === 201) {
                        // if successful, bind success message to message
                            instance.cache['getFormFields'][resourceId+lang] = response.data;   
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Get form fields failed ! --> getFormFields',status:response.status});     
                    }
                //});    
            });
           
       }
        return deferred.promise;
    };
        //**********************************************************
	//           GET FORM FIELDS BY TYPE
	//**********************************************************      
  getFormFieldsByType  (resourceId,relatedId,elementType,lang) {
    
          var http_method="GET";
          
          var url='../api/get-form-fields-by-type/'+ resourceId +'/'+ relatedId +'/'+elementType+'/'+lang+'/';
          var deferred = this.$q.defer();   
          this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 200   || response.status === 201) {
                        // if successful, bind success message to message
                            
                            deferred.resolve({data: response.data, status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Get form fields failed ! --> getFormFieldsByType',status:response.status});     
                    }
               // });    
            });
          
            return deferred.promise;
    };
  //**********************************************************
	//           GET RESOURCE DATA LIST
	//**********************************************************      
  getResourceDataList  (resourceId,presentationId,lang,search,form,fromCache) {
          
          if (fromCache==undefined)  fromCache=false;
          var http_method="POST";
          if (form==undefined) form=[];
          var url='../api/get-resource-data-list/'+ resourceId +'/'+ presentationId +'/'+lang+'/';
          url = url+'?page=1&page_size=10000';
          var deferred = this.$q.defer();
          var gotoServer=false;
          if (resourceId==undefined || resourceId==null)
          {
           
            deferred.reject({msg: 'Resource id is not defined. Element id = '+presentationId,status:400});  
            
          }
          else{
           

               if (this.cache['getResourceDataList'] !==undefined)
               {
                  if(this.cache.getResourceDataList[resourceId+'_'+presentationId+lang] !==undefined)
                  {
                     if (this.cache.getResourceDataList[resourceId+'_'+presentationId+lang].downloaded && (search==="" || search=={} || search==[{}] || fromCache))
                     deferred.resolve({data:  this.cache.getResourceDataList[resourceId+'_'+presentationId+lang].data,status:200});
                  }
                  else
                  {
                    this.cache['getResourceDataList'][resourceId+'_'+presentationId+lang] = {data:[],downloaded:false};
                    gotoServer=true; 
                  }
               } else
               {
                  this.cache['getResourceDataList'] = {};
                  this.cache['getResourceDataList'][resourceId+'_'+presentationId+lang] = {data:[],downloaded:false};
                  gotoServer=true;
               }
               
              
               if (search!=="")
               {
                  gotoServer=true;
               }
          }
          if (gotoServer) {
            var instance = this;
            let post_data = {'filter':search,'form':form};
            
            this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 200   || response.status === 201) {
                        // if successful, bind success message to message
                        if (search ==="" || search=={} || search==[{}] || fromCache) {
                            instance.cache['getResourceDataList'][resourceId+'_'+presentationId+lang].data = response.data;
                            instance.cache['getResourceDataList'][resourceId+'_'+presentationId+lang].downloaded = true;   
                        }   
                        deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:response.data,status:response.status});     
                    }   
            });
            
           
          }
         
                return deferred.promise;
        };

         //**********************************************************
	//           GET RESOURCE DATA LIST ALL
	//**********************************************************      
  getResourceDataListAll  (resourceId,presentationId,lang,fromCache,callback,post_data) {
          
          if (fromCache==undefined)  fromCache=false;
          var http_method="GET";
          var url='../api/get-resource-data-list-all/'+ resourceId +'/'+ presentationId +'/'+lang+'/';
          var deferred = this.$q.defer();
          var gotoServer=false;
          if (resourceId==undefined || resourceId==null)
          {
           
            deferred.reject({msg: 'Resource id is not defined. Element id = '+presentationId,status:400});  
            
          }
          else{
           

               if (this.cache['getResourceDataListAll'] !==undefined)
               {
                  if(this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId] !==undefined)
                  {
                     if (this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].downloaded && fromCache)
                     deferred.resolve({data:  this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].data,status:200});
                     else if (this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].downloadInProgress)
                     {
                        if (this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks == undefined) this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks = [];
                        
                        this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks.push(callback); 
                     }
                  }
                  else
                  {
                    this.cache['getResourceDataListAll'][resourceId+'_'+lang+'_'+presentationId] = {data:[],downloaded:false,downloadInProgress:true};
                    gotoServer=true; 
                  }
               } else
               {
                  this.cache['getResourceDataListAll'] = {};
                  this.cache['getResourceDataListAll'][resourceId+'_'+lang+'_'+presentationId] = {data:[],downloaded:false,downloadInProgress:true};
                  gotoServer=true;
               }
               
              
          }
          if (gotoServer)
          {
          
            var instance = this;
            if (this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks==undefined) this.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks = [];

            this.RestApiService.post(url,post_data).then(response =>{
                

                    if (response.status === 200 || response.status === 200   || response.status === 201) {
                        // if successful, bind success message to message
                            instance.cache['getResourceDataListAll'][resourceId+'_'+lang+'_'+presentationId].data = response.data;
                            instance.cache['getResourceDataListAll'][resourceId+'_'+lang+'_'+presentationId].downloaded = true;
                             //send callback to inform data is available
                            for (let i=0;i< instance.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks.length;i++)
                            {
                                instance.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks[i]();
                        
                            }
                           
                            instance.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].callBacks=[];
                            instance.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].downloadInProgress=false;
                            deferred.resolve({data: response.data,status:response.status});
                      
                    } else {
                        
                        instance.cache.getResourceDataListAll[resourceId+'_'+lang+'_'+presentationId].downloadInProgress=false;
                        deferred.reject({msg:response.data,status:response.status});     
                    }
                //});    
            });
            
           
          }
         return deferred.promise;
    };
        
        
     //**********************************************************
	//           GET RESOURCE DATA PANDAS 
	//**********************************************************      
  getResourceDataPandas  (resourceId,presentationId,lang,fromCache,callback) {
          
          if (fromCache==undefined)  fromCache=false;
          var http_method="GET";
          var url='../api/get-resource-data-pandas/'+ resourceId +'/'+ presentationId +'/'+lang+'/';
          var deferred = this.$q.defer();
          var gotoServer=false;
          //debugger
          if (resourceId==undefined || resourceId==null) {
            deferred.reject({msg: 'Resource id is not defined. Element id = '+presentationId,status:400});    
          }
          else {
               if (this.cache['getResourceDataPandas'] !==undefined)
               {
                  if(this.cache.getResourceDataPandas[resourceId+'_'+lang] !==undefined)
                  {
                     if (this.cache.getResourceDataPandas[resourceId+'_'+lang].downloaded && fromCache)    deferred.resolve({data:  this.cache.getResourceDataPandas[resourceId+'_'+lang].data,status:200});
                     else if (this.cache.getResourceDataPandas[resourceId+'_'+lang].downloadInProgress)
                     {
                        if (this.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks == undefined) this.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks = [];
                        this.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks.push(callback); 
                     }
                  }
                  else
                  {
                    this.cache['getResourceDataPandas'][resourceId+'_'+lang] = {data:[],downloaded:false,downloadInProgress:true};
                    gotoServer=true; 
                  }
               } else {
                  this.cache['getResourceDataPandas'] = {};
                  this.cache['getResourceDataPandas'][resourceId+'_'+lang] = {data:[],downloaded:false,downloadInProgress:true};
                  gotoServer=true;
               }
            }
          if (gotoServer) {
          
            var instance = this;
            if (this.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks==undefined) this.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks = [];
            
                this.RestApiService.get(url).then(response =>{
                   

                        if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           instance.cache['getResourceDataPandas'][resourceId+'_'+lang].data = response.data;
                            instance.cache['getResourceDataPandas'][resourceId+'_'+lang].downloaded = true;
                             //send callback to inform data is available
                            for (let i=0;i< instance.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks.length;i++) {
                                instance.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks[i]();
                            }
                           
                            instance.cache.getResourceDataPandas[resourceId+'_'+lang].callBacks=[];
                            instance.cache.getResourceDataPandas[resourceId+'_'+lang].downloadInProgress=false;
                            deferred.resolve({data: response.data, status:response.status});
                      
                     } else {
                        instance.cache.getResourceDataPandas[resourceId+'_'+lang].downloadInProgress=false;
                        deferred.reject({msg:response.data,status:response.status});     
                    }
                //});    
            });
                
                
                
           
          }
         
        return deferred.promise;
    };
        
        
  //**********************************************************
	//           GET RESOURCE FORM DEFINITION
	//**********************************************************      
  getResourceFormWithData  (resourceDefId,resourceId,formType,lang) {
    
          var http_method="GET";
          
          var url='../api/get-resource-form-with-data/'+ resourceDefId +'/'+ resourceId +'/'+formType+'/'+lang+'/';
          var deferred = this.$q.defer();   
          this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:'Get resource form failed ! --> getResourceFormWithData',status:response.status});     
                    }
              //  });    
            });
          
           
        return deferred.promise;
    };
  //**********************************************************
	//           GET RESOURCE FORM EXTENDED
	//**********************************************************      
  getResourceFormExtended  (resourceId,taskDefId,formType,parentId,overrideType,lang) {
    
      var http_method="GET";
          
      var url='../api/get-resource-form-extended/'+ resourceId +'/'+taskDefId+'/'+formType+'/'+parentId+'/'+overrideType+'/'+lang+'/';
      var deferred = this.$q.defer();
     
        this.RestApiService.get(url).then(response =>{
            
             if (response.status === 200 || response.status === 201 ) {
                    // if successful, bind success message to message
                    deferred.resolve({data: response.data,status:response.status});            
                 } else {
                     // if not successful, bind errors to error variables
                     deferred.reject({msg:'Get resource form extended failed ! --> getResourceFormExtended',status:response.status});     
                 }
             //});    
            });
          
          
          
            return deferred.promise;
   };
    //**********************************************************
	//           GET RESOURCE FORM EXTENDED WITh DATA
	//**********************************************************  
  getResourceFormExtendedWithData  (resourceId,taskInitiateId,taskExecuteId,populateType,parentId,parentInitiateId,prevTaskExeId,formType,widgetType,resourceRecordId,overrideType,lang) {
    
          
         
          var http_method="GET";
          //debugger  //getResourceFormExtendedWithData(resourceId,taskInitiateId,taskExecuteId,populateType,parentId,parentInitiateId,prevTaskExeId,formType,widgetType,resourceRecordId,overrideType,lang) {
          var url='../api/get-resource-form-extended-with-data/'+ resourceId +'/'+taskInitiateId+'/'+taskExecuteId+'/'+populateType+'/'+parentId+'/'+parentInitiateId+'/'+prevTaskExeId+'/'+formType+'/'+widgetType+'/'+resourceRecordId+'/'+overrideType+'/'+lang+'/';
          var deferred = this.$q.defer();
          
          if (resourceId ==null || resourceId ==undefined)
          {
            deferred.reject({msg:"Invalid API params in function ResourceDataService.getResourceFormExtendedWithData --> resourceId is null or undefined",status:400}); 
            var gotoServer=false;
          }
       
             this.RestApiService.get(url).then(response =>{
                
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        // if not successful, bind errors to error variables
                        let msg="";
                        
                        if (response.data.msg!=undefined) {
                            msg = response.data.msg;
                        } else 
                            msg = response.data;
                    
                        deferred.reject({msg:'Get resource form extended with data failed ! --> getResourceFormExtendedWithData --> '+msg, status:response.status});     
                    }
               // });    
            });
          
           
          
          return deferred.promise;
    };
   //**********************************************************
	//           GET RESOURCE FORM EXTENDED MULTIPLE
	//**********************************************************      
  getResourceFormExtendedMultiple  (resourceId,taskDefId,formType,parentId,overrideTypes,lang) {
    
          var http_method="POST";
          
          var url='../api/get-resource-form-extended-multiple/'+ resourceId +'/'+taskDefId+'/'+formType+'/'+parentId+'/'+lang+'/';
          var deferred = this.$q.defer();   
          
           this.RestApiService.post(url,overrideTypes).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource form extended multiple failed ! --> getResourceFormExtendedMultiple',status:response.status});     
                    }
               // });    
            });
           
           return deferred.promise;
    };           
   //**********************************************************
	//           GET RESOURCE FORM EXTENDED MULTIPLE
	//**********************************************************      
  getResourceFormExtendedWithDataMultiple (resourceId,taskInitiateId,taskExecuteId,populateType,parentId,parentInitiateId,prevTaskExeId,formType,widgetType,resourceRecordIds,overrideType,filter,parentDatasetDefId,rmdId,lang) {
    
          var http_method="POST";
          //debugger
          var url='../api/get-resource-form-extended-with-data-multiple/'+ resourceId +'/'+taskInitiateId+'/'+taskExecuteId+'/'+populateType+'/'+parentId+'/'+parentInitiateId+'/'+prevTaskExeId+'/'+formType+'/'+widgetType+'/'+resourceRecordIds+'/'+overrideType+'/'+parentDatasetDefId+'/'+rmdId+'/'+lang+'/';
          var deferred = this.$q.defer();   
          this.RestApiService.post(url,filter).then(response =>{
               // response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource form extended multiple with data failed ! --> getResourceFormExtendedWithDataMultiple',status:response.status});     
                    }
               // });    
            });
          
          
           return deferred.promise;
        };           
  
   //**********************************************************
	//           GET RESOURCE FORM EXTENDED MULTIPLE
	//**********************************************************      
 getResourceFormAddMultiple (resourceId,taskInitiateId,taskExecuteId,parentId,parentInitiateId,prevTaskExeId,formType,widgetType,overrideType,filter,lang) {
    
          var http_method="POST";
          
          var url='../api/get-resource-form-add-multiple/'+ resourceId +'/'+taskInitiateId+'/'+taskExecuteId+'/'+parentId+'/'+parentInitiateId+'/'+prevTaskExeId+'/'+formType+'/'+widgetType+'/'+overrideType+'/'+lang+'/';
          var deferred = this.$q.defer();
          
             this.RestApiService.post(url,filter).then(response =>{
               // response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource form add multiple failed ! --> getResourceFormAddMultiple',status:response.status});     
                    }
              //  });    
            });
            
            
          
           return deferred.promise;
    };           
  //**********************************************************
	//           SAVE DATASET RECORDS MULTIPLE
	//**********************************************************      
  saveDatasetRecordsMultiple  (resourceId,form) {
    
          var http_method="POST";
          
          var url='../api/save-dataset-records-multiple/'+ resourceId +'/';
          var deferred = this.$q.defer();   
        
           this.RestApiService.post(url,form).then(response =>{
               // response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            
                            deferred.resolve({msg: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Save dataset record multiple failed ! --> saveDatasetRecordsMultiple'+ response.data,status:response.status});     
                    }
             //   });    
            });
           
       
          return deferred.promise;
    };           
  
  //**********************************************************
	//           GET RESOURCE DEFINITION LIST
    //
    //           called by: resourceFormCtrl , 
	//**********************************************************          
 getResourceDef (lang){
      
      
       var deferred = this.$q.defer();
       
       let url = '../api/get-resource-def-bylang/'+lang+'/';
       this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            //this.cache.getResourceDef[lang] = response.data;
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                        deferred.reject({msg:'Get resource def failed ! --> getResourceDef',status:response.status});     
                    }
                //});    
            });
          
        //}     
        return deferred.promise;
    }
    
  //**********************************************************
	//           GET RESOURCE DEFINITION LIST
	//**********************************************************          
 getResourceDefFiltered (new_page,page_size,filter,lang){
      
      var http_method="POST";
                  
          var url='../api/get-resource-def-bylang-filtered/'+lang+'/';
          
          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page;
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size;
            
            }
          }
          
       var deferred = this.$q.defer();
       
       this.RestApiService.post(url,filter).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {  
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Get resource def failed ! --> getResourceDefFiltered',status:response.status});     
                    }   
            });
            
      
                
        return deferred.promise;
    }
             
  //**********************************************************
	//           GET RESOURCE DEFINITION LIST BY TYPE
	//**********************************************************          
 getResourceDefByType (lang,type){
      
      
      var deferred = this.$q.defer();   
      var gotoServer=false;
      if (this.cache['getResourceDefByType'] !==undefined)
      {
         if(this.cache.getResourceDefByType[lang+type] !==undefined)
         {
            deferred.resolve({data:  this.cache.getResourceDefByType[lang+type],status:200});
         }
         else
         {
           this.cache['getResourceDefByType'][lang+type] = {};
           gotoServer=true; 
         }
      } else
      {
         this.cache['getResourceDefByType'] = {};
         this.cache['getResourceDefByType'][lang+type] = {};
         gotoServer=true;
      }  
      if (gotoServer)
      { 
         var instance = this;
         let url ='../api/get-resource-def-bylang-bytype/'+lang+'/'+type+'/';
         this.RestApiService.get(url).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {
                            instance.cache['getResourceDefByType'][lang+type] = response.data;
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource def failed ! --> getResourceDefByType',status:response.status});     
                    }   
            });
         
         
      }          
        return deferred.promise;
    };
     //**********************************************************
	//           GET RESOURCE DEFINITION BY TYPE PAGINATED
	//**********************************************************          
 getResourceDefByLangByTypePaginated (lang,type,url_next,url_prev,new_page,page_size){
          
          var url = '../api/get-resource-def-bylang-bytype-paginated/'+lang+'/'+type+'/';
          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page;
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size;
            
            }
          }
          
       var deferred = this.$q.defer();
       
       this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource def by lang failed ! --> getResourceDefByLangByTypePaginated',status:response.status});     
                    }
                //});    
            });
       
       
                
        return deferred.promise;
    }
    
 //**********************************************************
	//           GET RELATED DATASET FOR Widget
	//**********************************************************
  getRelatedDatasetForWidget(datasetId,lang){
 
  var deferred = this.$q.defer();
        let url = '../api/get-related-dataset-forwidget/'+datasetId+'/'+lang+'/';
        this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Related Dataset definition download error --> getRelatedDatasetForWidget',status:response.status});     
                    }
               // });    
            });
        
      
                
      return deferred.promise;
 
 }
  
  //**********************************************************
	//           GET RESOURCE DEFINITION LIST BY TYPE
	//**********************************************************
 getResourceDefByTypeForWidget (lang,type){
      
      
       var deferred = this.$q.defer();
        let url = '../api/get-resource-def-bytype-forwidget/'+lang+'/'+type+'/';
          this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource def by type error --> getResourceDefByTypeForWidget',status:response.status});     
                    }
                //});    
            });
          
      
                
        return deferred.promise;
    } 
   //**********************************************************
	//           GET RESOURCE DEFINITION LIST BY TYPE FOR DROPDOWN
	//**********************************************************          
 getResourceDefListByType (lang,type)
 { 
      var deferred = this.$q.defer();  
      let url = '../api/get-resource-def-list-bylang-bytype/'+lang+'/'+type+'/';
      this.RestApiService.get(url, false).then(response =>{
                if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            deferred.resolve({data: response.data,status:response.status});            
                } else {
                        deferred.reject({msg:'Get resource def list by type error --> getResourceDefListByType',status:response.status});     
                }   
            });
         
       
        return deferred.promise;
    }

//**********************************************************
	//           GET RESOURCE DEFINITION LIST BY TYPE WITH FILTER FOR DROPDOWN
	//**********************************************************          
 getResourceDefListByTypeFilter (lang,type,search,selectedResource){
      
      
       var deferred = this.$q.defer();
        var gotoServer=false;
          if (selectedResource==undefined || selectedResource==null)
          {
           
            deferred.reject({msg: 'Resource is not defined!',status:400});  
            
          }
          else{
           
            /*
               if (this.cache['getResourceDefListByTypeFilter'] !==undefined)
               {
                  if(this.cache.getResourceDefListByTypeFilter[lang+'_'+type+'_'+selectedResource] !==undefined && (search==="" || search===undefined))
                  {
                     deferred.resolve({data:  this.cache.getResourceDefListByTypeFilter[lang+'_'+type+'_'+selectedResource],status:200});
                  }
                  else
                  {
                    this.cache['getResourceDefListByTypeFilter'][lang+'_'+type+'_'+selectedResource] = {};
                    gotoServer=true; 
                  }
               } else
               {
                  this.cache['getResourceDefListByTypeFilter'] = {};
                  this.cache['getResourceDefListByTypeFilter'][lang+'_'+type+'_'+selectedResource] = {};
                  gotoServer=true;
               }
               
              
               if (search!=="" && search!==undefined)
               {
                  gotoServer=true;
               }
          }
          if (gotoServer)
          {*/
             var instance = this;
             
            let url = '../api/get-resource-def-list-bylang-bytype-filter/'+lang+'/'+type+'/'+selectedResource+'/';
            let post_data = {'search':search};
            this.RestApiService.post(url,post_data).then(response =>{
                //response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           //instance.cache['getResourceDefListByTypeFilter'][lang+'_'+type+'_'+selectedResource] = response.data;
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource def list by type error --> getResourceDefListByTypeFilter',status:response.status});     
                    }
               // });    
            });
            
        }    
        return deferred.promise;
    }
    
    
           
    //**********************************************************
    //           GET RESOURCE DEFINITION LIST FOR EXISTING RELATIONSHIPS
    //**********************************************************          
 getExistingResourceRelationship (resourceId){
      
      
       var deferred = this.$q.defer();
     
          if (resourceId==undefined || resourceId==null)
          {
           
            deferred.reject({msg: 'Invalid resource id!',status:400});  
            
          }
          else{
           
             var instance = this;
             
            let url = '../api/get-existing-resource-relationships/'+resourceId+'/';
            this.RestApiService.get(url).then(response =>{

                    if (response.status === 200) {

                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get existing resource relationhips error --> getExistingResourceRelationship',status:response.status});     
                    }

            });
            
        }    
        return deferred.promise;
    }
            
                                          
  //**********************************************************
	//           GET RESOURCE DEFINITION LIST BY TYPE AND USER
	//**********************************************************          
 getResourceDefListByLangByTypeUser (lang,type,user){
      
      
       var deferred = this.$q.defer();
       let url = '../api/get-resource-def-list-bylang-bytype-user/'+lang+'/'+type+'/'+user+'/';
           
       this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource def list by type by user error --> getResourceDefListByLangByTypeUser',status:response.status});     
                    }
               // });    
            });
      
                
        return deferred.promise;
    }    
   
    //************************************************************************************//
    //************************************************************************************//
	//           Blockchain API calls
	//************************************************************************************//
    //***********************************************************************************//
    //**********************************************************
	//           getBlockchainDatasetRecords
	//**********************************************************          
 getBlockchainDatasetRecords (dataset_id,dataset_name,contract,limit,p_key,lang){
      
        let url='../api/get-blockchain-dataset-records/';
       
        let post_data = {"resource_id":dataset_id,"dataset":dataset_name,"contract":contract,"limit":limit,"p_key":p_key,"language":lang}  
        let deferred = this.$q.defer();   
        
        this.RestApiService.post(url,post_data).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {  // if successful, bind success message to message   
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Error --> getBlockchainDatasetRecords',status:response.status});     
                    }    
            });
 
                 return deferred.promise;
    }
  
    //**********************************************************
	//           getBlockchainDatasetRecords
	//**********************************************************          
 executeBlockchainFunction (contract,function_name,params){
      
        let url='../api/execute-blockchain-function/';
       
        let post_data = {"contract":contract,"function":function_name,"params":params}  
        let deferred = this.$q.defer();   
        
        this.RestApiService.post(url,post_data).then(response =>{
                //response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message  
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Error --> executeBlockchainFunction',status:response.status});     
                    }
              //  });    
            });

            return deferred.promise;
    }
  
    
      //**********************************************************
	//           getBlockchainDatasetRecords
	//**********************************************************          
 getBlockchainTransaction (transaction_id,block_num_hint){
      
        let url='../api/get-blockchain-transaction/';
       
        let post_data = {"transaction_id":transaction_id,"block_num_hint":block_num_hint}  
        let deferred = this.$q.defer();   
        
        this.RestApiService.post(url,post_data).then(response =>{
                //response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Error --> getBlockchainTransaction',status:response.status});     
                    }
                //});    
            });
       
        
            return deferred.promise;
    }    
      //**********************************************************
	//           readBlockchainContractPublicVars
	//**********************************************************          
 readBlockchainVars (contract,abi){
      
        let url='../api/get-contract-public-vars/';
       
        let post_data = {"contract":contract,"abi":abi}  
        let deferred = this.$q.defer();   
        this.RestApiService.post(url,post_data).then(response =>{
               // response.json().then(data=>{
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message    
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Error --> readBlockchainVars',status:response.status});     
                    }
              //  });    
            });
                 
            return deferred.promise;
    }
   //**********************************************************
	//           GET RESOURCE DEFINITION LIST BY TYPE AND USER
	//**********************************************************          
 getResourceDefByLangByTypeUserPaginated (lang,type,url_next,url_prev,new_page,page_size){
      
      var url='../api/get-resource-def-bylang-bytype-user/'+lang+'/'+type+'/';
       if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page;
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size;
            
            }
          }
          
       var deferred = this.$q.defer();
       this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        deferred.reject({msg:'Get resource def failed --> getResourceDefByLangByTypeUserPaginated',status:response.status});     
                    }
              //  });    
            });
       
      
                
        return deferred.promise;
    }    
   
  //**********************************************************
	//           SYNC DATABASE - CREATE OR ALTER TABLE
	//**********************************************************      
  syncdb  (resourceId,version) {
    
          var http_method="POST";
          
          var url='../api/syncdb-resource-form/'+resourceId+'/'+version+'/';
             var deferred = this.$q.defer();
             
             this.RestApiService.post(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({msg: 'Database synchronisation successful !',status:response.status});            
                    } else if (response.status === 201) {
                        // if successful, bind success message to message
                           
                            deferred.resolve({msg: 'Database synchronisation not needed !',status:response.status});            
                    } 

                    else {
     
                        deferred.reject({msg: 'Database synchronisation failed! ',status:response.status});     
                    }
                   
            });
            
                   return deferred.promise;
        };
         //**********************************************************
	//           SAVE RESOURCE FORM 
	//**********************************************************          
        saveResourceForm  (widgets,resourceId,formType,desc) {
    
            var http_method="POST";
            var lang=this.$rootScope.globals.currentUser.lang;
            var url='../api/save-resource-form/'+formType+'/'+resourceId+'/'+lang+'/';
            var deferred = this.$q.defer();
            if (desc==undefined) desc=''
            //var instance=this;
            let post_data = {form:widgets,description:desc}
            
            this.RestApiService.post(url,post_data).then(response =>{
                //response.json().then(data=>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                       // instance.cache.getResourceForm[resourceId +'_'+formType+'_'+lang].widgets=data.formData;
                        deferred.resolve({data: response.data,msg:response.data.msg,version:response.data.version,updateForm:response.data.updateForm,msgType:response.data.msgType,new_items:response.data.new_items,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Save dataset form failed (function saveResourceForm) --> '+ response.data,status:response.status});     
                    }
               // });    
            });
               
            
                
            return deferred.promise;
        };
   
/*MIGOR     
//**********************************************************
//           GET FILES DATA
//**********************************************************      
^api/ ^files/(?P<id>.+)/$ [name='Files-list']
*/
	getFilesData  (widgetId,fileDefId,datasetDefId,datasetRecordId) {
    
          var http_method="GET";
          
		  var url='../api/files/'+widgetId+'/'+fileDefId+'/'+datasetDefId+'/'+datasetRecordId+'/';
		  
            var deferred = this.$q.defer(); 
          
          this.RestApiService.get(url).then(response =>{
                
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        
                            deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get files data failed ! --> getFilesData',status:response.status});     
                    }
              //  });    
            });
           
                   return deferred.promise;
        };
		
  //**********************************************************
	//           DOWNLOAD FILE
	//**********************************************************      
  downloadFile  (resourceId,fileId) {
    
          var http_method="GET";
          //debugger //downloadFile
          var url='../api/download-file/'+resourceId+'/'+fileId+'/';
          var deferred = this.$q.defer();
          
           this.RestApiService.get_file(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'File download failed ! --> downloadFile',status:response.status});     
                    }    
            });
           
          
           return deferred.promise;
    };		        
  
//*********************************************************
//           IMPORT  RESOURCE
//*********************************************************    
  importResource  (fileId, resourceId, mappingsId) {
    
          var http_method="GET";
          var url='../api/import-resource/'+fileId+'/'+resourceId+'/'+mappingsId+'/';
          var deferred = this.$q.defer(); 
          
            this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Import resource failed ! --> importResource',status:response.status});     
                    }
               // });    
            });
          
          
           
            return deferred.promise;
    };		
        

 //**********************************************************
	//           GET RESOURCE DATA
	//**********************************************************      
  getResourceData  (resourceId,taskId,formType,lang) {
    
          var http_method="GET";
          
          var url='../api/get-resource-data/'+resourceId+'/'+taskId+'/'+formType+'/'+lang+'/';
             var deferred = this.$q.defer(); 
           
           this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                        
                        deferred.resolve({data: response.data.data,columnsDef: data.columnsDef,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data failed ! --> getResourceData',status:response.status});     
                    }
               // });    
            });

                   return deferred.promise;
        };
    //**********************************************************
    //           GET DATASET RECORD
    //**********************************************************      
  getDatasetData  (resourceId,filter,resourceVersion) {

        if (!resourceVersion) resourceVersion = 0

          var url='../api/get-dataset-data/'+resourceId+'/'+resourceVersion+'/';
             var deferred = this.$q.defer(); 
           
           this.RestApiService.post(url,filter).then(response =>{
                if (response.status === 200 || response.status === 201 ) {  // if successful, bind success message to message   
                            deferred.resolve({data: response.data,status:response.status});          
                    } else {
                        deferred.reject({msg: 'Get dataset record failed (fn getDatasetRecord) status = '+response.status+' statusText = '+response.statusText,status:response.status});     
                    }
                
            });

            return deferred.promise;
        };
		
		
		    //**********************************************************
    //           GET DATASET RECORDS SIMPLE
    //**********************************************************      
  getDatasetDataSimple  (resourceId,filter) {

          var url='../api/get-dataset-data-simple/'+resourceId+'/';
             var deferred = this.$q.defer(); 
           
           this.RestApiService.post(url,filter).then(response =>{
                if (response.status === 200 || response.status === 201 ) {  // if successful, bind success message to message   
                            deferred.resolve({data: response.data,status:response.status});          
                    } else {
                        deferred.reject({msg: 'Get dataset record failed (fn getDatasetRecord) status = '+response.status+' statusText = '+response.statusText,status:response.status});     
                    }
                
            });

            return deferred.promise;
        };
  //**********************************************************
	//           GET RESOURCE DATA PAGINATED
  //**********************************************************      
  getResourceDataPaginated  (resourceId,url_next,url_prev,new_page,page_size) {
    
          var http_method="GET";
            var lang=this.$rootScope.globals.currentUser.lang;       
          var url='../api/get-resource-data-paginated/'+resourceId+'/'+lang+'/';

          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page;
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size;
            
            }
          }
         
          var deferred = this.$q.defer(); 
           this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                             
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data failed ! --> getResourceDataPaginated',status:response.status});     
                    }    
            });
          
          
         
            return deferred.promise;
        };
  //**********************************************************
	//           GET RESOURCE DATA PAGINATED FILTER
  //**********************************************************      
  getResourceDataPaginatedFilter  (resourceId,url_next,url_prev,new_page,page_size,lang,filter,form_layout,columns_for_query) {
    
          var http_method="POST";
          
                  
          var url='../api/get-resource-data-paginated-filter/'+resourceId+'/'+lang+'/';

          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page;
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size;
            
            }
          }
         
          var deferred = this.$q.defer(); 
          let post_data = {'filter':filter,'form':form_layout,'columns':columns_for_query};
           this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                            deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data filter failed ! --> getResourceDataPaginatedFilter',status:response.status});     
                    }   
            });
           
         
            return deferred.promise;
        };
  //**********************************************************
	//           GET RESOURCE DATA PAGINATED FILTER
  //**********************************************************      
  getResourceDataPaginatedAuto  (resourceId,url_next,url_prev,new_page,page_size,lang,presentationId,taskInstanceId,formType) {
    
          var http_method="GET";
          
                  
          var url='../api/get-resource-data-paginated-auto/'+resourceId+'/'+presentationId+'/'+taskInstanceId+'/'+formType+'/'+lang+'/';

          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page;
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size;
            
            }
          }
         
          var deferred = this.$q.defer(); 
          this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201) {
                        // if successful, bind success message to message
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data auto failed ! --> getResourceDataPaginatedAuto',status:response.status});     
                    }
                //});    
            });
         
            return deferred.promise;
        };
//********************************************************** 
 //           GET RESOURCE COLUMNS 
  //**********************************************************       
  getResourceColumns  (resourceId,resourceType,lang,version) { 
     
          var http_method="GET"; 
          var deferred = this.$q.defer();  
                   
          var url='../api/get-resource-columns/'+resourceId+'/'+resourceType+'/'+lang+'/'+version+'/'; 
 
           
           
          this.RestApiService.get(url).then(response =>{ 
                 
 
                    if (response.status === 200 || response.status === 201 ) {

                        deferred.resolve({data: response.data,status:response.status});     
                    } else { 
                        deferred.reject({msg: 'Get resource columns failed (getResourceColumns),' + response.msg  ,status:response.status});      
                    } 
              //  });     
            }); 
          
          
          
       
        return deferred.promise; 

    };
  //**********************************************************
	//           GET RESOURCE DATA
	//**********************************************************      
  getResourceDataModel  (resourceId,taskId,modelId,formType,lang) {
    
          var http_method="GET";
          
          var url='../api/get-resource-data-model/'+resourceId+'/'+taskId+'/'+modelId+'/'+formType+'/'+lang+'/';
             var deferred = this.$q.defer(); 
             this.RestApiService.get(url).then(response =>{
               

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        
                        deferred.resolve({data: response.data.data,columnsDef: response.data.columnsDef , status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data model failed ! --> getResourceDataModel',status:response.status});     
                    }
               // });    
            });
            
            
          
            return deferred.promise;
        };
  //**********************************************************
	//           GET RESOURCE DATA FILTERED
	//**********************************************************      
  getResourceDataFiltered  (resourceId,taskId,formType,lang,filter) {
    
          var http_method="POST";
          
          var url='../api/get-resource-data-filtered/'+resourceId+'/'+taskId+'/'+formType+'/'+lang+'/';
             var deferred = this.$q.defer(); 
           
            let post_data = filter;
           this.RestApiService.post(url,post_data).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                            
                        deferred.resolve({data: response.data.data,columnsDef: response.data.columnsDef,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data filter failed ! --> getResourceDataFiltered',status:response.status});     
                    }    
            });
          
            return deferred.promise;
        };
 
 //**********************************************************
	//           GET RESOURCE DATA DIRECT FILTER 
    //  called by:  ktResource CTRL -  $scope.fd.getDatasetDataForSmartTable() 
	//**********************************************************      
  getResourceDataDirectFilter  (resourceId,filter,columns_for_select) {
    
          var http_method="POST";
          
          var url='../api/get-resource-data-direct-filter/'+resourceId+'/';
             var deferred = this.$q.defer(); 
          
            let post_data = {'search_filter':filter,'columns':columns_for_select}
           this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message  
                        deferred.resolve({data: response.data.data, status:response.status});       
                    } else {
                        deferred.reject({msg: 'Get resource data direct filter failed ! --> getResourceDataDirectFilter',status:response.status});     
                    }    
            });
          
          
           
           return deferred.promise;
    };
 
 
  //**********************************************************
	//           GET RESOURCE DATA RELATED
	//**********************************************************      
  getResourceDataRelated  (datasetId,recordId,relatedId,taskId,modelId,formType,lang) {
    
          var http_method="POST";
          
          var url='../api/get-resource-data-related/'+datasetId+'/'+recordId+'/'+relatedId+'/'+taskId+'/'+modelId+'/'+formType+'/'+lang+'/';
             var deferred = this.$q.defer(); 
          
          
           this.RestApiService.post(url).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {// if successful, bind success message to message

                        deferred.resolve({data: response.data.data,columnsDef: response.data.columnsDef,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource data related failed ! --> getResourceDataRelated',status:response.status});     
                    }  
            });
          
          
           
            return deferred.promise;
        };
  
  //**********************************************************
	//           GET RESOURCE DATA WITH RELATED
	//**********************************************************      
  getResourceDataM2m (widgetId,datasetId,relatedId,recordId) {
    
            let http_method="POST";
          
            let url='../api/get-resource-data-m2m/'+widgetId+'/'+datasetId+'/'+relatedId+'/'+recordId+'/';
            var deferred = this.$q.defer(); 
         
           this.RestApiService.post(url).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        deferred.resolve({data: response.data.data,columnsDef: response.data.columnsDef,status:response.status});          
                    } else {
                        deferred.reject({msg: 'Get resource data m2m failed ! --> getResourceDataM2m',status:response.status});     
                    }   
            });
          
          
            return deferred.promise;
        };
        
      //**********************************************************
    //           GET RESOURCE DATA WITH RELATED
    //**********************************************************      
  getResourceDataM2mFilter (widgetId,datasetId,relatedId,recordId,filter,form) {
    
            let http_method="POST";
          
            let url='../api/get-resource-data-m2m/'+widgetId+'/'+datasetId+'/'+relatedId+'/'+recordId+'/';
            var deferred = this.$q.defer(); 
            
            if (filter === undefined) filter = {}
            if (form === undefined) form = []

            let post_data = {'filter_data':filter,'form_data':form}
           this.RestApiService.post(url,post_data).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        deferred.resolve({analytics: response.data.Analytics, data: response.data.data,columnsDef: response.data.columnsDef,status:response.status});          
                    } else {
                        deferred.reject({msg: 'Get resource data m2m failed ! --> getResourceDataM2m',status:response.status});     
                    }   
            });
          
          
            return deferred.promise;
        };
           
  
  //**********************************************************
	//           GET DATASET MAPPING HEAD
  //**********************************************************      
  getMappingPackages  (fromDs,toDs,lang) {
    
         if (fromDs==undefined) fromDs=0;
         if (toDs==undefined) toDs=0;
         
          var http_method="GET";
          
          var url='../api/get-dataset-mapping-head/'+fromDs+'/'+toDs+'/'+lang+'/';
             var deferred = this.$q.defer(); 
           
             this.RestApiService.get(url).then(response =>{
                
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message   
                        deferred.resolve({data: response.data,status:response.status});     
                    } else {
                        deferred.reject({msg: 'Get mapping packages failed ! --> getMappingPackages',status:response.status});     
                    }
               // });    
            });
          
           
           return deferred.promise;
        };
   //**********************************************************
	//           GET DATASET TRANSFORM HEAD
  //**********************************************************      
  getTransformPackages  (lang) {
    
          var http_method="GET";
          
          var url='../api/get-dataset-transform-head/'+lang+'/';
             var deferred = this.$q.defer(); 
            
             this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        deferred.resolve({data: response.data , status:response.status});     
                    } else {
                        deferred.reject({msg: 'Get transform packages failed ! --> getTransformPackages'+ response.data,status:response.status});     
                    }
               // });    
            });
            
           
            return deferred.promise;
        };
   
  //**********************************************************
	//           GET RESOURCE FIELDS
  //**********************************************************      
  getResourceFields  (resourceId,lang = $rootScope.globals.currentUser.lang ) {
    
          var http_method="GET";
          var url='../api/get-resource-fields/'+resourceId+'/'+lang+'/';
             var deferred = this.$q.defer(); 
           
            this.RestApiService.get(url).then(response =>{
                
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message 
                        deferred.resolve({data: response.data , status:response.status});        
                    } else {
                        deferred.reject({msg: 'Get resource fields failed ! --> getResourceFields'+ response.data,status:response.status});     
                    }
               // });    
            });
           
           
            return deferred.promise;
        };     
   //**********************************************************
	//           GET DATASET MAPPINGS
  //**********************************************************      
  getDatasetMappings  (mappingId,lang) {
    
          var http_method="GET";
          
          var url='../api/get-dataset-mapping-detail/'+mappingId+'/'+lang+'/';
             var deferred = this.$q.defer(); 
            
            this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get dataset mappings failed ! --> getDatasetMappings'+ response.data,status:response.status});     
                    }
              //  });    
            });
            
           
           
           
            return deferred.promise;
        };     
        //**********************************************************
	//           GET DATASET MAPPINGS
  //**********************************************************      
  saveDatasetMappingDetails  (mappings,mappingId) {
    
          var http_method="POST";
          
          var url='../api/save-dataset-mapping-details/'+mappingId+'/';
             var deferred = this.$q.defer(); 
            this.RestApiService.post(url,mappings).then(response =>{

                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message

                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Save dataset mappings failed ! --> saveDatasetMappingDetails'+ response.data,status:response.status});     
                    }   
            });
            
            
          
           return deferred.promise;
        };     
//**********************************************************
//        SAVE DATASET MAPPING HEAD
//**********************************************************      
  saveDatasetMappingHead  (mappings,mappingId,lang) {
    
          var http_method="POST";
          
          var url='../api/save-dataset-mapping-head/'+mappingId+'/'+lang+'/';
          var deferred = this.$q.defer(); 
          
          this.RestApiService.post(url,mappings).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
    
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Save dataset mappings head failed ! --> saveDatasetMappingHead'+ response.data,status:response.status});     
                    }   
            });
         
            return deferred.promise;
        }; 
 
//**********************************************************
//        SAVE TRANSFORM HEAD
//**********************************************************      
  saveDatasetTransformHead  (transforms,transformId,lang) {
    
          var http_method="POST";
          
          var url='../api/save-dataset-transform-head/'+transformId+'/'+lang+'/';
          var deferred = this.$q.defer(); 
          
          this.RestApiService.post(url,transforms).then(response =>{
                 if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                           
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Save dataset transform head failed ! --> saveDatasetTransformHead'+ response.data,status:response.status});     
                    }    
            });
          
        
            return deferred.promise;
        };  
   //**********************************************************
	//           GET TRANSFORM FIELDS
  //**********************************************************      
  getTransformFields  (transformId) {
    
          var http_method="GET";
          
          var url='../api/get-dataset-transform-details/'+transformId+'/';
             var deferred = this.$q.defer(); 
           
           this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {

                        deferred.resolve({data: response.data , status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get transform fields failed ! --> getTransformFields'+ response.data,status:response.status});     
                    }
              //  });    
            });
           
           
           
            return deferred.promise;
        };
     //**********************************************************
	//           SAVE TRANSFORM FIELDS
  //**********************************************************      
  saveTransformFields  (transformations,transformId) {
    
          var http_method="POST";
          
          var url='../api/save-dataset-transform-details/'+transformId+'/';
             var deferred = this.$q.defer(); 
            
             this.RestApiService.post(url,transformations).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message 
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
                        deferred.reject({msg: 'Save transform fields failed ! --> saveTransformFields'+ response.data,status:response.status});     
                    }  
            });
             
            
            return deferred.promise;
        };     
  //**********************************************************
	//           GET Inititate widgets
	//**********************************************************      
  getFormWidgets  (resource_id,task_id,presentation_id,type,lang,version) {
    
          var http_method="GET";
          
          if (presentation_id==undefined || presentation_id==null) presentation_id=0;
          
          var url='../api/get-form-widgets/'+resource_id+'/'+task_id+'/'+presentation_id+'/'+type+'/'+lang+'/'+version+'/';
        
          var deferred = this.$q.defer(); 
           
           this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});     
                    } else {
                        deferred.reject({msg: 'Get form widgets failed ! --> getFormWidgets'+ response.data, status:response.status});     
                    }
               // });    
            });
          
          
           
            return deferred.promise;
        };
  //**********************************************************
	//           SAVE RESOURCE DATA
	//**********************************************************      
  saveResourceData  (resourceId,resourceData) {
    
          var http_method="POST";
          
          var url='../api/save-resource-data/'+resourceId+'/';
             var deferred = this.$q.defer(); 
          
           this.RestApiService.post(url,resourceData).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                        deferred.resolve({msg: 'New record saved successfully !',status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Save record failed ! --> saveResourceData',status:response.status});     
                    }   
            });
          
          
            
            return deferred.promise;
        };
    
    //**********************************************************
	//           SAVE RESOURCE DATA
	//**********************************************************      
  deleteDatasetRecord  (resourceDefId,resourceId) {
    
          var http_method="POST";
          
          var url='../api/delete-dataset-record/'+resourceDefId+'/'+resourceId+'/';
             var deferred = this.$q.defer(); 
           
            this.RestApiService.post(url).then(response =>{

                    if (response.status === 200 || response.status === 201 ) {  // if successful, bind success message to message
                        deferred.resolve({data: response.data, msg: response.data.msg, status:response.status});
                            
                    } else {
                        deferred.reject({msg: 'Delete record failed ! --> deleteDatasetRecord'+ response.data.msg, status:response.status});     
                    }   
            });
           
            return deferred.promise;
        };
    
       //**********************************************************
	//           GET RESOURCE PARAMS
	//**********************************************************      
    GetResourceParams  () {
    
          var http_method="GET";
          var url='../api/get-resource-params/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get resource params failed ! --> GetResourceParams' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };
        
        
        // function GetPublishedResources
        
        GetPublishedResources  (resourceId, resourceType='all') {
    
          
          var url='../api/published-resources/'+resourceId+'/' + resourceType + '/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get published resources failed ! ' + response.msg, status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };
        
        
		        
     
        
        GetResourceAccess  (app) {
			//debugger
          let id = app.uid ? app.uid : 0
		  let ver = app.version ? app.version: 0
			 
			 
          var url='../api/get-resource-access/'+ id +'/' + ver + '/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get published resources failed! ' + response.msg,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };
        
            
		  SaveResourceAccess  (resource, post_data) 
		  {
			  let resId =  resource.uid ?  resource.uid : 0
			  var url='../api/save-resource-access/'+ resId +'/' + resource.version + '/' ;
			  var deferred = this.$q.defer(); 
	 
				this.RestApiService.post(url, post_data).then(response =>{
						if (response.status === 200 || response.status === 201 ) {       
							deferred.resolve({data: response.data,status:response.status});           
						} else {
							deferred.reject({msg: 'SaveResourceAccess failed ! - ' + response.data, status:response.status});     
						}   
				});
            
            return deferred.promise;
        }
	
	
	  SaveMenuAccess  (resource, post_data) 
		  {
			  let resId =  resource.uid ?  resource.uid : 0
			  var url='../api/save-menu-access/'+ resId +'/' + resource.version + '/' ;
			  var deferred = this.$q.defer(); 
	 
				this.RestApiService.post(url, post_data).then(response =>{
						if (response.status === 200 || response.status === 201 ) {       
							deferred.resolve({data: response.data,status:response.status});           
						} else {
							deferred.reject({msg: 'SaveResourceAccess failed ! - ' + response.data, status:response.status});     
						}   
				});
            
            return deferred.promise;
        }
        
        
			
        // function GetPublishedResourcesByType      
        GetPublishedResourcesByType  (resourceType,publishType, useCache=true) {
    
          
          var url='../api/get-published-resources/'+resourceType+'/' + publishType + '/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url, useCache).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get published resources failed ! (GetPublishedResourcesByType)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };
        
    // function GetPublishedResources
        
        //GetPublishedResourcesByResourceDef

        GetPublishedResourcesByTypeId  (resource_id,resource_type) {
    
          
          var url='../api/get-published-resources-by-type-id/'+resource_id+'/' + resource_type + '/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get published resources failed ! (GetPublishedResourcesByTypeId)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

        GetPublishedResourceById  (publish_id) {
    
          
          var url='../api/get-published-resource-by-id/'+publish_id+'/'
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get published resource by id failed ! (GetPublishedResourcesById)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

         GetPublishedResourceVersion  (resource_id,publish_type,ver,envType) {
    
          if (ver==undefined) ver=0
          if (!envType) envType='dev'
          var url='../api/get-published-resource-version/'+resource_id+'/'+publish_type+'/'+envType+'/'+ver+'/'
          var deferred = this.$q.defer(); 
          
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get published resource version failed ! (GetPublishedResourceVersion)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

        GetPublicPublishedResource  (resource_id,ver) {
    
          if (ver==undefined) ver=1
          var url='../api/get-public-published-resource/'+resource_id+'/'+ver+'/'
          var deferred = this.$q.defer(); 
          
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get public published resource failed ! (GetPublishedPublicResource)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

           GetPublishedPublicResourcesByType  (publish_type) {
    
          
          var url='../api/get-published-public-resource-by-type/'+publish_type+'/'
          var deferred = this.$q.defer(); 
          
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get public published resources by type failed ! (GetPublishedPublicResourcesByType Fn)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };


        loadApp  (app_id,version) {

            if (version==undefined) version = 0
          var url='../api/load-app/'+app_id+'/'+version+'/'
          var deferred = this.$q.defer(); 
          
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Load application failed ! (loadApp) --> ' + response.msg,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

        PublishResource  (resourceId,resourceVersion,publishDesc,publishType) {
    
            if (publishDesc == undefined) publishDesc=''
            let url='../api/published-resources/'+resourceId+'/'+publishType+'/';
            let deferred = this.$q.defer(); 
            let publish = {'ResourceId':resourceId,'Version':resourceVersion,'Description':''+ publishDesc}
 
    
            this.RestApiService.post(url,publish).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({msg: response.data.msg,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Publish resource failed ! (PublishResource)' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

        getExternalResourceInfo  (resource_id) {

          let url='../api/get-external-resource-info/'+resource_id+'/'
          let deferred = this.$q.defer(); 
          
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200 || response.status === 201 ) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get external resource info failed ! (getExternalResourceInfo) --> ' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

      //**********************************************************
	//           GET RESOURCE SUBSCRIPTIONS
	//**********************************************************      
  GetResourceSubscriptions  (subscriptionType) {
    
          var http_method="GET";
          
          var url='../api/resource-subscriptions/'+subscriptionType+'/';
             var deferred = this.$q.defer(); 
           
           this.RestApiService.get(url).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get resource subscriptions failed ! --> GetResourceSubscriptions',status:response.status});     
                    }   
            });
           
           return deferred.promise;
        };
         //**********************************************************
    //           GET WIDGET  TOOLBOX
    //**********************************************************      
  GetWidgetToolbox  (resourceType) {
    
          var http_method="GET";
          
          var url='../api/get-widget-toolbox/'+resourceType+'/';
          var deferred = this.$q.defer(); 
          
          this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind data
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get widget toolbox failed ! --> GetWidgetToolbox'+data,status:response.status});     
                    }
         
            });
          
            return deferred.promise;
        };

           //**********************************************************
    //           GET WIDGET  TOOLBOX
    //**********************************************************      
  DeleteChildRelationship  (presentationId,parentDefId,parentRecordid,childRecordId) {
    
          var http_method="GET";
          
          var url='../api/delete-child-relationship/'+presentationId+'/'+parentDefId+'/'+parentRecordid+'/'+childRecordId+'/';
          var deferred = this.$q.defer(); 
          
          this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind data
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Delete child relationship failed ! --> DeleteChildRelationship'+data,status:response.status});     
                    }
         
            });
          
            return deferred.promise;
        };
    
    //**********************************************************
    //           SET NEW RESORUCE VERSION
    //**********************************************************      
    SetNewResourceVersion  (resourceId) {
    
           
          var url='../api/set-new-resource-version/'+resourceId+'/'
          var deferred = this.$q.defer(); 
          
          this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind data
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Set new version failed ! --> SetNewResourceVersion'+response.data,status:response.status});     
                    }
         
            });
          
            return deferred.promise;
    };
    /**********************************************************
    ***************** PROCESS WIDGETS  ******************
    ***********************************************************/     
  processWidgets  (resourceId,resourceData,resourceInstance,pageVersion) {
    
          var http_method="POST";
          
          if (resourceId!=undefined && resourceInstance!=undefined)
          {
          var url='../api/process-widgets/'+resourceId+'/'+resourceInstance+'/'+pageVersion+'/';
          
          var deferred = this.$q.defer(); 
        
            let post_data= {'resourceData':resourceData} ;
            this.RestApiService.post(url,post_data).then(response =>{
                

                 if (response.status === 200 || response.status === 201) {
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {   
                             //deferred.reject({msg:'Process widgets error!  '+response.status+' statusText = '+response.statusText+' '+ response.data,status:response.status});  
						deferred.reject({msg: response.data, status:response.status});							 
                    }
 
            });
          
            return deferred.promise;
          }
        };
    //**********************************************************
	//           MANAGE RESOURCE SUBSCRIPTIONS
	//**********************************************************      
  ManageResourceSubscriptions  (resource,subscriptionType,actionType) {
    
          var http_method="POST";
          
          var url='../api/resource-subscriptions/'+subscriptionType+'/';
          var deferred = this.$q.defer(); 
          let post_data = { 'resource': resource,'action':actionType};
          
          this.RestApiService.post(url,post_data).then(response =>{
               
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});       
                    } else {
                        deferred.reject({msg: 'Manage resource subscriptions failed ! --> ManageResourceSubscriptions'+ response.data,status:response.status});     
                    }   
            });
          
            return deferred.promise;
        };
        
    //**********************************************************
	//           save application menu
	//**********************************************************      
  saveResourceLinks (rlink) {
    
          var http_method="POST";
          
          var url='../api/save-resource-links/';
          var deferred = this.$q.defer(); 
          let post_data = rlink;
          
          this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Save resource links failed ! --> saveResourceLinks'+response.data,status:response.status});     
                    } 
            });
           
            
            return deferred.promise;
        };

  saveApplication (app) {
    
          var http_method="POST";
          
          var url='../api/save-application/';
          var deferred = this.$q.defer(); 
          let post_data = app;
          
          this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Save application failed ! --> saveApplication'+response.data,status:response.status});     
                    } 
            });
           
            
            return deferred.promise;
        };

     //**********************************************************
    //           publish application
    //**********************************************************      
  publishApplication (appId,version) {
    
          var http_method="GET";
          if (!version) version=0
          var url='../api/publish-application/'+appId+'/'+version+'/';
          var deferred = this.$q.defer(); 

         this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind data
                           
                        
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Publish application failed ! --> publishApplication'+response.data,status:response.status});     
                    }
         
            });
            
            return deferred.promise;
        };

    //**********************************************************
    //           save application menu
    //**********************************************************      
  publish2Prod (resources) {
    
         var http_method="POST";
          
          var url='../api/publish-2-prod/';
          var deferred = this.$q.defer(); 
          let post_data = resources;
          
          this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Publish 2 prod failed ! --> publish2Prod'+response.data,status:response.status});     
                    } 
            });
           
            
            return deferred.promise;
        };


    //**********************************************************
    //           publish public resource
    //**********************************************************      
  publishPublicResource (resourceId,resourceType,version,publish) {
    
         var http_method="POST";
          
          var url='../api/publish-public-resource/'+resourceId+'/'+resourceType+'/'+version+'/';
          var deferred = this.$q.defer(); 
          let post_data = publish;
          
          this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Publish public resource failed ! --> publishPublicResource Fn --> '+response.data,status:response.status});     
                    } 
            });
           
            
            return deferred.promise;
        };
    //**********************************************************
    //           add resource record
    //**********************************************************      
    addResourceRecord (resourceId,presentationId,resource,version,rinstance,prinstance) {
    
        
        if (version==undefined) version=0
        if (rinstance==undefined) rinstance=0
        if (prinstance==undefined) prinstance=0

        let url='../api/kt-task-add-resource/'+resourceId+'/'+presentationId+'/'+version+'/'+rinstance+'/'+prinstance+'/'
        let deferred = this.$q.defer(); 
        let post_data = resource;
          
        this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Add record failed ! '+response.data,status:response.status});     
                    } 
        });
            
        return deferred.promise;
    };
    //**********************************************************
    //           add resource record
    //**********************************************************      
    updateResourceRecord (resourceId,presentationId,resource,version) {
    
        let url='../api/kt-task-update-resource/'+resourceId+'/'+presentationId+'/'
        if (version!=undefined) url+=version+'/'
        let deferred = this.$q.defer(); 
        let post_data = resource;
          
        this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Update resource record failed ! --> updateResourceRecord'+response.data,status:response.status});     
                    } 
        });
           
            
        return deferred.promise;
    };


     //**********************************************************
    //           add resource record
    //**********************************************************      
    processR2rMapping (widgetId,resourceId,resourceVersion,mappings,filter_data,resourceInstanceId,prevResourceId) {
    
        let url='../api/process-r2r-mapping/'+widgetId+'/'+resourceId+'/'+resourceVersion+'/'
        let deferred = this.$q.defer(); 
        if (resourceInstanceId==undefined) resourceInstanceId=0
        if (prevResourceId==undefined) prevResourceId=0
        url = url+resourceInstanceId+'/'+prevResourceId+'/'
        let post_data = {'mappings':mappings,'filter':filter_data};
          
        this.RestApiService.post(url,post_data).then(response =>{
                    if (response.status === 200 || response.status === 201 ) { // if successful, bind success message to message
                        deferred.resolve({data: response.data,status:response.status});    
                    } else {
                        deferred.reject({msg: 'Process resource 2 resource mapping failed ! --> processR2rMapping'+response.data,status:response.status});     
                    } 
        });
           
            
        return deferred.promise;
    };
    //**********************************************************
    //           open secured page
    //**********************************************************      
  openSecuredPage (pageId, pageVersion , page_instance,prev_resource_id) {
        
        if (page_instance==undefined) page_instance=0
        if (prev_resource_id==undefined) prev_resource_id=0
        let url='../api/open-secured-page/'+pageId + '/' + pageVersion +'/'+page_instance+'/'+prev_resource_id+'/';
        let deferred = this.$q.defer(); 

        this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                      
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: response.msg,status:response.status});     
                    }
         
            });
            
            return deferred.promise;
        };

        //**********************************************************
    //           open public page
    //**********************************************************      
  openPublicPage (orgId,pageId,pageVersion,page_instance,prev_resource_id) {
    
         
       
        let deferred = this.$q.defer(); 
        if (pageVersion==undefined) pageVersion=0
        if (page_instance==undefined) page_instance=0
        if (prev_resource_id==undefined) prev_resource_id=0

        let url='../api/open-public-page/'+orgId+'/'+pageId+'/'+ pageVersion +'/'+page_instance+'/'+prev_resource_id+'/';

        this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                      
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: response.msg,status:response.status});     
                    }
         
            });
            
            return deferred.promise;
        };

    //**********************************************************
    //           preparePublish2Prod
    //**********************************************************      
  preparePublish2Prod (resourceId,resourceType,version) {
    
         
          let url='../api/prepare-publish-2-prod/'+resourceId+'/'+resourceType+'/'+version+'/';
          let deferred = this.$q.defer(); 

         this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                      
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: response.msg,status:response.status});     
                    }
         
            });
            
            return deferred.promise;
        };

 //**********************************************************
    //           preparePublish2Prod
    //**********************************************************      
  InstallResource (publishId,resourceId,resourceType) {
    
         
          let url='../api/install-resource/'+publishId+'/'+resourceId+'/'+resourceType+'/';
          let deferred = this.$q.defer(); 

         this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                      
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: response.msg,status:response.status});     
                    }
         
            });
            
            return deferred.promise;
        };


       //**********************************************************
	//           MANAGE RESOURCE SUBSCRIPTIONS
	//**********************************************************      
  CallApiGeneric  (end_point,http_method,i_data) {
    
          
             var deferred = this.$q.defer(); 
           
           this.RestApiService.send(end_point,http_method,i_data).then(response =>{
                response.json().then(data=>{
                    if (response.status === 200 || response.status === 201 ) {
                       deferred.resolve({data: data,status:response.status});         
                    } else {
                        deferred.reject({data: data,status:response.status});     
                    }
                });    
            });
           
           
            return deferred.promise;
        };
        
   prepareResourceWidgetData  (rows,resourceRecordId)
    {
      
      var resourceData = {};
      var value="",fieldId="";
      if (resourceRecordId>0)
      {
        resourceData['id'] = {type:'resourceId', value: resourceRecordId};
      }
     for (var i=0;i<rows.length;i++) {
              if ('layout' in rows[i])
               {
                   for (var j=0;j<rows[i].layout.length;j++) {
                    if ('layout' in rows[i].layout[j])
                    {
                     for (var l=0;l<rows[i].layout[j].layout.length;l++) { 
                      
                      
                      if(rows[i].layout[j].layout[l].type=='resourceid' && rows[i].layout[j].layout[l].sItemValue!=null && resourceRecordId==-1)
                       {
                               
                          resourceData['id'] = {type:'resourceId', value: rows[i].layout[j].layout[l].sItemValue};                     
                       }
                      if ((rows[i].layout[j].layout[l].ItemId!=null && rows[i].layout[j].layout[l].sItemValue!=undefined && rows[i].layout[j].layout[l].sItemValue!=null && !rows[i].layout[j].layout[l].noUpdate) || (rows[i].layout[j].layout[l].ItemId!=null && rows[i].layout[j].layout[l].type=='resource'))
                      {
                         
                       fieldId='Field'+rows[i].layout[j].layout[l].ItemId;
                       value = rows[i].layout[j].layout[l].sItemValue;
                       if (rows[i].layout[j].layout[l].Dbtype=='foreignkeydefinition') {
                          fieldId=fieldId+'_id';//code
                          if (rows[i].layout[j].layout[l].type=='user')
                          {
                            value = rows[i].layout[j].layout[l].sItemValue;
                            }
                            else
                            {
                              
                             if (rows[i].layout[j].layout[l].sItemValue!=undefined)
                             {
                              value= rows[i].layout[j].layout[l].sItemValue.id;
                             }
                             
                            }
                          if (value!=""  && value!=undefined)
                          {
                            resourceData[fieldId] = {type:rows[i].layout[j].layout[l].type, value: value };
                          }
                       }
                       else if(rows[i].layout[j].layout[l].type=='resource')
                       {
                          resourceData[fieldId]={type:rows[i].layout[j].layout[l].type,value:{resourceDefId: rows[i].layout[j].layout[l].Related,resourceId:rows[i].layout[j].layout[l].Value }}
                       }
                       else if(rows[i].layout[j].layout[l].type=='date')
                       {
                        if (rows[i].layout[j].layout[l].useSystemDate)
                        {
                          resourceData[fieldId]={type:'systemdate',value:rows[i].layout[j].layout[l].sItemValue}
                        }
                        else
                        {
                          resourceData[fieldId]={type:rows[i].layout[j].layout[l].type,value:rows[i].layout[j].layout[l].sItemValue}
                        }
                       }
                       else
                       {
                         if (value!="" && value!=undefined)
                          {
                            resourceData[fieldId] = {type:rows[i].layout[j].layout[l].type, value: value }
                          }
                       }
                       
                      }

                    }
                  }
                }
               }
              }
               
               return resourceData;
      
    };

  /*MIGOR     
//**********************************************************
//           GET FILES DATA
//**********************************************************      
^api/ ^files/(?P<id>.+)/$ [name='Files-list']
*/
	getFilesData1  (resourceId,lang) {
    
          var http_method="GET";
          
		  var url='../api/files/'+resourceId+'/';
		  
            var deferred = this.$q.defer(); 
           
           this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message 
                        deferred.resolve({data: response.data,status:response.status});
                            
                    } else {
     
                        deferred.reject({msg: 'Get files data failed ! --> getFilesData1',status:response.status});     
                    }
                //});    
            });
           
           
            return deferred.promise;
        };
		
		// MIGOR START

    //**********************************************************
	//           MIGOR - UPDATE FILE DATA
	//**********************************************************      
          
     fileMetaUpdate (fileId,fileMetaDefId,publishId,taskInstanceId,prevTaskId,parentDatasetDefId,parentDatasetId,fileResourceData,fd,page_id) {
    
          var http_method="POST";
          
          var url='../api/file-meta-data-update/';
            var deferred = this.$q.defer();
            if (prevTaskId==undefined) prevTaskId=0;
            let post_data = {'fileId':fileId,'fileMetaDefId':fileMetaDefId,'publishId':publishId,'taskInstanceId':taskInstanceId,'prevTaskId':prevTaskId,'parentDatasetDefId':parentDatasetDefId,'parentDatasetId':parentDatasetId,'fileResourceData':fileResourceData,'fd':fd,'pageId':page_id};
            
            this.RestApiService.post(url,post_data).then(response =>{
              
                    if (response.status === 200 || response.status === 201 ) {
                        // if successful, bind success message to message
                           
                                deferred.resolve({data: response.data,status:response.status});   
                            
                    } else {
                                deferred.reject({msg:'File metadata update error (fn fileMetaUpdate) --> status = '+response.status+' statusText = '+response.statusText+' '+ response.data,status:response.status});      
                        } 
               
            });
            
            
           return deferred.promise;
        };
       
// MIGOR END

	getCodes (code_id,language){
             var codeIndex=-1;
             if (code_id != undefined) {
              var deferred = this.$q.defer();                   
              var pos = listOfCodesById.map(function(e) { return e.unique_id; }).indexOf(code_id+language);
              let url = '../api/get-codes-detail-byidlang/'+ code_id+'/'+language+'/';
              if (pos<0){
				  //if  not found - download
                this.RestApiService.get(url, true).then(response =>{
                    

                        if (response.status === 200 || response.status === 201 ) {
                            // if successful, bind success message to message
                               
                            let retrivedList = {unique_id: code_id+language, id: code_id,lang: language, codeList: response.data};
              
          					  listOfCodesById.push(retrivedList);
                            codeIndex=listOfCodesById.length-1;
                            deferred.resolve(listOfCodesById[codeIndex].codeList);
                            deferred.resolve({data: response.data,status:response.status});
                                
                        } else {
         
                            deferred.reject(response.data);     
                        }
                   // });    
                });
      
				
              } else {
                codeIndex=pos;
                deferred.resolve(listOfCodesById[codeIndex].codeList);
              }
            };
	
          return deferred.promise;
		};
		  

}
 export default ResourcesDataService;