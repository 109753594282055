'use strict';

/*
import KatalyoCtrls from '../../0001_index/angularJS/katalyoCtrls.js';
var moduleName='tasksCtrls';
export default moduleName;

var module = angular.module('KatalyoCtrls',KatalyoCtrls);
*/

export default function taskListCtrl ($scope, $rootScope, AuthenticationService,ResourcesDataService,uiGridConstants,MessagingService,$timeout) {
    
        $scope.CRTRLNAME='taskListCtrl';
         
        //Get data from server
    $scope.gridOptions = {
       columnDefs: [
       { name: 'id', displayName: 'ID', type: 'number', width:'5%',enableHiding: false, visible: true },
       { name: 'resourcedeflang_set[0].Name', displayName: 'Task name'},
       { name: 'resourcedeflang_set[0].Subject', displayName: 'Task subject' },
       { name: 'resourcedeflang_set[0].Description', displayName: 'Task description' },
       //  { name: 'resourcedeflang_set[0].Lang.name', displayName: 'Language'},
        // { name: 'ResourceType.CodesDetailLang[0].Value', displayName: 'Task type' },
        // { name: 'CreatedDateTime', displayName: 'Date created',enableHiding: false,type: 'date', cellFilter: 'date:\'dd.MM.yyyy HH:MM:ss:sss\'' },
        // { name: 'CreatedBy.name', displayName: 'Created by'},
       { name: 'Status.CodesDetailLang[0].Value', displayName: 'Status'},
       ],
       enableGridMenu: true,
       showGridFooter: true,
       showColumnFooter: true, 
       multiSelect: false,
       enableHorizontalScrollbar : 0, 
       enableVerticalScrollbar : 2,
       enableFiltering: true,
       enableRowHeaderSelection: false,
       enableColumnResizing: true,
       useExternalPagination: true,
       paginationPageSizes: [5, 10, 20, 50, 100,200,500,1000],
       enablePaginationControls: false,
      
   };
   
      var paginationOptions = {
        pageNumber: 1,
        pageSize: 10,
        sort: null
      }; 


    $scope.gridOptions.onRegisterApi = function (gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
        
          gridApi.selection.on.rowSelectionChanged($scope,function(row){
          
            $scope.taskSelected=row.isSelected;
            $scope.selectedTask = row.entity.id;
            $scope.selectedTaskObject = row.entity;
          });
     }
   
   
   
 $scope.getTaskDef = function(url_next,url_prev,new_page,page_size){
   
   
    var lang=$rootScope.globals.currentUser.lang;
    
    if (new_page==undefined) new_page = paginationOptions.pageNumber;
    
    if (page_size==undefined) page_size = paginationOptions.pageSize;
                                    
    if (Math.ceil($scope.totalCount/page_size)<new_page)
    {
      new_page = Math.ceil($scope.totalCount/page_size);
    }

    
    ResourcesDataService.getResourceDefByLangByTypePaginated(lang,"task",url_next,url_prev,new_page,page_size).then(function(data){
                               
                                  $scope.gridOptions.data = data.data.results;
                                  $scope.nextPage = data.data.next;
                                  $scope.previousPage = data.data.previous;
                                  $scope.totalCount=data.data.count;
                                  $scope.currentPage=new_page;
                                  $scope.numPages = Math.ceil($scope.totalCount/page_size);
                                  $scope.pageSize = page_size;
                                  $scope.gridOptions.totalItems=$scope.totalCount;
                                  $scope.tasksLoaded=true;
                                 },function(error){
                                  
                                 MessagingService.addMessage(error.msg,'error');
                                 $scope.tasksLoaded=false;
                             });
      };
        
    $scope.pageChanged = function(){
        $scope.getTaskDef($scope.nextPage,$scope.previousPage,$scope.currentPage,$scope.pageSize);
      };
      
    //napuni grid s definicijama resursa
    $scope.pageChanged();
    
       
    
    }
export function taskFormCtrl ($timeout,$scope, $rootScope, AuthenticationService,ResourcesDataService,TaskDataService,ProcessingService,MessagingService,WidgetRegisterService,$stateParams,GridsterService,dragulaService,$element,WidgetDataExchangeService) {
    
      //console.log("resourceFormCtrl scope:" + $scope.$id)
      $scope.resourceObject = $stateParams.resource;
      $scope.formLoaded = false;
      $scope.resourceId=$stateParams.id;
      $scope.gsrvc = GridsterService;
      $scope.showFieldProperties = false;
      $scope.CTRLNAME='taskFormCtrl';
     
      $scope.formType= $stateParams.formType;
      
      $scope.taskDefinition = {ResourceDefId_id:$scope.resourceId};
      $scope.taskDefinition.form_type=$scope.formType;
      $scope.taskDefinition.form_definition=true;
      $scope.gridsterType="task";
      
      //$rootScope.$emit('RegisterTaskWidgets',null);
      
      WidgetRegisterService.EmitRegisterTaskWidgetEvent();
      
      WidgetDataExchangeService.deRegisterAllWidgets();	
      
      $scope.hideTabIndex = true;
      $scope.outcomes = {outcomeCodesDetails:[]};
      $scope.outcomes.selectedOutcomeIndex=-1;
      $scope.dashboard ={widgets:{layout: GridsterService.getEmptyTaskForm(),data:{}}};
       var lang=$rootScope.globals.currentUser.lang;
      //$scope.dashboard.widgets=GridsterService.ProcessWidgets($scope.dashboard.widgetsOriginal);
      
      
      $scope.FormLockUnlock = function()
      {
        $scope.resourceDefinition.formParams.formLocked=!$scope.resourceDefinition.formParams.formLocked;
      
      };
      
       $scope.ShowFormView = function(viewId,locked)
      {

            var bag = dragulaService.find($rootScope, 'bag-task');
            if (bag) {
            dragulaService.destroy($rootScope, 'bag-task')
           
            }
            var bag = dragulaService.find($rootScope, 'bag-dataset');
            if (bag) {
            dragulaService.destroy($rootScope, 'bag-dataset')
           
            }
            $scope.resourceDefinition.formParams.formViewType=viewId;
            $scope.resourceDefinition.formParams.formLocked = locked;
            $scope.gsrvc.setFormViewType($scope.resourceDefinition.formParams.formViewType);
        
      };
     
     
     // $scope.$watch('taskAssignments.taskInitiatorsUsers.AssigneeList',function(newValue,oldValue){ 
      //        // watch taskAssignments.taskInitiatorsUsers.AssigneeList
      //      });
      
        $scope.showHideProperties = function(value)
                {
                  $scope.gsrvc.setFieldPropertiesOpened(value);
                  $scope.showFieldProperties=$scope.gsrvc.getFieldPropertiesOpened();
       
                };
      
      $scope.$watch('gsrvc.getFieldPropertiesOpened()',function(newValue,oldValue){
              
              if (newValue!=oldValue)
              {
               $scope.selectedField=$scope.gsrvc.getFieldProperties();
               $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
              }
            });
      
      
       $scope.$watch('gsrvc.getFieldProperties()',function(newValue,oldValue){
              
              if (newValue!=oldValue)
              {
               $scope.selectedField=$scope.gsrvc.getFieldProperties();
               $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
              }
            });
      
      
       $scope.getTaskParameters = function(){
          TaskDataService.GetTaskParameters($scope.resourceId).then(function(data) {
                                   //  $scope.ac.alerts = data.data;
                                
                                if (data.data.length>0) {
                                                                         
                                    $scope.taskDefinition = data.data[0];
                                    $scope.taskDefinition.form_type=$scope.formType;
                                    $scope.taskDefinition.form_definition=true;
                                    if (!$scope.taskDefinition.InfiniteTask)
                                    {
                                           //get Task Outcomes 
										if ($scope.taskDefinition.TaskOutcomeHeadId == null || $scope.taskDefinition.TaskOutcomeHeadId==undefined)
											{
											 $scope.taskDefinition.TaskOutcomeHeadId=0; //vidjeti da li ovo treba MIGOR
											}
								   
								   
										//TaskDataService.GetTaskCodesActionsById($scope.resourceId,lang,$scope.taskDefinition.TaskOutcomeHeadId).then(function(data) {
										TaskDataService.GetTaskOutcomes($scope.resourceId,$scope.taskDefinition.TaskOutcomeHeadId,lang).then(function(data) {            
																  $scope.outcomes.outcomeCodesDetails = data.data;
																  //prikazi defaultno detalje od prvog
																  if ($scope.outcomes.outcomeCodesDetails.length>0)   $scope.clickedOutcome = $scope.outcomes.outcomeCodesDetails[0];
																  
															},function(error){
																  MessagingService.addMessage(error.msg,'error');
															  });
                                    }
                                      
                                }  
                             },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');
                              });
                               
                            
       };
      
      
      $scope.getResourceForm = function (afterSave,afterSaveMsg,refresh) {
            ResourcesDataService.getResourceForm($scope.resourceId,$scope.formType,lang,refresh).then(function(data) {
                                
                                    if (data.data.widgets.length>0)
                                   {
                                    $scope.dashboard.widgets.layout=data.data.widgets;
                                   }
                                   $scope.resourceDefinition= data.data.resourceDefinition;
                                   $scope.resourceDefinition.formParams={formViewType:2,formLocked:false};
                                   $scope.gsrvc.setFormLockStatus($scope.resourceDefinition.formParams);
                                   $scope.gsrvc.setFormViewType($scope.resourceDefinition.formParams.formViewType);
        
      
                                   $scope.importantFields =  $scope.localVarsWidget.getFields($scope.localVarsWidget.dashboard.widgets.layout, ['Required', 'ReadOnly']);  
                              
                                   //$scope.dashboard.widgets = GridsterService.ProcessWidgets($scope.dashboard.widgetsOriginal);
                                   $scope.formLoaded=true;
                                   if (afterSave)
                                   {
                                     MessagingService.addMessage(afterSaveMsg,'success');
                                    
                                   }
                                },function(error){
                             
                                  
                                 MessagingService.addMessage(error.msg,'error');
                                
                                
                             });
      };
  
     
  //**********************************************************
	//           SAVE TASK FORM DEFINITION
	//**********************************************************

  $scope.saveTaskForm = function () {
    //if (isValid) {
      //code
         
         $scope.activity.activityMessage = ProcessingService.setActivityMsg('Saving task form for '+$scope.resourceId);
         $scope.formLoaded=false;       
         ResourcesDataService.saveResourceForm($scope.dashboard.widgets.layout,$scope.resourceId,$scope.formType).then(function(data) {
                                 

                                 $scope.formLoaded=true;
                                // $scope.dashboard.widgets = data.data;
                                 $scope.getResourceForm(true,data.msg,true);
                                
                             },function(error){
                               
                                 MessagingService.addMessage(error.msg,'error');
                                
                              });
                                
                            
      //   }
        };
        
   //**********************************************************
	//           SAVE TASK BUTTONS
	//**********************************************************      
  $scope.saveTaskBtns = function () {
 
    
         //$scope.activity.activityMessage = ProcessingService.setActivityMsg('Saving task btns for '+$scope.resourceId);
         
         TaskDataService.SaveTaskBtns($scope.resourceDefinition.initTaskBtnDef,$scope.resourceId,lang).then(function(data) {
                                 
                                  
                                 MessagingService.addMessage(data.msg,'success');
                            
                             },function(error){
                                  
                                 MessagingService.addMessage(error.msg,'error');
                                
                              });
                                
                            
 
        };
        
//**********************************************************
	//           SAVE TASK OUTCOMES
	//**********************************************************    
      $scope.saveTaskOutcomes = function(){
      
		//check for errors
		var saveTaskOutcomesErrors=[];
    if (!$scope.taskDefinition.InfiniteTask)
		{
      for(var i=0;i<$scope.outcomes.outcomeCodesDetails.length;i++){
        if 	($scope.outcomes.outcomeCodesDetails[i].outcomeName==null) saveTaskOutcomesErrors.push('Outcome name must be defined!');
        //if 	($scope.outcomes.outcomeCodesDetails[i].actions.length==0) saveTaskOutcomesErrors.push('Outcome action must be defined!');
      }
      if ($scope.outcomes.outcomeCodesDetails.length==0) {	saveTaskOutcomesErrors.push("Outcome must be defined!");};
    }
    if (saveTaskOutcomesErrors.length>0) 
			MessagingService.addMessage(saveTaskOutcomesErrors,'error') //MessagingService.addMessage('No outcomes defined!','error');}
		else 
		{
		
			var lang=$rootScope.globals.currentUser.lang;
			
			//ako ne postoji outcomeHead a ima podataka onda kreiramo novi outcomeHead 
			if (($scope.taskDefinition.TaskOutcomeHeadId == null || $scope.taskDefinition.TaskOutcomeHeadId==undefined || $scope.taskDefinition.TaskOutcomeHeadId==0) && !$scope.taskDefinition.InfiniteTask)
			{
			   if ($scope.outcomes.outcomeCodesDetails.length>0) {
			   
				  $scope.OutcomeHead = {Active:true,id:null};
				  $scope.OutcomeHead.Lang = lang;
				  $scope.OutcomeHead.OutcomeName=$scope.resourceDefinition.name;
				  $scope.OutcomeHead.Name=$scope.resourceDefinition.name;
           
				  TaskDataService.SaveOutcomeDefinition($scope.OutcomeHead).then(function(data) {
						  
						  $scope.taskDefinition.TaskOutcomeHeadId=data.data.data.id;
						  MessagingService.addMessage(data.data.msg,'success');     
						  $scope.saveTaskOutcomesFinalize();
					   
				  },function(error){                   
						MessagingService.addMessage(error.msg,'error');
					}); 
         }

		  }  //ako već postoji outcomehead onda samo snimamo outcome 
		  else  $scope.saveTaskOutcomesFinalize();
       }
	   }
	  
	  
	   $scope.saveTaskOutcomesFinalize = function(){
		   
		   	var outcomes = {outcomeId:$scope.taskDefinition.TaskOutcomeHeadId,defaultOutcomeId:$scope.taskDefinition.DefaultOutcomeId,outcomes:$scope.outcomes.outcomeCodesDetails};

			TaskDataService.SaveTaskOutcomes($scope.resourceId,!$scope.taskDefinition.InfiniteTask,outcomes,lang).then(function(data) {
                                                        
                                  $scope.outcomes.outcomeCodesDetails = data.data;
                                  $scope.outcomes.selectedOutcomeIndex=-1;
                                  MessagingService.addMessage(data.msg,'success');
                       
                                
                            },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                              });
							  
	   }
	   
	   
    
     //**********************************************************
	//           CALL FUNCTIONS FOR FETCHING TASK FORM, PARAMETERS, OUTCOMES
	//**********************************************************    
     if ($scope.resourceId != undefined)
      {
        $scope.getResourceForm();
      }
      
       $scope.getTaskParameters();
       
    
   
}
export function taskDefinitionCtrl ($scope, $rootScope,$timeout,uiGridConstants,ProcessingService,MessagingService,GridsterService,CodesService,$stateParams,TaskDataService,TaskDefinitionService,ResourcesDataService,$state,dragulaService,KatalyoStateManager) {
      
      $scope.is_superuser =  $rootScope.globals.currentUser.is_superuser;
      //$scope.organisation_id =  $rootScope.globals.currentUser.uep.Organisation;
	  $scope.organisation_id =  $rootScope.globals.currentUser?.organisation?.id
      
      $scope.CTRLNAME='taskDefinitionCtrl';
      //  //taskDefinitionCtrl
      
      $scope.taskId=parseInt($stateParams.id.split("_")[0])     
      $scope.taskUid=$stateParams.id;
       
       
      $scope.tds=TaskDefinitionService;
      $scope.gsrvc = GridsterService;
      $scope.firstDownloadAssignments = false;
      $scope.localVars = {};
      $scope.localVars.gsrvc = GridsterService;
      $scope.localVars.reloadToolbox = false;
      //$scope.gsrvc.destroyDragula('bag-resource');
      //$scope.gsrvc.destroyDragula('bag-task');
      $scope.ksm = KatalyoStateManager;
      $scope.tds.SetScopeForDefinition($scope);
      $scope.gsrvc.resetBodyBgColor()   
    
      $scope.loadingActions={loading:false};  
      $scope.checklist = {form:true,assign:false,outcomes:false,status:true,params:false};
      $scope.outcomes = {outcomeCodesDetails:[]};
      $scope.codesActions = {codesActionsList:[],codesActionsListOriginal:[]};
      $scope.taskDefinition={};
      $scope.task={};
      var lang = $rootScope.globals.currentUser.lang;
     
      $scope.taskDefinition.TaskTypeObject={};
      $scope.taskDefinition.NoLimit = false;
      $scope.taskDefinition.MaxActualOwners = 0;
      $scope.taskDefinition.AutoStart = false;
      $scope.taskDefinition.AutoClaim = true;
      $scope.taskDefinition.AutoAssign = true;
      $scope.taskDefinition.AutoInitiate = true;
      $scope.taskDefinition.AutoExecute = false;
      $scope.taskDefinition.SelfAssigned = true;
      $scope.taskDefinition.CanDelegate = false;
      $scope.taskDefinition.CanHold = false;
      $scope.taskDefinition.CanRelease = false;
      $scope.taskDefinition.InfiniteTask = false;
      $scope.taskDefinition.PublicTask = false;
      $scope.taskDefinition.AssignTypeOwner = 's'
      $scope.taskDefinition.AssignTypeInitiator='a'
      $scope.taskDefinition.SetupAssignments=true
      $scope.taskDefinition.ResourceDefId = $scope.taskId;
      $scope.taskDefinition.ResourceDefUid = $scope.taskUid;
      $scope.taskDefinition.UniqueName = $scope.taskId;
      $scope.taskDefinition.OrganisationId = 1;
      //$scope.taskDefinition.CreatedBy = $rootScope.globals.currentUser.userid;
      //$scope.taskDefinition.ChangedBy = $rootScope.globals.currentUser.userid;
      //$scope.taskDefinition.VersionFrom = 1;
      //$scope.taskDefinition.VersionTo = 1;
      $scope.taskDefinition.TaskOutcomeCodeId = null;
      $scope.taskDefinition.DefaultOutcomeId = null;
      $scope.taskStatusFrom={'id':null,'name':null};
      $scope.taskStatusTo={'id':null,'name':null};
     // $scope.taskTypeList=[];
     $scope.taskDefinition.Parameters = {};
     $scope.taskDefinition.form_definition=true;
     $scope.taskDefinition.formParams={formViewType:1,showGridNavigation:false}; 

     
      $rootScope.markAll = {
          readOnly: false,
          required: false
      }
      
      
      // $scope.$watch('taskAssignments.taskInitiatorsUsers.AssigneeList',function(newValue,oldValue){    
          //  });
            
      $scope.localVars.initTaskTypeSelection = function ()
      {
         let a= $scope.taskTypeList.find( e => e.id ==  $scope.taskDefinition.TaskType)             
          $scope.taskDefinition.TaskTypeObject = a
      }
      $scope.localVars.markAll = function(what){
          $rootScope.markAll[what] = true
      }
      
      
      $scope.localVars.getFields = function(form,requiredFields,msg)
      {
         let resultProperties =  TaskDataService.getFields(form, requiredFields);
         if (msg) MessagingService.addMessage(msg,'info');
         return resultProperties
      }; 
      
      
     $scope.clearTaskAssignments = function()
     {
        //  //clearTaskAssignments
         $scope.taskAssignments = {
            potentialOwnersUsers:{
                  AssigneeList:[],
                  AssignType:"a"
              },
            potentialOwnersGroups: {
               AssigneeList:[],
               AssignType:"a"
          },
          taskInitiatorsUsers:{
              AssigneeList:[],
                AssignType:"a"
          },
          taskInitiatorsGroups:{
               AssigneeList:[],
               AssignType:"a"
          }
              
          }
   
     }
     
      $scope.clearTaskAssignments();
      
          
      $scope.taskDefinition.AssignTypeAdmin="";
       

        var form_changed = function(data)
        {
            $scope.tds.showHideTaskForm('');
            $timeout(function(){$scope.tds.showHideTaskForm(data);},300)
        };
                
        let taskDefinitionState= $scope.ksm.addSubject('taskDefinitionState'+$scope.taskId,null);
        let taskDefinitionStateName = taskDefinitionState.subject.name;
        
        
        let action_form_changed = $scope.ksm.addAction(taskDefinitionStateName,"formTypeChanged",form_changed);

        //populate dropdown
        CodesService.getCodesByName('_TASK_TYPE_',lang).then(function (data) {
                                if (data.data.length>0)
                                {
                                $scope.taskTypeList = data.data;
                                }else
                                {
                                  $scope.taskTypeList = [{id:1,name:'Single instance'},{id:2,name:'Multiple instance'}];
                                  
                                }
        });
      

         CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.resourceStatusList = data.data;
                             });
         CodesService.getCodesByName('_APPS_',lang).then(function (data) {
                                $scope.resourceTagsList = data.data;
                             });
  
  $scope.setAutoExecute= function(){
    
    $scope.taskDefinition.AutoExecute=!$scope.taskDefinition.AutoExecute;
    if  ($scope.taskDefinition.AutoExecute)
    {
      $scope.taskDefinition.SelfAssigned=true;
      $scope.taskDefinition.InfiniteTask=false;
    }//else
   // {
    //     $scope.task=angular.copy($scope.taskOriginal);
   // }
  };  
  
    $scope.localVars.setAutoInitiate= function(){
    
    
      if ($scope.taskDefinition.AutoInitiate===undefined) $scope.taskDefinition.AutoInitiate=true
      else $scope.taskDefinition.AutoInitiate = !$scope.taskDefinition.AutoInitiate
      $scope.localVars.saveTaskParameters()
        
    };

   
  $scope.getActions= function(){
  
    $scope.showActionsList = true;
    
  };  

  $scope.selectPreviousTaskUser= function(previousTaskUserType){
    
      $scope.taskDefinition.PublicTask=false;
    
      if (previousTaskUserType!="i" && previousTaskUserType!="o")
      {
        
      }
      $scope.taskAssignments.taskInitiatorsUsers.AssignType =previousTaskUserType;
      $scope.taskAssignments.taskInitiatorsGroups.AssignType = previousTaskUserType;
  
  };
  
    $scope.selectPreviousTaskForOwnerUser= function(previousTaskUserType){
    
      $scope.taskAssignments.potentialOwnersUsers.AssignType=previousTaskUserType;
       $scope.taskAssignments.potentialOwnersGroups.AssignType=previousTaskUserType;
       if (previousTaskUserType=="s" )
      {
        $scope.taskDefinition.SelfAssigned=true;
      }else
      {
         $scope.taskDefinition.SelfAssigned=false;
      }
      
      if (previousTaskUserType=="d" || previousTaskUserType=="s")
      {
        $scope.setTaskType('oneinstance');
      }
    
  };
  
  $scope.showHidePreviousTask = function()
  {
      if (!$scope.taskAssignments.showPreviousTask)  $scope.taskAssignments.showPreviousTask=!$scope.taskAssignments.showPreviousTask;
      else if ($scope.taskAssignments.taskInitiatorsUsers.AssignType!='i' && $scope.taskAssignments.taskInitiatorsUsers.AssignType!='o' && $scope.taskAssignments.taskInitiatorsUsers.AssignType!=undefined)
      {
      $scope.taskAssignments.showPreviousTask=!$scope.taskAssignments.showPreviousTask;
      }  
  };
  
  $scope.showHidePreviousTaskForOwner = function()
  {
      if (!$scope.taskAssignments.showPreviousTaskForOwner)  $scope.taskAssignments.showPreviousTaskForOwner=!$scope.taskAssignments.showPreviousTaskForOwner;
      else if ($scope.taskAssignments.potentialOwnersUsers.AssignType!='i' && $scope.taskAssignments.potentialOwnersUsers.AssignType!='o' && $scope.taskAssignments.potentialOwnersUsers.AssignType!=undefined)
      {
      $scope.taskAssignments.showPreviousTaskForOwner=!$scope.taskAssignments.showPreviousTaskForOwner;
      }  
  };
  
  $scope.changeInitAssignTypeUser = function(selection)
  {
      if (selection) {$scope.taskAssignments.taskInitiatorsUsers.AssignType=selection} 
      else     {
        if ($scope.taskAssignments.taskInitiatorsUsers.AssignType=='a')
        {
          $scope.taskAssignments.taskInitiatorsUsers.AssignType='e';
        }else $scope.taskAssignments.taskInitiatorsUsers.AssignType='a';
      } 
  };
  
  
    $scope.changeInitAssignTypeGroup = function(selection)
  {
      if (selection) {$scope.taskAssignments.taskInitiatorsGroups.AssignType=selection }
      else {
        if ($scope.taskAssignments.taskInitiatorsGroups.AssignType=='a')
        {
          $scope.taskAssignments.taskInitiatorsGroups.AssignType='e';
        }else $scope.taskAssignments.taskInitiatorsGroups.AssignType='a';
      }   
  };
  
  
    $scope.changeOwnerAssignTypeUser = function(selection)
  {
      if (selection) {$scope.taskAssignments.potentialOwnersUsers.AssignType=selection } else {
        if ($scope.taskAssignments.potentialOwnersUsers.AssignType=='a')
        {
          $scope.taskAssignments.potentialOwnersUsers.AssignType='e';
        }else $scope.taskAssignments.potentialOwnersUsers.AssignType='a';
      }
  };
  
  
    $scope.changeOwnerAssignTypeGroup = function(selection)
  {
      if (selection) {$scope.taskAssignments.potentialOwnersGroups.AssignType=selection}
      else {
        if ($scope.taskAssignments.potentialOwnersGroups.AssignType=='a')
        {
          $scope.taskAssignments.potentialOwnersGroups.AssignType='e';
        }else $scope.taskAssignments.potentialOwnersGroups.AssignType='a';
      }  
  };
  
  //get codes by name
   $scope.GetCodesByName = function()
    {
                     var lang=$rootScope.globals.currentUser.lang;
                  
                     CodesService.getCodesByName("taskstatuses",lang).then(function(data) {
                             $scope.taskStatuses = data.data;
                            
                                 
                              },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });
       
    };
  $scope.GetCodesByName();
  
  //set task type
    $scope.setTaskType = function(taskTypeName)
    {
        $scope.taskDefinition.TaskTypeObject.Name=taskTypeName;
 
        var pos = $scope.taskTypeList.map(function(e) { return e.value; }).indexOf(taskTypeName);
              
              if (pos>=0){
                $scope.taskDefinition.TaskType = $scope.taskTypeList[pos].id;
                 $scope.taskDefinition.TaskTypeObject.id=$scope.taskTypeList[pos].id;
              }
       
         
    };
  

    
    $scope.localVars.getTaskParameters = function()
    {
        TaskDataService.GetTaskParameters($scope.taskUid).then(
            function(data)   {
                  if (data.length>0) {
                          $scope.processTaskDefinition(data[0])                                           
                  } else  {
                         CodesService.getCodesDetailObjectByName('_TASK_TYPE_','oneinstance',lang,true).then(
                                function(dataCodes){
                                      $scope.taskDefinition.TaskType = dataCodes.id;
                                      $scope.localVars.saveTaskParameters(true);      
                                 });                  
                        }
            }, 
            function(error)   {
                if (error.status===904)  {
                         CodesService.getCodesDetailObjectByName('_TASK_TYPE_','oneinstance',lang,true).then(function(dataCodes){
                          $scope.taskDefinition.TaskType = dataCodes.id;
                          $scope.localVars.saveTaskParameters(true);
                          $scope.getResourceDefinition($scope.taskDefinition.ResourceDefUid);
                          });
                       
                } else 
                       MessagingService.addMessage(error.msg,'error');
            }
        );
                                                        
    };


    $scope.processTaskDefinition=function (taskParameters) 
    {
        $scope.taskDefinition = taskParameters;
        $scope.prettyTask = JSON.stringify($scope.taskDefinition,null,2);  
        $scope.taskParametersId = $scope.taskId;
        if ($scope.taskDefinition.Parameters==null) 
            $scope.taskDefinition.Parameters={AutoLoadExeForm:false,RestartTask:false};

        $scope.taskDefinition.form_definition=true;

        
        $scope.clearTaskAssignments();     
        
        if ($scope.taskDefinition.AssignTypeOwner)
            $scope.taskAssignments.potentialOwnersUsers.AssignType = $scope.taskDefinition.AssignTypeOwner;
        if ($scope.taskDefinition.AssignTypeInitiator)
            $scope.taskAssignments.taskInitiatorsUsers.AssignType = $scope.taskDefinition.AssignTypeInitiator;
        if ($scope.taskDefinition.AssignTypeOwnerGroup) 
            $scope.taskAssignments.potentialOwnersGroups.AssignType = $scope.taskDefinition.AssignTypeOwnerGroup;
        if ($scope.taskDefinition.AssignTypeInitiatorGroup)
                $scope.taskAssignments.taskInitiatorsGroups.AssignType = $scope.taskDefinition.AssignTypeInitiatorGroup;
        
        $scope.getResourceDefinition($scope.taskDefinition.ResourceDefUid);
        $scope.taskDefinition.formParams={formLocked:true,formViewType:1,showGridNavigation:false};        
        $scope.taskDefinition.form_type=$scope.tds.showTaskFormFn()       
        $scope.taskOriginal = angular.copy($scope.taskDefinition);
        $scope.tds.SetSelectedTask($scope.taskDefinition);
        $scope.localVars.getResourceForm();
    }
    
    
    
    $scope.saveTaskDef = function (isValid) {
          
          if (isValid) {
           // ResourcesDataService.saveResourceDefAll($scope.resourceDefinition).then(function(data) {
            ResourcesDataService.SaveResourceDef2 ($scope.resourceDefinition).then(
                function(data) {               
                           if (data.status===200 || data.status===201) {
                                MessagingService.addMessage("Task properties saved successfuly",'success');  
                           } 
                 },
                function(error){
                              MessagingService.addMessage(error.msg,'error');  
                });
            
          } else {  
            MessagingService.addMessage("Please enter required data",'warning'); 
          }
    };         
    $scope.EditTask = function(value)
    {
              $scope.editTask=value;
              if (value)  $scope.getResourceDefinition($scope.taskDefinition.ResourceDefUid);
    };
                
      //get resource def
      $scope.getResourceDefinition = function(resourceDefId){
      
            ResourcesDataService.getResourceDefAll(resourceDefId).then(function(data) {
                               
                             // MessagingService.addMessage(data.msg,'success');
                              $scope.resourceDefinition = data.data;
                              $scope.resourceDefDownloaded = true
                              $scope.resourceDefinition.formParams={formLocked:true,formViewType:1,showGridNavigation:false};
                              $scope.gsrvc.setTaskFormVersion($scope.resourceDefinition.id+$scope.taskDefinition.form_type,{Version:$scope.resourceDefinition.Version,Published:$scope.resourceDefinition.Published,NewVersion:$scope.resourceDefinition.NewVersion});  
                                },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');  
                            });
        };
             
      //save task parameters
    $scope.localVars.saveTaskParameters = function(isInitial){
        
        TaskDataService.SaveTaskParameters($scope.taskDefinition.id,$scope.taskDefinition).then(function(data) {
 
                                MessagingService.addMessage(data.msg,'success');
                                
                                if (isInitial) $scope.processTaskDefinition(data.data) 
                                else $scope.taskDefinition=data.data 
                               
                            },function(error){     
                                  MessagingService.addMessage(error.msg,'error');   
                             });  
    };
         
      
      
    //get task assignments
    $scope.getTaskAssignments = function(){
        if ($scope.taskId!==undefined)
        {
            if (!$scope.taskAssignmentsDownloaded)
            {
                TaskDataService.GetTaskAssignments($scope.taskId).then(function(data) {
                                    
                    $scope.taskAssignmentsOriginal = angular.copy(data.data);
                    $scope.processTaskAssignments(data.data);
                    $scope.taskAssignmentsDownloaded = true;
                    $scope.firstDownloadAssignments = true;
                },function(error){
                                   
                    MessagingService.addMessage(error.msg,'error');
                });
           }else
           {
            $scope.processTaskAssignments($scope.taskAssignmentsOriginal); //MIGOR TODO - vidjeti d ali ovo odkomentirati ili sta
           }
        } else MessagingService.addMessage('Task id (taskId) is undefined','error');
    };
    
    //save task status actions
    $scope.saveTaskStatuses = function(){
      if ($scope.codesActions.codesActionsList[$scope.codesActions.selectedActionIndex] == null) $scope.codesActions.codesActionsList[$scope.codesActions.selectedActionIndex] = {};

        TaskDataService.SaveTaskStatuses($scope.taskId,[$scope.codesActions.codesActionsList[$scope.codesActions.selectedActionIndex]],'taskstatuses').then(function(data) {
                                
            MessagingService.addMessage(data.msg,'success');
                                
        },
        function(error){
                               
            MessagingService.addMessage(error.msg,'error');
        });
    };
    
    //save task assignments
    $scope.saveTaskAssignments = function(assignType){
       
        TaskDataService.SaveTaskAssignments($scope.prepareTaskAssignments($scope.taskAssignments,assignType),$scope.taskId,$scope.taskDefinition.SelfAssigned,$scope.taskDefinition.PublicTask,$scope.taskDefinition.UniqueName,assignType,$scope.taskDefinition.TaskType).then(function(data) {
        
            MessagingService.addMessage(data.msg,'success');
            $scope.showTaskInitiators = false;
            $scope.showTaskOwners = false;
            if (assignType===0) $scope.taskDefinition.TaskInitiatorsSaved = true;
            if (assignType===1) $scope.taskDefinition.PotentialOwnersSaved = true;
            $scope.taskAssignmentsDownloaded = false; 
          
            },
            function(error){
                MessagingService.addMessage(error.msg,'error');
        });
       
    };
    
    $scope.processTaskAssignments = function(taskAssignments){ //MIGOR TODO  - Ova funkcija se pokreće i u BUILD modu !!  Da li treba??
      
     
        //     Initialize taskAssigments if undefined !
        if (taskAssignments.potentialOwnersUsers ===undefined)
            taskAssignments.potentialOwnersUsers = {AssigneeList : []};
     
        if (taskAssignments.potentialOwnersGroups ===undefined)
            taskAssignments.potentialOwnersGroups = {AssigneeList : []};
 
        if (taskAssignments.taskInitiatorsUsers ===undefined)
            taskAssignments.taskInitiatorsUsers = {AssigneeList : []};

        if (taskAssignments.taskInitiatorsGroups ===undefined)
            taskAssignments.taskInitiatorsGroups = {AssigneeList : []};

       
        
     

      
       
       // $scope.clearTaskAssignments();//migor - čini mi se da je ovo pjesnik htio reći umjesto gornjeg
        
        var users = taskAssignments.users;
        var groups = taskAssignments.groups;
             
        if (taskAssignments.taskDefinition!==null && taskAssignments.taskDefinition!==undefined)
        {
          if (taskAssignments.taskDefinition.AssignTypeOwner)    $scope.taskAssignments.potentialOwnersUsers.AssignType = taskAssignments.taskDefinition.AssignTypeOwner;
          if (taskAssignments.taskDefinition.UniqueId)   $scope.taskAssignments.potentialOwnersUsers.UniqueId = taskAssignments.taskDefinition.UniqueId;
          if (taskAssignments.taskDefinition.AssignTypeInitiator)    $scope.taskAssignments.taskInitiatorsUsers.AssignType = taskAssignments.taskDefinition.AssignTypeInitiator;
          if ( taskAssignments.taskDefinition.AssignTypeOwnerGroup)    $scope.taskAssignments.potentialOwnersGroups.AssignType = taskAssignments.taskDefinition.AssignTypeOwnerGroup;
          if ( taskAssignments.taskDefinition.UniqueIdGroup)    $scope.taskAssignments.potentialOwnersGroups.UniqueId = taskAssignments.taskDefinition.UniqueIdGroup;
          if (taskAssignments.taskDefinition.AssignTypeInitiatorGroup)    $scope.taskAssignments.taskInitiatorsGroups.AssignType = taskAssignments.taskDefinition.AssignTypeInitiatorGroup;                            
        }
        
         
        var pos;  
        for (var i=0;i<users.length;i++)  {   
          if (users[i].AssignGroup=='potentialOwners') {
              
               if ($scope.taskAssignments.potentialOwnersUsers.AssigneeList===undefined) 
                   $scope.taskAssignments.potentialOwnersUsers.AssigneeList = [];
               
               pos = $scope.taskAssignments.potentialOwnersUsers.AssigneeList.map(function(e) { return e.id; }).indexOf(users[i].AssigneeId.id);
                  
               if (pos<0)
                      $scope.taskAssignments.potentialOwnersUsers.AssigneeList.push(users[i].AssigneeId);

               $scope.taskAssignments.potentialOwnersUsers.SubAssignType = users[i].AssignType;
              
          } else 
                { // NOT potential owners
              
                 if ($scope.taskAssignments.taskInitiatorsUsers.AssigneeList===undefined) 
                        $scope.taskAssignments.taskInitiatorsUsers.AssigneeList = [];
               
                 pos = $scope.taskAssignments.taskInitiatorsUsers.AssigneeList.map(function(e) { return e.id; }).indexOf(users[i].AssigneeId.id);
                  
                 if (pos<0)
                      $scope.taskAssignments.taskInitiatorsUsers.AssigneeList.push(users[i].AssigneeId);
     
                 $scope.taskAssignments.taskInitiatorsUsers.AssignType = users[i].AssignType;
            
                }
          
        }//for
        
        
        for (var j=0;j<groups.length;j++)   {    
          if (groups[j].AssignGroup=='potentialOwners') {
            
               if ($scope.taskAssignments.potentialOwnersGroups.AssigneeList===undefined) 
                   $scope.taskAssignments.potentialOwnersGroups.AssigneeList = [];
               
               pos = $scope.taskAssignments.potentialOwnersGroups.AssigneeList.map(
                function(e) { return e.id; }).indexOf(groups[j].AssigneeId.id);
                  
               if (pos<0)
                    $scope.taskAssignments.potentialOwnersGroups.AssigneeList.push(groups[j].AssigneeId);

               $scope.taskAssignments.potentialOwnersGroups.AssignType=groups[j].AssignType;
              
           
          } else {
                 if ($scope.taskAssignments.taskInitiatorsGroups.AssigneeList===undefined) 
                     $scope.taskAssignments.taskInitiatorsGroups.AssigneeList = []; 
                 
                  pos = $scope.taskAssignments.taskInitiatorsGroups.AssigneeList.map(function(e) { return e.id; }).indexOf(groups[j].AssigneeId.id);
                  
                  if (pos<0)
                    $scope.taskAssignments.taskInitiatorsGroups.AssigneeList.push(groups[j].AssigneeId);

                 $scope.taskAssignments.taskInitiatorsGroups.AssignType=groups[j].AssignType;
            
          }
        
        }//for
    };
     
    $scope.prepareTaskAssignments = function(taskAssignments,assignType){
      
        var lTaskAssignments = {};
        lTaskAssignments.users = {};
        lTaskAssignments.groups = {};
  
        lTaskAssignments.users.list = [];
        lTaskAssignments.groups.list = [];
        
        lTaskAssignments.users.poAssignType= taskAssignments.potentialOwnersUsers.AssignType;
        lTaskAssignments.users.poUniqueId=taskAssignments.potentialOwnersUsers.UniqueId;
        
        lTaskAssignments.users.tiAssignType=  taskAssignments.taskInitiatorsUsers.AssignType;
        lTaskAssignments.groups.tiAssignType=  taskAssignments.taskInitiatorsGroups.AssignType;
        
        
        
        var potentialOwnersUsers = $scope.taskAssignments.potentialOwnersUsers.AssigneeList;
        var taskInitiatorsUsers =  $scope.taskAssignments.taskInitiatorsUsers.AssigneeList;
        
        var potentialOwnersGroups = $scope.taskAssignments.potentialOwnersGroups.AssigneeList;
        var taskInitiatorsGroups =  $scope.taskAssignments.taskInitiatorsGroups.AssigneeList;
        
        if (potentialOwnersUsers===undefined) potentialOwnersUsers = [];
        if (taskInitiatorsUsers===undefined) taskInitiatorsUsers = [];
        if (potentialOwnersGroups===undefined) potentialOwnersGroups = [];
        if (taskInitiatorsGroups===undefined) taskInitiatorsGroups = [];
        var userItem={};
        var i = 0;
        
        userItem.TaskDefId=$scope.taskId;
        userItem.CreatedBy=$rootScope.globals.currentUser.userid;
        userItem.ChangedBy=$rootScope.globals.currentUser.userid;
        userItem.CreatedDateTime=new Date();
      
        userItem.VersionFrom = 1;
        userItem.VersionTo = 1;
        //userItem.AssignType=potentialOwnersUsers.AssignType;
        
        if (assignType===0)
        {
            for (i=0;i<taskInitiatorsUsers.length;i++) 
           
           {   
             
                userItem.AssigneeId= taskInitiatorsUsers[i].id;
                userItem.AssignGroup = 'taskInitiators';
                userItem.AssignType = $scope.taskAssignments.taskInitiatorsUsers.AssignType;
                lTaskAssignments.users.list.push(angular.copy(userItem));        
             
           }
             for (i=0;i<taskInitiatorsGroups.length;i++) 
           {   
             
               userItem.AssigneeId= taskInitiatorsGroups[i].id;
               userItem.AssignGroup = 'taskInitiators';
               userItem.AssignType = $scope.taskAssignments.taskInitiatorsGroups.AssignType;
               lTaskAssignments.groups.list.push(angular.copy(userItem));
             
           }
        }
        if (assignType===1)
        {
          for (i=0;i<potentialOwnersUsers.length;i++) 
           {   
             
                userItem.AssigneeId = potentialOwnersUsers[i].id;
                userItem.AssignGroup = 'potentialOwners';
                userItem.AssignType = $scope.taskAssignments.potentialOwnersUsers.AssignType;
                
                lTaskAssignments.users.list.push(angular.copy(userItem));
             
           }
           for (i=0;i<potentialOwnersGroups.length;i++) 
           {   
             
                userItem.AssigneeId= potentialOwnersGroups[i].id;
                userItem.AssignGroup = 'potentialOwners';
                userItem.AssignType = $scope.taskAssignments.potentialOwnersGroups.AssignType;
               
                lTaskAssignments.groups.list.push(angular.copy(userItem));
             
           }
        }
      
  
        return lTaskAssignments;
    };
    
    $scope.returnToPreviousPage = function()
    {
                let page = "app.navbar.resources.list";
                //let navbar = MenuService.getPageNavbarByName(page);
                $state.go(page,{});
             
    }

    $scope.showHideProperties = function(value)
    {
                  $scope.gsrvc.setFieldPropertiesOpened(value);
                  $scope.showFieldProperties=$scope.gsrvc.getFieldPropertiesOpened();
       
    };
   
    /*
     $scope.$watch('tds.showTaskOwnersFn()',function(newValue,oldValue){
                    
        if (newValue!=oldValue)
        {
            $scope.getTaskAssignments();
        }
    });
    */
    $scope.$watch('gsrvc.getFieldProperties()',function(newValue,oldValue){
                    
                    if (newValue!=oldValue)
                    {
                     $scope.selectedField=$scope.gsrvc.getFieldProperties();
                     $scope.showFieldProperties = $scope.gsrvc.getFieldPropertiesOpened();
                    }
    });
      
    if ($scope.taskId!=undefined) {
        $scope.tds.showHideTaskForm('e');
    }

      $scope.stateName=$state.current.name;
      $scope.stateParams = $state.current.params;
      
      
       
    }

export function taskDefinitionListCtrl ($scope, $rootScope,$timeout,uiGridConstants,ProcessingService,MessagingService,MenuService,$stateParams,GridsterService,TaskDataService,TaskDefinitionService,WidgetDataExchangeService,$state,$cookies) {
   $scope.CTRLNAME='taskDefinitionListCtrl';
   $scope.tds = TaskDefinitionService;
   $scope.tds.SetScopeForSelectedTask($scope);
   
    $scope.getSelectedTask = function()
    
    {
      $scope.task = TaskDefinitionService.GetSelectedTask();
      
    };
    $scope.$on('TaskDefinitionLoaded',function(event,data){
                  $scope.getSelectedTask();
    });

    
      
}
export function taskInitiateListCtrl ($scope, $rootScope,uiGridConstants,ProcessingService,MessagingService,ResourcesDataService,TaskDataService,$state,$stateParams) {
    
     $scope.is_superuser =  $rootScope.globals.currentUser.is_superuser;
   $scope.CTRLNAME='taskInitiateListCtrl';
    $scope.selectedResourceGroup=$stateParams.resource_group_id;
    
    $scope.activeLink = function(n) {
      return ($state.current.name.indexOf(n)>=0 ? "active" : "");   
    }

    $scope.gridOptions = {
        columnDefs: [
         { name: 'id', displayName: 'ID', aggregationType: uiGridConstants.aggregationTypes.avg,type: 'number', width:'5%',enableHiding: false, visible: true },
         { name: 'resourcedeflang_set[0].Name', displayName: 'Task name'},
         { name: 'resourcedeflang_set[0].Subject', displayName: 'Task subject' },
         { name: 'resourcedeflang_set[0].Description', displayName: 'Task description' },
        // { name: 'resourcedeflang_set[0].Lang.name', displayName: 'Language'},
         //{ name: 'ResourceType.CodesDetailLang[0].Value', displayName: 'Task type' },
         { name: 'Status.CodesDetailLang[0].Value', displayName: 'Status'},
        
         ],
		enableGridMenu: true,
    showGridFooter: true,
		showColumnFooter: true,
    enableRowSelection: true,
		multiSelect: false,
		enableHorizontalScrollbar : 0, 
		enableVerticalScrollbar : 2,
		enableFiltering: true,
		enableRowHeaderSelection: true,
		enableColumnResizing: true,
   // minRowsToShow : 3
   }
   
   $scope.currentStateName=$state.current.name;
   $scope.currentStateParams=$state.params;
   
     $scope.gridOptions.onRegisterApi = function (gridApi) {
        $scope.gridApi = gridApi;
        gridApi.selection.on.rowSelectionChanged($scope,function(row){
         $scope.taskSelected=row.isSelected;
       
         if(row.isSelected){
              $scope.selectedTask=row.entity.id;
             $scope.selectedTaskObject = row.entity;
            }
        });     
     }
 
 
    
    $scope.InititateAllList = function(){
      
      var lang=$rootScope.globals.currentUser.lang;
      
      ResourcesDataService.getResourceDefByType(lang,'task').then(function(data) {
                                
                                  $scope.gridOptions.data = data.data;
                                   
                                 
                               },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });
    }
        
    
    //napuni grid s definicijama resursa
    $scope.InititateAllList();
     $scope.InitiateTask = function (taskDefId){
      
                           $state.go('app.navbar.tasks.proc',{id: taskDefId,fromStateName:$state.current.name,fromStateParams:$state.params,outcome_id:0,prev_task_execute_id:$scope.taskExecuteId,menu_type:"t"});
                
                 }
                 
     $scope.InititateMyList = function(){
      
      var lang=$rootScope.globals.currentUser.lang;
      var user=$rootScope.globals.currentUser.userid;
      
      
                 
      ResourcesDataService.getResourceDefListByLangByTypeUser(lang,'task',user).then(function(data) {
                                
                                  //$scope.gridOptions.data = data.data;
                                  $scope.myInitiateTaskList = data.data.results;
                              },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });
    };   
    
    $scope.InititateMyList();
     
    }
export function taskProcCtrl ($scope, $rootScope,$timeout,uiGridConstants,ProcessingService,MessagingService,MenuService,$stateParams,GridsterService,TaskDataService,TaskProcService,WidgetDataExchangeService,$state,$cookies) {
    
    $scope.gsrvc=GridsterService;
    $scope.fd={};
    $scope.openedTaskInNewPage={localVars:null,taskDefinition:null};
    // $scope.localVars={};
    // $scope.taskDefinition={};
    $scope.gsrvc.setOpenTaskType("current");
   
    $scope.openTaskInNewPage=function(){
        
        $scope.openedTaskInNewPage = $scope.gsrvc.getTaskDataInNewPage();
           
        
    };
    
    //$scope.gsrvc.destroyDragula('bag-resource');
    //$scope.gsrvc.destroyDragula('bag-task');
      
}
export function taskPublicCtrl ($scope, $rootScope,$timeout,uiGridConstants,ProcessingService,MessagingService,MenuService,$stateParams,GridsterService,TaskDataService,TaskProcService,WidgetDataExchangeService,$state,$cookies) {
    
    let org_id = $state.params.org_id;
    let task_name = $state.params.task_name;
    let task_id = $state.params.task_id;
    $rootScope.headTitle ="Katalyo | "+task_name;
    $scope.CTRLNAME='taskPublicCtrl';
    $scope.localVars={};
    $scope.localVars.publicTask=true;
    $scope.taskDefinition={};
    $scope.localVars.publicTaskName = task_name;
    
    $scope.localVars.publicTaskId = task_id;
    $scope.localVars.organisationId = org_id;
      
}

export function taskInitiateCtrl ($scope, $rootScope,$timeout,uiGridConstants,ProcessingService,MessagingService,$stateParams,GridsterService,TaskDataService,WidgetDataExchangeService,$state,$cookies) {
      $scope.CTRLNAME='taskInitiateCtrl';
     $scope.initiateFormLoaded=false;
      $scope.isSaving=false;
      $scope.taskDefId=$stateParams.id;
      $scope.previousTask=$stateParams.prev_task_execute_id;
      $scope.previousTaskOutcomeId=$stateParams.outcome_id;
      $scope.previousTaskPackage=$stateParams.prev_task_package; 
      $scope.gridsterType="task";
      $scope.gridsterOpts = GridsterService.getGridsterOptions();
      $scope.showSearchFilters=false;      
      $scope.dashboard.widgets = [];
      $scope.dashboard.data = {};
      
      $scope.backCloseIcon = "fa fa-close";
      $scope.cancelBackBtnTxt = "Cancel";
      
      $scope.fromStateName=$stateParams.fromStateName;
      $scope.fromStateParams=$stateParams.fromStateParams;
      $scope.cookieFromStateName=$cookies.getObject('initiateTaskFromStateName');
      $scope.cookieFromStateParams=$cookies.getObject('initiateTaskFromStateParams');
      $scope.noBackbutton = false;
      $scope.backBtnFromCookie=false;
      
      
      
      if ($scope.fromStateName==undefined || $scope.fromStateName=="")
      {
          if ($scope.cookieFromStateName==undefined || $scope.cookieFromStateName=="")
          {
            $scope.noBackButton = true;
          }else
          {
            $scope.fromStateName =  $scope.cookieFromStateName;
            $scope.backBtnFromCookie = true;
          }
          
        
      }else{
        $cookies.putObject('initiateTaskFromStateName',$scope.fromStateName);
        
      }
      
      if ($scope.fromStateParams==undefined)
      {
          if ($scope.cookieFromStateParams!=undefined)
          {
              if ($scope.backBtnFromCookie)
              {
                $scope.fromStateParams =  $scope.cookieFromStateParams;
              }
          }
          
      }else if ($scope.cookieFromStateParams==undefined)
      {
        $cookies.putObject('initiateTaskFromStateParams',$scope.fromStateParams);
        
      }else
      {
          if ($scope.backBtnFromCookie)
          {
        
            $scope.fromStateParams =  $scope.cookieFromStateParams;
        
          }else
          {
              $cookies.putObject('initiateTaskFromStateParams',$scope.fromStateParams);    
          }
      }
      
      
      
      
      
       if ($scope.previousTask==undefined || $scope.previousTask=="")
          {
           // $state.go();
            
            $scope.previousTask = 0;
            
          }
      $scope.potentialOwners = {};
      
     // $scope.potentialOwnersUsers=[];
      //$scope.potentialOwnersGroups=[];     
      
    // ILJ 22.12.2018 can't deregister - deregister prev task which is needed   
    //WidgetDataExchangeService.deRegisterAllWidgets();	//Migor - deregistering taskwidgetRegistry 

      $scope.localTaskId=WidgetDataExchangeService.registerTask($scope.taskDefId);
      
     $scope.taskDefinition ={};
      $scope.potentialOwners.potentialOwnersUsers = [];
      $scope.potentialOwners.potentialOwnersGroups = [];
      
         $scope.FormLockUnlock = function()
      {
        $scope.resourceDefinition.formParams.formLocked=!$scope.resourceDefinition.formParams.formLocked;

        /*
        if ($scope.resourceDefinition.formLocked) {
            $scope.dashboard.widgets=GridsterService.ProcessWidgets($scope.dashboard.widgetsOriginal);
        }else
        {
         $scope.dashboard.widgets = $scope.dashboard.widgetsOriginal;
        
         }
        */
      };
      
      $scope.PreInitiateTask = function(){
           var lang=$rootScope.globals.currentUser.lang;
          $scope.taskInitiateId=0;
          $scope.taskExecuteId=0;
          
         
        TaskDataService.preInitiateTask($scope.taskDefId,$scope.previousTask,lang).then(function(data) {
                                
                                   
                                   $scope.taskDefinition = data.data.taskdef[0];
                                   $scope.taskDefinition.prev_task_id = $scope.previousTask;
                                   $scope.taskDefinition.form_type="i";
                                   $scope.taskDefinition.source_type = $stateParams.src_type;
                                   $scope.taskDefinition.taskFormLoaded = false;
                                   if ($scope.taskDefinition.AutoInitiate) {
                                  
                                       $scope.AutoInitiateTask();
                                                                    
                                  }else
                                      {
                                       $scope.dashboard.data= data.data.form_data;
                                       $scope.resourceDefinition=data.data.resourceDefinition;
                                       $scope.dashboard.widgets= data.data.widgets;
                                      
                                      $scope.resourceDefinition.formParams={formLocked:true,formViewType:2};
                                      
                                      $timeout(function(){$scope.initiateFormLoaded = true;});
                                     /* if ($scope.resourceDefinition.formParams) {
                                          $scope.dashboard.widgets=GridsterService.ProcessWidgets($scope.dashboard.widgetsOriginal);
                                      }else
                                      {
                                         $scope.dashboard.widgets = $scope.dashboard.widgetsOriginal;
        
                                      }*/
        
                                       // $state.go('app.navbar.tasks.initiate',{id: $scope.taskDefId,fromStateName:$state.current.name,fromStateParams:$state.params,prev:$scope.taskExecuteId});
                                      }
                                     
     
                              },function(error){
                                
                            
                                  MessagingService.addMessage(error.msg,'error');
                            });
      };
      
      $scope.CancelClicked = function(){
        
        $state.go( $scope.fromStateName, $scope.fromStateParams);
      
      };
      
       $scope.AutoInitiateTask = function(){
        var lang=$rootScope.globals.currentUser.lang;
        //  if (isValid) {
        var parentDataset=$scope.getPreviousTaskWidget();
        
        TaskDataService.initiateTask($scope.taskDefId,$scope.previousTask,$scope.previousTaskOutcomeId,[],$scope.potentialOwners,parentDataset['datasetId'],parentDataset['datasetRecordId'],lang).then(function(data) {
                                
                                    
                                    if (data.data.taskExecuteInstance.length>0)
                                    {
                                    $scope.task_initiate_id = data.data.taskExecuteInstance.TaskInitiateId.id;
                                    $scope.task_execute_id = data.data.taskExecuteInstance.id;  
                                      
                                    }
                                    else if (data.data.taskInitiateInstance.length>0)
                                    {
                                       $scope.task_initiate_id = data.data.taskInitiateInstance.id;
                                       $scope.task_execute_id = 0;  
                                    
                                    }
                                    $state.go('app.navbar.tasks.execute',{id: $scope.taskDefId,task_initiate_id:$scope.task_initiate_id,task_execute_id:$scope.task_execute_id,fromStateName:$scope.fromStateName,fromStateParams:$scope.fromStateParams,prev_task_package:$scope.previousTaskPackage,prev:$scope.taskExecuteId});
                                   
                                  
                              },function(error){
                                    
                                  MessagingService.addMessage(error.msg,'error');
                            });
         // }
      }
      
       $scope.InitiateTask = function(form){
        var f = $scope.forma;
        if (form.$submitted) $scope.setSubmitted(form);
        if (form.$valid) {
            
            //var initiateData={};
            $rootScope.$broadcast('ProcessTaskWidgets',{}); //svi widgeti slusaju ovaj event
            $scope.isSaving = true;
            var lang=$rootScope.globals.currentUser.lang;
            var parentDataset=$scope.getPreviousTaskWidget();
            
            TaskDataService.initiateTask($scope.taskDefId,$scope.previousTask,$scope.previousTaskOutcomeId,$scope.dashboard.data,$scope.potentialOwners,parentDataset['datasetId'],parentDataset['datasetRecordId'],lang).then(function(data) {
                                
								//trebam dobiti od svih widgeta informacije koje mi trebaju npr resuorceID u ret_value - array 
								// widgeti slusaju 'InitiateTaskPostProcess' i onda moraju sa SETDATA broadcasti za sruge widgete npr resourceid 
                             
                             
                                  MessagingService.addMessage(data.data.msg,'success');
                                  $rootScope.$broadcast('InitiateTaskPostProcess',data.data.ret_value);
                                  //ovo treba staviti u event InitiateTaskPostProcessFinished                        
                                  $scope.backCloseIcon = "fa fa-arrow-circle-left";
                                  $scope.cancelBackBtnTxt = "Go back";
                                  $scope.taskInitiated=true;
                                 
     
                              },function(error){
                                 // $scope.isSaving = false;    
                                  MessagingService.addMessage(error.msg,'error');
                            });
                          
                          $scope.isSaving = false;  
						
         // });			
							
          } // if (isValid)
          else
          {
            MessagingService.addMessage("Please enter required data",'warning');
            
          }
      };
         // 
     
     
    $scope.getPreviousTaskWidget  = function()
    {
      var pTaskData = WidgetDataExchangeService.getPrevTaskData($scope.taskDefinition.prev_task_id);
            var datasetId=0,datasetRecordId=0;
            if (pTaskData.DEI!=undefined && pTaskData.DEI!=null)
            {
            for (var i=0;i<pTaskData.DEI.length;i++)
            {
              if (pTaskData.DEI[i].name=="datasetListRecordId")
              {
                if (pTaskData.DEI[i].value!=undefined && pTaskData.DEI[i].value!=null)
                {
                  for (var j=0;j<pTaskData.DEI[i].value.length;j++)
                  {
                   if (pTaskData.DEI[i].value[j].name=="resourceId" && pTaskData.DEI[i].value[j].value!=undefined) datasetId=pTaskData.DEI[i].value[j].value;
                   if (pTaskData.DEI[i].value[j].name=="resourceRecordId" && pTaskData.DEI[i].value[j].value!=undefined) datasetRecordId=pTaskData.DEI[i].value[j].value;
                   
                    
                  }
                     
                    
                }
                  
              }
                
              }
            }
    return {'datasetId':datasetId,'datasetRecordId':datasetRecordId};
    };

     $scope.setSubmitted = function (form) {
        form.$setSubmitted();
        angular.forEach(form, function(item) {
        if(item && item.$$parentForm === form && item.$setSubmitted) {
            $scope.setSubmitted(item);
        }
    });
} 
      //get form   
      $scope.PreInitiateTask();
     
     
      
    }
export function taskExecuteCtrl ($scope, $rootScope,ProcessingService,MessagingService,TaskDataService,$stateParams,GridsterService,$timeout,$state,WidgetDataExchangeService,$cookies) {
     $scope.CTRLNAME='taskExecuteCtrl';
      if ($scope.localVars==undefined) $scope.localVars={};
      $scope.executeTaskDataLoaded=false;
      $scope.task_initiate_id=$stateParams.task_initiate_id;
      $scope.task_execute_id=$stateParams.task_execute_id;
      $scope.forGroup = $stateParams.for_group;
      WidgetDataExchangeService.deRegisterAllWidgets();//MIGOR TODO - dodao ovo ali gdje je registerTASK i da li treba ovdje????
	
      $scope.fromStateName=$stateParams.fromStateName;
      $scope.fromStateParams=$stateParams.fromStateParams;
      $scope.cookieFromStateName=$cookies.getObject('executeTaskFromStateName');
      $scope.cookieFromStateParams=$cookies.getObject('executeTaskFromStateParams');
      $scope.noBackbutton = false;
      $scope.backBtnFromCookie=false;
      
      $scope.dashboard = { widgets : {}, data : {} };
      
      if ($scope.fromStateName==undefined || $scope.fromStateName=="")
      {
          if ($scope.cookieFromStateName==undefined || $scope.cookieFromStateName=="")
          {
            $scope.noBackButton = true;
          }else
          {
            $scope.fromStateName =  $scope.cookieFromStateName;
            $scope.backBtnFromCookie = true;
          }
          
        
      }else{
        $cookies.putObject('executeTaskFromStateName',$scope.fromStateName);
        
      }
      
      if ($scope.fromStateParams==undefined)
      {
          if ($scope.cookieFromStateParams!=undefined)
          {
              if ($scope.backBtnFromCookie)
              {
                $scope.fromStateParams =  $scope.cookieFromStateParams;
              }
          }
          
      }else if ($scope.cookieFromStateParams==undefined)
      {
        $cookies.putObject('executeTaskFromStateParams',$scope.fromStateParams);
        
      }else
      {
          if ($scope.backBtnFromCookie)
          {
        
            $scope.fromStateParams =  $scope.cookieFromStateParams;
        
          }else
          {
              $cookies.putObject('executeTaskFromStateParams',$scope.fromStateParams);    
          }
      }
      
      $scope.gridsterType="task";
      //$scope.gridsterExeFormOpts = GridsterService.getGridsterOptions();
      $scope.formType="e";
     
      $scope.isCollapsedTaskDetails=true;
      
        $scope.FormLockUnlock = function()
      {
        $scope.resourceDefinition.formParams.formLocked=!$scope.resourceDefinition.formParams.formLocked;
      /*
        if ($scope.resourceDefinition.formLocked) {
            $scope.dashboard.widgets=GridsterService.ProcessWidgets($scope.dashboard.widgetsOriginal);
        }else
        {
         $scope.dashboard.widgets = $scope.dashboard.widgetsOriginal;
        
         }
        */
      }
      
      $scope.goBack = function(){
                          $state.go($scope.fromStateName,$scope.fromStateParams);
                   
      }
      $scope.getTaskExecuteData = function(){
            var lang=$rootScope.globals.currentUser.lang;
            if ($scope.task_initiate_id==="") {
              $scope.task_initiate_id = 0;
            }
             if ($scope.task_execute_id==="") {
              $scope.task_execute_id = 0;
            }
            TaskDataService.preExecuteTask($scope.task_initiate_id,$scope.task_execute_id,lang).then(function(data) {
                                   
                                   $scope.resourceDefinition= data.data.resourceDefinition;
                                
                                   $scope.taskDefinition = data.data.taskdef[0];
                                   if ($scope.taskDefinition!=undefined) $scope.taskDefinition.inline_task=false;
                                   $scope.dashboard.widgets.layout = data.data.widgets;
                                   $scope.dashboard.data = data.data.form_data;
                                   $scope.taskInitiateInstance = data.data.taskInitiateInstance;
                        
                                   $scope.taskExecuteInstance = data.data.taskExecuteInstance;
                                   $scope.resourceDefinition.formParams={formLocked:true,formViewType:2};
                                   $scope.thisWidget={resourceDefinition: $scope.resourceDefinition,taskInitiateInstance: $scope.taskInitiateInstance,taskExecuteInstance: $scope.taskExecuteInstance}; 
                                   
                                   if ($scope.taskDefinition==undefined)
                                   {
                                    $scope.taskDefinition={};
                                    
                                   }
                                   if ($scope.taskExecuteInstance!=undefined)
                                   {
                                      if ($scope.taskExecuteInstance.length>0) $scope.taskDefinition.task_execute_id = $scope.taskExecuteInstance[0].id;
                                      else $scope.taskDefinition.task_execute_id = 0;
                                   
                                   }
                                   if ($scope.taskInitiateInstance!=undefined)
                                   {
                                      if ($scope.taskInitiateInstance.length>0) $scope.taskDefinition.task_initiate_id = $scope.taskInitiateInstance[0].id;
                                       else $scope.taskDefinition.task_initiate_id=0;
                                   }
                                   $scope.taskDefinition.form_type="e";
                                  
                                  if ($scope.taskDefinition.TaskOutcomeHeadId_id==undefined || $scope.taskDefinition.TaskOutcomeHeadId_id==null)
                                  {
                                    $scope.taskDefinition.TaskOutcomeHeadId_id = 0;
                                    
                                  }
                                  
                                  TaskDataService.GetTaskOutcomes($scope.taskDefinition.ResourceDefId_id,$scope.taskDefinition.TaskOutcomeHeadId_id,lang).then(function(data) {            
                                    
                                        $scope.localVars.taskOutcomes = data.data;
                                        $timeout(function() {
                                             $scope.localVars.OpenTaskType="newPage";
                                             $scope.executeTaskDataLoaded=true;
                                             $scope.localVars.initiateFormLoaded=true;
                                    
                                        });
                                  
                                    },function(error){
                                
                                        MessagingService.addMessage(error.msg,'error');
                                    });
                                   
                                  
                                   
                                 
                                    
                                  
     
                              },function(error){
                                
                            
                                  MessagingService.addMessage(error.msg,'error');
                            });
          /*   
            TaskDataService.GetTaskExecuteData($scope.taskInstanceId,lang).then(function(data) {
                                   //  $scope.ac.alerts = data.data;
                          
                                    $scope.taskInstance = data.data;
                                   
                             
                             },function(error){
                              
                              MessagingService.addMessage(error.msg,'error');
                              });
                               
            */                  
       };
      $scope.ExecuteTaskAction = function(action){
           
           
              var lang=$rootScope.globals.currentUser.lang;
             
            
             TaskDataService.ExecuteTaskAction($scope.task_initiate_id,$scope.task_execute_id,action,lang,$scope.forGroup).then(function(data) {
       
                                   
                                    $scope.taskInitiateInstance =data.data.data.taskInitiateInstance[0];
                                    $scope.taskExecuteInstance =data.data.data.taskExecuteInstance[0];
                                    
                                    if ($scope.task_execute_id==0 &&  $scope.taskExecuteInstance!=undefined)
                                    {
                                       $state.go('app.navbar.tasks.execute',{id: $scope.taskDefinition.id,task_initiate_id:0,task_execute_id:$scope.taskExecuteInstance.id,fromStateName:$state.current.name,fromStateParams:$state.params,prev:$scope.taskExecuteId});
                                   
                                      
                                    }
                                    
                                    MessagingService.addMessage(data.data.msg,'success');
                                   
     
                              },function(error){
                                
                            
                                  MessagingService.addMessage(error.msg,'error');
                            });
      }
       $scope.getTaskExecuteData();
      
       $scope.$on('ExecuteTask'+$scope.task_execute_id,function(event,data){
              $scope.ExecuteTask(data.form,data.outcomeId);
          });
      
      
       $scope.$on('ExecuteTaskPostProcess',function(event,data){
              $scope.getTaskExecuteData();
          });
      
         $scope.$on('RefreshData',function(event,data){
              $scope.getTaskExecuteData();
          });
      
      $scope.ExecuteTask = function(form,outcomeId){
              
              
              var lang=$rootScope.globals.currentUser.lang;
            
              //$rootScope.$broadcast('ProcessTaskWidgets',{});
             if (form.$submitted) $scope.setSubmitted(form);
              if (form.$valid) {
              
              TaskDataService.executeTask($scope.task_execute_id,$scope.dashboard.data,outcomeId,lang).then(function(data) {
                            
                        MessagingService.addMessage(data.data.msg,'success');
                        $rootScope.$broadcast('ExecuteTaskPostProcess',data.data.ret_value);
                      },function(error){
                        
                        MessagingService.addMessage(error.msg,'error');           
                                  
              });
   				
          } // if (isValid)
          else
          {
            MessagingService.addMessage("Please enter required data",'warning');
            
          }
      };
         // 
     
     $scope.setSubmitted = function (form) {
        form.$setSubmitted();
        angular.forEach(form, function(item) {
        if(item && item.$$parentForm === form && item.$setSubmitted) {
            $scope.setSubmitted(item);
        }
    });
} 
      
      
    }
export function taskExecuteListCtrl ($scope, $rootScope,ProcessingService,MessagingService,TaskDataService,$state,$timeout) {
$scope.CTRLNAME='taskExecuteListCtrl';
    
    $scope.stateName=$state.current.name;
    $scope.stateParams = $state.current.params;
    
     $scope.is_superuser =  $rootScope.globals.currentUser.is_superuser;
   
    $scope.gridOptions = {
    enableGridMenu: true,
    //showGridFooter: true,
		//showColumnFooter: true,
    enableRowSelection: true,
		multiSelect: false,
		enableHorizontalScrollbar : 0, 
		enableVerticalScrollbar : 2,
		//enableFiltering: true,
		enableRowHeaderSelection: true,
		enableColumnResizing: true,
    useExternalPagination: true,
    paginationPageSizes: [5,10, 20, 50,100,200,500,1000],
    enablePaginationControls: false,
    //paginationPageSize: 10,
    //useExternalSorting : true,
    //useExternalFiltering: true,
   // minRowsToShow : 3
   }

  var paginationOptions = {
    pageNumber: 1,
    pageSize: 10,
    sort: null
    };
    
    var columnDefsInitiate = [
         { name: 'TaskInitiationId.id', displayName: 'ID', type: 'number', width:'5%',enableHiding: false, visible: true },
         { name: 'TaskInitiationId.InitiatorId.name', displayName: 'Started by'},
         { name: 'TaskInitiationId.Status.CodesDetailLang[0].Value', displayName: 'Task status' },
         { name: 'TaskInitiationId.Resourcedef.resourcedeflang_set[0].Name', displayName: 'Task name' },
         { name: 'TaskInitiationId.Resourcedef.resourcedeflang_set[0].Description', displayName: 'Task description' },
         { name: 'TaskInitiationId.Resourcedef.id', displayName: 'Task def id',enableHiding: false,visible: false },
         { name: 'TaskInitiationId.CreatedDateTime', displayName: 'Date created',enableHiding: false,type: 'date', cellFilter: 'date:\'dd.MM.yyyy HH:mm:ss:sss\'' },
         { name: 'TaskInitiationId.CreatedBy.name', displayName: 'Created by'},
         { name: 'ForGroup.name', displayName: 'For group'},
         ];
    
   var columnDefsExecute = [
         { name: 'id', displayName: 'Task Execute ID', type: 'number', width:'10%',enableHiding: false, visible: true },
         { name: 'resourcedef.resourcedeflang_set[0].Name', displayName: 'Task name' },
         { name: 'resourcedef.resourcedeflang_set[0].Description', displayName: 'Task description' },
         { name: 'actualOwner.name', displayName: 'Task Owner'},
         { name: 'status.CodesDetailLang[0].Value', displayName: 'Task status' },
         { name: 'TaskOutcomeCodeItemId.Name', displayName: 'Task outcome' },
         { name: 'resourcedef.id', displayName: 'Task def id',enableHiding: false,visible: false },
         { name: 'CreatedDateTime', displayName: 'Date created',enableHiding: false,type: 'date', cellFilter: 'date:\'dd.MM.yyyy HH:mm:ss:sss\'' },
         { name: 'createdBy.name', displayName: 'Created by'},
         ];   
   
   $scope.taskExecuteId = 0;
   $scope.taskInitiateId = 0;
                
   $scope.type = "claim";
    if ($state.current.name =="app.navbar.tasks.claim")
    {
      $scope.gridOptions.columnDefs = columnDefsInitiate;
      
    }
    else
    {
      
      $scope.gridOptions.columnDefs = columnDefsExecute;
      $scope.type = "execute";
    }
    
     $scope.gridOptions.onRegisterApi = function (gridApi) {
        $scope.gridApi = gridApi;
        gridApi.selection.on.rowSelectionChanged($scope,function(row){
         $scope.taskSelected=row.isSelected
       
         if(row.isSelected){
           
              if ($scope.type == "claim")
              {
                $scope.selectedTask=row.entity.TaskInitiationId.id
                 $scope.selectedTaskDefId=row.entity.TaskInitiationId.Resourcedef.id
                 $scope.selectedTaskObject = row.entity.TaskInitiationId;
                 $scope.taskInitiateId = $scope.selectedTask;
              }
              else
              {
                 $scope.selectedTask=row.entity.id
                 $scope.selectedTaskDefId=row.entity.resourcedef.id
                 $scope.selectedTaskObject = row.entity;
                 $scope.taskExecuteId = $scope.selectedTask;
              }
               
          }
        });
        
        //external pagination
      gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
          paginationOptions.pageNumber = newPage;
          paginationOptions.pageSize = pageSize;
          $scope.ExecuteAllListPaginated($scope.type,1,false,"",$scope.nextPage,$scope.previousPage,newPage,pageSize);
      });
      
       gridApi.core.on.filterChanged($scope, function() {
           // Set some scope variable that both lists use for filtering
          // $scope.someFilterValueBothListsLookAt = ......
       });

      
     }
      
      
 
    $scope.ClaimSelected = function(){
              
              
            var lang=$rootScope.globals.currentUser.lang;
            var forGroup = $scope.selectedTaskObject.forgroup[0].ForGroup;
             TaskDataService.ExecuteTaskAction($scope.selectedTask,0,'claim',lang,forGroup).then(function(data) {
       
                                   
                                    
                                    //refresh liste task-ova za claim
                                    $scope.ExecuteAllList($scope.type,1,true,data.data.msg);
                                   
                              },function(error){
                                
                            
                                  MessagingService.addMessage(error.msg,'error');
                            });
      } 
    
    $scope.ExecuteAllList = function(type,filter,afterClaim,msgAfter){
      
      var lang=$rootScope.globals.currentUser.lang;
      
      TaskDataService.getInitiatedTasks(type,filter).then(function(data) {
                                
                                  $scope.gridOptions.data = data.data.results;
                                 if (afterClaim)
                                 {
                                   MessagingService.addMessage(msgAfter,'success');
                                  
                                 }
                               },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });
    }
        $scope.ExecuteAllListPaginated = function(type,filter,afterClaim,msgAfter,urlNext,urlPrev,newPage,pageSize){
      
      var lang=$rootScope.globals.currentUser.lang;
      
      if (Math.ceil($scope.totalCount/pageSize)<newPage)
      {
        newPage = Math.ceil($scope.totalCount/pageSize);
      }
      TaskDataService.getInitiatedTasksPaginated(type,filter,urlNext,urlPrev,newPage,pageSize).then(function(data) {
                                
                                  $scope.gridOptions.data = data.data.results;
                                  $scope.nextPage = data.data.next;
                                  $scope.previousPage = data.data.previous;
                                  $scope.totalCount=data.data.count;
                                  $scope.currentPage=newPage;
                                  $scope.numPages = Math.ceil($scope.totalCount/pageSize);
                                  $scope.pageSize = pageSize;
                                  $scope.gridOptions.totalItems=$scope.totalCount;
                                 if (afterClaim)
                                 {
                                   MessagingService.addMessage(msgAfter,'success');
                                  
                                 }
                               },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });
    }
    
     $scope.pageChanged = function(){
        $scope.ExecuteAllListPaginated($scope.type,1,false,"",$scope.nextPage,$scope.previousPage,$scope.currentPage,$scope.pageSize);
         }        
    //napuni grid s definicijama resursa
     //$scope.currentPage =  paginationOptions.pageNumber;
     //$scope.pageSize = paginationOptions.pageSize;
      
     $scope.ExecuteAllListPaginated($scope.type,1,false,"","","",paginationOptions.pageNumber,paginationOptions.pageSize);
     
     $scope.ExecuteListPrePaginated = function(listFilter){
            $scope.ExecuteAllListPaginated($scope.type,listFilter,false,"","","",paginationOptions.pageNumber,paginationOptions.pageSize);
         }   
    
      
    }

export function taskOutcomeDefinitionCtrl ($scope, $rootScope,uiGridConstants,ProcessingService,MessagingService,$stateParams,TaskDataService,$cookies,$state) {
    
      $scope.CTRLNAME='taskOutcomeDefinitionCtrl';
      
      $scope.fromStateName=$stateParams.fromStateName;
      $scope.fromStateParams=$stateParams.fromStateParams;
      $scope.cookieFromStateName=$cookies.getObject('outcomeDefinitionFromStateName');
      $scope.cookieFromStateParams=$cookies.getObject('outcomeDefinitionFromStateParams');
      $scope.noBackbutton = false;
      $scope.backBtnFromCookie=false;
      
      if ($scope.fromStateName==undefined || $scope.fromStateName=="")
      {
          if ($scope.cookieFromStateName==undefined || $scope.cookieFromStateName=="")
          {
            $scope.noBackButton = true;
          }else
          {
            $scope.fromStateName =  $scope.cookieFromStateName;
            $scope.backBtnFromCookie = true;
          }
          
        
      }else{
        $cookies.putObject('outcomeDefinitionFromStateName',$scope.fromStateName);
        
      }
      
      if ($scope.fromStateParams==undefined)
      {
          if ($scope.cookieFromStateParams!=undefined)
          {
              if ($scope.backBtnFromCookie)
              {
                $scope.fromStateParams =  $scope.cookieFromStateParams;
              }
          }
          
      }else if ($scope.cookieFromStateParams==undefined)
      {
        $cookies.putObject('outcomeDefinitionFromStateParams',$scope.fromStateParams);
        
      }else
      {
          if ($scope.backBtnFromCookie)
          {
        
            $scope.fromStateParams =  $scope.cookieFromStateParams;
        
          }else
          {
              $cookies.putObject('outcomeDefinitionFromStateParams',$scope.fromStateParams);    
          }
      }
    
      $scope.goBack = function(){
                          $state.go($scope.fromStateName,$scope.fromStateParams);
                   
      }
   
    $scope.OutcomeHead = {Active:true,id:null};
    $scope.outcomeDefinitionId = $stateParams.id;
    
    
     $scope.getOutcomeDefinition = function(outcomeDefinitionId,lang){
          var lang=$rootScope.globals.currentUser.lang;
          TaskDataService.GetOutcomeDefinition(outcomeDefinitionId,lang).then(function(data) {
    
               //MessagingService.addMessage(data.msg,'error');
               $scope.OutcomeHead=data.data;
                
          },function(error){
                                
            MessagingService.addMessage(error.msg,'error');
          });
       
      
      
     }
    
        $scope.saveOutcomeDefinition = function(){
          var lang=$rootScope.globals.currentUser.lang;
          $scope.OutcomeHead.Lang = lang;
          //if ($scope.OutcomeHead.id==undefined) $scope.OutcomeHead.id=0;
          TaskDataService.SaveOutcomeDefinition($scope.OutcomeHead).then(function(data) {
    
               MessagingService.addMessage(data.data.msg,'success');
               //$scope.OutcomeHead=data.data;
                
          },function(error){
                                
           MessagingService.addMessage(error.msg,'error');
          });
       
      
      
     }
        
    if ($scope.outcomeDefinitionId!="" && $scope.outcomeDefinitionId!=undefined)
    {
      var lang=$rootScope.globals.currentUser.lang;
      $scope.getOutcomeDefinition($scope.outcomeDefinitionId,lang);
      
    }
      
    
}
