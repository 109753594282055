'use strict';

angular.module('angularjs-dragula',[]);

//import 'ngVue';

import katalyoCtrls from './katalyoCtrls.js';
import indexJson from './index.json'//with {type: 'json'};


import * as katalyoDirectives from '../../../0000_common/0001_directives/angularJS/build/ktdirectives.js';

import * as widgetServices from '../../../0000_common/0002_services/angularJS/build/services.js';

import AuthenticationService,{UserService} from '../../../0000_common/0004_modules/authModule.js';
import {MenuService,ClearService} from '../../../0000_common/0004_modules/menuModule.js';
import CodesService from '../../../0000_common/0004_modules/codesModule.js';
import SettingsService from '../../../0000_common/0004_modules/settingsModule.js';
import MessagingService from '../../../0000_common/0004_modules/messagingModule.js';
import KatalyoStateManager from '../../../0000_common/0004_modules/stateManagerModule.js';
import ProcessingService from '../../../0000_common/0004_modules/processingModule.js';
import ResourcesDataService from '../../../0000_common/0004_modules/resourcesDataModule.js';
import TaskDataService,{PublicTaskDataService,TaskDefinitionService,TaskProcService} from '../../../0000_common/0004_modules/tasksDataModule.js';
import GridsterService from '../../../0000_common/0004_modules/gridsterModule.js';
import RestApiService from '../../../0000_common/0004_modules/apiModule.js';
import WidgetDataExchangeService from '../../../0000_common/0004_modules/widgetDataExchangeModule.js';
import WidgetRegisterService from '../../../0000_common/0004_modules/widgetRegisterModule.js';
import UtilityService from '../../../0000_common/0004_modules/utilityModule.js';
import ProcessingInterceptor from '../../../0000_common/0004_modules/interceptorModule.js';
import {AuthInterceptor} from '../../../0000_common/0004_modules/interceptorModule.js';
import exceptionHandler from '../../../0000_common/0004_modules/errorModule.js';

var kds = angular.module('KatalyoDirectiveServices',[]);

angular.forEach(widgetServices, function(value, key) {
  kds.service(key,value);
 });

var ceh = angular.module('CustomErrorHandler',[]);
ceh.decorator('$exceptionHandler',exceptionHandler);


var appModuleName = 'Katalyo';

var app = angular.module(appModuleName, [
    katalyoCtrls,
    'colorpicker',
    'KatalyoDirectiveServices',
    'CustomErrorHandler',
    'ui.router',
    'ui.grid',
    'ui.bootstrap',
    'ngAnimate',
    'ui.grid.pagination',
    'ui.grid.selection',
    'ui.grid.resizeColumns',
    'ui.grid.autoResize',
    'ui.grid.grouping' ,
    'ui.grid.moveColumns',
    'ui.grid.saveState',
    'ui.grid.exporter',
    'ui.grid.edit',
    'ngCookies',
    'ui.select',
    'ngSanitize',
    'ui.uploader',
    'ngFileUpload',
    'dragularModule',
    angularDragula(angular),
    'vcRecaptcha',
    'trumbowyg-ng',
    'chart.js',
    'smart-table',
    'lrDragNDrop'
])
.service('AuthenticationService',AuthenticationService)
.service('MenuService',MenuService)
.service('ClearService',ClearService)
.service('CodesService',CodesService)
.service('SettingsService',SettingsService)
.service('MessagingService',MessagingService)
.service('KatalyoStateManager',KatalyoStateManager)
.service('ProcessingService',ProcessingService)
.service('ResourcesDataService',ResourcesDataService)
.service('TaskDataService',TaskDataService)
.service('PublicTaskDataService',PublicTaskDataService)
.service('TaskDefinitionService',TaskDefinitionService)
.service('TaskProcService',TaskProcService)
.service('GridsterService',GridsterService)
.service('RestApiService',RestApiService)
.service('UserService',UserService)
.service('WidgetDataExchangeService',WidgetDataExchangeService)
.service('WidgetRegisterService',WidgetRegisterService)
.service('UtilityService',UtilityService)
.config(['$provide','$httpProvider','$locationProvider',function ($provide, $httpProvider,$locationProvider) {
  $httpProvider.interceptors.push(ProcessingInterceptor.ProcessingInterceptorService);
  $httpProvider.interceptors.push(AuthInterceptor.AuthInterceptorService);
  $locationProvider.html5Mode(true);
}]);

angular.forEach(katalyoDirectives, function(value, key) {
  app.directive(key,value);
});

//import vue components - find a generic way
//app.value('kt-time',ktTime);


export default appModuleName;

app.constant('PROCESSING_CONST', {'_START_REQUEST_':'_START_REQUEST_','_END_REQUEST_': '_END_REQUEST_'});

app.constant('CODES_CONST', {'_POPULATE_TYPE_':{11:'Current task initiate form',12:'Current task execute form',21:'Previous task initiate form',22:'Previous task execute form',3:'Fixed resource'},
              '_POPULATE_TYPE_NEW_':{1:'Fixed dataset',2:'Dataset record from this task',3:'Dataset record from previous task'}});

app.filter('getCode', function () {
  return function (input) {
      
      if (input!=null) {
     
          if (input.trim()!="") {
          
          var cleanInput=input.split('\'').join('"');
          var inputObj={};
          
          inputObj = angular.fromJson(cleanInput);
          return inputObj.name;
          }else
          {
            return input;
            
          }
      }else
      {
        
        return input;
        
      }
    };
});

app.filter('getUser',['UserService', function (UserService) {
  var cache = {};
  
  function getUser(input) {
      
     // if (cache['user'+input]) {
       // return cache['user'+input].name;
     // }else
     // {
        if (input!=undefined) {
          
          var user_obj=null;
          UserService.getUserObjectById(input).then(function (data){
            user_obj = data;
            if (user_obj!=null) {             
             return  user_obj.name;              
            }
          }); 
         
         // else
          //{
          //  getUser(input);
         // }
      
        }
      //}
      
    };
    getUser.$stateful = true;
    return getUser;
    
}]);

app.filter('getCodeById',['$rootScope','CodesService', function ($rootScope,CodesService) {
  var cache = {}
  
  function getCodeById (input,entity)
  {
     var lang = $rootScope.globals.currentUser.lang;
      if (cache[lang+entity+input]) {
        //code
        return cache[lang+entity+input];
      }
      cache[lang+entity+input] ='fetching codes...';
        if (entity!=undefined && input!=undefined) {
    
          CodesService.getCodesValue(entity,lang,input,1).then(function (data){
    
            cache[lang+entity+input] = data;
          }); 
                        
        } else{
          
           cache[lang+entity+input] ='';
        }
         
        
    };
    getCodeById.$stateful = true;
    return getCodeById;
    
}]);

app.filter('getCodeByName',['$rootScope','CodesService', function ($rootScope,CodesService) {
   var cache = {}
  function getCodeByName (input,entity) {
    
     var lang = $rootScope.globals.currentUser.lang;
      if (cache[lang+entity+input]) {
        //code
        return cache[lang+entity+input];
      }
      cache[lang+entity+input] ='fetching codes...';
      if (entity!=undefined && input!=undefined) {
    
      
         CodesService.getCodesValueByName(entity,lang,input,2).then(function (data){
    
            cache[lang+entity+input] = data;
         }); 
      }else{
          
           cache[lang+entity+input] ='';
        }
     

         
    };
    getCodeByName.$stateful = true;
    return getCodeByName;
}]);


app.config(['$provide','$stateProvider', '$urlRouterProvider','$locationProvider','$httpProvider','$controllerProvider','$compileProvider','$filterProvider',
            function($provide,$stateProvider, $urlRouterProvider,$locationProvider,$httpProvider,$controllerProvider,$compileProvider,$filterProvider) {
   
      app.register = {
      controller: $controllerProvider.register,
      directive: $compileProvider.directive,
      filter: $filterProvider.register,
      factory: $provide.factory,
      service: $provide.service
    };
    
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';

  
   //$urlRouterProvider.otherwise("app.otherwise"); 
   //$locationProvider.hashPrefix("");
   //.html5Mode(true)
   
  $stateProvider
    .state('app', {
           abstract: true,
           //url:'/builder?app_ref&tab&env',
           url: indexJson.config.app_url,
          template: "<div ui-view></div>",
            data: {
            requireLogin: true // this property will apply to all children of 'app'
            },  
  })
.state('app.menu', {
            url:'/m',
           abstract: true,
            views: {
                'header@': {
                    templateUrl: '/0001_index/angularTemplates/navbar.html',
                   // controller: 'homeNavbarCtrl'
                },
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/layout.html',
                    //controller: 'homeContentCtrl' 
                },
              
              'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'homeCtrl' 
            },
            }
        }) 
.state('app.nomenu', {
            url:'/nm',           
           abstract: true,
            views:
            {
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/layout.html',
                   
                },
               'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               },
            }
})

.state('app.navbar', {
            url:'/n',
           abstract: true,
            views: {
                'header@': {
                    templateUrl: '/0001_index/angularTemplates/navbar.html',
                   // controller: 'homeNavbarCtrl'
                },
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/layout.html',
                    //controller: 'homeContentCtrl' 
                },
              
              'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'homeCtrl' 
            },
            }
        })

.state('app.nomenu.onboarding', {
            url:'/onboarding',
              views: {
              
               'contentR': {
                    templateUrl: '/0001_index/angularTemplates/onboarding.html',
                    controller: 'onboardingCtrl', 
                },
              /*   'menu': {
                    templateUrl: '/0003_resources/angularTemplates/homeMenu.html',
                   controller: 'menuCtrl' 
                },*/   
          
              },
               
            
})
.state('app.navbar.home', {
            url:'/',
              views: {
              
               'contentR': {
                    templateUrl: '/0001_index/angularTemplates/home.html',
                    controller: 'homeCtrl', 
                },
              /*   'menu': {
                    templateUrl: '/0003_resources/angularTemplates/homeMenu.html',
                   controller: 'menuCtrl' 
                },*/   
          
              }
              
            
        })
.state('app.navbar.appsettings', {
            url:'/settings',
              views: {
               'contentR': {
                    templateUrl: '/0001_index/angularTemplates/appsettings.html',
                    controller: 'appSettingsCtrl', 
                },
              /*   'menu': {
                    templateUrl: '/0003_resources/angularTemplates/homeMenu.html',
                   controller: 'menuCtrl' 
                },*/   
              }
              
            
        })
.state('app.navbar.market', {
            url:'/market',
              views: {
              
               'contentR': {
                    templateUrl: '/0001_index/angularTemplates/market.html',
                    controller: 'marketCtrl', 
                },
              /*   'menu': {
                    templateUrl: '/0003_resources/angularTemplates/homeMenu.html',
                   controller: 'menuCtrl' 
                },*/   
          
              }
              
            
        })
.state('app.navbar.home.dashboard', {
            url:'/dashboard',
            views: {
              'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/homeDashboard.html',
                    controller: 'homeDashboardCtrl' 
                
              },
            }
        }) 
.state('app.menu.resources', {
              abstract: true,
              url:'/resources',
              views: {
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/layout.html',
                },
              }             
        })
.state('app.navbar.resources', {
              abstract: true,
              url:'/resources',
              views: {
              /*  'header@': {
                    templateUrl: '/0001_index/angularTemplates/navbar.html',
                   // controller: 'homeNavbarCtrl'
                },*/
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/layout.html',
                    //controller: 'resourceUnifiedCtrl' 
                },
              /*
              'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'loginCtrl' 
            },*/
              }
              
        })
.state('app.navbar.resources.import', {
             url:'/import/:resource_id/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/importData.html',
                    controller: 'importDataCtrl' 
                },
           
              },
                params: {
                resource_id: {
                value: '',
                },
                id: {
                value: '',

                },
               
                menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
 })
 .state('app.navbar.resources.list', {
           url:'',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/resourceList.html',
                    controller: 'resourceListCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
               params: {
                menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                resource_type: {
                value: '',

                },
                resource_name: {
                value: '',

                },
                pageid: {
                value: '',

                },
                }
})
 .state('app.navbar.resources.new', {
           url:'/new',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/newResource.html',
                   controller: 'newResourceCtrl' 
                },
              },
                  params: {
                from_resource: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                resource_type: {
                value: '',

                },
                pageid: {
                value: '',

                },
                }
              
})
 .state('app.navbar.resources.edit', {
           url:'/edit/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/editResource.html',
                   controller: 'editResourceCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/
              },
              params: {
                id: {
                value: '',

                },
                prev_state: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
              }
})
 .state('app.navbar.resources.view', {
           url:'/view/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/viewResource.html',
                   controller: 'viewResourceCtrl' 
                },
               
              },
              params: {
                id: {
                value: '',

                },
                prev_state:{
                 value:'', 
                },
                resource_type:{
                 value:'', 
                }
              }
}) 
.state('app.navbar.resources.form', {
           url:'/form/:id',
              views: {
       
                 'contentR': {
                  templateUrl: '/0003_resources/angularTemplates/resourceForm.html',
                  controller: 'resourceFormCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/toolbox.html',
                    controller: 'toolboxCtrl' 
                },*/
              },
              params: {
                id: {
                value: '',

                },
                menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                subTypeSelected: {
                value: '',
                },
                returnState: {
                value: '',
                },
                
                }
}) 
.state('app.navbar.resources.form-vue', {
           url:'/form-vue/:id',

              views: {
          
                 'contentR': {
                  templateUrl: '/0003_resources/angularTemplates/resourceFormVue.html',
                  controller: 'resourceFormComponent' 
                },
                /* 'menu': {
                    templateUrl: '/0003_resources/angularTemplates/toolboxVue.html',
                    controller: 'toolboxComponent' 
                },*/
              },
              params: {
                id: {
                value: '',

                },
                menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        }) 

.state('app.navbar.resources.mappings', {
           url:'/mappings/:id',
              views: {
                 'contentR': {
                  templateUrl: '/0003_resources/angularTemplates/mappingsForm.html',
                  controller: 'mappingsFormCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/
              },
              params: {
                id: {
                value: '',
                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        })
 .state('app.navbar.resources.data', {
           url:'/rdata/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/resourceData.html',
                    controller: 'resourceDataCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
                params: {
                id: {
                value: '',

                },
                menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        })
 .state('app.navbar.resources.data-record', {
           url:'/data-record/:resource_id/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/resourceDataRecord.html',
                    controller: 'resourceDataRecordCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
                params: {
                resource_id: {
                value: '',

                },
                id: {
                value: '',

                },
                menu_id: {
                value: '',

                },
                }
        })
  .state('app.navbar.resources.dataset-transform', {
           url:'/dataset-transform',

              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/datasetTransform.html',
                    controller: 'datasetTransformCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
               params: {
               
                menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
              
        })
  .state('app.navbar.resources.mapping-def', {
           url:'/mapping-def/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/mappingDefinition.html',
                    controller: 'mappingDefinitionCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
               params: {
               
                id: {
                value: '',

                },
              }
              
        })
.state('app.navbar.resources.application-definition', { 			
             url:'/application-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/applicationDefinition.html',
                    controller: 'applicationDefinitionCtrl' 
                },
          
              },
              params: {
                navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                id: {
                value: '',

                },
              }
                
              
 })
.state('app.navbar.resources.app-menu-definition', { 			
             url:'/app-menu-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/appMenuDefinition.html',
                    controller: 'appMenuDefinitionCtrl' 
                },
          
              },
              params: {
                id: {
                value: '',
                
                },
                fromApplication: {
                value: '',
                
                },
              }
                
              
 })
.state('app.navbar.resources.integration-definition', { 			
             url:'/integration-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/integrationDefinition.html',
                    controller: 'integrationDefinitionCtrl' 
                },
          
              },
              params: {
                navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                id: {
                value: '',

                },
              }
                
              
 })
.state('app.navbar.resources.notification-definition', { 			
             url:'/notification-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/notificationDefinition.html',
                    controller: 'notificationDefinitionCtrl' 
                },
          
              },
              params: {
                navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                id: {
                value: '',

                },
              }
                
              
 })
.state('app.navbar.resources.token-definition', { 			
             url:'/token-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/tokenDefinitionVue.html',
                    controller: 'tokenDefinitionCtrl' 
                },
          
              },
              params: {
               
                id: {
                value: '',

                },
              }
                
              
 })
.state('app.navbar.resources.smart-contract-definition', { 			
             url:'/smart-contract-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/smartContractDefinition.html',
                    controller: 'smartContractDefinitionCtrl' 
                },
          
              },
              params: {
                id: {
                value: '',

                },
              }
                
              
 })
  .state('app.navbar.resources.pages', {
             url:'/pages',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/pages.html',
                    controller: 'pagesCtrl' 
                },
                 /*'menu': {
                   templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
              params: {
                navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
              }
                
              
 })
.state('app.navbar.resources.pages-form', {
             url:'/pages-form/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/pagesForm.html',
                    controller: 'pagesFormCtrl' 
                },
                 /*'menu': {
                   templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
              params: {
              id: {
                value: '',

                },
              prev_state: {
                value: '',

                },
              navbar_id: {
                value: '',

                },
              sidemenu_id: {
                value: '',

                },
              }
                
 })
.state('app.navbar.resources.pages-definition', {
             url:'/pages-definition/:id',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/pagesDefinition.html',
                    controller: 'pagesDefinitionCtrl' 
                },
                
          
              },
              params: {
              id: {
                value: '',

                },
               
               navbar_id: {
                value: '',

                },
               prev_state: {
                value: '',

                },
                }
 })
.state('app.navbar.resources.menus-definition', {
             url:'/menus-definition',
              views: {
                 'contentR': {
                    templateUrl: '/0003_resources/angularTemplates/menusDefinition.html',
                    controller: 'menusDefinitionCtrl' 
                },
                 /*'menu': {
                   templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
              params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
            
 })
 .state('app.navbar.codes', {
    url: '/codes',

    abstract: true,
      
    /*views: {
               
                  'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },
              
     },*/
     
     
  })
.state('app.navbar.codes.list', {
    url: '',

      views: {
                'contentR@app.navbar': {
                    templateUrl: '/0004_settings/angularTemplates/codesData.html',
                    controller: 'codesDataCtrl' 
                },
               
      },
        /*          /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/
      
          params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
   
  })
    .state('app.navbar.codes.details', {
    url: '/details',

        views: {
                 'contentR@app.navbar': {
                    templateUrl: '/0004_settings/angularTemplates/codesDetails.html',
                  controller: 'codesDetailsCtrl' 
                },
               /* 'menu': {
                    templateUrl: '/0004_settings/angularTemplates/codesMenu.html',
                   controller: 'menuCtrl' 
                },*/   
      },
      params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
   
   
  })
    
.state('app.navbar.codes.new', {
    url: '/new',

        views: {
                 'contentR@app.navbar': {
                  templateUrl: '/0004_settings/angularTemplates/codesNew.html',
                  controller: 'codesNewCtrl' 
                },
               /* 'menu': {
                    templateUrl: '/0004_settings/angularTemplates/codesMenu.html',
                   controller: 'menuCtrl' 
                },*/   
      },
      params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
   
   
  })
   
 /*
.state('app.navbar.codes.edit', {
    url: '/edit/:id',

        views: {
                 'contentR@app.navbar': {
                  templateUrl: '/0004_settings/angularTemplates/codesEdit.html',
                  controller: 'codesEditCtrl' 
                },   
              },
       params: {
                id: {
                value: '',

                },
               
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        
   
   
  })
  */


.state('app.navbar.user', {
    url: '/user',

    abstract: true,
      
    /*views: {
               
                  'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },
              
     },*/
     params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
   
   
  })
 .state('app.navbar.user.details', {
    url: '/details',

        views: {
                 'contentR@app.navbar': {
                    templateUrl: '/0004_settings/angularTemplates/userDetails.html',
                  controller: 'userDetailsCtrl' 
                },
               /* 'menu': {
                    templateUrl: '/0004_settings/angularTemplates/codesMenu.html',
                   controller: 'menuCtrl' 
                },*/   
      },
      params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
   
   
  })
.state('app.navbar.groups', {
           url:'/groups',

              views: {
                 'contentR': {
                    templateUrl: '/0004_settings/angularTemplates/groupsDefinition.html',
                    controller: 'groupsCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
              params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        })
.state('app.navbar.users', {
           url:'/users',
              views: {
                 'contentR': {
                    templateUrl: '/0004_settings/angularTemplates/usersDefinition.html',
                    controller: 'usersDefinitionCtrl' 
                },        
              },
              params: {
              id: {
                value: '',
                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        })
.state('app.navbar.userssecurity', {
           url:'/security',
              views: {
                 'contentR': {
                    templateUrl: '/0004_settings/angularTemplates/usersSecurity.html',
                    controller: 'usersSecurityCtrl' 
                },        
              },
              params: {
              id: {
                value: '',
                },
                 menu_id: {
                value: '',
                },
                  navbar_id: {
                value: '',
                },
                sidemenu_id: {
                value: '',
                },
                }
        })
.state('app.menu.tasks', {
    url: '/tasks',
    abstract:true,
        views: {
                 'content@': {
                  templateUrl: '/0001_index/angularTemplates/layout.html',
                  //controller: 'taskListCtrl' 
                },
              /*  'menu': {
                    templateUrl: '/0004_settings/angularTemplates/codesMenu.html',
                   controller: 'menuCtrl' 
                },
                */
      },
      
   
  })
.state('app.navbar.tasks', {
    url: '/tasks',
    abstract:true,
        views: {
                 'content@': {
                  templateUrl: '/0001_index/angularTemplates/layout.html',
                  //controller: 'taskListCtrl' 
                },
              /*  'menu': {
                    templateUrl: '/0004_settings/angularTemplates/codesMenu.html',
                   controller: 'menuCtrl' 
                },
                */
      },
      
   
  })
.state('app.navbar.tasks.list', {
           url:'/',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksList.html',
                    controller: 'taskListCtrl' 
                  },
                /* 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                  },*/
                
                },
                params: {
                  
              menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
           
})
.state('app.navbar.tasks.form', {
           url:'/form/:formType/:id',

              views: {
  
                 'contentR': {
                  templateUrl: '/0005_tasks/angularTemplates/tasksForm.html',
                  controller: 'taskFormCtrl' 
                },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/tasktoolbox.html',
                   controller: 'taskToolboxCtrl' 
                },
              },
              params: {
                id: {
                value: '',

                },
                formType: {
                value: '',

                },
               
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                resource:{value: {}},
                }
        }) 
.state('app.navbar.tasks.new', {
           url:'/new',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksNew.html',
                    controller: 'taskNewCtrl' 
                },
                /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/  
          
          
              },
              params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
})
.state('app.navbar.tasks.definition', {
           url:'/definition/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksDefinition.html',
                    controller: 'taskDefinitionCtrl' 
                },
            /*     'menu': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksDefinitionList.html',
                   controller: 'taskDefinitionListCtrl' 
                },   
          */  
          
              },
                params: {
                id: {
                value: '',

                },
                  tab_id: {
                value: '',

                },
              
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
})
.state('app.navbar.tasks.definition2', {
           url:'/definition2/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksDefinitionDetailed.html',
                    controller: 'taskDefinitionCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
            
          
              },
                params: {
                id: {
                value: '',

                },
                  tab_id: {
                value: '',

                },
              
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
})
.state('app.navbar.tasks.initiate-list', {
           url:'/initiate-list',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksInitiateList.html',
                    controller: 'taskInitiateListCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/
              },
            params: {
                              
                navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
              
              }
               
                
})
.state('app.navbar.tasks.execute-list', {
           url:'/execute-list',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksExecuteList.html',
                    controller: 'taskExecuteListCtrl' 
                  },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                  },
                },  
             params: {
                navbarId: {
                value: '',

                },
              menu_id: {
                value: '',

                },
          
                navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
               
                }
})
.state('app.navbar.tasks.proc', {
           url:'/proc/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksProc.html',
                    controller: 'taskProcCtrl' 
                },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },   
              },
                params: {
                id: {
                value: '',

                },
                fromStateName: {
                value: '',

                },
                fromStateParams: {
                value: '',

                },
                prev_task_execute_id: {
                value: '',

                },
                prev_task_package: {
                value: '',

                },
                outcome_id: {
                value: '',

                },
                presentation_id: {
                value: '',

                },
                 init_type: {
                value: '',

                },
                menu_id: {
                value: '',

                },
                 navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                
                }
})
.state('app.navbar.tasks.initiate', {
           url:'/initiate/:id/:prev_task_execute_id/:outcome_id/:presentation_id',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksInitiate.html',
                    controller: 'taskInitiateCtrl' 
                },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },   
              },
                params: {
                id: {
                value: '',

                },
                fromStateName: {
                value: '',

                },
                fromStateParams: {
                value: '',

                },
                prev_task_execute_id: {
                value: '',

                },
                prev_task_package: {
                value: '',

                },
                outcome_id: {
                value: '',

                },
                presentation_id: {
                value: '',

                },
                
                menu_id: {
                value: '',

                },
                
                }
})
.state('app.navbar.tasks.execute', {
           url:'/execute/:id/:task_initiate_id/:task_execute_id',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksExecute.html',
                    controller: 'taskExecuteCtrl' 
                },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },   
            
          
              },
                params: {
                id: {
                value: '',

                },
                fromStateName: {
                value: '',

                },
                fromStateParams: {
                value: '',

                },
                  task_initiate_id: {
                value: '',

                },
                 task_execute_id: {
                value: '',

                },
                prev_task_package: {
                value: '',

                },
                for_group: {
                value: '',

                },
               
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                
                }
})
.state('app.navbar.tasks.claim', {
           url:'/claim',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksExecuteList.html',
                    controller: 'taskExecuteListCtrl' 
                },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },   
            
          
              },
              params: {
               
               navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
})

.state('app.navbar.tasks.outcome-definition', {
           url:'/outcome-definition/:id',

              views: {
                 'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksOutcomeDefinition.html',
                    controller: 'taskOutcomeDefinitionCtrl' 
                },
                 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },   
            
          
              },
                params: {
                id: {
                value: '',

                },
                fromStateName: {
                value: '',

                },
                fromStateParams: {
                value: '',

                },
             
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                
                }
})
.state('app.navbar.organisation', {
           url:'/organisation',

              views: {
                 'contentR': {
                    templateUrl: '/0004_settings/angularTemplates/organisation.html',
                    controller: 'organisationCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
              params: {
              id: {
                value: '',

                },
                   navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        })
.state('app.navbar.community', {
           url:'/community',

              views: {
                 'contentR': {
                    templateUrl: '/0004_settings/angularTemplates/community.html',
                    controller: 'organisationCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
          
              },
              params: {
              id: {
                value: '',

                },
                   navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
        })

.state('app.navbar.menu-create', {
           url:'/menu-create',
           abstract:true,

              views: {
                 'content@': {
                  templateUrl: '/0001_index/angularTemplates/layout.html',
                  //controller: 'taskListCtrl' 
                },
          
              }
})
.state('app.navbar.menu-create.navbar', {
           url:'/menu-create/navbar',
           

             views: {
                 'contentR': {
                    templateUrl: '/0004_settings/angularTemplates/createNavbar.html',
                    controller: 'organisationCtrl' 
                },
                 /*'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                   controller: 'menuCtrl' 
                },*/   
          
              },
              params: {
              id: {
                value: '',

                },
                 menu_id: {
                value: '',

                },
                  navbar_id: {
                value: '',

                },
                sidemenu_id: {
                value: '',

                },
                }
})
.state('app.navbar.vpage', {
           url:'/vpage/:pageid/:version?rinstance',
           

             views: {
                 'contentR': {
                    templateUrl: '/0006_vpage/angularTemplates/vpage.html',
                    controller: 'vpageCtrl' 
                },
                /* 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },  */ 
          
              },
              params: {
                pageid: {
                    value: '',
                },
                parameters: {
                    value: '',
                },
                version: {
                    value: '0',
                }
                }
})
.state('app.navbar.public-vpage', {
           url:'/pvpage/:orgid/:version/:pageid?rinstance',
           

             views: {
                 'contentR': {
                    templateUrl: '/0006_vpage/angularTemplates/vpage.html',
                    controller: 'vpageCtrl' 
                },
                /* 'menu': {
                    templateUrl: '/0001_index/angularTemplates/menu.html',
                    controller: 'menuCtrl' 
                },  */ 
          
              },
              params: {
                pageid: {
                    value: '',
                },
                version: {
                    value: '',
                },
                orgid: {
                    value: '',
                },
                parameters: {
                    value: '',
                }
              },

              data: {
           
                requireLogin: false // change default value from 'app'
            
              }
})
.state('app.navbar.selectenvironment', {
            url:'/selectenv/:type',
     views: {
                 'contentR': {
                   templateUrl: '/0002_auth/angularTemplates/selectenvironment.html',
                    controller: 'selectenvironmentCtrl' 
                },
           
            },

            params: {
                  type: {
                      value: '',
                  },
            },
    

}) 
.state('app.signup', {
            url:'/signup',

            views: {
          /*  'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
               // controller: 'simpleheaderController'
            },*/
            'content@': {
                templateUrl: '/0001_index/angularTemplates/signup.html',
                controller: 'signupCtrl' 
            },
           /* 'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'loginCtrl' 
            },
           */
        },
         data: {
            requireLogin: false // change default value from 'app'
            
            }
    })
.state('app.register', {
            url:'/register/:signupid',

            views: {
           /* 'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
               // controller: 'simpleheaderController'
            },*/
            'content@': {
                templateUrl: '/0001_index/angularTemplates/register.html',
                controller: 'registerCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'loginCtrl' 
            },
           params: {
                signupid: {
                value: '',

                },
           }

        },
         data: {
            requireLogin: false // change default value from 'app'
            
            }
    }) 
.state('app.unregister', {
            url:'/unregister/:signupid',

            views: {
           /* 'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
               // controller: 'simpleheaderController'
            },*/
            'content@': {
                templateUrl: '/0001_index/angularTemplates/unregister.html',
                controller: 'unregisterCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'loginCtrl' 
            },
           params: {
                signupid: {
                value: '',

                },
           }

        },
         data: {
            requireLogin: false // change default value from 'app'
            
            }
    }) 
.state('app.nomenu.publictasks', {
           url:'/public/:org_id/:task_name',
           views: {  
               'contentR': {
                    templateUrl: '/0005_tasks/angularTemplates/tasksPublicExecute.html',
                    controller: 'taskPublicCtrl',
                },   
              },
           params: {
            
             org_id: {
              value: '',
               
            },
            task_name: {
              value: '',
               
            },
            task_id: {
              value: '',
               
            }            
          },
           data: {
           
              requireLogin: false // change default value from 'app'
            
                }
            
})


.state('app.index', {
 
 // redirectTo: () => "app.navbar.home",
 // data: {
           
 //   requireLogin: true // change default value from 'app'
            
  //}  
   url:'/',
            views: {
            /*'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
            },*/
            'content@': {
                templateUrl: function() {
                       return indexJson.config.homepagetemplate
                    },
                

                controller: 'changePasswordRequestCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
            },
           
        },
       data: {
              requireLogin: false // change default value from 'app'
              }  
})
.state('app.changepassrequest', {
            url:'/changepassrequest',
            views: {
            'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
            },
            'content@': {
                templateUrl: '/0002_auth/angularTemplates/changePasswordRequest.html',
                controller: 'changePasswordRequestCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
            },
           
        },
       data: {
              requireLogin: false // change default value from 'app'
              }  
}) 
.state('app.changepass', {
            url:'/changepass/:digest',
            views: {
                'header@': {
                    templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
                },
                'content@': {
                    templateUrl: '/0002_auth/angularTemplates/changePassword.html',
                    controller: 'changePasswordCtrl' 
                },
                'footer@': {
                    templateUrl: '/0001_index/angularTemplates/footer.html',
                },
                params: {
                    digest: {
                    value: '',
                    },
                } 
            },
               data: {
                      requireLogin: false // change default value from 'app'
                      }  
}) 
.state('app.login', {
            url:'/login',
            views: {
            'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
            },
            'content@': {
                templateUrl: '/0002_auth/angularTemplates/login.html',
                controller: 'loginCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
            },   
        },
        params: {
                    return_url: {
                    value: '',
                    },
                }, 
       data: {
              requireLogin: false // change default value from 'app'
              }  
}) 
.state('app.selectenvironment', {
            url:'/selectenv',
            views: {
            'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
            },
            'content@': {
                templateUrl: '/0002_auth/angularTemplates/selectenvironment.html',
                controller: 'selectenvironmentCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html', 
            },
        },
       data: {
              requireLogin: true // change default value from 'app'  
        }  
}) 
.state('app.logout', {
    url: '/logout',

      views: {
                'header@': {
                    templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
                   // controller: 'simpleheaderController'
                },
                'content@': {
                    templateUrl: '/0002_auth/angularTemplates/logged_out.html',
                    controller: 'logoutCtrl'
                  
                },
      },
   
    data: {
      requireLogin: false
    }
})
.state('app.sso', {
            url:'/sso',

            views: {
            'header@': {
                templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
               // controller: 'simpleheaderController'
            },
            'content@': {
                templateUrl: '/0002_auth/angularTemplates/sso.html',
                controller: 'ssoCtrl' 
            },
            'footer@': {
                templateUrl: '/0001_index/angularTemplates/footer.html',
               // controller: 'loginCtrl' 
            },
            params: {
                    is_ldap: {
                    value: false,
                    },
                } 
        },
       data: {
           
              requireLogin: false // change default value from 'app'
            
              }  
}) 
.state("app.otherwise", {
    url: "*path",
    views: {
                'header@': {
                    templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
                    
                },
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/error_not_found.html',
                    controller: 'errorNotFoundCtrl'
                  
                },
      },
     
    data: {
            requireLogin: false   
            }
}).state("app.error", {
    url: "*path",
    views: {
                'header@': {
                    templateUrl: '/0002_auth/angularTemplates/simpleheader.html',
                    
                },
                'content@': {
                    templateUrl: '/0001_index/angularTemplates/error.html',
                    controller: 'errorCtrl'
                  
                },
      },
	    params: {
                   msg: {
                    value: '',
                    },
                },
     
    data: {
            requireLogin: false   
            }
})  

}]);

app.run(['$rootScope','$state', '$cookies','$location','$timeout','RestApiService','AuthenticationService','MenuService','MessagingService',
    function ($rootScope,$state,$cookies, $location,$timeout,RestApiService,AuthenticationService,MenuService,MessagingService) 
    {  
        if ($rootScope.globals===undefined) $rootScope.globals={}
        var lastFromState, lastToState, requireLogin, env, defaultenv , app_ref
        
        let userid = sessionStorage.getItem('user')
        let cUser = $cookies.getObject('currentUser') || null; 
        if (cUser) $rootScope.globals.currentUser = cUser
        else if (userid) $rootScope.globals.currentUser = JSON.parse(localStorage.getItem(userid))
	      
		
        if ($rootScope.globals.currentUser) {
              if ($rootScope.globals.currentUser.authdata.substring(0,5)==='Token') {
                RestApiService.add_api_classes('token',$cookies.get('csrftoken'),$rootScope.globals.currentUser.authdata);
              } else 
                  RestApiService.add_api_classes('sso',$cookies.get('csrftoken'));    
        }
        else {
              $rootScope.globals = {currentUser:{lang:'en-GB'}};
              RestApiService.add_api_classes('sso',$cookies.get('csrftoken'));
        }
     
	 

										
	 
        $rootScope.$on('$stateChangeStart', function (ev,toState,toParams,fromState,fromParams) // when state transition starts!
		{ 
      if (!$rootScope.globals.currentUser)
      {
        let userid = sessionStorage.getItem('user')
        let cUser = $cookies.getObject('currentUser') || null; 
        if (cUser) $rootScope.globals.currentUser = cUser
        else if (userid) $rootScope.globals.currentUser = JSON.parse(localStorage.getItem(userid))
      }
			$rootScope.globals.toState = toState
			requireLogin = toState.data?.requireLogin ?? true;  
			//debugger
		
			// SYNCHRONIZE ENV between globals and params
			if ($location.search().env ) 	
				$rootScope.globals.currentEnv = $location.search().env	//override curentENV with URL env	
			if (toParams['env'] ) //set currentENV if we have toParams['env']
				$rootScope.globals.currentEnv = toParams['env'] 
			else {
				toParams['env'] = $rootScope.globals.currentEnv 
			}
			
			if ($rootScope.globals?.currentEnv && $rootScope.globals?.currentUser?.uep?.defaultenv && $rootScope.globals?.environments) {
				//check  if user does not belong to current env!
				//debugger
				if ($rootScope.globals?.environments?.[$rootScope.globals.currentEnv] == undefined ) { //reset env to default
					toParams['env'] = $rootScope.globals?.currentUser?.uep?.defaultenv 
					$rootScope.globals.currentEnv = $rootScope.globals?.currentUser?.uep?.defaultenv 
					$location.search({'env': $rootScope.globals.currentUser.uep.defaultenv })
				}
			}
			
			//exit when in excluded state 
			const excludedStates = ["app.nomenu.onboarding", "app.error", "app.signup", "app.register", "app.index", "app.login", "app.sso", "app.logout", "app.otherwise", "app.nomenu.publictasks", "app.changepass", "app.changepassrequest"]
			if (excludedStates.includes(toState.name)) return

					
			const today = new Date();
			const expiresValue = new Date(today);
			expiresValue.setMinutes(today.getMinutes() + 10);

			$cookies.putObject('lastToState', toState, {'expires': expiresValue});
			$cookies.putObject('lastToParams', toParams, {'expires': expiresValue});
      
      sessionStorage.setItem('lastToState',JSON.stringify(toState))
      sessionStorage.setItem('lastToParams',JSON.stringify(toParams))
			
      if ($rootScope.globals.currentUser?.username) { //if user is logged
				lastFromState = fromState;
				lastToState = toState;
				//debugger

				if ($location.search().app_ref ) 
						app_ref = $location.search().app_ref 
					
			    if ( MenuService.navbarItems.length==0 )	 MenuService.loadDefaultMenu( !!app_ref )

				$rootScope.globals.InterceptorIsActive = true;
				
				 if ( $rootScope.globals?.currentUser?.uep?.id == undefined) { // if we have user ID but no extended
						ev.preventDefault(); 
                        AuthenticationService.getUserInfo($rootScope.globals.currentUser?.organisation.id)
						   .then( (response) => {  
								  AuthenticationService.SetCredentials(response.data.user) 
								  if (response.data.environments)   
									  $rootScope.globals.environments = response.data.environments.reduce((acc, obj) =>  {
                                          acc[obj.id.toString()] = obj;
                                          return acc;
                                    }, {});  
								  $state.go(toState.name, toParams);
                           }).catch(error =>{
								$state.go("app.error", {msg: 'Server error: ' + error.msg  })								
						   }).finally(() => {
								 //$state.go(toState.name, toParams);// ovdje je možda već kasno
						   });
						//$state.go(toState.name, toParams); //TODO - staviti ovdje umjesto gore ????
						return 
				} 
                      
                  if (!$rootScope.globals.envinfo) { // when ENV changed get from envinfo statistics from server - TODO  - correct to get proper env from server
                        ev.preventDefault();
						AuthenticationService.getEnvInfo($rootScope.globals.currentUser?.organisation.id)
						   .then( (response) => { 
									$rootScope.globals.envinfo = response.data	
								
							  }).catch(error =>{
								alert(error.message)	  
						   }).finally(() => {
								 $state.go(toState.name, toParams);
						   });
						//$state.go(toState.name, toParams); //TODO - staviti ovdje umjesto gore????
						return 
                  }
				  
                  if (! $rootScope.globals?.environments) { // when ENV changed get from envinfo statistics from server - TODO  move this in loadAPP ??     
                        ev.preventDefault();
						AuthenticationService.getOrgSchemas($rootScope.globals.currentUser?.organisation.id)
							.then((response) => {
                              if (response.data)  $rootScope.globals.environments = response.data.reduce((acc, obj) =>  {
                                          acc[obj.id.toString()] = obj;
                                          return acc;
                                    }, {});    
							}).catch(error =>{
								alert(error.message)	  
							}).finally(() => {
								 $state.go(toState.name, toParams);
							});	
						//$state.go(toState.name, toParams); //TODO - staviti ovdje umjesto gore ????
						return						
					}
				
				// **********************
				// TODO - mozda bi trebalo napraviti da ovdje dođe samo kad su završile sve gornje asinkrone radnje ??  ($state.go iz finally prebaciti prije 'return')
				// ***********************
				
				if (app_ref && !MenuService.isAppSelected() ) { // if app is selected, but menu is not ??
					toParams = toParams ?? {};
					toParams['app_ref'] = app_ref;

					AuthenticationService.loadApp(toState.name, toParams, app_ref)
						.then((data) => {
							let lapp = data?.subscriptions
							//AuthenticationService.SetCredentials(data?.user) // migor commented
							AuthenticationService.SetUep(data?.user?.uep)	
							if (!lapp) 	throw new Error('Error loading application id =' + app_ref);

							return AuthenticationService.SetMenusForApp(lapp)
								.then((menuLoaded) => {
									return AuthenticationService.loadFirstPage(toState.name, toParams, lapp, menuLoaded);
								});
						})
						/*.catch((error) => {
							MessagingService.addMessage(error.msg || error.message, 'error');
							toParams['app_ref'] = undefined
							//$state.go(toState.name, toParams);
								//$state.go("app.error", {msg: 'Server error: ' + error.msg  }) //maybe this instead of upper ?
						});*/
				} else {
					MenuService.setSelectedAppMenuWithParams(toState.name, toParams);
					//$state.go(toState.name, toParams);
				}		
				
			} else { // USER does not exist or is empty!
				if (!requireLogin) {
					// login not required for PUBLIC pages
					lastFromState = fromState;
					lastToState = toState;
					$rootScope.globals.InterceptorIsActive = true;
					MenuService.setSelectedAppMenuWithParams(toState.name, toParams);
					$state.go(toState.name, toParams); //TODO - vidjeti da li ovo uopce treba ili ce otici sam po sebi na taj state
				} else {
					// login is required - go to login page
					ev.preventDefault();
					toParams = toParams ?? {};
					let params = angular.copy(toParams);
					params['return_url_name'] = toState.name;
					toParams['return_url'] = params;
					$state.go('app.login', toParams);
				}
			}
       
         });//onStateChange//
        
    }]);

