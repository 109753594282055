//**************************************************************************************************
//*************************************** WidgetRegisterService FACTORY ****************************
//**************************************************************************************************

'use strict';

//example of emitter
class EventEmitter {
  constructor() {
    this.events = {};
  }
  on(event, listener) {
      if (typeof this.events[event] !== 'object') {
          this.events[event] = [];
      }
      this.events[event].push(listener);
      return () => this.removeListener(event, listener);
  }
  removeListener(event, listener) {
    if (typeof this.events[event] === 'object') {
        const idx = this.events[event].indexOf(listener);
        if (idx > -1) {
          this.events[event].splice(idx, 1);
        }
    }
  }
  emit(event, ...args) {
    if (typeof this.events[event] === 'object') {
      this.events[event].forEach(listener => listener.apply(this, args));
    }
  }
  once(event, listener) {
    const remove = this.on(event, (...args) => {
        remove();
        listener.apply(this, args);
    });
  }
};

class WidgetRegisterService{
	
  	constructor($rootScope,GridsterService,$injector) {
               

            this.$rootScope=$rootScope;
            this.GridsterService = GridsterService;
            this.$injector = $injector;   
            this.toolboxLoaded = false;
            this.taskToolboxLoaded=false;
            GridsterService.SetTaskToolboxLoaded(false);    
           
        
    }
        
    static $inject = ['$rootScope','GridsterService','$injector'];//,'MenuService']; //add ClearService 'ClearService',

  
  
  
 
  ListenToEvents()
  {
	var instance = this;
	this.$rootScope.$on('RegisterDatasetWidgets',function(event, data){
               
                 if (!instance.toolboxLoaded) instance.RegisterDatasetWidgets();
               
                 instance.toolboxLoaded=true;
    });
			  
    this.$rootScope.$on('RegisterTaskWidgets',function(event, data){
  
                    if (!instance.taskToolboxLoaded)    instance.RegisterTaskWidgets();
                    instance.taskToolboxLoaded = true;
                    instance.GridsterService.SetTaskToolboxLoaded(true);    
                    
    });
  }
  EmitRegisterWidgetEvent ()
  {
    var f;
    var instance = this;
    if (!this.toolboxLoaded && !this.taskToolboxLoaded)
    { 
      angular.module('KatalyoDirectiveServices')['_invokeQueue'].forEach(function(value){ 
        f =  instance.$injector.get(value[2][0]);
      });
    }  

  this.$rootScope.$emit('RegisterDatasetWidgets',null);
  };

  EmitRegisterTaskWidgetEvent ()
  {
    var f;
    var instance = this;
     if (!this.toolboxLoaded && !this.taskToolboxLoaded)
    { 
      angular.module('KatalyoDirectiveServices')['_invokeQueue'].forEach(function(value){ 
      f =  instance.$injector.get(value[2][0]);
      });
    }
    this.$rootScope.$emit('RegisterTaskWidgets',null);
  };
  
  RegisterDatasetWidgets ()
  
  {
    var toolboxItem;
  
    var toolboxTemplate =this.GridsterService.getToolboxTemplate();
    
    for (var i=0;i<toolboxTemplate.length;i++)
    {
      toolboxItem = toolboxTemplate[i];
      this.GridsterService.RegisterDatasetWidget(toolboxItem);
    }  
  };
 
 
  RegisterTaskWidgets ()
  
  {
    var taskToolboxItem;
    var taskToolboxTemplate=this.GridsterService.getTaskToolboxTemplate();
  
    for (var i=0;i<taskToolboxTemplate.length;i++)
    {
      taskToolboxItem = taskToolboxTemplate[i];
      this.GridsterService.RegisterTaskWidget(taskToolboxItem);
    }
  
    
  };
};

export default WidgetRegisterService;