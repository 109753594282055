'use strict';



//import userDefinitionJson from './usersDefinition.json' assert {type: 'json'};
//import dict from './dict.json' assert {type: 'json'};
import indexJson from './../../0001_index/angularJs/index.json'//with {type: 'json'};


export default function codesDataCtrl ($scope, $stateParams,$state,$rootScope,uiGridConstants,ProcessingService,MessagingService,CodesService,GridsterService,MenuService,ResourcesDataService, $q, $timeout,KatalyoStateManager) {
    
    $scope.CTRLNAME='codesDataCtrl';
    $scope.isCollapsed  = false;
    $scope.pageState={} //MIGOR - samo da proradi GETRESOUCEDEF
    $scope.paginationOptions={}//MIGOR - samo da proradi GETRESOUCEDEF
    $scope.showTableActions=true;// Needed by SmartTable
    $scope.actionDef= [{id:1,btnLabel:'Edit code',btnClass:'btn-outline-primary',icon:'fa fa-edit'}] 
    $scope.smParams = {UuidRef:1}
    $scope.codeId=0;
    $scope.searchInitiated = true
    var lang=$rootScope.globals.currentUser.lang;
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()     
    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()
    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
    
	  $scope.codeTypeList = [{id:1,name:'System'},{id:2,name:'User'}]

    $scope.columnDefs = [// Needed by SmartTable
         {name: 'id', displayName: 'ID', type: 'number'},
         {name: 'CodeName', displayName: 'Code name', type: 'string'},
         {name: 'CodeHeadName', displayName: 'Code unique identifier',  type: 'string'},
         {name: 'Description', displayName: 'Code description',  type: 'string'},
         {name: 'Active', displayName: 'Active', type: 'bool'},
         {name: 'CodeType.name', displayName: 'Code Type',  type: 'number'},
       //  {name: 'Version', displayName: 'Version', type: 'string'},
        // {name: 'Lang', displayName: 'Language',  type: 'string'}
        ]
 
    $scope.ResourceSearch = function(page){
            $scope.searchInitiated=false;
            $scope.paginationOptions.totalItemCount=0;
            if (page!=undefined) $scope.currentPage=page; 
            $timeout(function(){
                $scope.searchInitiated=true;
            });
     };
     
       $scope.gotoMessageDef = function(){
               $state.go("app.navbar.codes.new",{id:$scope.resourceId,returnState:{Label:'Return to notification definition',state:$state.current,params:{id:$scope.resourceId}}});
        }
                        
                        
     $scope.processTableAction = function(row,actionId)
         {   // Needed by SmartTable - on EDIT ROW selection
            
            $scope.codeId = row.id
            $scope.codeSelected=true;
            $scope.selectedCode= row.id
            $scope.CodeHead = {}
            $scope.codesList = []
            $scope.codesDetails = []
             
            $scope.codeSelected=row.isSelected      
            $scope.selectedCode = row.id;
                  
            $scope.selectedCodeLang = row.Lang;     
            $scope.CodeHead = {CodeName: row.CodeName,CodesHeadId: row.id,Description:row.Description,Lang:row.Lang};
            
            $scope.getCodesDetails($scope.selectedCode, $scope.CodeHead.CodeName)
                  
            CodesService.CodesGetById($scope.selectedCode,lang)
                        .then(function(data) {          
                                    $scope.CodeHead = data.data;
                         },function(error){    
                                    MessagingService.addMessage(error.msg,'error');
                    });      
        };
    

       
     $scope.getCodesFiltered = function(pageNumber,pageSize,filter, lang) {// Needed by SmartTable
          let deferred = $q.defer();
         
         // if ($scope.data1 == undefined) 
        //  {
                CodesService.CodesGet(lang).then(function(data) {
                                   
                                   if (filter?.Name?.trim().length>0)  //filter by Search query
                                       $scope.data1  = data.data.filter(item =>  item?.CodeName?.toLowerCase().includes(filter.Name?.toLowerCase()))
                                   else
                                       $scope.data1 = data.data;
                                   
                                   $scope.totalCount = $scope.data1.length
                                   $scope.paginationOptions.totalItemCount  =$scope.data1.length
                                   let startAt = (pageNumber - 1) *  pageSize 
                                   let newData =  $scope.data1.slice(startAt , Math.min( pageNumber * pageSize,  $scope.totalCount) )
                                  //debugger
                                   deferred.resolve( {status: 200, data:newData, totalCount: $scope.totalCount});
                              },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                            })
         // } else deferred.resolve( $scope.data1);

        return deferred.promise;       
         
     }
    
     
    $scope.getResourceDef = function (pageNumber,pageSize) {// Called by SmartTable
       
        let deferred = $q.defer();    
        $scope.resourceLoading=true;
        var filter=$scope.resource;
        //debugger //getResourceDef
        
        if (pageNumber==undefined) pageNumber = $scope.paginationOptions.pageNumber;
        if (pageSize==undefined) pageSize = $scope.paginationOptions.pageSize;
        if ($scope.totalCount>0){
            if (Math.ceil($scope.totalCount/pageSize) < pageSize) {
              let newPage = Math.ceil($scope.totalCount/pageSize);
            }
         }
         $scope.pageState.resource = $scope.resource;
         
         if ($scope.firstQuery) {
          $scope.pageState.currentPage = pageNumber;
          $scope.pageState.pageSize = pageSize;           
         } else  {
                if ($scope.pageState.rememberSearch) {
                        if ($scope.currentPage == 1)  {
                                $scope.pageState.currentPage = $scope.currentPage;
                        }
                        pageNumber = $scope.pageState.currentPage;
                        pageSize = $scope.pageState.pageSize;
                }
         }
        
         // $scope.ksm.executeAction(resourceListStateName,"resourceListSearch",$scope.pageState);
        
            $scope.getCodesFiltered (pageNumber,pageSize,filter,lang).then( 
                function(response) {
                     
                   //debugger
                    
                    $scope.firstQuery=true;
                    $scope.resourceTableData = response.data;
                    let newData = {}
                    newData.results = response.data;
                    $scope.paginationOptions.totalItemCount = response.totalCount
                    
                    
                    //$scope.nextPage = data.data.next;
                    //$scope.previousPage = data.data.previous;
                 
                    $scope.currentPage=pageNumber;
                    $scope.paginationOptions.numberOfPages = Math.ceil($scope.paginationOptions.totalItemCount/pageSize);
                     
                    newData.pageSize = pageSize;
                    newData.numberOfPages = $scope.paginationOptions.numberOfPages;
                    newData.columns = $scope.columnDefs;
                    newData.count = response.totalCount
                    deferred.resolve({data: newData });
                   
                    $scope.resourceLoading=false;
                }
                , function (error) {
                 deferred.resolve( {data: newData });
                }
            ) 
                                 
                            
     return deferred.promise;
    }; // ---getResourceDEf ---
      
   
    CodesService.getCodesByName('languages',lang)
		.then( (data) =>  {
            //debugger
            $scope.codeLanguageList = data.data; });
		
/*		
    CodesService.getCodesByName('_CODE_TYPE_LIST_',lang)
		.then( (data) =>{ $scope.codeTypeList = data.data; });
     CodesService.getCodesByName('codestatus',lang)
		.then( (data) => {  $scope.codeStatusList = data.data; });
                              */
           
     
    
    $scope.setDirectiveFn = function(directiveFn) { // directiveFn == getCodesDetails()
        //debugger //setDirectiveFn 
        $scope.directiveFn = directiveFn;
    };

     $scope.isCollapsedEditItem = false;
     
     
    $scope.editCodeItem = function(){
        
        //populate from grid
        $scope.codeItemHeader={Text: 'Edit code item',Type:'Edit'};
         if ($scope.CodesDetailLang.Lang==undefined) {
          //code
           $scope.CodesDetailLang.Lang=$scope.CodeHeadLang.Lang;
        }
         $scope.CodesDetailLang.CreatedBy=null;
         $scope.CodesDetailLang.ChangedBy=null;
        
        $scope.isCollapsedEditItem = !$scope.isCollapsedEditItem;
            
    }
    
  $scope.CodesDetail = {codesdetaillang_set: []};
  $scope.CodesDetailLang ={CodesDetailId:{}}; 
  
  
      $scope.newCodeItem = function(){
        
            $scope.codeItemHeader={Text: 'New code item',Type:'New'};
            //populate from grid
            $scope.isCollapsedEditItem = !$scope.isCollapsedEditItem;
            $scope.CodesDetailLang.id=undefined;
            $scope.CodesDetailLang.Lang=$scope.CodeHeadLang.Lang;
            $scope.CodesDetailLang.CodesDetailId.CodesHeadId=$scope.CodeHeadLang.CodesHeadId;
        }
     
    $scope.editCodeLang = function(){
        //populate from grid
        $scope.isCollapsedEditLang = !$scope.isCollapsedEditLang 
    }
     
    $scope.saveCodeLang = function(id) {
        //debugger
            $scope.isCollapsedEditLang = !$scope.isCollapsedEditLang
       
            CodesService.CodesPost($scope.CodeHead).then(function(data) {    
                         $scope.data1 = data.data;
                          //$scope.gridOptions1.data = $scope.data1;
                       
                    },function(error){
                      
                        MessagingService.addMessage(error.msg,'error');
                  });
      
    }
     
    $scope.getListOfCodes = function(){

       var lang=$rootScope.globals.currentUser.lang;
       CodesService.CodesGet(lang).then(function(data) {
                                
                                   $scope.data1 = data.data;
                                  //  $scope.gridOptions1.data = $scope.data1;
                                 
                              },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });    
    }
    
 
    $scope.getListOfCodes();
    
    
     
      $scope.saveCodeItem = function (isValid) {
           //debugger
           if (isValid) {
              //var codesStatus = CODES_LIST._CODE_STATUS_LIST_;
            $scope.CodesDetailLang.CodesDetailId.Status = 'Active';
        
                CodesService.addUpdateCodesDetails($scope.codeItemHeader.Type,$scope.CodesDetailLang).then(function(data) {
                                
                                  MessagingService.addMessage(data.msg,'success');
                                 
                              },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });
          }
        };
      
    }
    
    
    
export function codesDetailsCtrl ($scope, $rootScope,uiGridConstants,GridsterService,MenuService,CodesService,KatalyoStateManager) {
    
       $scope.CTRLNAME='codesDetailsCtrl';
        //Get data from server
        var colDefs = [
         { name: 'id', displayName: 'ID', aggregationType: uiGridConstants.aggregationTypes.max,type: 'number', width:'5%',enableHiding: false, visible: true },
         { name: 'CodeName', displayName: 'Code name'},
         { name: 'Description', displayName: 'Code description' },
         { name: 'Status', displayName: 'Status'},
         { name: 'Lang', displayName: 'Language',cellFilter:"getCodeByName: 'languages'"},
         ];
         
     $scope.paginationOptions = {
        pageNumber: 1,
        pageSize: 10,
        sort: null
    };
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()   


    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()

    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
    

    $scope.gridOptions = {
        columnDefs: colDefs,
      enableGridMenu: true,
        showGridFooter: true,
    showColumnFooter: true,
        enableRowSelection: true,
       multiSelect: false,
       enableHorizontalScrollbar : 0, 
      enableVerticalScrollbar : 2,
       enableFiltering: true,
       enableRowHeaderSelection: true,
       enableColumnResizing: true,
     /* 
        paginationPageSizes: [5, 10, 20, 50, 100],
         paginationPageSize: 5,
       enableSelectAll: false,
       
      
       
       enableCellEdit: false*/
      
   }
   

    $scope.gridOptions.onRegisterApi = function (gridApi) {
        //set gridApi on scope
        $scope.gridApi = gridApi;
        gridApi.selection.on.rowSelectionChanged($scope,function(row){
            //debugger
            $scope.codeSelected=row.isSelected
            });
    
     }
    
    $scope.getListOfCodes = function(){
      
      var lang=$rootScope.globals.currentUser.lang;
      CodesService.CodesGet(lang)
            .then(function(data) { 
                     $scope.data = data.data;
                     $scope.gridOptions.data = $scope.data;
          },function(error){
                    MessagingService.addMessage(error.msg,'error');
              });
       
    }
    
    getListOfCodes();
  
    
     
    }


export function codesNewCtrl ($scope, $rootScope,uiGridConstants,ProcessingService,MessagingService,GridsterService,MenuService,CodesService,KatalyoStateManager) {
    
    $scope.CodeHead = { Active: true, CodesHeadLang:[]};
    $scope.CTRLNAME='codesNewCtrl';
    $scope.activity.activityMessage = ProcessingService.setActivityMsg('Processing request');

    
    var lang=$rootScope.globals.currentUser.lang;
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()   
    

    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()

    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
        
    $scope.codeTypeList = [{id:1,name:'System'},{id:2,name:'User'}]
    $scope.codeTypeListDownloaded=true;
 
    $scope.saveNewCode = function (isValid) {

         //debugger
          if (isValid) {
    
            $scope.CodeHead.Version=1;
              
               CodesService.CodesPost($scope.CodeHead).then(function(data) {         
                                   MessagingService.addMessage(data.data.msg,'success');
                                   $scope.CodeHead.id=data.data.data;    
                              },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });                             
            
          }
        };
        
       
 }


export function codesEditCtrl ($scope, $rootScope,uiGridConstants,ProcessingService,MessagingService,GridsterService,MenuService,CodesService,$stateParams,KatalyoStateManager) {
    
     $scope.CTRLNAME='codesEditCtrl';
    
    var lang=$rootScope.globals.currentUser.lang;

    $scope.activity.activityMessage = ProcessingService.setActivityMsg('Processing request');
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()    

    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()

    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
    
    $scope.codeTypeList = [{id:1,name:'System'},{id:2,name:'User'}]
    $scope.codeTypeListDownloaded=true;

    CodesService.getCodesByName('languages',lang).then(function (data) {
                                $scope.codeLanguageList = data.data;
                             });
    /*
    CodesService.getCodesByName('_CODE_TYPE_LIST_',lang).then(function (data) {
                                $scope.codeTypeList = data.data;
                             });
     
    CodesService.getCodesByName('codestatus',lang).then(function (data) {
                                $scope.codeStatusList = data.data;
                             });
    */
    $scope.codeId = $stateParams.id;
    
     $scope.getCode = function (codeid) {
      
       
       CodesService.CodesGetById($scope.codeId,lang).then(function(data) {
                                
                    $scope.CodeHead = data.data[0];
         },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });                             
           
     }
     
     $scope.getCode($scope.codeId);
     
      $scope.clear = function(event, select)
      
      {
        select.selected = null;
        
      }
    $scope.saveCode = function (isValid) {

         //debugger

          if (isValid) {
            $scope.activity.activityMessage = ProcessingService.setActivityMsg('Saving code '+$scope.CodeHead.CodesHeadLang[0].CodeName);
              
               CodesService.CodesPost($scope.CodeHead).then(function(data) {
                                
                                   MessagingService.addMessage(data.data,'success');
                                   
                },function(error){
                                
                                  MessagingService.addMessage(error.msg,'error');
                            });                             
           
            
          }
        };
        
       
    }
    


export function userDetailsCtrl ($scope, $rootScope, $location, $timeout,AuthenticationService,ProcessingService,MessagingService,GridsterService,MenuService,CodesService,KatalyoStateManager) {
    
    $scope.CTRLNAME='userDetailsCtrl';
    var lang=$rootScope.globals.currentUser.lang;    
	var langCode = $rootScope.globals?.currentUser?.langObj?.Code ?? 'en-GB'	
    $scope.strongPassRegex ="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&<>;:,_/'\"\$\\s\*\(\)\=\?\+\^\.\-]).{8,}$";

    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()   


    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()

    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
    

    $scope.pageLabels = indexJson[langCode]
	
	//debugger

    $scope.validate_pass2 = function (form) {
        
        if (form.pass1.$valid && $scope.pass1!=$scope.pass2) form.pass2.$setValidity('pass2',false);
        else if (form.pass1.$valid && $scope.pass1==$scope.pass2) form.pass2.$setValidity('pass2',true);
        else form.pass2.$setValidity('pass2',false);
    };
      
    $scope.user = $rootScope.globals.currentUser;

    $scope.change_password = function() {
        
        AuthenticationService.ChangePasswordWithAuth($scope.currentPass,$scope.pass1).then(function(response) {
            
           
                if (response.success)
                {
                    MessagingService.addMessage(response.data.msg,'success');
                    AuthenticationService.UpdateAuthToken(response.data.token);
                }else
                {
                     MessagingService.addMessage(response.message,'error');
                }
            },function(error){
                MessagingService.addMessage(error.message,'error');

            })

    };
       
      
}

export function usersDefinitionCtrl ($scope, $rootScope, $timeout,SettingsService, UserService, AuthenticationService, ResourcesDataService, MessagingService,GridsterService,MenuService,CodesService,KatalyoStateManager) {
    
    $scope.CTRLNAME='usersDefinitionCtrl';
	$scope.localVars = {}
    var lang=$rootScope.globals.currentUser.lang; 
	var langCode = $rootScope.globals?.currentUser?.langObj?.Code ?? 'en-GB'
	if ($rootScope.globals?.environments) 
			$rootScope.globals.environments.length=0 //must reset this for watcher
	
    $scope.inviteUserObj={}
	
	$scope.selectedUserCopy={}
    
	$scope.pageLabels = indexJson[langCode]
	
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()   


    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()

    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    
    $scope.ksm = KatalyoStateManager 
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
	$scope.datasetFieldsList={}
	
			
	$scope.getOrgSchemas = function()
	{
		let caching=true
		if ($rootScope?.globals?.environments) 
					return $rootScope.globals.environments

	

		if ($scope?.environments == undefined)
			
		       AuthenticationService.getOrgSchemas($rootScope.globals.currentUser?.organisation.id, caching).then((response) => {
					//debugger
					if (response.data) 
							$rootScope.globals.environments = response.data.reduce((acc, obj) => {
										  acc[obj.id.toString()] = obj;
										  return acc;
							}, {});			  		 	  
			  })
	}
	
	$scope.onSelectResourceCallback = function(dataset, model){
		//debugger
		let item=dataset.selectedItem
		item['selectedField']={}
		item['resourceId']=item['id']
		//delete item['id']
		item['UserIdField']=""
		let pos = $scope.resourceList.map(function(e) { return e.id }).indexOf(item.resourceId);
		dataset.selectedItem= $scope.resourceList[pos]
		dataset.selectedField = {}
					
		ResourcesDataService.getResourceFields (item.resourceId ,lang  ).then( (resp)=> { 
					resp.data = resp.data.filter (  (item) => item.generictype=="user")
				  $scope.datasetFieldsList[item.resourceId] = resp.data					
		})							
	}
	


    $scope.getOrganisationInfo = function () {
        SettingsService.getOrganisationInfo($scope.organisationId).then(function(data) {                             
            $scope.organisation = data.data;           
        },function(error){                      
            MessagingService.addMessage(error.data.msg,'error');
        });
    
    };
	
		 
		$scope.saveAdvancedUserParams = async function() 
		{
			//debugger
			let resp = await  SettingsService.getOrganisationInfo($rootScope.globals.currentUser?.organisation.id)
		    let org = resp.data; 
			if (org.Parameters==undefined) org.Parameters={}
			//let org = JSON.parse(JSON.stringify($rootScope.globals.currentUser?.organisation)) //CLONE
			let cleanList = []
			$scope.advancedUserPropList.forEach( (item) => {
				if (item?.selectedField?.id  &&  item?.selectedItem?.id && !item?.isDeleted) {
					let UserIdField = "Field" + item.selectedField.id  + "_" + org.id
					cleanList.push( {'UserIdField': UserIdField, 'resourceId':item.selectedItem.id })
				}					
			})
			
			if (cleanList.length==0) {
				MessagingService.addMessage('No data to save!','info');
				return
			}
			
			
			org.Parameters.AdvancedUserPropertiesDef = cleanList //Alternative way. 2.param in fun.call can be obsolete

		    SettingsService.saveOrganisationInfo (org).then(
				function(data) {               
				    MessagingService.addMessage('Saved!','success');                     
				},function(error){    
				    MessagingService.addMessage(error.message,'error');
		   });
		}
	
	
	$scope.removeDataset = function(item, index) {
		item.isDeleted=true	
		
	}
	
	$scope.addDataset = function(){
		
		if (!$scope.advancedUserPropList) $scope.advancedUserPropList=[]
		$scope.advancedUserPropList.push( {
			selectedField: {},
			selectedItem: {}
		})
	}
	
	$scope.getOrgSchemas()
	
	$scope.$watch(function() { //waiting for Menu controller to fill-in globals
		  return $rootScope.globals.environments}, 
		  async function( newValue, oldValue) {
			  //debugger
				if (newValue && !$scope.advancedUserPropList  )  {
						//if  (newValue && oldValue && Object.keys(newValue).length == Object.keys(oldValue).length ) return //if no changes in environments
						$scope.resourceList = await $scope.getResourcesList()
						
						//only if newData exists and advancedUserPropList is empty
						$scope.advancedUserPropList = $rootScope.globals.environments[$rootScope.globals.currentEnv]?.Parameters?.AdvancedUserPropertiesDef

						if ($scope.advancedUserPropList) 
								$scope.advancedUserPropList.forEach ( (item) => 
								{
									let pos = $scope.resourceList.map(function(e) { return e.id }).indexOf(item.resourceId);
									item.selectedItem = $scope.resourceList[pos]
									
									ResourcesDataService.getResourceFields (item.resourceId ,lang  )
									.then( (resp)=> {
												  //debugger
												  resp.data = resp.data.filter (  (item) => item.generictype=="user")
												  $scope.datasetFieldsList[item.resourceId] = resp.data
												  let id = Number(item.UserIdField.substring(5).split("_")[0])
												  let pos = resp.data.map(function(e) { return e.id }).indexOf(id);
												  if (pos>=0) item.selectedField = resp.data[pos]
										})
								
								})
				}
	});

	
	$scope.prepareSelected = function (){
		   //TODO
	}

    CodesService.getCodesByName('languages',lang).then(function (data) {
                                $scope.codeLanguageList = data.data;
                                
    });
        
		
	// POPUNI LISTU DEFINICIJA OD RESURSA (RESOURCES LIST)
    $scope.getResourcesList = async function()  
	{
       if ($scope.resourceList==undefined) $scope.resourceList=[];
       return ResourcesDataService.getResourceDefListByType(lang,'dataset').then(
            function(data) {               
                   return  data.data;                             
            },function(error){    
               MessagingService.addMessage(error.msg,'error');
       });
    }
	
	

    $scope.getGroups = function ()
	{
          UserService.getUsersGroups('groups').then(function(data){
          $scope.groupList = data.data;
          $scope.groupsDownloaded = true;
				});

    };
		
        $scope.getGroups();
		
        $scope.editUser = function (user,index) {  
            $scope.selectedUser = user  
			$scope.selectedUserCopy[index] = angular.copy(user); 
            $scope.newUser=false;
            $scope.showUserDetails=true;
            $scope.selectedIndex=index;
        };
		
		
       
        $scope.showUserDetailsFn = function (show) {  
           //debugger
			if (show==false && $scope.selectedIndex >= 0) {
				$scope.users[$scope.selectedIndex] = $scope.selectedUserCopy[$scope.selectedIndex] 
			}
		
			$scope.showUserDetails=show;
            $scope.newUser = null;
            $scope.selectedUser = null;
            $scope.actionType=null
			$scope.selectedIndex = null
        };

        
       $scope.getUsersExtended = function () {
          UserService.getUsersExtended().then(function(data){
          $scope.users = data.data;
          $scope.usersLoaded=true
				});

        };
				//$scope.getUsers();
        $scope.getUsersExtended();
      
       $scope.newUserFunc = function (form) {
          $scope.newUser=true;
          $scope.actionType=1
          $scope.showUserDetails=true;
          $scope.selectedUser = {'UserId':null,'Lang':null};
          if (form!=undefined)
          {
            form.$setPristine();
            if (form.username!=undefined) form.username.$setUntouched();
            //form.password.$setUntouched();
            if (form.first_name!=undefined) form.first_name.$setUntouched();
            if (form.last_name!=undefined) form.last_name.$setUntouched();
            if (form.email!=undefined) form.email.$setUntouched();
            if (form.lang!=undefined) form.lang.$setUntouched();
          }
       };

    $scope.inviteUserFunc = function (form) {
          $scope.inviteUser=true;
          $scope.actionType=0
          
          
          if (form!=undefined)
          {
            form.$setPristine();
            if (form.email!=undefined) form.email.$setUntouched();
          }
       };
        $scope.sendInvitation = function (form) {
          form.$setSubmitted()
          if (form.email.$valid && form.groups.$valid)
          {
            UserService.JoinInvitaton($scope.inviteUserObj).then(function(data){
                MessagingService.addMessage(data.msg,'success');
            },
            function(error){
                    MessagingService.addMessage(error.msg,'error');

            });
        }else MessagingService.addMessage('Please enter valid e-mail!','warning');
       };
       
        $scope.ResetPassword = function (user_id) {
          UserService.ResetPassword(user_id).then(function(data){
                $scope.selectedUser.resetPasswordRef = data.data.digest;
                MessagingService.addMessage(data.data.msg,'success');
            },
            function(error){
                    MessagingService.addMessage(error.data.msg,'error');

            });

        };

          $scope.CopyResetPasswordLink = function () {
         
            
            let pass_reset_link = window.location.origin+"/changepass/"+$scope.selectedUser.resetPasswordRef

             $scope.copyPassResetLink = true;

                    navigator.clipboard.writeText(pass_reset_link).then( 
                        () => {
                            $timeout(function(){$scope.copyPassResetLink = false;},1000);
                        },
                        () => {    /* clipboard write failed */
                            MessagingService.addMessage('Copy to clipboard failed','error');
                            $timeout(function(){$scope.copyPassResetLink = false;},1000);
                    });
                                    

        };

      $scope.saveUser = function (isValid) {     
           if (isValid)   
                UserService.SaveUser($scope.selectedUser).then(
                    function(data) {      
                           MessagingService.addMessage(data.data.msg,'success');
                           $scope.selectedUser=data.data.user;
                           
                           if ($scope.newUser) {    
                                $scope.users.push($scope.selectedUser);
                                $scope.newUser=false;
                           } else
                                $scope.users[$scope.selectedIndex] = $scope.selectedUser;
                                 
                      },function(error){
                          MessagingService.addMessage(error.data.msg,'error');
                    });                
               
      };
       
      
    }

export function groupsCtrl ($scope, $rootScope, $timeout,UserService,MessagingService,GridsterService,MenuService,CodesService,KatalyoStateManager) {
    
     var lang=$rootScope.globals.currentUser.lang; 
     $scope.CTRLNAME='groupsCtrl';
   	   
    
	//debugger
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()       


    $scope.menu_service = MenuService    
    $scope.menu_service.clearSideMenu()

    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
    

    /*
    CodesService.getCodesByName('languages',lang).then(function (data) {
                                $scope.codeLanguageList = data.data;
                                
    });
      */  
       $scope.newGroup = {GroupId:null};
        $scope.editGroup = function (group,index) {  
          //$scope.selectedGroup = angular.copy(group);
		  $scope.selectedGroup = group
          $scope.isShowNewGroup=false;
		$scope.selectedIndex=index;
        		};

         $scope.deselectGroup = function () {  
            $scope.newGroup = {GroupId:null}
            $scope.selectedGroup = null
            $scope.isShowNewGroup=false

		};
        
        $scope.showNewGroup = function (form) {
            
            if (form!=undefined)
            {
                form.$setPristine();
                if (form.groupName!=undefined) form.groupName.$setUntouched();
            }
           $scope.isShowNewGroup=true;
       }
        
       $scope.getUsers = function () {
          UserService.getUsersGroups('users').then(function(data){
          $scope.userList = data.data;
				});
        };
         $scope.groupsLoaded=false
       $scope.getGroupsExtended = function () {

          UserService.getGroupsExtended().then(function(data){
          $scope.groups = data.data;
           $scope.groupsLoaded=true


				},
                function(error){
                    let msg
                    if (error.msg || error.data?.msg){

                    if (error.msg) msg = error.msg
                    if (error.data?.msg) msg = error.data.msg
                    }else msg = error

                    MessagingService.addMessage(error.data.msg,'error');
                });

        };
        

       
       
        //Execute this
        $scope.getUsers();
        $scope.getGroupsExtended(); 

    
    
      $scope.saveGroup = function (selectedGroup,isValid) {
          
           if (isValid) {
            
             UserService.SaveGroup(selectedGroup).then(function(data) {
                                
                                   
                                   MessagingService.addMessage(data.data.msg,'success');
                                   $scope.selectedGroup=data.data.group;
                                   if (selectedGroup.id == null)
                                   {
                                    selectedGroup.GroupId.id=$scope.selectedGroup.id
                                    selectedGroup.id=$scope.selectedGroup?.eid
                                    $scope.groups.push(selectedGroup);
                                   }else{
                                     $scope.groups[$scope.selectedIndex] = selectedGroup
                                   }
                                   
                                   $scope.deselectGroup();
                                 
                              },function(error){
                                
                                  MessagingService.addMessage(error.data.msg,'error');
                            });                
             
            } 
        
        
      };
       
      
    }



export function usersSecurityCtrl ($scope, $rootScope, $timeout,UserService,MessagingService,GridsterService,CodesService,KatalyoStateManager,ResourcesDataService) 
{
    
    $scope.CTRLNAME='usersSecurityCtrl';
    var lang=$rootScope.globals.currentUser.lang; 
	var langCode = $rootScope.globals?.currentUser?.langObj?.Code ?? 'en-GB'	
                 
    //$scope.label =dict[lang]
	$scope.label = indexJson[langCode]
	$scope.userFilter=''
	$scope.groupFilter=''
	$scope.groupsecurityDict={}
	$scope.groupsecurityList=[]
	$scope.usersecurityDict={}
	$scope.usersecurityList=[]

    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()  


    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    
    $scope.ksm = KatalyoStateManager 
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});

        
    $scope.getGroups =  async function () 
	{
		 $scope.groupsDownloaded = false;
         let data = await UserService.getUsersGroups('groups')
		$scope.groupList = data.data
		$scope.groupName = {}
		 $scope.groupList.forEach( item => {
				  $scope.groupName[item.id] = item.name
		})

		//$scope.getGroupsSecurity()
    };
	
	 $scope.getUsers=  async function () 
{
		 $scope.usersDownloaded = false;
         let data = await UserService.getUsersGroups('users')
		$scope.userList = data.data;
		$scope.userName = {}
		 data.data.forEach( item => {
				  $scope.userName[item.id] = item.name
			  })
	
		// $scope.getUsersSecurity()

    };
				 
	
	
	 $scope.saveUsersGroupsSecurity= function (record,index) {  

		  UserService.saveGroupsSecurity(record).then(function(data){
			
		  })
     };
		
			
			
		$scope.changedFilter = function() {

				$scope.listReady=false
				$scope.groupsecurityList.length=0
				for (const [key, item] of Object.entries($scope.groupsecurityDict)) {
					  if (item && item.resourcedef_name.includes($scope.userFilter)) $scope.groupsecurityList.push(item)
				  }
		
				$timeout( function(){$scope.listReady=true} )

		}
		
			
	$scope.getGroupsSecurityDict = async function () {
          let data = await UserService.getGroupsSecurity()

		  //first create dictionary with resources  having each "access" list of group objects
		  data.data.forEach( item => {
			  if ( $scope.groupName?.[item.group_id]) 
					item['group_name'] = $scope.groupName?.[item.group_id]
			  if  ( $scope.groupsecurityDict[item.resourcedefuid] == undefined) //first time
					$scope.groupsecurityDict[item.resourcedefuid] = {
							resourcedefuid: item.resourcedefuid,
							version: item.version, 
							resourcedef_name: item.resourcedef_name, 
							groups: [{id: item.group_id, name: item.group_name}] ,
							users: []
							}
			  else 
				  $scope.groupsecurityDict[item.resourcedefuid].groups.push({id: item.group_id, name: item.group_name})
		  })
		  
		  
		  //$scope.changedFilter()
		  //$scope.groupsDownloaded = true;	    
	
    };
	
	
		$scope.getUsersSecurityDict = async function () {
          let data = await UserService.getUsersSecurity()

		  //first create dictionary with resources  having each "access" list of group objects
		  data.data.forEach( item => {
			  if ( $scope.userName?.[item.user_id]) 
					item['user_name'] = $scope.userName?.[item.user_id]
			  if  ( $scope.groupsecurityDict[item.resourcedefuid] == undefined)  //only relevant data is kept
				 
					$scope.groupsecurityDict[item.resourcedefuid] = {
							resourcedefuid: item.resourcedefuid,
							version: item.version, 
							resourcedef_name: item.resourcedef_name, 
							users: [{id: item.user_id, name: item.user_name}] ,
							groups: []
							}
			  else 
				  $scope.groupsecurityDict[item.resourcedefuid].users.push({id: item.user_id, name: item.user_name})
		  })
		 
		  //$scope.changedFilter()
		 // $scope.usersDownloaded = true;	    
    };
	
	// Promise chaining
	$scope.getGroups().then( (data) => 
	$scope.getUsers()).then( (data) =>
	$scope.getUsersSecurityDict()).then( (data) =>
	$scope.getGroupsSecurityDict()).then ((data) => {
		//debugger
		 //convert dict to list  
		  for (const item in $scope.groupsecurityDict) {
			  $scope.groupsecurityList.push($scope.groupsecurityDict[item])
		  }
		  $scope.changedFilter()
		  $scope.groupsDownloaded = true;	
	
	})
       
      
}

export function organisationCtrl ($scope, $rootScope, $location, $timeout,SettingsService,CodesService,GridsterService,MessagingService,KatalyoStateManager) {
    
  
    $scope.CTRLNAME='organisationCtrl';
    $scope.organisationId = $rootScope.globals.currentUser.organisation.id;
    var lang = $rootScope.globals.currentUser.langObj.id;
    //get all codes
      
    //status
   /* CodesService.getCodesByName('codestatus',lang).then(function (data) {
        $scope.statusList = data.data;
    });
     */ 
    $scope.gsrvc = GridsterService
    $scope.gsrvc.resetBodyBgColor()
    $scope.showTab = 1;
    
    let menuVisibility= $scope.ksm.addSubject("menuVisibility",null);
    let menuVisibilityName = menuVisibility.subject.name;
    $scope.ksm = KatalyoStateManager     
    $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", {state:{sideMenu:false}});
    
    $scope.getOrganisationInfo = function () {
        
        SettingsService.getOrganisationInfo($scope.organisationId).then(function(data) {
                                
            $scope.organisation = data.data;

            $scope.getCredits('org')
             //country
           /* CodesService.getCodesByName('countries',lang).then(function (data) {
                $scope.countryList = data.data;
                $scope.countryList.unshift({id:null});
            });
      
            //industry
            CodesService.getCodesByName('industry',lang).then(function (data) {
                $scope.industryList = data.data;
                $scope.industryList.unshift({id:null});
            });
            */                     
        },function(error){
                                
            MessagingService.addMessage(error.data.msg,'error');
        });
    
    };

    $scope.getCredits = function (balanceType) {
        
        SettingsService.getCredits(balanceType).then(function(data) {
                                
            $scope.balances = data.data?.balances;
            
            $scope.trans = data.data?.trans;
                                              
        },function(error){
                                
            MessagingService.addMessage(error.data.msg,'error');
        });
    
    };
    $scope.formatNumber = function(number)
    {
        return new Intl.NumberFormat().format(number)
    }
    $scope.GenerateApiKeys = function () {
        let api_id;
        if ($scope.organisation.ApiUser == null) api_id = $scope.organisation.ApiUser;
        else api_id = $scope.organisation.ApiUser.username;
        SettingsService.GenerateApiKeys(api_id).then(function(data) {
                                
            $scope.api_id = data.data.api_id;
            $scope.api_key = data.data.api_key;
            
            MessagingService.addMessage(data.data.msg,'success');
            
                                  
        },function(error){
                                
            MessagingService.addMessage(error.msg,'error');
        });
    
    };

     $scope.getOrganisationInfo();
     
    $scope.saveOrganisation = function (isValid) {
          
        if (isValid) {
             
      
            SettingsService.saveOrganisationInfo($scope.organisation).then(function(data) {
                                
                MessagingService.addMessage(data?.msg,'success');
               
            },function(error){
                                
                MessagingService.addMessage(error?.msg,'error');
            });
        }

    }
      
};