//**************************************************************************************************
//*************************************** CodesService - ES6 module ****************************
//**************************************************************************************************

'use strict';

class CodesService{
   
    
   constructor ($q,$rootScope,RestApiService) {
     
      this.listOfCodesById={};
      this.listOfCodesByName={};
      this.listOfPromises={};
      this.codesList = [];
      this.gettingCodeList = false;
      this.$q=$q;
      this.$rootScope =$rootScope; 
       this.RestApiService = RestApiService;
    }

   static $inject = ['$q','$rootScope','RestApiService'];

   //**********************************************************
	//           getCodesDetailObjectByName
	//**********************************************************      
 
  getCodesDetailObjectByName (code_head_name,code_name_id,lang,byValue) {
  
    var deferred = this.$q.defer();                    
    if (byValue==undefined) byValue=false;
  
    if (this.listOfCodesByName[code_head_name+lang]!= undefined)
      {
        for (var i=0;i<this.listOfCodesByName[code_head_name+lang].codesList.length;i++)
        {
          if (byValue)
          {
          if (this.listOfCodesByName[code_head_name+lang].codesList[i].value==code_name_id) deferred.resolve(this.listOfCodesByName[code_head_name+lang].codesList[i]);
          }else{
           if (this.listOfCodesByName[code_head_name+lang].codesList[i].id==code_name_id) deferred.resolve(this.listOfCodesByName[code_head_name+lang].codesList[i]); 
            }              
        }
      }
    else
    {
      
       this.getCodesByName(code_head_name,lang).then(function(data) {
                                 
          for (var j=0;j<this.listOfCodesByName[code_head_name+lang].codesList.length;j++)
          {
               if (byValue)
              {
                if (this.listOfCodesByName[code_head_name+lang].codesList[j].value==code_name_id)  deferred.resolve(this.listOfCodesByName[code_head_name+lang].codesList[j]);
              }else{
                 if (this.listOfCodesByName[code_head_name+lang].codesList[j].id==code_name_id)  deferred.resolve(this.listOfCodesByName[code_head_name+lang].codesList[j]);
                
              }
          }
          deferred.resolve({});
                             },function(error){
                                  
                                 //MessagingService.addMessage(error.msg,'error'); implement later
                                 deferred.resolve({});
                                 
                                
       });
          
    
    }
  
  return deferred.promise;
  };
  
  
  //MIGOR NOVI 
	getCodes (code_id,language){
        var codeIndex=-1;
        if (code_id != undefined) {
            var deferred = this.$q.defer();
            if (!this.listOfCodesById.hasOwnProperty(code_id.toString()+language)) {

                let url = '../api/get-codes-detail-byidlang/'+ code_id+'/'+language+'/';
                 
                this.RestApiService.get(url,true).then(response =>{
                        if (response.status === 200) {
                              var retrivedList = {unique_id: code_id.toString()+language, id: code_id,lang: language, codesList: response.data};
                              this.listOfCodesById[code_id.toString()+language] = retrivedList;  
                              deferred.resolve({data: angular.copy(this.listOfCodesById[code_id.toString()+language].codesList), status: response.status}); 
                        } else {
                            deferred.reject({data:response.data, status:response.status}); //TODO - check if this needs to be msg: response.msg
                        }       
                });
                    
            } else {
                    deferred.resolve({data: angular.copy(this.listOfCodesById[code_id.toString()+language].codesList),status:200});
                  }
        };
	
          return deferred.promise;
	 };
     
     
     //MIGOR NOVI
      getCodesByName (code_name,language){             
         if (code_name != undefined) {
 
            var deferred = this.$q.defer(); 
            if (!this.listOfCodesByName.hasOwnProperty(code_name+language))  
            {
                var  url = '../api/get-codes-by-name/'+ code_name+'/'+language+'/'; 
                this.RestApiService.get(url, true).then(response =>{
                        if (response.status === 200) {
                              var retrivedList = {unique_id: code_name+language, name: code_name,lang: language, codesList: response.data};
                              this.listOfCodesByName[code_name+language] = retrivedList;
                              deferred.resolve({data: this.listOfCodesByName[code_name+language].codesList , status: response.status});
                           
                        } else {
                            deferred.reject({data:response.data, status:response.status});//TODO - check if this needs to be msg: response.msg
                        }
                             
                });
                                   
            } else {
                    deferred.resolve({data: this.listOfCodesByName[code_name+language].codesList,status:200});
                  }
         };
         return deferred.promise;
    };    
      
      
          
	getCodesValue (code_id,language,codeItem,type){
     var listOfCodes = {}
     var retval=codeItem;
    var deferred = this.$q.defer();    
     getCodes(code_id,language).then(function (data) {
                              if (data.status==200)
                              {
                                  listOfCodes = data.data;
                                  
                                  for (var i=0; i<listOfCodes.length;i++)
                                  {
                                    if (type==1) 
                                    {
                                      if (listOfCodes[i].id == codeItem)
                                      {
                                     
                                          retval = listOfCodes[i].name;
                                          break;
                                      }
                                    }
                                    else
                                      {
                                         if (listOfCodes[i].value == codeItem)
                                         {
                                            retval = listOfCodes[i].name;
                                            break;
                                          }  
                                      
                                      }
                                    
                                  }
                                 deferred.resolve(retval);
                                }
                                 
                              
              
          });
     //return retval;
     return deferred.promise;
  
    
  }
	getCodesValueByName (code_name,language,codeItem,type){
     var listOfCodes = {}
     var retval=codeItem;
    var deferred = this.$q.defer();    
     this.getCodesByName(code_name,language).then(function (data) {
                              if (data.status==200)
                              {
                                  listOfCodes = data.data;
                                  
                                  for (var i=0; i<listOfCodes.length;i++)
                                  {
                                    if (type==1)
                                    {
                                      if (listOfCodes[i].id == codeItem)
                                      {
                                     
                                          retval = listOfCodes[i].name;
                                          break;
                                      }
                                    }
                                    else
                                      {
                                         if (listOfCodes[i].value == codeItem)
                                         {
                                            retval = listOfCodes[i].name;
                                            break;
                                          }  
                                      
                                      }
                                    
                                  }
                                 deferred.resolve(retval);
                                }
                                 
                              
              
          });
     //return retval;
     return deferred.promise;
  
    
  }
    
	getCodesHeadLang (id){
    
    var deferred = this.$q.defer();
    
    
    
      let url = '../api/codes-headlang/'+id+'/';
                 
      this.RestApiService.get(url).then(response =>{
              if (response.status === 200) {
                
                     deferred.resolve({data:data, status: response.status});
                
                
              } else {
                  deferred.reject({data:{msg:"Download failed ! "+response.status}, status:response.status});
              }
              
            
      });
  
    return deferred.promise;
  }
  
  CodesPost (codeHead){
    
    var deferred = this.$q.defer();
    let lang=this.$rootScope.globals.currentUser.lang
    let url = '../api/codes/'+lang+'/';
    
    this.RestApiService.post(url,codeHead).then(response =>{
            if (response.status === 200 || response.status === 201) {
                     deferred.resolve({data: response.data, status: response.status});
           } else {
                     deferred.reject({msg:error, status:response.status});       
              }
                        
         });
                
    return deferred.promise;
  }
  CodesPut (codeHead){
    
    var deferred = this.$q.defer();
     let lang=this.$rootScope.globals.currentUser.lang
    let url = '../api/codes/'+lang+'/';
    
    this.RestApiService.put(url,codeHead).then(response =>{
            if (response.status === 200 || response.status === 201) {
                 response.json().then(data=>{
                     deferred.resolve({data:data, status: response.status});
                 });
               
                
              } else {
                  deferred.reject({msg:data, status:response.status});
              }
                        
      });
  
    return deferred.promise;
  }
  
  
  
   CodesGet (lang){
    
    var deferred = this.$q.defer();
    let url = '../api/codes/'+lang+'/';
                 
      this.RestApiService.get(url).then(response =>{
              if (response.status === 200) {
                     deferred.resolve({data:response.data, status: response.status});

              } else {
                  deferred.reject({data:{msg:"Download failed  -> CodesGet  ! "+response.status}, status:response.status});
              }
              
            
      });
                
    return deferred.promise;
  }
  
   CodesGetById (id,lang){
    
      var deferred = this.$q.defer();
      let url = '../api/codes-head/'+id+'/'+lang+'/';
                 
      this.RestApiService.get(url).then(response =>{
              if (response.status === 200) {
                
                     deferred.resolve({data:response.data, status: response.status});
                
              } else {
                  deferred.reject({data:{msg:"Download failed  -> CodesGetById  ! "+response.status}, status:response.status});
              }
              
            
      });
                
      return deferred.promise;
   }
   
  CodesList (){
    
      var deferred = this.$q.defer();
      var lang=this.$rootScope.globals.currentUser.lang;

      if (this.codesList.length==0)
      {
        this.gettingCodeList = true;
        let url = '../api/codes-list/'+lang+'/';
                 
        this.RestApiService.get(url).then(response =>{
              if (response.status === 200) {
                    this.codesList = response.data;
                    this.gettingCodeList = false;
                    deferred.resolve({data: this.codesList, status: response.status});
              } 
              else  deferred.reject({data:{msg:"Download failed  -> CodesList  ! "+response.status}, status:response.status});
                    
         });
       
      } else  deferred.resolve({data: this.codesList, status: 200});
    
      return deferred.promise;
  }
  
  
  
   CodesDetailsList (id){
    
      var deferred = this.$q.defer();
    
      var lang=this.$rootScope.globals.currentUser.lang;
      let url = '../api/codes-detail-list/'+id+'/'+lang+'/';
                 
         this.RestApiService.get(url).then(response =>{
              if (response.status === 200) {
                
                    deferred.resolve({data: response.data, status: response.status});
              
                
              } else {
                  deferred.reject({data:{msg:"Download failed  -> CodesDetailsList  ! "+response.status}, status:response.status});
              }
              
            
         });

    return deferred.promise;
  }
  
  
   addUpdateCodesDetails (type,codesDetailLang){
    
    //debugger
    var deferred = this.$q.defer();
    var url = '../api/save-codes-detail/';
    var http_method="";
    if (type=="New") {
            //code
            http_method="POST";     
    } else { 
      var id= codesDetailLang.id;
      http_method="PUT";
      url=url+id+'/';
    }
                 
      this.RestApiService.send(url,http_method,codesDetailLang).then(response =>{
               if (response.status === 201 && type=="New") {
                   // if successful, bind success message to message
                          
                             deferred.resolve({msg:"New code item created successfuly !",status:response.status});            
                
               }else if (response.status==200 && type=="Edit") {

                        // if Edit successful, bind success message to message
                         deferred.resolve({msg:"Code item changed successfuly!",status:response.status});            
                      
                    
               } else {

                   // if not successful, bind errors to error variables
               
                      deferred.reject({msg:"Save failed ! "+response.status,status:response.status});     
               }
            
      });

    return deferred.promise;
  }
  
   saveCodesDetails (codeHeadId,codesDetails,lang){
    
        var deferred = this.$q.defer();
        var url = '../api/save-codes-detail/';
          
        var http_method="POST";
        url=url+codeHeadId+'/'+lang+'/';
            
        var instance = this;
        //debugger
            
        this.RestApiService.send(url,http_method,codesDetails).then(response =>{
                       response.json().then(data=>{
                             if (response.status === 200) {
                                //debugger
                                //refresh specific codes list
                                let code_name = data.data.code_head_name
                                if (code_name==undefined || code_name==null) code_name=''
                                    
                                instance.listOfCodesById[codeHeadId.toString()+lang] = {id:codeHeadId,unique_id:codeHeadId+lang,lang:lang,codesList:data.data.codesList};
                                instance.listOfCodesByName[code_name.toString()+lang] = {name:code_name,unique_id:code_name.toString()+lang,lang:lang,codesList:data.data.codesList};
                                // if successful, bind success message to message
                                  
                                deferred.resolve({data:angular.copy(data.data.code),msg:data.msg,status:response.status});            
                        
                          
                            } else {

                                // if not successful, bind errors to error variables
                            
                                   deferred.reject({msg:data.msg,status:response.status});     
                            }
                         });
                       
                      
                    
              });
           
            return deferred.promise;
  }
  

   getAllCodesAndDetails (){   // MIGOR NOVO
    
       var deferred = this.$q.defer();
        let url = '../api/get-codes-detail/';
                 
         this.RestApiService.get(url).then(response =>{
              if (response.status === 200 || response.status===201) {
                      deferred.resolve({data:response.data, status: response.status});

              } else {
                  deferred.reject({data:{msg:"Download failed  -> getAllCodesAndDetails  ! "+response.status}, status:response.status});
              }
              
            
         });
      
       return deferred.promise;
   }
   
   
   getCodesDetails (id,lang = $rootScope.globals.currentUser.lang ){
        var deferred = this.$q.defer();
        
        let url = '../api/get-codes-detail-bycodeidlang/'+id+'/'+lang+'/';
                 
         this.RestApiService.get(url).then(response =>{
              if (response.status === 200 || response.status===201) {
                
                    
                            deferred.resolve({data:response.data, status: response.status});
                 
                
              } else {
                  deferred.reject({data:{msg:"Download failed  -> getCodesDetails  ! "+response.status}, status:response.status});
              }
              
            
         });
         
       
        return deferred.promise;
    }              
	 
     
    getHardCodes (codeId){  
			var pos = this.listOfCodesById.map(function(e) { return e.id; }).indexOf(codeId);
			return this.listOfCodesById[pos].codesList;
		  }

}

export default CodesService;