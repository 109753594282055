'use strict';

import appController,{appSettingsCtrl, homeNavbarCtrl,errorNotFoundCtrl,errorCtrl,homeCtrl,onboardingCtrl,signupCtrl,registerCtrl,unregisterCtrl,navbarCtrl,menuCtrl,toolboxCtrl,taskToolboxCtrl,marketCtrl} from './indexCtrls.js';
import loginCtrl,{selectenvironmentCtrl,ssoCtrl,logoutCtrl,changePasswordRequestCtrl,changePasswordCtrl} from '../../0002_auth/angularJS/authCtrls.js';
import importDataCtrl,{datasetTransformCtrl,resourceFormCtrl,resourceDataCtrl,resourceDataRecordCtrl,newResourceCtrl,editResourceCtrl,viewResourceCtrl,resourceListCtrl,menusDefinitionCtrl,integrationDefinitionCtrl,applicationDefinitionCtrl,notificationDefinitionCtrl,tokenDefinitionCtrl,smartContractDefinitionCtrl,appMenuDefinitionCtrl,resourceFormComponent,toolboxComponent,pagesCtrl,pagesDefinitionCtrl,mappingDefinitionCtrl,mappingsFormCtrl,formDefinitionCtrl} from '../../0003_resources/angularJS/resourcesCtrls.js';
import codesDataCtrl,{usersSecurityCtrl,codesDetailsCtrl,codesNewCtrl,userDetailsCtrl,usersDefinitionCtrl,groupsCtrl,organisationCtrl} from '../../0004_settings/angularJS/settingsCtrls.js';
import taskListCtrl,{taskFormCtrl,taskDefinitionCtrl,taskDefinitionListCtrl,taskInitiateListCtrl,taskProcCtrl,taskPublicCtrl,taskInitiateCtrl,taskExecuteCtrl,taskExecuteListCtrl} from '../../0005_tasks/angularJS/tasksCtrls.js';
import {vpageCtrl} from '../../0006_vpage/angularJS/vpageCtrls.js';

var moduleCtrls='KatalyoCtrls';

var module = angular.module(moduleCtrls, []);

module
.controller('selectenvironmentCtrl',selectenvironmentCtrl)
.controller('appController',appController)
.controller('homeNavbarCtrl',homeNavbarCtrl)
.controller('errorNotFoundCtrl',errorNotFoundCtrl)
.controller('errorCtrl',errorCtrl)
.controller('homeCtrl',homeCtrl)
.controller('onboardingCtrl',onboardingCtrl)
.controller('signupCtrl',signupCtrl)
.controller('registerCtrl',registerCtrl)
.controller('unregisterCtrl',unregisterCtrl)
.controller('appSettingsCtrl',appSettingsCtrl)
.controller('navbarCtrl',navbarCtrl)
.controller('menuCtrl',menuCtrl)
.controller('menusDefinitionCtrl',menusDefinitionCtrl)
.controller('toolboxCtrl',toolboxCtrl)
.controller('toolboxComponent',toolboxComponent)
.controller('taskToolboxCtrl',taskToolboxCtrl)
.controller('loginCtrl',loginCtrl)
.controller('ssoCtrl',ssoCtrl)
.controller('changePasswordRequestCtrl',changePasswordRequestCtrl)
.controller('changePasswordCtrl',changePasswordCtrl)
.controller('mappingsFormCtrl', mappingsFormCtrl)
.controller('logoutCtrl',logoutCtrl)
.controller('importDataCtrl',importDataCtrl)
.controller('datasetTransformCtrl',datasetTransformCtrl)
.controller('resourceFormCtrl',resourceFormCtrl)
.controller('resourceDataCtrl',resourceDataCtrl)
.controller('resourceDataRecordCtrl',resourceDataRecordCtrl)
.controller('resourceFormComponent',resourceFormComponent)
.controller('resourceListCtrl',resourceListCtrl)
.controller('newResourceCtrl',newResourceCtrl)
.controller('editResourceCtrl',editResourceCtrl)
.controller('viewResourceCtrl',viewResourceCtrl)
.controller('pagesCtrl',pagesCtrl)
.controller('pagesDefinitionCtrl',pagesDefinitionCtrl)
.controller('applicationDefinitionCtrl',applicationDefinitionCtrl)
.controller('notificationDefinitionCtrl',notificationDefinitionCtrl)
.controller('tokenDefinitionCtrl',tokenDefinitionCtrl)
.controller('smartContractDefinitionCtrl',smartContractDefinitionCtrl)
.controller('codesDataCtrl',codesDataCtrl)
.controller('codesDetailsCtrl',codesDetailsCtrl)
.controller('codesNewCtrl',codesNewCtrl)
.controller('marketCtrl',marketCtrl)
.controller('userDetailsCtrl',userDetailsCtrl)
.controller('usersSecurityCtrl',usersSecurityCtrl)
.controller('usersDefinitionCtrl',usersDefinitionCtrl)
.controller('groupsCtrl',groupsCtrl)
.controller('organisationCtrl',organisationCtrl)
.controller('taskListCtrl',taskListCtrl)
.controller('taskFormCtrl',taskFormCtrl)
.controller('taskDefinitionCtrl',taskDefinitionCtrl)
.controller('taskDefinitionListCtrl',taskDefinitionListCtrl)
.controller('taskInitiateListCtrl',taskInitiateListCtrl)
.controller('taskProcCtrl',taskProcCtrl)
.controller('taskPublicCtrl',taskPublicCtrl)
.controller('taskInitiateCtrl',taskInitiateCtrl)
.controller('taskExecuteCtrl',taskExecuteCtrl)
.controller('taskExecuteListCtrl',taskExecuteListCtrl)
.controller('integrationDefinitionCtrl',integrationDefinitionCtrl)
.controller('appMenuDefinitionCtrl',appMenuDefinitionCtrl)
.controller('mappingDefinitionCtrl',mappingDefinitionCtrl)
.controller('formDefinitionCtrl',formDefinitionCtrl)
.controller('vpageCtrl',vpageCtrl)
;


export default moduleCtrls;