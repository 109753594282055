//**************************************************************************************************
//*************************************** ProcessingService ****************************
//**************************************************************************************************

class ProcessingInterceptor{
 
 
  constructor($rootScope,$q,PROCESSING_CONST)
  {
      var instance = this;
      this.$rootScope =$rootScope;
      this.$q = $q;
      this.PROCESSING_CONST = PROCESSING_CONST;
      
        this.request = (config) => {
        // do something on success
          if (this.$rootScope.globals.InterceptorIsActive)
          {
            this.$rootScope.$broadcast(this.PROCESSING_CONST._START_REQUEST_,config.url);
          }
          return config;
        }
        
        this.requestError = (config) => {
        // do something on error
        
          if (config!=undefined)
         {
           if (this.$rootScope.globals.InterceptorIsActive)
           {
             this.$rootScope.$broadcast(this.PROCESSING_CONST._END_REQUEST_,config.url);
           }
         }
         return this.$q.reject(config);
        };
  
        // optional method
        this.response = (response) => {
        // do something on success
        if (this.$rootScope.globals.InterceptorIsActive)
        {
          this.$rootScope.$broadcast(this.PROCESSING_CONST._END_REQUEST_,response.config.url);
        }
        return response;
        };
  
        // optional method
        this.responseError = (rejection) => {
        // do something on error
          if (rejection!=undefined)
          {
            if (rejection.config!=undefined)
            {
              if (this.$rootScope.globals.InterceptorIsActive)
              {
                this.$rootScope.$broadcast(this.PROCESSING_CONST._END_REQUEST_,rejection.config.url);
              }
            }
          
          }
          return this.$q.reject(rejection);
        }
        
  }
  
   static $inject = ['$rootScope','$q','PROCESSING_CONST'];
    
      static ProcessingInterceptorService($rootScope,$q,PROCESSING_CONST){ //
        return new ProcessingInterceptor($rootScope,$q,PROCESSING_CONST); //$rootScope,$q,PROCESSING_CONST
      }
   
}
 
export default ProcessingInterceptor;


//**************************************************************************************************
//*************************************** AuthInterceptor ****************************
//**************************************************************************************************

class AuthInterceptor{
 
  constructor($q,$location,$cookies,$rootScope)
  {
    
     
      this.$q =$q;
      this.$location =$location;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
      //this.MessagingService = MessagingService;
        // optional method
       /* this.response = (response) => {
          // do something on success
          return response;
        };
        */
        this.responseError = (rejection) => {
        // redirect to login page if not authenticated
          if (rejection!=undefined)
          {
            if (rejection.config!=undefined)
            {
              if (rejection.status ==401)
              {
                this.$rootScope.globals.currentUser = {lang:'en-GB',InterceptorIsActive : true};
                this.$cookies.remove('globals');
                //rejection.config.headers.Authorization = "";
                //this.MessagingService.clearAllMessages();
                this.$location.path('/login');
              } else return this.$q.reject(rejection);
              
            }
          
          }
         
        }
  
       
  }
  
   static $inject = ['$q','$location','$cookies','$rootScope'];
    
      static AuthInterceptorService($q,$location,$cookies,$rootScope){ 
        return new AuthInterceptor($q,$location,$cookies,$rootScope); 
      }
   
}
 
export {AuthInterceptor};