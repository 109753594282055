'use strict';

class AuthenticationService{
        
        constructor($cookies, $rootScope, $timeout,$q,$state,MenuService,ClearService,MessagingService,RestApiService,ResourcesDataService) {
                this.$cookies=$cookies;
                this.$rootScope=$rootScope;
                this.$timeout = $timeout;
                this.ClearService=ClearService;
                this.$q=$q;
                this.$state=$state;
                this.MenuService=MenuService;
                this.MessagingService = MessagingService;
                this.RestApiService = RestApiService;
                this.ResourcesDataService = ResourcesDataService;
                
                
        }
        
        static $inject = ['$cookies', '$rootScope', '$timeout','$q','$state','MenuService', 'ClearService','MessagingService','RestApiService','ResourcesDataService'];

        // Requesting password reset , server will send e-mail to requester
        // ----------------------------------------------
        RequestPswReset (email, callback) {
               
                let url = '../api/change-password-request/';
                let csrf_token = this.$cookies.get('csrftoken');
                let post_data = { 'email': email }
                this.RestApiService.add_api_classes("token",csrf_token);
                
                this.RestApiService.post(url,post_data).then(response =>{
                        
                        //response.json().then(data=>{
                                let success = false;
                                let message='';
                                if (response.status===200) success = true;
                                else message = 'E-mail not in database!';
                                let resp = {'data':response.data,'message':message,'status':response.status,'success':success}
                                callback(resp);
                        });
                        
               // });
           
        };
        
         //**********************************************************
       //           get Organisation schemas
        //**********************************************************      
        getOrgSchemas (orgId, caching=false) {
                let url='../api/get-org-schemas/'+orgId+'/';
                var deferred = this.$q.defer();
                var instance = this;
                this.RestApiService.get(url, caching).then(response =>{           
                         if (response.status === 200) {          
                                        deferred.resolve({data: response.data, status: response.data.status})    
                                } else {
                                        deferred.reject({data:response.data ,status:response.status});
                                      }
               
                });
            
                return deferred.promise;
        };
		
		
		//**********************************************************
       //           get environemnet info statistics
	   //**********************************************************      
        getEnvInfo (orgId, caching=false) {
                let url='../api/get-env-info/'+orgId+'/';
                var deferred = this.$q.defer();
                var instance = this;
                this.RestApiService.get(url, caching).then(response =>{           
                         if (response.status === 200) {          
                                        deferred.resolve({data: response.data, status: response.data.status})    
                                } else {
                                        deferred.reject({data:response.data ,status:response.status});
                                      }
               
                });
            
                return deferred.promise;
        };
		
		//**********************************************************
       //           get environemnet info statistics
	   //**********************************************************      
        getUserInfo (orgId, caching=false) {
                let url='../api/get-user-info/'+orgId+'/';
				//debugger
                var deferred = this.$q.defer();
                var instance = this;
                this.RestApiService.get(url, caching).then(response =>{           
                         if (response.status === 200) {          
                                        deferred.resolve({data: response.data, status: response.data.status})    
                                } else {
                                        deferred.reject({data:response.msg.detail, status:response.status});
                                      }
               
                });
            
                return deferred.promise;
        };
        
        // Password reset function
        // ----------------------------------------------
        PswReset (username,userId, password1, password2,digest, callback) {
               
            
                let url = '../api/change-password/';
                let csrf_token = this.$cookies.get('csrftoken');
                let post_data = {'username':username,'user_id':userId,'password1':password1,'password2':password2,'digest': digest ,'type':0};
                this.RestApiService.add_api_classes("token",csrf_token);
                //POST, send password1, password2, digest, user_id
                this.RestApiService.post(url,post_data).then(response =>{

                
                        let success = false;
                        let message='';
                        if (response.status===200)
                        {
                                //change-password success   
                                success = true;
                                message = response.data.msg

                        }
                        else message = response.data.msg
                        
                        let resp = {'data':response.data,'message':message,'status':response.status,'success':success}
                        callback(resp);
                
                });
         
 
        };
             //**********************************************************
    //           Change password with auth
    //**********************************************************
        ChangePasswordWithAuth(cPass,nPass){
                
                let url = '../api/change-password-with-auth/';
                let csrf_token = this.$cookies.get('csrftoken');
                let post_data = { password1: cPass, password2:nPass};
                this.RestApiService.add_api_classes("token",csrf_token,this.$rootScope.globals.currentUser.authdata);
                var deferred = this.$q.defer();

                this.RestApiService.post(url,post_data).then(response =>{
                        
                        if (response.status===200)
                        { 
                           // response.json().then(data=>{
                                
                                let message='';
                                let success = true;
                                let resp = {'data':response.data,'message':message,'status':response.status,'success':success}
                                deferred.resolve(resp);
                            //});
                        }else   {   
                            let success2 = false;
                            let message2 = 'Change password failed';
                            let resp2 = {'data':{},'message':message2,'status':response.status,'success':success2}
                            deferred.reject(resp2);

                        }
                        
                });

                return deferred.promise
                
 
        }
         //**********************************************************
	//          Change password
	//**********************************************************
        ChangePassword(cPass,nPass,callback){
                
                let url = '../api/change-password/';
                let csrf_token = this.$cookies.get('csrftoken');
                let post_data = { password1: cPass, password2:nPass,digest:'',user_id:0,type:1};
                this.RestApiService.add_api_classes("token",csrf_token);
                
                this.RestApiService.post(url,post_data).then(response =>{
                       
                        if (response.status===200)
                        { 
                            //response.json().then(data=>{
                                
                                let message='';
                                let success = true;
                                let resp = {'data':response.data,'message':message,'status':response.status,'success':success}
                                callback(resp);
                           // });
                        }else
                        {
                            let success1 = false;
                            let message1 = 'Change password failed';
                            let resp1 = {'data':{},'message':message1,'status':response.status,'success':success1}
                            callback(resp1);    

                        }
                        
                });
                
 
        }
        CheckPassChangeRequest (digest, callback) {
 
           
             var response = {};
            /* Use this for real authentication
             ----------------------------------------------*/
            
                let url = '../api/check-change-password/';
                let post_data = { 'digest': digest };
                let csrf_token = this.$cookies.get('csrftoken'); 
                this.RestApiService.add_api_classes("token",csrf_token);
                this.RestApiService.post(url,post_data).then(response =>{
                        
                        let success = false;
                        let message='';
                        let resp = {};
                        if (response.status==200)
                        {
                                //response.json().then(data=>{
                                
                                if (response.status===200) success = true;     
                                    else message = 'Check failed';
                                let resp = {'data':response.data,'message':message,'status':response.status,'success':success}
                                callback(resp);
                                //}); 
                        }else {
                                if (response.status===200) success = true;      
                                    else message = 'Check failed';
                                resp = {'message':message,'status':response.status,'success':success}
                                callback(resp);
                        }
                        
                });
           
        };
        Login (username, password, callback) {
 
           
             var response = {};
            /* Use this for real authentication
             ----------------------------------------------*/
            
                let url = '../api/login/';
                let csrf_token = this.$cookies.get('csrftoken'); 
                this.RestApiService.add_api_classes("token",csrf_token);
                let post_data = { username: username, password: password }
                this.RestApiService.post(url,post_data).then(response =>{
                        
                        let success = false;
                        let message='';
                        let resp = {};
                        if (response.status==200)
                        {
                                //response.json().then(data=>{
                                
                                if (response.status===200) success = true;     
                                    else message = 'Login failed'
                                let resp = {'data':response.data,'message':message,'status':response.status,'success':success}
                                callback(resp);
                                //}); 
                        }else {
                                if (response.status===200) success = true;      
                                else message = 'Login failed'
                                resp = {'message':message,'status':response.status,'success':success}
                                callback(resp);
                        }
                        
                });
           
        };
        
        LoginSsoFetch (callback) {
                
                let url = '../api/login-sso/';
                let csrf_token = this.$cookies.get('csrftoken'); 
                this.RestApiService.add_api_classes("sso",csrf_token);
               
                this.RestApiService.post(url).then(response =>{
                        
                       // response.json().then(data=>{
                                let success = false;
                                if (response.status===200) success = true;
                                let resp = {'data':response.data,'status':response.status,'success':success}
                                callback(resp);
                        //});
                        
                });
                
                
        };
        
          Logout (callback) {
 
            
                let url = 'api/logout/';
            
                this.RestApiService.post(url).then(response =>{

                                let success = false;
                                let message='';
                                if (response.status===200) success = true;
                                else message = 'Logout failed';
                                let resp = {'message':message,'status':response.status,'success':success}
                                callback(resp);
                     
                        
                });
            
         
 
        };
  
		
        SetCredentials (user, username, password,setCookie) {       // set global "current user"  and cookie 
            var authdata = "Token " +user.token;
            //var authdata = "Bearer " +user.access_token; // TODO - enable for fastapi endpoint (fast api will return user.access_token as jwt)
    
			if (!this.$rootScope.globals) this.$rootScope.globals = {}
			//debugger
			
			this.$rootScope.globals.currentUser= {
                userid : user.userid,
                first_name: user.first_name,
                last_name : user.last_name,
                email: user.email,
                username: user.username,
                is_superuser:user.is_superuser,
                last_login:user.last_login,
                authdata: authdata,
                auth_type: user.auth_type,
                lang: user?.lang?.id,
                langObj : user.lang,
                pages:[],
                pagesLoaded:false,
                navBars:[],
                navbarLoaded:false,
                cMenus:{},
                organisation:user.organisation,
                groups:user.groups,
                uep:user.uep,
            }
                
           // this.$http.defaults.headers.common['Authorization'] = 'Basic ' + authdata; // jshint ignore:line
           // this.$http.defaults.headers.common['Authorization'] = authdata; // jshint ignore:line
		   
            this.RestApiService.set_header('Authorization',authdata);
			//let env = {}
			//env[user.organisation.id] =  user.uep.defaultenv 
			//env[user.organisation.id] =  this.$rootScope.globals?.currentEnv ?? user.uep.defaultenv 
			
			this.$cookies.putObject('currentUser',  {
                    userid : user.userid,
                    username: user.username,
                    first_name: user.first_name,
                    last_name : user.last_name,
                    last_login:user.last_login,
                    is_superuser:user.is_superuser,
                    authdata: authdata,
                    auth_type: user.auth_type,
                    lang: user?.lang?.id, 
					langObj: { id: user?.lang?.id, Name: user?.lang?.Name, Code:user?.lang?.Code },
                    uep:{Params:{Onboarding:user.uep?.Params?.Onboarding}},
                   organisation: { id: user.organisation.id },
				   //env: env
                })
        };


       SetUep (uep) { 	   
            this.$rootScope.globals.currentUser.uep= uep
                
        };
		
		
        SetCredentialsSso (user) {
            
           var authdata = "";
            if (!this.$rootScope.globals) this.$rootScope.globals = {}
			this.$rootScope.globals.currentUser= {
                    userid : user.userid,
                    first_name: user.first_name,
                    last_name : user.last_name,
                    email: user.email,
                    username: user.username,
                    is_superuser:user.is_superuser,
                    last_login:user.last_login,
                    authdata: authdata,
                    auth_type: user.auth_type,
                    lang: user?.lang?.id,
                    langObj : user.lang,
                    pages:[],
                    pagesLoaded:false,
                    navBars:[],
                    navbarLoaded:false,
                    cMenus:{},
                    organisation:user.organisation,
                    groups:user.groups,
                    uep:user.uep,
                }
                
                //this.$http.defaults.headers.common['Authorization'] = authdata; // jshint ignore:line
                let csrf_token = this.$cookies.get('csrftoken'); 
                this.RestApiService.add_api_classes("token",csrf_token);
                this.$cookies.putObject('currentUser',  {
                    userid : user.userid,
                    username: user.username,
                    first_name: user.first_name,
                    last_name : user.last_name,
                    environments: user.environments,
                    last_login:user.last_login,
                    is_superuser:user.is_superuser,
                    authdata: authdata,
                    auth_type: user.auth_type,
                    lang: user?.lang?.id, 
                    langObj: { id: user?.lang?.id, Name: user?.lang?.Name, Code:user?.lang?.Code },
                    uep:{Params:{Onboarding:user.uep?.Params?.Onboarding}},
                   organisation: { id: user.organisation.id }
                });
        };
        UpdateAuthToken(token)
        {
                let authdata = "Token " +token;
                this.$rootScope.globals.currentUser.authdata=authdata;
                //this.$http.defaults.headers.common['Authorization'] = authdata;
                this.RestApiService.set_header('Authorization',authdata);
                this.$cookies.putObject('globals', this.$rootScope.globals);
        }        
        
        
        SetMenusForApp(app) 
		{
            var deferred = this.$q.defer();
            this.$rootScope.globals.currentUser.navBars = [];

            this.MenuService.setSelectedApp(app);
            let instance = this;
            let type='0'
            let menu_id = app.uid
            let m_ver=0
            let menu = this.findLinkFull('app-menu',app)
            menu_id = menu?.Resource2
            if (menu?.Version!=undefined) m_ver = menu.Version

            if (app.app_type=='external') type='e'
            this.MenuService.resetCurrentMenuWithProps()
            if (menu_id!=undefined)   {
				
                this.MenuService.getMenusExe(menu_id,type,m_ver).then(
					function (data) {
                               
                                let tempNavbarItems = instance.MenuService.getNavbarsFromMenus(instance.$rootScope.globals.currentUser.lang,type,false,menu_id,m_ver);
                                let menu_loaded = false;
                                if (tempNavbarItems?.length>0) {
                                    //debugger //  SetMenusForApp
									instance.MenuService.navbarItems.splice(0, instance.MenuService.navbarItems.length);
									instance.MenuService.navbarItems.push(...tempNavbarItems)
                                    //instance.MenuService.navbarItems =  tempNavbarItems
                                    instance.MenuService.currentApp = instance.$rootScope.globals.currentApp  = {  uid:app.uid, uniqueId: app.uniqueId, id:app.id , name: app.Name , menuId: tempNavbarItems[0].s_menus_ResourceDefUid }
                                    if (type!='e') instance.MenuService.setCurrentMenuWithProps(type,menu_id,m_ver)
                                    menu_loaded = true
   
                                }
                                else
                                {
                                    tempNavbarItems=[]
                                    instance.MenuService.navbarItems =  tempNavbarItems
                                    instance.MessagingService.addMessage("Menu not set for application #"+app.Name,'info');
                                }
                                instance.$rootScope.globals.currentUser.navbarLoaded=true;
                               
                                deferred.resolve(menu_loaded);
                });
            }else 
            {
                instance.MessagingService.addMessage("Menu not found for application #"+app.Name,'warning')
                deferred.resolve(false);
            }

            return deferred.promise; 
        };
        
        SetNavbars() { 
           var deferred = this.$q.defer();
           let instance = this;
           this.MenuService.getNavbar(this.$rootScope.globals.currentUser.lang).then(function (data) {
                               instance.$rootScope.globals.currentUser.navBars = data;
                                //$scope.navbar=$scope.getLinkNavbar($state.current.name);
                               instance.MenuService.generateChildrenNavbarRight();
                               instance.$rootScope.globals.currentUser.navbarLoaded=true;
                               //$cookies.put('globals', $rootScope.globals);
                               return deferred.resolve(true);
                               // $scope.getMenusForPage();
            });
           return deferred.promise; 
        }
  
 /**********************************************************
  ***************** Fink link from app record******************
  ***********************************************************/       
  findLink = function (link_type,app) {
                for (let i=0;i<app?.ResourceExtended?.length;i++) 
                {
                        if (app.ResourceExtended[i].LinkType==link_type) return app.ResourceExtended[i].Resource2;
                }
                return null;
  }
  findLinkFull = function (link_type,app) {
                for (let i=0;i<app?.ResourceExtended?.length;i++) 
                {
                        if (app.ResourceExtended[i].LinkType==link_type) return app.ResourceExtended[i]
                }
                return null;
  }
   
    /**********************************************************
	***************** Load application first page******************
	***********************************************************/  
    loadFirstPage = function (stateName,params,app,menu_loaded) {
                
        let instance=this;         
         
          
          instance.MenuService.setSelectedApp(app,stateName,params);
          if (stateName!==undefined && stateName!==null && stateName.trim()!=="")
          {
          if (menu_loaded) {
                instance.MessagingService.addMessage('#'+app.Name+' launched successfuly!','info');
                  
          }
              else  instance.MessagingService.addMessage('#'+app.Name+' application is not fully launched!','warning');                 
              /*if (params.pageid==undefined || params.pageid == null || params.pageid.trim()=='')
                {
                    let pageId = instance.findLink("default-page",app);
                    params.pageid = pageId
                }*/
              if (params['app_ref']==undefined) params['app_ref'] = app.uid
			  //if (params['env']==undefined) params['env'] = this.$rootScope?.globals?.currentEnv 
			  
              instance.$state.go(stateName,params,{ location: true });
              instance.MenuService.setSelectedAppMenuWithParams(stateName,params)
          }
          //load default page
          else{
            let pageId = instance.findLink("default-page",app);
			
            if (pageId!=null && pageId!=undefined && app.app_type=='internal')
            {
                instance.ResourcesDataService.getResourceDefAll(pageId).then(function(data1) {
                       let page = data1.data;
                       if (menu_loaded) instance.MessagingService.addMessage('#'+app.Name+' launched successfuly!','info');
                       else  instance.MessagingService.addMessage('#'+app.Name+' application is not fully launched!','warning');
                       if (page.ResourceExtended?.Target!=undefined) instance.$state.go(page.ResourceExtended.Target  ,{ parameters: params, pageid: page.uid, app_ref:app.uid})
                       else instance.$state.go("app.navbar.home"  ,{ parameters: params, pageid: pageId, app_ref:app.uid  })
                         
                },function(error1){
                         
                    instance.MessagingService.addMessage(error1.msg,'error');  
                });
            } else if (pageId!=null && pageId!=undefined && app.app_type=='external')
            {
                
                instance.ResourcesDataService.GetPublicPublishedResource(pageId).then(function(data1) {
                       let page = data1.data;
                       if (menu_loaded) instance.MessagingService.addMessage('#'+app.Name+' launched successfuly!','info');
                       else  instance.MessagingService.addMessage('#'+app.Name+' application is not fully launched!','warning');
                       if (page.ResourceExtended?.Target!=undefined) instance.$state.go(page.ResourceExtended.Target  ,{ parameters: params, pageid: pageId, app_ref:app.uid})
                       else instance.$state.go("app.navbar.home"  ,{ parameters: params, pageid: pageId, app_ref:app.uid})
                         
                },function(error1){
                         
                    instance.MessagingService.addMessage(error1.msg,'error');  
                });

            }

            else 
            {   
                instance.$state.go('app.navbar.home'  ,{app_ref:app.uid});       
                instance.MessagingService.addMessage('#'+app.Name+' application is not fully launched! Default page is not defined','warning');
            }
          }
                     
      
           
   };     
   
   /**********************************************************
	***************** Load application ******************
   ***********************************************************/  
   loadApp (stateName,params,appId){
      
      let instance= this;
      let qs = instance.$q.defer()
      this.ResourcesDataService.loadApp(appId).then(function(data) {
        
            let app = data.data;
			instance.$rootScope.globals.app = app.subscriptions

             qs.resolve(app)
           
        },function(error){
            qs.reject(error)
            //instance.MessagingService.addMessage(error1,'error');  
       });
        
        return qs.promise
    };
    
        ClearCredentials () {
           // let auth_type = this.$rootScope.globals?.currentUser?.auth_type;
           // this.$rootScope.globals.currentUser = {lang:'en-GB',auth_type:auth_type,InterceptorIsActive : true};
            this.$cookies.remove('globals');
            //this.$http.defaults.headers.common.Authorization = '';
            this.ClearService.Clear();
            this.MessagingService.clearAllMessages();
        };
         
  }

export default AuthenticationService;

class UserService {
        
        constructor($q,RestApiService,$rootScope)
        {
                this.users={};
                this.groups ={};
                this.count_call=0;
                this.$q =$q;
                this.RestApiService=RestApiService;
                this.$rootScope=$rootScope;
                
        }
        
        static $inject = ['$q','RestApiService','$rootScope'];
        
        getUsersGroups (auth_type) {
        
                var deferred = this.$q.defer();                   
                var dataFound=false;   
                if (auth_type=="users") {
                        if (this.users[this.$rootScope.globals.currentUser.organisation?.id]?.length>0) {
                                dataFound=true;
                                deferred.resolve({data: angular.copy(this.users[this.$rootScope.globals.currentUser.organisation?.id]), status: 200});
                        }
                }
      
                if (auth_type=="groups") {
                        
                        if (this.groups[this.$rootScope.globals.currentUser.organisation?.id]?.length>0) {
                                dataFound=true;
                                deferred.resolve({data: angular.copy(this.groups[this.$rootScope.globals.currentUser.organisation?.id]), status: 200});
                        }
                }
                if (!dataFound)
                {
                        var instance = this;  
                        let url = '../api/'+auth_type+'/';
           
                        this.RestApiService.get(url, true).then(response =>{
                                 if (response.status === 200) {
                                                if (auth_type=="users")    instance.users[instance.$rootScope.globals.currentUser.organisation?.id] = response.data;
                                                if (auth_type=="groups")    instance.groups[instance.$rootScope.globals.currentUser.organisation?.id] = response.data;
                                                deferred.resolve( {'data':angular.copy(response.data),'status':response.status});
                                 }  else {
                                        deferred.reject ({'data':response,'status':response.status});
                                }
                        });
            
                }
        
                return deferred.promise;
        };

        getGroupsExtended () {
        
                var deferred = this.$q.defer();                   
        
                let url = '../api/groupsextended/';
                this.RestApiService.get(url).then(response =>{
                        if (response.status === 200) {
                                    deferred.resolve({data:response.data,status:response.status}); 
                                } else {
                               deferred.reject({data: response, status:response.status});
                     }                                    
                        });
                       
        
                return deferred.promise;
        }



        getGroupsSecurity() {
        
          var deferred = this.$q.defer();                   
                 let url = '../api/groupssecurity/';
                this.RestApiService.get(url).then(response =>{
                         if (response.status === 200) {
                                        deferred.resolve({data:response.data,status:response.status});     
                        } else {
                                deferred.reject({data: response.data, status:response.status});
                        }      
                });
        
                return deferred.promise;
        }
		
		getUsersSecurity() {
        
          var deferred = this.$q.defer();                   
                 let url = '../api/userssecurity/';
                this.RestApiService.get(url).then(response =>{
                         if (response.status === 200) {
                                        deferred.resolve({data:response.data,status:response.status});     
                        } else {
                                deferred.reject({data: response.data, status:response.status});
                        }      
                });
        
                return deferred.promise;
        }

        saveGroupsSecurity(record) {
        
          var deferred = this.$q.defer();                   
                let url = '../api/groupssecurity/';
				let post_data = {'record': record}
                this.RestApiService.post(url, post_data).then(response =>{
                         if (response.status === 200) {
                                        deferred.resolve({data:response.data,status:response.status});     
                        } else {
                                deferred.reject({data: response.data, status:response.status});
                        }      
                });
        
                return deferred.promise;
        }


        getUsersExtended () {
        
          var deferred = this.$q.defer();                   
                 let url = '../api/usersextended/';
                this.RestApiService.get(url).then(response =>{
                         if (response.status === 200) {
                               
                                        deferred.resolve({data:response.data,status:response.status});
                              
                        } else {
                                deferred.reject({data: response.data, status:response.status});
                        }
                        
                });
        
                return deferred.promise;
 
        }

        saveUserExtended (onboardingStatus) {
        
            var deferred = this.$q.defer();                   
            let url = '../api/usersextended/';
            let post_data = {'onboarding':onboardingStatus}
            this.RestApiService.post(url,post_data).then(response =>{
                if (response.status === 200) {
                               
                    deferred.resolve({data:response.data,status:response.status});
                              
                } else {
                    deferred.reject({data: response.data, status:response.status});
                }
                        
            });
        
            return deferred.promise;
 
        }

        
       getUserObjectById (userId){
        
       var deferred = this.$q.defer();
       
        if (userId==null)
        {
          userId="";
          
        }
        if (this.users.length==0)
        {
          this.count_call++;
          if (this.count_call<1)
          {
              getUsersGroups('users').then(function(){
            
             var pos = this.users.map(function(e) { return e.id.toString(); }).indexOf(userId.toString());
              if (pos>=0)
              {
                deferred.resolve(this.users[pos]);
              
              } else  deferred.resolve(null);     
            
            });
          }
          
        }
            var pos = this.users.map(function(e) { return e.id.toString(); }).indexOf(userId.toString());
            if (pos>=0)
            {
              deferred.resolve(this.users[pos]);
            } else deferred.resolve(null);     
        
        
               return deferred.promise;
           
    };
    
       getGroupObjectById (groupId){
        
       var deferred = this.$q.defer();
       
        if (groupId==null)
        {
          groupId="";
          
        }
        if (this.groups.length==0)
        {
          this.count_call++;
          if (this.count_call<1)
          {
              service.getUsersGroups('groups').then(function(){
            
             var pos = this.groups.map(function(e) { return e.id.toString(); }).indexOf(groupId.toString());
              if (pos>=0)
              {
                deferred.resolve(this.groups[pos]);
              
              } else  deferred.resolve(null);     
            
            });
          }
          
        }
            var pos = this.groups.map(function(e) { return e.id.toString(); }).indexOf(groupId.toString());
            if (pos>=0)
            {
              deferred.resolve(this.groups[pos]);
            } else deferred.resolve(null);     
        
        
               return deferred.promise;
           
    };
    
    //**********************************************************
	//           SAVE Group
  //**********************************************************      
        SaveGroup (group) {
    
          var http_method="POST";
          var url='../api/save-group/';
          var deferred = this.$q.defer();
          var instance=this;
          this.RestApiService.post(url,group).then(response =>{
                        
                          if (response.status === 200) {
                                        instance.groups= response.data.groups;    
                                        deferred.resolve({data: response.data,status:response.data.status});
                        } else {
                                        deferred.reject({data:response.data ,status:response.status});
                        }
                        
                });
          
                   return deferred.promise;
        };

        //**********************************************************
	  //           Execute actions (set environment variables...)
        //**********************************************************      
        ExecuteActions (user) {
                let url='../api/execute-actions/';
                var deferred = this.$q.defer();
                var instance = this;
                this.RestApiService.post(url,user).then(response =>{                        
                         if (response.status === 200) { 
                                        instance.users=response.data.users;    
                                        deferred.resolve({data: response.data, msg:response.data.msg, status: response.data.status});
                                  
                                } else {
                                        deferred.reject({data:response.data,msg:response.data.msg,status:response.status});
                                      }                       
                });
            
                return deferred.promise;
        };

        //**********************************************************
	//           SAVE User
        //**********************************************************      
        SaveUser (user) {

                let url='../api/save-user/';
                var deferred = this.$q.defer();
                var instance = this;
                this.RestApiService.post(url,user).then(response =>{
                        
                         if (response.status === 200) {
                                      
                                        instance.users=response.data.users;    
                                        deferred.resolve({data: response.data, msg:response.data.msg, status: response.data.status});
                                  
                                } else {
                                        deferred.reject({data:response.data,msg:response.data.msg,status:response.status});
                                      }
                        
                });
            
                   return deferred.promise;
        };
    //**********************************************************
    //           reset pass request User
        //**********************************************************      
        ResetPassword (user_id) {

                let url='../api/reset-password-request/'+user_id+'/';
                var deferred = this.$q.defer();
                var instance = this;
                this.RestApiService.get(url).then(response =>{
                        
                         if (response.status === 200) {
                                        
                                        deferred.resolve({data: response.data, status: response.data.status});
                                  
                                } else {
                                        deferred.reject({data:response.data,status:response.status});
                                      }
               
                });
            
                   return deferred.promise;
        };

       CheckResetRequest = function(){
                
                let url = '../api/check-change-password/';
                let deferred = this.$q.defer()
                this.RestApiService.post(url,{'signupkey':$scope.signupkey}).then(response =>{
                        
                        if (response.status === 200) {
                                        
                            deferred.resolve({data: response.data, status: response.data.status});
                                  
                        } else {
                            deferred.reject({data:response.data,status:response.status});
                        }

                       
                });
				
				return deferred.promise;           
      
        };
		
		JoinInvitaton = function(post_data){
                
                let url = '../api/join-invitation/';
                let deferred = this.$q.defer()
                if (post_data==undefined) post_data={}
                this.RestApiService.post(url,post_data).then(response =>{
                        
                        if (response.status === 200) {
                                        
                            deferred.resolve({msg: response.data, status: response.status});
                                  
                        } else {
                            deferred.reject({msg:response.data,status:response.status});
                        }

                       
                });
                 
                return deferred.promise;
        };
        




}

export {UserService};   
   
class Base64 {
    /* jshint ignore:start */
        constructor()
        {
                this.keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';    
        }
    
  
  
        encode (input) {
            var output = "";
            var chr1, chr2, chr3 = "";
            var enc1, enc2, enc3, enc4 = "";
            var i = 0;
  
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
  
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
  
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
  
                output = output +
                    this.keyStr.charAt(enc1) +
                    this.keyStr.charAt(enc2) +
                    this.keyStr.charAt(enc3) +
                    this.keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);
  
            return output;
        }
  
        decode (input) {
            var output = "";
            var chr1, chr2, chr3 = "";
            var enc1, enc2, enc3, enc4 = "";
            var i = 0;
  
            // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
            var base64test = /[^A-Za-z0-9\+\/\=]/g;
            if (base64test.exec(input)) {
                window.alert("There were invalid base64 characters in the input text.\n" +
                    "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
                    "Expect errors in decoding.");
            }
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
  
            do {
                enc1 = this.keyStr.indexOf(input.charAt(i++));
                enc2 = this.keyStr.indexOf(input.charAt(i++));
                enc3 = this.keyStr.indexOf(input.charAt(i++));
                enc4 = this.keyStr.indexOf(input.charAt(i++));
  
                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;
  
                output = output + String.fromCharCode(chr1);
  
                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }
  
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
  
            } while (i < input.length);
  
            return output;
        }
}

//export Base64;

/* jshint ignore:end */
