//**************************************************************************************************
//*************************************** MessagingService FACTORY ****************************
//**************************************************************************************************

'use strict';

class MessagingService  {
 
       constructor ($injector){ //replace $timeout with setTImeout after move to vuejs
              this.messages = [];
              this.message = {};
              this.hiddenMessages=[];
              this.show_messages = false;
              this.$injector = $injector;
       }
      
      static $inject = ['$injector'];
       
     addMessage  (msg,type) 
     {       
            //debugger //MessagingService
            var count=0;
            this.message = {};
            if (this.messages.length < 100) {
                
                this.message.message = msg;
                if (typeof msg=="string")   this.message.message = {'message':msg};   
                if (typeof msg=="object")   this.message.message = {'message':JSON.stringify(msg)};     
                    
                this.message.show=true;
                this.message.type=type;
                this.message.src='unknown';
                this.message.dt=new Date().toLocaleString();
                this.messages.unshift(this.message);
                this.show_messages = true;
                this.messages[0].count = this.messages.length
                count =  this.messages.length
                
                var $timeout = $timeout || this.$injector.get('$timeout');
                var instance = this;
                if (this.message.type!='error') 
                    $timeout(function(){
                          for (var i =0;i<instance.messages.length;i++) {
                            if (instance.messages[i].count == count)     {
                              instance.hiddenMessages.push(angular.copy(instance.messages[i]));
                              instance.messages.splice(i,1);
                              break;
                            }   
                          }
                 
                    },5000);
              
            }
        };
 
     addMessageFromCustom (msg,stack,type,src) {
            //debugger
            var count=0;
            this.message = {};
            var instance = this;
            if (this.messages.length<100) {
                     
                     this.message.message = msg;
                     if (typeof msg=="string")  this.message.message = {'message':msg};  
                     if (typeof msg=="object") this.message.message = {'message':JSON.stringify(msg)};
                  
                     this.message.stack = stack; 
                     if (typeof stack=="string") this.message.message = {'stack':stack};
                     if (typeof stack=="object") this.message.message = {'stack':JSON.stringify(stack)};
                                    
                     this.message.show=true;
                     this.message.type=type;
                     this.message.src=src;
                     this.message.dt=new Date().toLocaleString();
                     this.messages.unshift(this.message);
                     this.show_messages = true;
                     this.messages[0].count = this.messages.length
                     count = this.messages.length
                     
                     var $timeout = $timeout || this.$injector.get('$timeout');
                     if (this.message.type!='error')  
                     
                         $timeout(function(){
                             for (var i =0;i<instance.messages.length;i++) {
                               if (instance.messages[i].count == count)    {
                                 instance.hiddenMessages.push(angular.copy(instance.messages[i]));
                                 instance.messages.splice(i,1);
                                 break;
                               }
                               
                             }
             
                        },5000);
            }
     }
     
     checkForVisibleMessages  ()
     {
           var visibility=0;
           for(var i=0;i<this.messages.length;i++)  { 
            if (this.messages[i].show) visibility++;
           }

        return visibility;
      
    };
            
        getMessages = function () {
           return this.messages;
        };
        
          hideMessages = function () {
           for (var i=0;i<this.messages.length;i++){
            this.messages[i].show = false;
           }
           
                  
           this.show_messages = false;
 
        };
        
         removeAllMessages = function () 
         {
         
              var instance = this;
              var $timeout = $timeout || this.$injector.get('$timeout');
              $timeout(function(){
                     for (var i=0;i<instance.messages.length;i++)  {
                        instance.hiddenMessages.push(angular.copy(instance.messages[i]));
                     }   
                     
                     instance.messages.length=0;
                     instance.show_messages = false;
          });
 
        };
        
        removeMessage = function (id) 
        {
         
          var instance = this;   
          $timeout(function(){
              this.hiddenMessages.push(angular.copy(instance.messages[id]));
              instance.messages.splice(id,1);
          });
         
        };
      
      getMessageVisibility = function () 
      { 
           return this.show_messages;
         
        };
      
      
      clearAllMessages = function (msg,type)
      {
         
            this.messages.length = 0;
              
        }
 }
 
 export default MessagingService;