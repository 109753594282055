//**************************************************************************************************
//*************************************** GridsterService ****************************
//**************************************************************************************************

'use strict';

class GridsterService{



  constructor($q,$rootScope,MessagingService,KatalyoStateManager,dragulaService,$timeout)
  {
    var me=this
    this.deleteElement = this.deleteElement.bind(this);
     this.setConfirmDeleteElement = this.setConfirmDeleteElement.bind(this);
      this.getConfirmDeleteElement = this.getConfirmDeleteElement.bind(this);
       this.changeConfirmDeleteElement = this.changeConfirmDeleteElement.bind(this);
 
 
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.MessagingService = MessagingService;
    this.dragulaService = dragulaService; 
    this.ksm = KatalyoStateManager;
    this.$timeout = $timeout;
    this.showFooter=false;
    this.formLocked=false;
    this.formViewType=1;
    this.selectedField={};
    this.selectedFieldParent={
        countActive: 0
    };
    this.propertiesOpened=false;
    this.btnSelectedField=null;
    this.confirmDeleteElement=false;
    this.navbarHeight=0;
    this.widgetToolboxItems={}
    this.widgetToolboxLoaded={}
    this.parentResource={}
    this.toolboxItems=[];
    this.taskToolboxItems=[];
    this.dragulaContainers={};
    this.taskToolboxLoaded=false;
    this.showOverlay = false;
    this.dragulaSetupCompleted={};
    this.formViewTypeCounterTask = 0;
    this.formViewTypeCounterDs = 0;
    this.taskOpenedInNewPage=false;
    this.openTaskType="current";
    this.bodyBgColor=""
    this.resourceFormVersion={}
    this.copyUuid=false
    this.taskDataInNewPage = {'localVars':null,'taskDefinition':null};
    this.pageToolboxItems=[
                                 {id:1,Label:'Start task widget',Type:'init',ElementType:'init',Facss:'fa fa-rocket',PresentationId:null,Locked:false,PresentationLangId:null,ItemId: null,wItemValue: null,TabIndex:100,col: 0,row: 0,SizeY: 5,SizeX: 12, ItemValue: null,Saved: false,Required: true,Related: null,SearchText:null, Name: 'Start task',CodeId: 0,populateType: null,Status:'Active',parentElementId: null,ErrorMsg:'Enter ',Dbtype:"none"},
                                
                                 {id:2,Label:'Execute task widget',Type:'tlist',ElementType:'tlist',Facss:'fa fa-cog',PresentationId:null,PresentationLangId:null,Locked:false,ItemId: null, ItemValue: null,Saved: false,Required: true,Related: null,SizeX: 6,SearchText: null, Name: 'Widget',CodeId: 0,populateType: null,parentElementId: null,ErrorMsg:'Enter error message',Status:'Active',Dbtype:"none",TabIndex:100},
                                 {id:3,Label:'Header',Type:'header',ElementType:'header',Facss:'fa fa-header',PresentationId:null, PresentationLangId:null,FormType:'',ShowField:true,  UseDefault:false, ItemId: null, sItemValue: null,ItemValue: null,Saved: false,Required: true,Related: null,SearchText: null, Dbtype:"none",Name: 'Header ',CodeId: 0,Status:'Active',ErrorMsg:'Enter'},
                                  
                                 ];
    this.column = {id:null,Order:2,DirectiveName:'ktGridColumn',AngularDirectiveName:'kt-grid-column',HideFromToolbox:true,Label:'Widget',Type:'gcol',ElementType:'gcol',Facss:'fa fa-columns',PresentationId:null,PresentationLangId:null,Locked:false,ItemId: null, ItemValue: null,Saved: false,Required: true,Related: null,SizeX: 24,SearchText: null, Name: 'Layout column',CodeId: 0,PopulateType: null,ParentElementId: null,ErrorMsg:'Enter error message',Dbtype:"none",TabIndex:100,ShowField:true,Status:'Active',layout:[]};
 
    this.row =  {id:null,Order:1,DirectiveName:'ktGridRow',AngularDirectiveName:'kt-grid-row',Label:'Layout container',Type:'row',ElementType:'row',Facss:'fa fa-th-large',PresentationId:null,Locked:false,PresentationLangId:null,ItemId: null,wItemValue: null,TabIndex:100,Col: 0,Row: 0,SizeY: 24,SizeX: 24, ItemValue: null,Saved: false,Required: true,Related: null,SearchText: null, Name: 'Layout container',CodeId: 0,PopulateType: null,ParentElementId: null,ShowField:true,ErrorMsg:'Enter error message',Status:'Active',Dbtype:"none",layout:this.getEmptyColumn()};
                    
                  
  
    this.menu_item_initial = {id:null,Order:0,DirectiveName:'ktMenuItemDropDown',AngularDirectiveName:'kt-menu-item-dropdown',HideFromToolbox:true,Label:'Navbar dropdown',Type:'navbardd',ElementType:'navbardd',Facss:'fa fa-columns',PresentationId:null,PresentationLangId:null,Locked:false,ItemId: null, ItemValue: null,Saved: false,Required: true,Related: null,SizeX: 24,SearchText: null, Name: 'Navbar dropdown',CodeId: 0,PopulateType: 'navbarright',ParentElementId: null,ErrorMsg:'Enter error message',Dbtype:"none",Params:{Icon:'fa fa-cog'},TabIndex:100,ShowField:true,Status:'Active',layout:[]};
   
   
 
     
  
  
    
  }
  static $inject = ['$q','$rootScope','MessagingService','KatalyoStateManager','dragulaService','$timeout'];


	showFilter =  (sourceScope) =>  {
					let formdatalength = Object.keys(sourceScope.formData[0]).length
					let gridType= sourceScope.gridType //$($0).scope().gridType : 'dataset'
					let sf = sourceScope.searchFilter //searchFilter:= {    "44988": {}}
					return (sourceScope.showSearchFilters || sourceScope.formParams.formViewType==1)
	}
				
 
    copyToClipboard(text){
      
      this.copyUuid = true;
      let instance = this
      navigator.clipboard.writeText(text).then( 
          () => {
              instance.$timeout(function(){instance.copyUuid = false;},1000);
          },
          () => {    /* clipboard write failed */
              instance.MessagingService.addMessage('Copy to clipboard failed','error');
              instance.$timeout(function(){instance.copyUuid = false;},1000);
      })
                                
    } 
    setBodyBgColor(color)
   {
      this.bodyBgColor = 'background-color:'+color+' !important;';
   };  
    resetBodyBgColor()
   {
      this.bodyBgColor = '';
   };


	
				
   showHideFooter (val)
   {
      this.showFooter = val;
   };
 
   setOverlay (val)
   {
      this.showOverlay = val;
   };
   
     getOverlay ()
   {
    return this.showOverlay;
   };
   setNavbarHeight (val)
   {
    this.navbarHeight=val;  
   };
   getPaddingForBody ()
   {
    return {'padding-top':(this.navbarHeight-8)+'px'}; 
   };
    getTopForSticky (offset,important)
    
   {
    var style = (this.navbarHeight+offset)+'px';
    if (important) style = style + ' !important';
    return {'top':style};
  
   };
    calcTopForExtendedProperties(element)
    {
      
      var offsetTop = element.offsetTop;
      
      return {'top':(offsetTop)+'px'}; 
      
    };
  
    
   
  getEmptyColumn (){
      
     var emptyColumn = [angular.copy(this.column)];
     emptyColumn[0].id=1;
     return emptyColumn;
  };
  
   
    getOpenTaskInNewPage ()
   {
     return this.taskOpenedInNewPage; 
   };
    setOpenTaskInNewPage (taskOpened)
   {
    this.taskOpenedInNewPage=taskOpened;
    if (!taskOpened) this.openTaskType="";
   };
   
    getOpenTaskType ()
   {
     return this.openTaskType; 
   };
    setOpenTaskType (openTaskType)
   {
    this.openTaskType=openTaskType;  
   };
   getTaskDataInNewPage (){

    return this.taskDataInNewPage;  
   };
   setTaskDataInNewPage (taskDataOpened){

    this.taskDataInNewPage=taskDataOpened;  
   };
   getToolboxTtems ()
   {
    return this.toolboxItems;  
   };
  
    setToolboxItems (widgets,toolboxType)

   {
    if (!toolboxType) toolboxType='datasetToolBox'
    this.toolboxItems = widgets;
    let widget = {id:null}
    if (widgets.length>0) widget = widgets[0]
    //get row and column
    this.ksm.executeAction(toolboxType,"toolboxLoaded",{widget_id:widget.id,state:true});  
   };
    getTaskToolboxTtems ()
   {
    return this.taskToolboxItems;  
   };
     getWidgetToolboxItems (resource_type)
   {

    if (this.widgetToolboxItems[resource_type]===undefined) this.widgetToolboxItems[resource_type]=[]

    return this.widgetToolboxItems[resource_type];  
   };
   setWidgetToolboxItems (widgets,resource_type)
   {
    
    let widget = {id:null}
    if (widgets.length>0) widget = widgets[0]
    this.widgetToolboxItems[resource_type] = widgets;
    this.ksm.executeAction(resource_type+"ToolBox","toolboxLoaded",{widget_id:widget.id,state:true});  
   };

   setTaskToolboxItems (widgets)
   {
    this.taskToolboxItems = widgets;
    let widget = {id:null}
    if (widgets.length>0) widget = widgets[0]
    this.ksm.executeAction("taskToolBox","toolboxLoaded",{widget_id:widget.id,state:true});  
   };
   getToolboxTemplate ()
   {
    //debugger
    return this.toolboxTemplate;  
   };
    getTaskToolboxTemplate ()
   {
    return this.taskToolboxTemplate;  
   };
  
  /*                 
  RegisterDatasetWidget  (widget)
  {
    var pos = this.toolboxItems.map(function(e) { return e.ElementType; }).indexOf(widget.ElementType);
    var insert = false;
    if (pos<0)
    {
      widget.id=this.toolboxItems.length+1;
      
      for (var j=0;j<this.toolboxItems.length;j++)
      {
      if (widget.Order<this.toolboxItems[j].Order)
      {
        this.toolboxItems.splice(j,0,widget);
        insert = true;
        break;
        }
      }
      if (!insert)
      
      {
       
        this.toolboxItems.push(widget);
        this.ksm.executeAction("datasetToolBox","toolboxLoaded",{widget_id:widget.id,state:true});
      }
    }
    else
    {
      this.MessagingService.addMessage('Duplicate registration error for dataset widget '+widget.ElementType+' in directive - '+widget.DirectiveName,'error');
    }
    
     
  };
  
    RegisterTaskWidget  (widget)
  {
    var pos = this.taskToolboxItems.map(function(e) { return e.ElementType; }).indexOf(widget.ElementType);
    var insert = false;
    if (pos<0)
    {
      widget.id=this.taskToolboxItems.length+1;
       for (var j=0;j<this.taskToolboxItems.length;j++)
      {
      if (widget.Order<this.taskToolboxItems[j].Order)
      {
        this.taskToolboxItems.splice(j,0,widget);
        insert = true;
        break;
        }
      }
      if (!insert)
      {
        this.taskToolboxItems.push(widget);
        this.ksm.executeAction("taskToolBox","toolboxLoaded",{widget_id:widget.id,state:true});
      }
    }
    else
    {
      this.MessagingService.addMessage('Duplicate registration error for task widget '+widget.ElementType+' in directive - '+widget.DirectiveName,'error');
    }
    
  };
  */
    GetElementPropertiesTemplate  (ElementType)
  {
     var pos = this.toolboxItems.map(function(e) { return e.ElementType; }).indexOf(ElementType);
     if (pos<0)
    {
       this.MessagingService.addMessage('Element properties error - '+ElementType+' not found!','error');
       return '';
    }else{
      
      return this.toolboxItems[pos].DirectiveName;
    }
  };
  
  
  getFormLockStatus (){
      return this.formLocked;
  }
   setFormLockStatus (iFormLockStatus){
      this.formLocked=iFormLockStatus;
  }
  
  getEmptyRow (){
      
     var emptyRow =  [
					this.getToolboxItemsById(0)];
     return emptyRow;
  }

getEmptyRowWithId (formLayout){
      
     var emptyRow = angular.copy(this.row);
         emptyRow.id = this.getNewElementId(formLayout)+1;
         emptyRow.layout =  [angular.copy(this.getEmptyColumnWithId(formLayout))];
     return emptyRow;
  }
 
  
  getEmptyColumnWithId (formLayout){
      
     var emptyColumn = angular.copy(this.column)
         emptyColumn.id = this.getNewElementId(formLayout)+1;
     return emptyColumn;
  }
 
  
  getEmptyDatasetForm (){
     
     var row =  angular.copy(this.row);
     row.id=1;
     var emptyDatasetForm = [row];
         emptyDatasetForm[0].layout =  angular.copy(this.getColumnWithDatasetId());
     return emptyDatasetForm;
  }
  
  getEmptyTaskForm (){
      
     var emptyDatasetForm = [angular.copy(this.row)];
     emptyDatasetForm[0].id=1;
     emptyDatasetForm[0].layout =  angular.copy(this.getEmptyColumn());
     return emptyDatasetForm;
  }
  
   getEmptyApplicationMenu (){
     
     var row =  angular.copy(this.row);
     row.id=1;
     var emptyApplicationMenu = [row];
         emptyApplicationMenu[0].layout =  [angular.copy(this.getInitialMenu())];
     return emptyApplicationMenu;
  }                               

  getColumnWithDatasetId (){
      
     let column = angular.copy(this.getEmptyColumn());
     //var resource_id = angular.copy(this.toolboxTemplate[2]);
     //resource_id.id=column[0].layout.length+1;
     //column[0].layout = [resource_id];
     return column;
  }
  
    getColumnWithInitialMenu (){
      
     //var column = angular.copy(this.getEmptyColumn());
     let menu_item = angular.copy(this.menu_item_initial);
     //menu_item.id=column[0].layout.length+1;
     menu_item.id=1;
     //column[0].layout = [menu_item];
     return menu_item;
  }
  
  getInitialMenu (){
      
     //var column = angular.copy(this.getEmptyColumn());
     let menu_item = {s_menus_MenuType:2,s_menus_Name:'Settings',name:'Settings',s_menus_Active:true,Params:{NoSideMenu:true},s_menus_Icon:'fa fa-cog'};
     //menu_item.id=column[0].layout.length+1;
     //menu_item.id=1;
     //column[0].layout = [menu_item];
     return menu_item;
  }

   getNewElementId (formLayout){
    
      var tmp_max=0,max=0;
      for (var i=0;i< formLayout.length;i++)
      {
        if ("layout" in formLayout[i])
        {
          if (formLayout[i].layout.length>0){
          
            tmp_max = this.getNewElementId(formLayout[i].layout);
          }
        }
        if ("id" in formLayout[i])
        {
            if(formLayout[i].id>tmp_max)
            {
              tmp_max = formLayout[i].id;  
            }            
        }
        if (tmp_max>max)
        {
          max=tmp_max;
          
        }
       
      }
      
      //max++;
      
      return max;
  }
 ProcessWidgets (widgets){
      
     var lWidgets = [];
     for (var i=0;i<widgets.length;i++){
      if (widgets[i].ShowField) {
        lWidgets.push(widgets[i]);
      }
     }
     return lWidgets;
  }
 
  
  getToolboxItems (){
  
       
    return this.toolboxItems;
  }
  
  getTaskToolboxItems (){
  
       
    return this.taskToolboxItems;
  }
    getPageToolboxItems (){
  
       
    return this.pageToolboxItems;
  }
    
  getTaskToolboxItemsById (id){
  
       
    return this.taskToolboxItems[id];
  }
  
  getToolboxItemsById (id){
  
       
    return this.toolboxItems[id];
  }
  
  getTaskToolboxItems (){
               
   
    return this.taskToolboxItems;
  }
 
 
getFieldPropertiesOpened (){
  
       
    return this.propertiesOpened;
  }
setFieldPropertiesOpened (value){
  
       
   this.propertiesOpened=value;
   
  } 
 getFieldProperties  (){
  
       
    return this.selectedField;
  }

   getFieldParent  (){
  
       
    return this.selectedFieldParent;
  }
  
   getSelectedItemId  (){
  
       
    return this.selectedField.ItemId;
  }
  
   getSelectedFd  (){
  
       
    return this.selectedField;
  }
 
 setFieldProperties1 (field,parent){ 
   
   //this.selectedField = null;

  
    this.propertiesOpened=true;
    this.selectedField=field;
	
    this.selectedFieldParent = parent;
    if (parent == undefined) 
        return null  // parent is only applicable to ROW, otherwise we dont care
    if ('layout' in this.selectedFieldParent)
        this.selectedFieldParent.countActive  =  this.selectedFieldParent.layout.reduce(function (result, item) {
              return result + (item.Status=='Active')
            }, 0);       
  }

 setFieldProperties (field,parent){ 
   
   
   if (this.selectedField == field && this.propertiesOpened && this.selectedFieldParent == parent)
        return // return if already opened
   
   this.selectedFieldParent = parent;
   var instance = this;
   this.propertiesOpened=false;   
   this.$timeout(function () {
     instance.propertiesOpened=true;
    instance.selectedField=field;
    //debugger //setFieldProperties
    instance.selectedFieldParent = parent;
    if (parent == undefined) 
        return null  // parent is only applicable to ROW, otherwise we dont care
    if ('layout' in instance.selectedFieldParent)
        instance.selectedFieldParent.countActive  =  instance.selectedFieldParent.layout.reduce(function (result, item) {
              return result + (item.Status=='Active')
            }, 0);       
  
   })
  }
 
 getFormViewType  (){
  
       
    return this.formViewType;
  }

   setFormViewType  (formViewType){
  
       
    this.formViewType=formViewType;
    if (formViewType == 4)
    {
      this.formViewTypeCounterTask=0;
      this.formViewTypeCounterDs=0;
    }
  };

 

  setResourceFormVersion  (id,version){
  
    if (this.resourceFormVersion[id]===undefined) this.resourceFormVersion[id]={}   
    if (version.Version!=undefined) this.resourceFormVersion[id].Version=version.Version
    if (version.NewVersion!=undefined) this.resourceFormVersion[id].NewVersion=version.NewVersion
    if (version.Published!=undefined) this.resourceFormVersion[id].Published=version.Published
         
   
  };
  
  setTaskFormVersion  (id,version){
  
    this.setResourceFormVersion(id,version)
         
   
  };

  getResourceFormVersion  (id){
  
       
    return this.resourceFormVersion[id]
   
  };
  
  addFormViewTypeCounterTask  (val){
      this.formViewTypeCounterTask+=val;
  };

  
  addFormViewTypeCounterDs  (val){
      this.formViewTypeCounterDs+=val;
  };  

 changeConfirmDeleteElement (){
  
       
    this.confirmDeleteElement=!this.confirmDeleteElement;
 };
 
 getConfirmDeleteElement (){
  
       
    return this.confirmDeleteElement;
 };

 setConfirmDeleteElement (value){
  
       
    this.confirmDeleteElement=value;
 };
 
  
 deleteElement  (item){  // ITEM can be used in future to count deleted elements

                        if (false == ('layout' in item) ){ // element is  not container
                          this.selectedField.Status="Deleted";
                          this.selectedField={};
                          this.propertiesOpened = false;
                          this.confirmDeleteElement=false;
                          this.selectedFieldParent.countActive--;
                        }
                        else  { //container elements
                           if ( (this.selectedField.ElementType=='tabs' || this.selectedField.ElementType=='row')   && this.selectedFieldParent.countActive==1) {
                                    this.selectedField.Status="Deleted";
                                    this.selectedField={};
                                    this.propertiesOpened = false;
                                    this.confirmDeleteElement=false;
                                    this.selectedFieldParent.countActive--;
                           } else if (this.selectedField.ElementType=='gcol' && this.selectedFieldParent.countActive==1) {
                               this.MessagingService.addMessage('Cannot delete last column in row, delete row instead !','error')
                           } else  if (this.selectedFieldParent.countActive > 1) { 
                                    this.selectedField.Status="Deleted";
                                    this.selectedField={};
                                    this.propertiesOpened = false;
                                    this.confirmDeleteElement=false;
                                    this.selectedFieldParent.countActive--;
                         } else 
                                this.MessagingService.addMessage('Delete failed, element is not empty!','error');
                        }

  };
 
  SetTaskToolboxLoaded (val)
   {
    this.taskToolboxLoaded=val; 
   
   };

   SetWidgetToolboxLoaded (val,resource_type)
   {
    this.widgetToolboxLoaded[resource_type]=val; 
   
   };
   
     GetTaskToolboxLoaded ()
   {
    return this.taskToolboxLoaded; 
   
   };
 
 //**********************************************************
 //          Drag And Drop dragular - not used
 //************************************************************
 /*
 this.setupDragular (model,container,namespace,handleClass,revOnSpill)
 
 {
              
                
                                  $timeout(function(){
                                   
                                     dragularService(container,
                                      {
                                      nameSpace: namespace,
                                      //copy: true,
                                      classes: {
                                      //mirror: 'show-border'
                                          },
                                      revertOnSpill: revOnSpill,
                                      containersModel: model,
                                      //direction:'vertical',
                                   
                                        moves: function (el, container, handle) {
                                         
                                          return handle.classList.contains(handleClass);
                                        }
                                 
                                       }
                                       );
                                     }
                                   );
  
  }
*/
 
 //************************************************************
 //        destroy dragula
 //************************************************************
 destroyDragula (dragula_bag)
{
    var bag = this.dragulaService.find(this.$rootScope, dragula_bag);
     if (bag) {
     // var drake = bag.drake;
     // drake.containers.length = 0;
     // drake.models.length = 0;
     this.dragulaService.destroy(this.$rootScope, dragula_bag);         
      }
};
 //************************************************************
 //        initial dragula setup
 //************************************************************
 setupDragula (dragula_bag,source_container,drag_handle)
{
 // if (!this.dragulaSetupCompleted[dragula_bag])
 // {
    //destroy dragula if exists
   
      var bag = this.dragulaService.find(this.$rootScope, dragula_bag);
      if (!bag) {
         //   this.dragulaService.destroy(this.$rootScope, dragula_bag)         
     
      
         this.dragulaService.options(this.$rootScope, dragula_bag, {
                           revertOnSpill: true,
                           removeOnSpill: false,
                           copy:function (el,source){
                            
                             return source.id==source_container;
                            },
                           accepts:function (el,target, source) {
                               return target.id!=source_container;
                           },
                           moves: function (el, container, handle) {
                                     return handle.classList.contains(drag_handle);
                           }
         
         });
      }
   //  this.dragulaSetupCompleted[dragula_bag] = true;   
};

 //**********************************************************
 //        register dragula containers
 //************************************************************
 registerDragulaContainer (taskId,formType,bag,container)
{
  var bag = this.dragulaContainers[bag];
  if (bag==undefined)
  {
   
   
  }
  var containers = this.dragulaContainers[bag][taskId+formType];
  //if (containers ==undefined)
};
 
}
export default GridsterService;