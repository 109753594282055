//**************************************************************************************************
//*************************************** KatalyoStateManager ****************************
//**************************************************************************************************

'use strict';

import ksm from '../0003_libs/kt-state-manager.js';
import api from '../0003_libs/kt-api.js'; 

class RestApiService {

    constructor($q,$cookies ,$location, $rootScope){
           this.api = api();
           this.cache = {};
           this.$cookies = $cookies
           this.$q = $q;
		   this.$location = $location;
		   this.$rootScope=$rootScope;
    }
      
      
    //  static $inject = ['$q','$rootScope','RestApiService'];
    
    add_api_classes(auth_type,csrf_token,auth_data) {
         if (auth_type==='token')  this.api.add_auth_token(csrf_token,auth_data);
         else  this.api.add_auth_sso(csrf_token);  
    }
    
    
    async post(url,post_data) {
    
		let env_from_url = this.$location.search().env 
		
        url+= url.indexOf('?') >= 0 ?  '&' : '?'
        //url+= "env=" + sessionStorage.getItem("environment")
        url+= "env=" + env_from_url 
		
        let tmp=this.$cookies.getObject('environment');
        
        let csrf_token=this.$cookies.get('csrftoken');
        
        if (this.api.KatalyoApiClass.getCsrf()===undefined) this.api.KatalyoApiClass.setCsrf(csrf_token)
        let response = await this.api.KatalyoApiClass.post(url , post_data)
        
        //let response = await this.api.KatalyoApiClass.fake_post("413") // Simulate 413 error message
        let resp2 = null
        let contentType = response?.headers?.get('Content-Type')
        try {
            if (contentType==='text/html' || contentType==='text/plain')   
                resp2 = await response.text()
            else  if (contentType==='application/json')    
                    resp2 = await response.json()
            else return response
                         
        } catch (err) { /*ignore errors*/}
   
        return {   data: resp2,
                       status:response.status
                 }
  
    }
  
    async put(url,put_data) {
         return this.api.KatalyoApiClass.put(url,put_data).then(response=>{
              
             return response;
         })
    }
  
    async get (url, useCache=false, acceptOlderThan=10000) { 
           
         var deferred = this.$q.defer(); 
         var gotoServer = false; 
        
		 let env_ = this.$location.search()?.env  ??  this.$rootScope.globals?.currentEnv //URL env overrides global env 
			
         let tmp=this.$cookies.getObject('environment');
         url+= url.indexOf('?') >= 0 ?  '&' : '?'
         // url+= "env=" + sessionStorage.getItem("environment")
		 url+= "env=" + env_

         let csrf_token=this.$cookies.get('csrftoken');
        
         if (this.api.KatalyoApiClass.getCsrf()===undefined) this.api.KatalyoApiClass.setCsrf(csrf_token)

        //if (this?.cache[url]?.lastDownloadTime) { 
        //    if (Date.now() - this.cache[url].lastDownloadTime > acceptOlderThan)  useCache = false;         
       // } 
                 
        //debugger //async get      
         if (url==undefined || url==null)  { 
             deferred.reject({msg: 'URL is not defined',status:400});   
         }  else  
         { 
            if (useCache) 
            { 
 
               if (this.cache[url] !==undefined) 
               { 
                     if (this.cache[url].downloaded ){ 
                        deferred.resolve({data:  angular.copy(this.cache[url].data),status:200}); 
                     } 
                     else if (this.cache[url].downloadInProgress)  { 
                         //when alreday download in progress return promise of the first connection 
                        return this.cache[url].promises[  this.cache[url].promises.length-1 ]       
                     } 
                } 
                else { 
                    this.cache[url] = {data:[],downloaded:false,downloadInProgress:true}; 
                    gotoServer=true;  
                } 
            }else  
            { 
                this.cache[url] = {data:[],downloaded:false,downloadInProgress:true}; 
                gotoServer=true; 
            } 
         }  
                    
          if (gotoServer) 
          { 
            var instance = this; 
            if (this.cache[url].promises==undefined)     this.cache[url].promises = []; 
                 
            this.cache[url].promises.push(deferred.promise) 
            
            
            this.api.KatalyoApiClass.get(url).then( async response =>{ 
                     let data = null 
                    let contentType = response?.headers?.get('Content-Type') 
                        if (contentType==='text/html' || contentType==='text/plain')    
                             data = await response.text() 
                        else  if (contentType==='application/json')     
                              data = await response.json() 
                    if (response.ok) {             
                                        instance.cache[url].data = data; 
                                        instance.cache[url].downloaded = true; 
                                        instance.cache[url].downloadInProgress=false;      
                                        instance.cache[url].lastDownloadTime = Date.now();                                              
                                        deferred.resolve({data: data,status:response.status}); 
                    } else { //not ok 
                                     instance.cache[url].downloadInProgress=false; 
                                    deferred.resolve({msg:data, status:response.status});         
                    } 
                });     
            
          } 
         return deferred.promise; 

    };
        
  
    async get_file (url) {
          
        var http_method="GET"; 
        var deferred = this.$q.defer();
        var instance = this;
        url+= url.indexOf('?') >= 0 ?  '&' : '?'
        url+= "env=" + sessionStorage.getItem("environment")

        let csrf_token=this.$cookies.get('csrftoken');
        
        if (this.api.KatalyoApiClass.getCsrf()===undefined) this.api.KatalyoApiClass.setCsrf(csrf_token)

        this.api.KatalyoApiClass.get(url).then(response =>{
                    if (response.status === 200) {            
                            response.blob().then( data => {
                                   
                                                                          
                                        deferred.resolve({data: data,status:response.status});
                            })
                            
                      
                    } else { 
                        response.json().then( data => {
                                   
                                    deferred.reject({msg:data,status:response.status});
                            })                  
                           
                    }
                });          
          
         return deferred.promise;
    };
        
  
  
  
  
    async send(url,method,post_data) {
    
        let env_ = this.$location.search()?.env  ??  this.$rootScope.globals?.currentEnv //URL env overrides global env 
            
        let tmp=this.$cookies.getObject('environment');
        url+= url.indexOf('?') >= 0 ?  '&' : '?'
        // url+= "env=" + sessionStorage.getItem("environment")
        url+= "env=" + env_

        let csrf_token=this.$cookies.get('csrftoken');
        
        if (this.api.KatalyoApiClass.getCsrf()===undefined) this.api.KatalyoApiClass.setCsrf(csrf_token)

        return this.api.KatalyoApiClass.send(url,method,post_data).then(response=>{
              
             return response;
        
         })
      }
      
      
    set_header(header,header_value) {
         this.api.KatalyoApiClass.set_header(header,header_value); 
    }
      
    send(url,method,post_data){
        
       
        let env_ = this.$location.search()?.env  ??  this.$rootScope.globals?.currentEnv //URL env overrides global env 
            
        let tmp=this.$cookies.getObject('environment');
        url+= url.indexOf('?') >= 0 ?  '&' : '?'
        // url+= "env=" + sessionStorage.getItem("environment")
        url+= "env=" + env_

        let csrf_token=this.$cookies.get('csrftoken');
        
        if (this.api.KatalyoApiClass.getCsrf()===undefined) this.api.KatalyoApiClass.setCsrf(csrf_token)

            
        return this.api.KatalyoApiClass.send(url,method,post_data); 
    }
     
}
 export default RestApiService;