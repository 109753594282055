'use strict';
/*
export default class exceptionHandler{

    constructor ($delegate,MessagingService,exception,cause)
    {
       
        this.MessagingService = MessagingService;
        this.$delegate = $delegate;
        this.message = '';
        this.stack='';
        
    }
    
    static $inject = ['$delegate','MessagingService','exception','cause'];
    
    static ProcessDecorator(exception,cause)
    {
        if (typeof(exception)!='string')
            {
                if ('message' in exception){
                this.message = exception.message + ' (caused by ' + cause + ')';
                }
                else
                {
                  message='caused by ' + cause; 
                }
                
                if ('stack' in exception){
                this.stack = exception.stack + ' (caused by ' + cause + ')';
                }
          
            }
            else
            {
                this.message=exception+' caused by ' + cause;
               
            }
            
            this.$delegate(exception, cause);
             
            this.MessagingService.addMessageFromCustom(this.message,this.stack,'error','frontend');
            
            return new exceptionHandler($delegate,MessagingService,exception,cause);
    }

//.decorator('$exceptionHandler',['$delegate','MessagingService',function($delegate,MessagingService) {
 
  

};
*/
export default function exceptionHandler($delegate,MessagingService){

    return function (exception,cause)
    {

        var message = '';
        var stack='';
        if (typeof(exception)!='string')
            {
                if ('message' in exception){
                message = exception.message + ' (caused by ' + cause + ')';
                }
                else
                {
                  message='caused by ' + cause; 
                }
                
                if ('stack' in exception){
                stack = exception.stack + ' (caused by ' + cause + ')';
                }
          
            }
            else
            {
                message=exception+' caused by ' + cause;
               
            }
            
            $delegate(exception, cause);
             
            MessagingService.addMessageFromCustom(message,stack,'error','frontend');
            
    }
};
//.decorator('$exceptionHandler',['$delegate','MessagingService',function($delegate,MessagingService) {

exceptionHandler.$inject = ['$delegate','MessagingService'];
  

