//**************************************************************************************************
//*************************************** ProcessingService ****************************
//**************************************************************************************************

'use strict';

class ProcessingService{

  constructor()
  {
    this.showProcessing =false;
    this.activityMessage = "";
        
  }
     showActivity   (msg) {
          return  setTimeout(function () {
            
            this.activityMessage = msg;
            this.showProcessing=true;
            return this.showProcessing;
              
             });
         
        };
        
        showActivityNoMsg  () {
          return  setTimeout(function () {
            
            this.showProcessing=true;
            return this.showProcessing;
              
             });
         
        };
        
        setActivityMsg  (msg) {
        
            this.activityMessage = msg;
         
            return this.activityMessage;
            //this.message.showProcessing = show;
              
             };
         
       
        hideActivity () {
           return setTimeout(function () {
                this.activityMessage = '';
                this.showProcessing=false;
                return this.showProcessing;
                
            }, 1000); // message will be visible at least 1 sec
        };
}
 export default ProcessingService;