//**************************************************************************************************
//*************************************** KatalyoStateManager ****************************
//**************************************************************************************************

'use strict';

import ksm from '../0003_libs/kt-state-manager.js'; 

class KatalyoStateManagerService{

  constructor()
  {
     
       this.smCollection = ksm();
  }

  addSubject (name,state_object)
  {
    let subject = this.smCollection.createSubject(name,state_object);
    //var status = service.smCollection.addSubject(subject);
    return subject;
  };
  
   getState (name)
  {
    let state = this.smCollection.getState(name);
    //var status = service.smCollection.addSubject(subject);
    return state;
  };
  
  addAction (subjectName,actionName,fnToCall)
  {
    let action = this.smCollection.addAction(subjectName,actionName,fnToCall);
    //var status = service.smCollection.addSubject(subject);
    return action;
  };
 
  getSubject (subjectName)
  {
    
    
    let subject = this.smCollection.getSubject(subjectName);
    
    return subject;
  };
  
  executeAction (subjectName,actionName,data)
  {
    
    let action;
    let subject = this.smCollection.getSubject(subjectName);
    
    if (subject!==undefined)
    {
       action = subject.executeAction(actionName,data);
    }
   
    //var status = service.smCollection.addSubject(subject);
    return action;
  };
  subscribe (subjectName,observerName,actionName,data,override)
  {
    
    let action,observer;
    let subject = this.smCollection.getSubject(subjectName);
    
    if (subject!==undefined)
    {
       observer = subject.subscribe(observerName,actionName,data,override);
    }else
    {
     let subscription = {'subject':subjectName,'observer':observerName,'action':actionName,'data':data,'override':override};
     let pending = this.smCollection.addPendingObserverSubscription(subscription);
     return pending;
    }
   
    //var status = service.smCollection.addSubject(subject);
    return observer;
  }; 

  subscribeWithParams (subjectName,observerName,actionName,data,override,inputParams)
  {
    
    let action,observer;
    let subject = this.smCollection.getSubject(subjectName);
    
    if (subject!==undefined)
    {
       observer = subject.subscribe(observerName,actionName,data,override,inputParams);
    }else
    {
     let subscription = {'subject':subjectName,'observer':observerName,'action':actionName,'data':data,'inputParams':inputParams,'override':override};
     let pending = this.smCollection.addPendingObserverSubscription(subscription);
     return pending;
    }
   
    //var status = service.smCollection.addSubject(subject);
    return observer;
  }; 
  undo_redo (subjectName,actionType)
  {
    let currentIndex = -1;
    let subject = this.smCollection.getSubject(subjectName);
    if (subject!==undefined)
    {
      if (actionType === 'undo') currentIndex= subject.undo();
      if (actionType === 'redo') currentIndex= subject.redo();
    }
    return currentIndex;
  };
}
 export default KatalyoStateManagerService;