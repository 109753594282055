'use strict';
/*import authJson from './auth.json' assert {type: 'json'};*/
import indexJson from './../../0001_index/angularJs/index.json' //with {type: 'json'};

export function changePasswordCtrl ($scope, $rootScope, $state,$cookies,$timeout,AuthenticationService,MenuService,$stateParams,$location) {
       
    $scope.CTRLNAME='changePasswordCtrl';
    let touched=[false,false]
    AuthenticationService.ClearCredentials();
    $scope.signupkey = $stateParams.digest

    $scope.cp={username : "", password1 : "",password2 : ""}
    
    let lang = $location.search().lang
    if (lang===undefined) lang ='en-GB'
    
    $scope.pageLabels = indexJson[lang]

    $scope.CheckChangeRequest = function(){
                        
                AuthenticationService.CheckPassChangeRequest($scope.signupkey,function(response) {
                      $timeout(function(){
                       if (response.status === 200) {
                                        
                          $scope.checkDigestOk = true
                          $scope.userId = response.data.user_id
                        }else
                        {
                          $scope.checkDigestOk = false
                          
                        }
                        $scope.checkDone = true
                      })
                });
                 
      
        };

    $scope.CheckChangeRequest()

     $scope.checkUsernameValidity=function() { 
           
            if ($scope.cp.username==='' || $scope.cp.username===undefined) $scope.usernameInvalid=true
            else $scope.usernameInvalid=false
        }

     $scope.hideError=function() { 
           
            $scope.errorMsg=""
        }

    $scope.checkPasswordValidity1=function() { 
             
            if ($scope.cp.password1==='' || $scope.cp.password1===undefined) $scope.passwordInvalid1=true
            else $scope.passwordInvalid1=false
        }
      $scope.checkPasswordValidity2=function() { 
             //touched[i]=true       
             //if (this.password[i]=='' || this.password[i]==undefined || this.password[i]==null) this.passwordInvalid[i]= true;
             //else this.passwordInvalid[i]=false;
             //if (!this.password[i] && touched[i]==true
             //passwords must match
             if ($scope.cp.password1!==$scope.cp.password2) $scope.passwordInvalid2=true
              else $scope.passwordInvalid2=false
        }


        $scope.ResetPassword=function(username,userId,password1,password2,digest) {
          
          $scope.dataLoading = true;

          AuthenticationService.PswReset(username,userId,password1,password2,digest, function(response) {
            
            $timeout(function(){

                if(response.success) {

                    $scope.passwordChangeSuccess=true
                    $scope.successMsg = response.message;
       
                    }
                    else {
                         
                      $scope.errorMsg = response.message;
                      $scope.passwordChangeSuccess=false
                      
                    }

                  $scope.dataLoading = false;


            })
          });

        }
        
         
  }

changePasswordCtrl.$inject=['$scope', '$rootScope', '$state','$cookies','$timeout','AuthenticationService','MenuService','$stateParams','$location'];


  export function changePasswordRequestCtrl ($scope, $rootScope, $state,$cookies,$timeout,AuthenticationService,MenuService) {
       
    $scope.CTRLNAME='changePasswordRequestCtrl';
    AuthenticationService.ClearCredentials();
    let vueLogin;
    
    
    function loadLogin()
    {
    vueLogin = Vue.createApp({
        data(){
          
          return {
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            signupSuccess: false,
            errorMsg: '',
            dataLoading: false,
            emailInvalid:false,
            email:''
          }
        },
      
        methods: {
           isEmailValid() {
              return (this.email == "")? "" : (this.reg.test(this.email)) ? 'is-valid' : 'is-invalid';
              },
            hideError() {
         
          },
          checkEmailValidity() {
           this.errorMsg=''
           if (this.email=='' || this.email==undefined || this.email==null) this.emailInvalid= true;
           else this.emailInvalid=false; 
          },
          
          
          login() 
          { 
            let vm = this;
            AuthenticationService.RequestPswReset(this.email, function(response) {
              if(response.success) {
                    vm.signupSuccess=true
              } else { 
                    vm.errorMsg = response.message;
                    vm.dataLoading = false;
              }
            });
          } //login
        
      },
      computed: {
          validity: function () {
              return this.reg.test(this.email)
      }
      
      }
    
    })

      vueLogin.mount('#loginForm')
    }
    
    $timeout(loadLogin, 0, false );


      
    }

  changePasswordRequestCtrl.$inject=['$scope', '$rootScope', '$state','$cookies','$timeout','AuthenticationService','MenuService'];


export default function loginCtrl ($scope, $rootScope, $state,$cookies,MessagingService,AuthenticationService,MenuService,$location,$timeout) {
    
    AuthenticationService.ClearCredentials();
    let vueLogin;
	
	let lang = $location.search().lang
    if (lang===undefined) lang ='en-GB'
    $scope.pageLabels = indexJson[lang]
	$scope.CTRL='loginCtrl'
   $scope.config = indexJson['config'] 
    function loadLogin(lang)
    {
		vueLogin = Vue.createApp({
         
      data() {
        return {
          errorMsg: '',
          dataLoading: false,
          usernameInvalid:false,
          passwordInvalid:false,
          firstTime:true,
          username:'',
          password:'',
          query_hash:'' ,
		      pageLabels:  $scope.pageLabels,
          config:$scope.config,
		  dataLoadingText:"<span class='fa fa-hourglass mr-2'></span>" + $scope.pageLabels?.dataLoadingText || "<span class='fa fa-hourglass'></span> Logging in...",
        /* pageLabels:authJson[lang],
        loginMsg: authJson[lang]?.loginMsg || 'Loging in...',
          usernameLabel: authJson[lang]?.username || 'Username',
          passwordLabel: authJson[lang]?.password || 'Password',
          usernameError: authJson[lang]?.usernameError || 'Username is required',
          passwordError: authJson[lang]?.passwordError || 'Password is required',
          loginButton: authJson[lang]?.loginButton || 'Login',
          changePasswordType: authJson[lang]?.changePasswordType || 'external',
          pageHeader: authJson[lang]?.pageHeader || 'Please login',
          dataLoadingText:"<span class='fa fa-hourglass mr-2'></span>"+authJson[lang]?.dataLoadingText || "<span class='fa fa-hourglass'></span> Logging in...",
          loginErrorMsg:authJson[lang]?.loginErrorMsg || 'Login failed'*/
        }
      },
      
      methods: {
        checkUsernameValidity() {
         if (this.username=='' || this.username==undefined || this.username==null) 	this.usernameInvalid= true;
         else this.usernameInvalid=false; 
        },
        checkPasswordValidity() {
         if (!this.firstTime && this.password=='' || this.password==undefined || this.password==null)	 this.passwordInvalid= true;
         else this.passwordInvalid=false;
         this.firstTime=false;
        },
        
        login() {
            
            let vm = this;
            this.firstTime=false;
            this.checkUsernameValidity()
            this.checkPasswordValidity()
            if (this.usernameInvalid || this.passwordInvalid) return;
            

            AuthenticationService.Login(this.username, this.password, function(response) {
            
              if (response.success) { 
			      if (response.data.environments)
						$rootScope.globals.environments = response.data.environments.reduce((acc, obj) => {
										  acc[obj.id.toString()] = obj;
										  return acc;
							}, {});	
							

                  AuthenticationService.SetCredentials(response.data, vm.username,vm.password);
                  vm.dataLoading=true;

                  var lang = $rootScope.globals.currentUser.lang;
                  var default_page = $rootScope.globals.currentUser.uep?.Params?.DefaultPageUri;
                  let params = $rootScope.globals.currentUser.uep?.Params?.DefaultPageParams;
                  let default_app = $rootScope.globals.currentUser.organisation?.DefaultApp
				  
			
                  if (params==undefined) 
						params = Object.assign({}, $state.params);

                  let stateName = 'app.navbar.home'    
                  let return_url = $state.params['return_url']
                  
                  if (default_page!=undefined) stateName=default_page
                  
                  if (return_url?.return_url_name!=undefined)   {
                    stateName = return_url.return_url_name
                    params = return_url
                  }
				 

                  if ( response.data?.uep?.defaultenv ) {                 
                     	 var defaultEnv =  response.data?.uep?.defaultenv.toString()
						 if (!$rootScope.globals?.currentEnv) {
							$rootScope.globals.currentEnv = defaultEnv // following API calls must have global ENV
						 }
						 if  (!$location.search().env ) {
							  $location.search({'env': defaultEnv})	
						 }
                  } else {  //  selectEnvironment if there is no Default user ENV  
						stateName = 'app.selectenvironment'		
						$state.go(stateName, params);
						return 
                  }


                  if (default_app) { //current only  ADRIA has default_App
                        let app_ref = $location.search().app_ref 	??   $rootScope.globals.currentUser?.organisation?.DefaultApp
			
                        AuthenticationService.loadApp("", {'environment': defaultEnv}, app_ref).then(
							function(data){
								let lapp = data?.subscriptions
								//debugger
								AuthenticationService.SetCredentials(data?.user)
							
								if (lapp!=null){
									  AuthenticationService.SetMenusForApp(lapp).then(
										function(data) {
												AuthenticationService.loadFirstPage(stateName,params,lapp,data) //todo - ovdje u params nema trenutni ENV !!
										},function(error2){
											  MessagingService.addMessage(error2,'error'); 
											  $state.go(toState.name,toParams);  
									  });    
								  } else  {
									MessagingService.addMessage('Error loading application id ='+app_ref,'error');
									$state.go(toState.name,toParams);
								  }
							},function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  if (error.status==405)  $state.go('app.navbar.home',{});
                        })
                        
                  } else 
                    {
                        var navbar=MenuService.getNavbarByTargetName(stateName,lang);
                        if (params==undefined) params={'environment': defaultEnv};
                        
                        $state.go(stateName,params);
                    }
              }  else { 			// when response is not success      
                  vm.errorMsg = vm.loginErrorMsg;
                  vm.dataLoading = false;
				    
				   $timeout( ()=> {
					    MessagingService.addMessage(response.message,'error');
				   } )
								  
              }
            });
        }, //login
       
      }
      
    })

      vueLogin.mount('#loginForm')
    }
    
    $timeout(function()
      {
        let lang = $location.search().lang
        if (lang===undefined) lang ='en-GB'

        loadLogin(lang)

      }, 0, false);
  
  }

  loginCtrl.$inject=['$scope', '$rootScope','$state','$cookies','MessagingService','AuthenticationService','MenuService','$location','$timeout'];

export function ssoCtrl ($scope, $rootScope, $state,$cookies,AuthenticationService,MenuService,$location) {
    
    AuthenticationService.ClearCredentials();

      let vueLogin;
    
    
    function loadLogin()
    {
    vueLogin = Vue.createApp({
         
      data() {
        return {
          errorMsg: '',
          dataLoading: false,
  
        }
      },
      
      methods: {
        loginSso() {
          let vm = this;

          vm.dataLoading=true;
          AuthenticationService.LoginSsoFetch(function(response) {
      
            if(response.success) {
                
                AuthenticationService.SetCredentialsSso(response.data, null,null);
                  vm.dataLoading=true;
                 //load menus
                var lang = $rootScope.globals.currentUser.lang;
                var default_page = $rootScope.globals.currentUser.uep?.Params?.DefaultPageUri;
                var params = $rootScope.globals.currentUser.uep?.Params?.DefaultPageParams;
                
                var stateName = 'app.navbar.home';           
                if (default_page!=undefined) stateName=default_page;
                
               if (params==undefined) params={};
                $state.go(stateName,params);
          
            }
            else {
                     
                vm.errorMsg = response.message;
                vm.dataLoading = false;
                }
          });

        }

        },
      mounted(){
      
        this.loginSso();   
      }
      
  })

    vueLogin.mount('#loginForm')
  }
    
    $timeout(loadLogin, 0, false );  
      
}

  ssoCtrl.$inject=['$scope', '$rootScope', '$state','$cookies','AuthenticationService','MenuService','$location'];


export function logoutCtrl ($scope,$rootScope, $location,  $state, GridsterService,AuthenticationService, MenuService,MessagingService,ProcessingService) {

      $scope.CTRL='logoutCtrl';
      $scope.ac={
         alerts : []
         };
      let lang=$rootScope.globals?.currentUser?.lang ?? 'en-GB'
      $scope.pageLabels = indexJson[lang]
      
      $scope.gsrvc = GridsterService
      $scope.gsrvc.resetBodyBgColor() 
      
      $scope.menu_service = MenuService    
      $scope.menu_service.clearSideMenu()
      $scope.config = indexJson['config']
      
      AuthenticationService.Logout(function(response) {
         $scope.activity.activityMessage = ProcessingService.setActivityMsg('Logging out...');
         if (response.success) {
              AuthenticationService.ClearCredentials(); 
              MessagingService.clearAllMessages();
              
              $scope.activity.activityMessage = ProcessingService.setActivityMsg('');
         } else 
             $scope.errorMsg = response.message;
       
      });
    
      $scope.login_page = function() 
        {
            let auth_type = $rootScope.globals?.currentUser?.auth_type;
            if (auth_type==='sso') 
                $state.go('app.sso');
            else 	{
				     $location.search('app_ref', null)
					$location.search('env', null)
					  $location.search('tab', null)
					$state.go('app.login', {app_ref: null, env: null,tab: null}); 

					}
               
		}
};

logoutCtrl.$inject=['$scope', '$rootScope','$location', '$state','GridsterService','AuthenticationService','MenuService','MessagingService','ProcessingService'];


export function selectenvironmentCtrl ($scope,$rootScope, $state, $cookies, AuthenticationService,MenuService,$location,$timeout, UserService, MessagingService,ProcessingService) {

      $scope.CTRL='selectenvironmentCtrl';
      $scope.ac = {  alerts : [] };
      $scope.actionType = $state.params.type
      let lang= $rootScope.globals.currentUser.lang
      $scope.pageLabels = indexJson[lang]
      
      $scope.menu_service = MenuService    
      $scope.menu_service.clearSideMenu()


	$scope.getUserEnvironments = function() // Used in "selectenv" page
	{
		  if (! $rootScope.globals?.environments) { // when ENV changed get from envinfo statistics from server - TODO  move this in loadAPP ??     
                        AuthenticationService.getOrgSchemas($rootScope.globals.currentUser?.organisation.id)
                              .then((response) => {
                                  if (response.data)  $rootScope.globals.environments = response.data.reduce((acc, obj) =>  {
                                          acc[obj.id.toString()] = obj;
                                          return acc;
                                    }, {}); 
                                    return $rootScope.globals?.environments
                              }) 
         } else return $rootScope.globals?.environments
	}
	
	

    
	$scope.goToEnv = function(whereto) 
      {       
        let actions = {  	'actions': { 
					 'set':  		 {  'defaultEnv': whereto,  }
         }}
        
        if ($scope.actionType == 'change') {
			//debugger
            sessionStorage.setItem("environment", whereto.id  )
			$rootScope.globals.currentEnv =  whereto.id	   
            MenuService.resetNavbar()
            $location.search('app_ref', null)
 			$state.go('app.navbar.home',{'app_ref': null, 'env':whereto.id});
        } else {
			UserService.ExecuteActions(actions).then(
				function(data) {      
					MessagingService.addMessage(data.data.msg,'success');           
				},function(error){
					MessagingService.addMessage(error.data.msg,'error');
			})  
          
			var default_page = $rootScope.globals.currentUser.uep?.Params?.DefaultPageUri;
			var params = $rootScope.globals.currentUser.uep?.Params?.DefaultPageParams;
			if (params == undefined)	 params={}

			params['schema'] = whereto.id

			let default_app = $rootScope.globals.currentUser.organisation?.DefaultApp

			var stateName = 'app.navbar.home'   
			if (default_page!=undefined) stateName=default_page  

			sessionStorage.setItem("environment", whereto.id  )
			$rootScope.globals.currentEnv =  whereto.id

			if (default_app) {
                let app_ref = $location.search().app_ref;
                if (app_ref===undefined)   app_ref = $rootScope.globals.currentUser?.organisation?.DefaultApp
                AuthenticationService.loadApp("", {'environment': whereto.id}, app_ref);
			} else 
            {
                var navbar=MenuService.getNavbarByTargetName(stateName,lang);
                if (params==undefined) params={};  
                $state.go(stateName,params);
            }    
        }
      }//goToEnv func//
	  


           
};



