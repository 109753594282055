//**************************************************************************************************
//*************************************** MenuService FACTORY ****************************
//**************************************************************************************************

'use strict';

class MenuService{
   
    
   constructor ($timeout,$q,$rootScope,$cookies,$state,CodesService,ResourcesDataService,MessagingService,UserService,RestApiService) {
   
	  
	//	  
	// testing object changes with Proxy handlers  
	//  
   const handler = { 	  
		set(target, prop, value)  {
			   console.log(`navbarItems changed ${prop} from ${target[prop]} to ${value}`);
				target[prop] = value;
				return true
		  },
		   get(target, key) {
				return target[key];
			  }
	};

	
      this.menuList = {};
      this.menusUser = {};
      this.pageList = {};
      this.navbar = {};
      this.navbarItems = []; 
      //this.navbarItems =  new Proxy( [], handler);	  
      this.sidemenus = {};
      this.currentMenu = {};
      this.currentNavbarId=null;
      this.currentPageId=null;
      this.currentTarget=null;
      this.selectedSideMenuId=null;
      this.selectedApp=null;
      this.currentEnv=null;
      this.appSelected = false;
      this.editMode = false;
      this.loadingMenu = false;
      this.sideMenuLarge=true;
      this.selectedNavbar = null;
      this.selectedSideMenu = null;
      this.navbarSelected = false;
      this.sideMenuSelected = false;
      this.$timeout=$timeout;
      this.$q=$q;
      this.$rootScope =$rootScope; 
      this.CodesService = CodesService;
      this.UserService = UserService;
      this.ResourcesDataService = ResourcesDataService; 
      this.MessagingService = MessagingService; 
      this.$cookies = $cookies;
      this.$state = $state;
      this.RestApiService = RestApiService;
      this.sideMenuVisible=false
      this.sideMenu={}
      this.publicPage=false
      this.DefaultMenu=[]
    }

   static $inject = ['$timeout','$q','$rootScope','$cookies','$state','CodesService','ResourcesDataService','MessagingService','UserService','RestApiService'];
   

   setDefaultMenu()
   {
    this.DefaultMenu = [
        {
            "s_menus_id": 228,
            "s_menus_Name": "Permissions",
            "s_menus_MenuType": 1,
            "s_menus_Params": {
                "pageid": "1_86",
                "pagestate": "app.navbar.appsettings"
            },
            "s_menus_ParentItem": 200,
            "s_menus_Icon": "fas fa-lock",
            "s_menus_Navbar": 200,
            "s_menus_Order": 1,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 16,
            "s_menus_MenuPosition": "sidemenu",
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": "1_86",
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 56,
            "s_pages_Target": "app.navbar.appsettings",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 86,
            "s_pages_PageType_id": 116,
            "s_resourcedef_id": 359,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359",
        },
       
        {
            "s_menus_id": 208,
            "s_menus_Name": "Users",
            "s_menus_MenuType": 1,
            "s_menus_Params": {
                "pageid": "1_86",
                "pagestate": "app.navbar.users"
            },
            "s_menus_ParentItem": 200,
            "s_menus_Icon": "far fa-user",
            "s_menus_Navbar": 200,
            "s_menus_Order": 1,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 56,
            "s_menus_MenuPosition": "sidemenu",
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": "1_86",
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 56,
            "s_pages_Target": "app.navbar.users",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 86,
            "s_pages_PageType_id": 116,
            "s_resourcedef_id": 359,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359",
        },
        {
            "s_menus_id": 213,
            "s_menus_Name": "Codes definition",
            "s_menus_MenuType": 1,
            "s_menus_Params": {
                "pageid": "1_361"
            },
            "s_menus_ParentItem": 212,
            "s_menus_CreatedDateTime": "2022-03-10T14:04:24.681431Z",
            "s_menus_ChangedDateTime": "2022-03-11T17:12:25.523367Z",
            "s_menus_ChangedBy_id": 1,
            "s_menus_CreatedBy_id": 1,
            "s_menus_Icon": "fa fa-globe",
            "s_menus_Navbar": 212,
            "s_menus_Order": 1,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 58,
            "s_menus_MenuPosition": "sidemenu",
            "s_menus_AlwaysNew": null,
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": "1_361",
            "s_menus_VersionFrom": 1,
            "s_menus_VersionTo": 1,
            "s_menus_WorkVersion": 1,
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 58,
            "s_pages_Target": "app.navbar.codes.list",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 361,
            "s_pages_ChangedBy_id": null,
            "s_pages_ChangedDateTime": null,
            "s_pages_CreatedBy_id": null,
            "s_pages_CreatedDateTime": null,
            "s_pages_PageType_id": 116,
            "s_resourcedef_id": 359,
            "s_resourcedef_Status_id": 17,
            "s_resourcedef_Private": false,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_Published": true,
            "s_resourcedef_FormDefined": false,
            "s_resourcedef_ChangedBy_id": null,
            "s_resourcedef_ChangedDateTime": null,
            "s_resourcedef_CreatedBy_id": 1,
            "s_resourcedef_CreatedDateTime": "2022-03-07T09:17:29.389029Z",
            "s_resourcedef_IsBlockchain": null,
            "s_resourcedef_NewVersion": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_Version": 1,
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359"
        },
        {
            "s_menus_id": 230,
            "s_menus_Name": "Home page",
            "s_menus_MenuType": 1,
            "s_menus_Params": {
                "pageid": "1_66",
                "pagestate": "app.navbar.home"
            },
            "s_menus_ParentItem": 230,
            "s_menus_Icon": "fa fa-home",
            "s_menus_Navbar": 230,
            "s_menus_Order": 1,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 2,
            "s_menus_MenuPosition": "navbar",
            "s_menus_AlwaysNew": null,
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": "1_66",
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 2,
            "s_pages_Target": "app.navbar.home",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 66,
            "s_pages_PageType_id": 116,
            "s_resourcedef_id": 359,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_Version": 1,
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359"
        },
       
        {
            "s_menus_id": 207,
            "s_menus_Name": "Groups",
            "s_menus_MenuType": 1,
            "s_menus_Params": {
                "pageid": "1_85",
                "pagestate": "app.navbar.groups"
            },
            "s_menus_ParentItem": 200,
            "s_menus_Icon": "fas fa-users",
            "s_menus_Navbar": 200,
            "s_menus_Order": 2,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 53,
            "s_menus_MenuPosition": "sidemenu",
            "s_menus_AlwaysNew": null,
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": "1_85",
            "s_menus_VersionFrom": 1,
            "s_menus_VersionTo": 1,
            "s_menus_WorkVersion": 1,
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 53,
            "s_pages_Target": "app.navbar.groups",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 85,
            "s_pages_PageType_id": 116,
            "s_resourcedef_id": 359,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359",
        },
        
        {
            "s_menus_id": 210,
            "s_menus_Name": "Codes",
            "s_menus_MenuType": 1,
            "s_menus_Params": {
                "pageid": "1_361",
                "pagestate": "app.navbar.codes.list"
            },
            "s_menus_ParentItem": 200,
            "s_menus_Icon": "fas fa-barcode",
            "s_menus_Navbar": 200,
            "s_menus_Order": 3,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 58,
            "s_menus_MenuPosition": "sidemenu",
            "s_menus_AlwaysNew": null,
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": "1_361",
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 58,
            "s_pages_Target": "app.navbar.codes.list",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 361,
            "s_pages_ChangedBy_id": null,
            "s_pages_ChangedDateTime": null,
            "s_pages_CreatedBy_id": null,
            "s_pages_CreatedDateTime": null,
            "s_pages_PageType_id": 116,
            "s_resourcedef_id": 359,
            "s_resourcedef_Status_id": 17,
            "s_resourcedef_Private": false,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_NewVersion": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359"
        },
        {
            "s_menus_id": 200,
            "s_menus_Name": "Settings",
            "s_menus_MenuType": 2,
            "s_menus_Params": {
                "pageid": null
            },
            "s_menus_ParentItem": 200,
            "s_menus_Icon": "fas fa-cog",
            "s_menus_Navbar": 200,
            "s_menus_Order": 5,
            "s_menus_Active": true,
            "s_menus_TargetPage_id": 21,
            "s_menus_MenuPosition": "navbarright",
            "s_menus_ResourceDefId_id": 359,
            "s_menus_Target": null,
            "s_menus_uid": null,
            "s_menus_guid": null,
            "s_pages_id": 21,
            "s_pages_Target": "app.login",
            "s_pages_Params": null,
            "s_pages_ResourceDefId_id": 65,
            "s_pages_PageType_id": null,
            "s_resourcedef_id": 359,
            "s_resourcedef_Status_id": 17,
            "s_resourcedef_Private": false,
            "s_resourcedef_ResourceType_id": 7,
            "s_resourcedef_Organisation_id": 1,
            "s_resourcedef_Parameters": {},
            "s_resourcedef_Version": 1,
            "s_resourcedef_ResourceSubType": "standard",
            "s_resourcedef_Active": true,
            "s_resourcedef_uid": "1_359"
        }
    ]
   }

   clearDefaultMenu()
   {
        this.DefaultMenu=[]
   }
   
   loadDefaultMenu(over)
   {    
        if (this.navbarItems.length==0 || over) {   
            this.setDefaultMenu()
            if (!this.menuList['0_0_0']?.menus) this.menuList['0_0_0'] = {menus:this.DefaultMenu}
            this.currentMenu = this.menuList['0_0_0'] 
           // this.navbarItems = this.getNavbarsFromMenus(0,0,true,0,0) 
			this.setNavbarItemsWith(this.getNavbarsFromMenus(0,0,true,0,0) )
        }
   }
   
   setNavbarItemsWith(newItems) 
   {
	    this.navbarItems = newItems
   }
   
   setSideMenu(menu_id,menu_version,menus,params)
   {
      if (this.sideMenu.id != menu_id || this.sideMenu.version != menu_version){
        this.sideMenu.id=menu_id
        this.sideMenu.version=menu_version
        this.sideMenu.menus = menus
        this.sideMenu.params = params  
      }
   } 

    clearSideMenu() { 
       this.sideMenu={}
   } 

    setPublicPage(val) { 
       this.publicPage=val
   } 
    resetPublicPage() { 
       this.publicPage=false
   } 
   
    setSideMenuVisibility(visible){
     this.sideMenuVisible=visible  
   }; 
   
   getSideMenuVisibility(){
     return this.sideMenuVisible  
   }; 
    
	toggleSideMenuVisibility() {
     this.sideMenuVisible=!this.sideMenuVisible  
   }; 
 

   getMenuEditMode (){
   return this.editMode;
   }

   getSideMenuSize( ){
   return this.sideMenuLarge; 
   } 
 
   changeSideMenuSize (){
      this.sideMenuLarge=!this.sideMenuLarge;
   } 

   setMenuEditMode (modeValue){
      this.editMode=modeValue;  
      this.navbarSelected = false;
      this.sideMenuSelected = false;
   }
 
  getSelectedNavbar(){
   return this.selectedNavbar;
 }
 
  checkIfActive = function(ni)
  {
       return  ni.s_menus_Params.pagestate==this.currentTarget && (ni.s_menus_Target==this.currentPageId || ni.s_menus_Params.pagestate!='app.navbar.vpage')
  }   
  
    getCurrentNavbarId (){    
         return this.currentNavbarId;
	 };
      
	  
  
  setCurrentNavbarId (navbarId,language){
         
         if (!this.navbarSelected || this.sideMenuSelected) this.loadingMenu=true; 
         this.currentNavbarId=navbarId;
         
         this.sideMenuSelected = false;
         this.selectedSideMenuId = null;
         if (this.currentNavbarId!=null) this.navbarSelected = true;
         
         
         var type=0;
         if (this.editMode) type=1;
         this.setCurrentNavbarObjectById(language,type);
         var self = this;
         this.$timeout(function(){
            self.loadingMenu=false;
         });
	}

    updateNavbarSelectedSideMenu ()
	{     
         let i,j;    
         for (i=0;i<this.navbarItems.length;i++)
         {
               if (this.navbarItems[i].s_menus_id == this.selectedSideMenu.s_menus_Navbar)
               {
                  for (j=0;j<this.navbarItems[i].SideMenus.length;j++)
                  {
                     if (this.navbarItems[i].SideMenus[j].s_menus_id == this.selectedSideMenuId)
                     {
                     
                        this.navbarItems[i].SideMenus[j] = this.selectedSideMenu;
                        break;
                     }
                  }
               }
         }
        
		}
      
      
      setSelectedNavbar (navbar){
         
        this.selectedNavbar=navbar;
        this.currentNavbarId=navbar?.s_menus_id;
        this.currentTarget=navbar?.s_pages_Target;
        this.currentPageId=navbar?.s_menus_Target_id;
        this.setSelectedSidemenu(null);               
        
		}

    setSelectedAppMenu (target,page_id){
         
        this.currentTarget=target;
        this.currentPageId=page_id;              
        
    }

     setSelectedAppMenuWithParams (target,params){
         
        this.currentTarget=target;
        if (params?.pageid!=undefined) this.currentPageId=params?.pageid;              
        
    }
      
      updateSelectedNavbar (){
         
         let i;
         for (i=0;i<this.navbarItems.length;i++) {
               if (this.navbarItems[i].s_menus_id == this.selectedNavbar.s_menus_id)
               {  
                    this.navbarItems[i]= this.selectedNavbar;
                    break; 
               }
         }    
	}
      
     setDelete (navbarId,index){
         
         this.navbarItems[index].delete= true;
         if (this.navbarItems[i].s_menus_id === null) this.navbarItems[i].splice(index,1);
         else {
            for (i=0;i<this.navbarItems.length;i++) {
                  if (this.navbarItems[i].s_menus_id == navbarId)
                  {  
                     this.navbarItems[i].delete= true;
                      break; 
                  }
            }
         }
	}
      
      addNewSideMenuToNavbar(sideMenu){
         
         let i;    
         for (i=0;i<this.navbarItems.length;i++)
         {
               if (this.navbarItems[i].s_menus_id == this.currentNavbarId)
               {
                  
                        this.navbarItems[i].SideMenus.push (sideMenu);
                        break;
                     
                  
               }
         }
        
		}
        
   setSelectedSidemenu (menuId){
      
      if (this.navbarSelected && !this.sideMenuSelected) this.loadingMenu=true;
      this.selectedSideMenuId=menuId;
      this.navbarSelected = false;
      if (menuId!=null) 
          this.sideMenuSelected = true;
      else 
          this.sideMenuSelected = false;
      this.setCurrentSideMenuObjectById();
      var self = this;
      this.$timeout(function(){
        self.loadingMenu=false;
      });
 }
 
    setSelectedApp (app,stateName,params){
    

     this.selectedApp=app;
     this.appSelected = true;
     if (stateName!==undefined) this.currentTarget=stateName
     if (params?.pageid!==undefined) this.currentPageId=params?.pageid
        
    };
    

     isAppSelected (){  
		return this.appSelected;
    };
 

	getOrgSchemas (orgId) {
         let url='../api/get-org-schemas/'+orgId+'/';
         var deferred = this.$q.defer();
         var instance = this;
         this.RestApiService.get(url, true).then(response =>{           
                  if (response.status === 200) {          
                                 deferred.resolve({data: response.data, status: response.data.status})    
                         } else {
                                 deferred.reject({data:response.data,status:response.status});
                               }
         });
        
         return deferred.promise;
     };
      
	  
	
	getcurrentEnv()
	{
		if (!this.$rootScope.globals?.environments) 	
			return	
		return this.$rootScope.globals?.environments?.[this.$rootScope?.globals?.currentEnv]
  
	}
	

   getSelectedSidemenu (){
      return this.selectedSideMenu;
  
 }
  getLoadingMenu (){
      return this.loadingMenu;
  
 }
  
       
    
    getUsersGroupsForMenu (menuId,language){
      
       var deferred = this.$q.defer();
      let url = '../api/get-users-groups-menus/'+menuId+'/'+language+'/';
       this.RestApiService.get(url).then(response =>{
                                if (response.status===200) deferred.resolve(response.data);
                              else deferred.reject(response.data);
                        
         });
       
          return deferred.promise;
	};
		     
              
    
    ProcessMenuClick (menuId){
    //debugger
    var deferred = this.$q.defer();
      let url = '../api/process-menu-click/'+menuId+'/';
       this.RestApiService.get(url).then(response =>{
                           if (response.status===200) deferred.resolve(response.data);
                           else deferred.reject(response.data);
                        
                });
       
          return deferred.promise;
		  };
   
  
           
    
    
    saveMenuOrder (language,type,appId){
      
       
       var menu;
       if (appId==undefined) appId="0";
       
       if (type==0) menu = this.navbarItems;
       else menu = this.selectedNavbar.SideMenus;
       
       var deferred = this.$q.defer();
       
         let url = '../api/save-menu-order/'+language+'/';
         this.RestApiService.post(url,menu).then(response =>{
 
                 if (response.status===200 || response.status===201) deferred.resolve(response.data)
                     else deferred.reject(response.data);
                
         });
       
          return deferred.promise;
		  };
   
   
    
   saveFullAppMenu (resourceId,navbarItemsLeft,navbarItemsRight, sideMenuItems,description,ver){
      
      var deferred = this.$q.defer();
      if (resourceId == undefined || resourceId==0 || resourceId==null) deferred.reject("Menu id is "+resourceId+"!");
      if (ver==undefined) ver=1
      if (description==undefined) description='v1'
         let url =  '../api/save-full-menu/';
         let post_data={'resource':resourceId,'navbarItemsLeft':navbarItemsLeft,'navbarItemsRight':navbarItemsRight,'sideMenuItems':sideMenuItems, 'desc':description,'version':ver};
         
         this.RestApiService.post(url,post_data).then(response =>{
                           if (response.status===200 ||response.status===201 ) deferred.resolve(response.data) 
                                else deferred.reject(response.data);       
                        });
           
        return deferred.promise;
	};
   
  
    
    saveHomePage (homePageProps){
      
      var deferred = this.$q.defer();
         let url =  '../api/save-home-page/';
         //let post_data={'resource':resourceId,'navbarItemsLeft':navbarItemsLeft,'navbarItemsRight':navbarItemsRight};
         this.RestApiService.post(url,homePageProps).then(response =>{
                           if (response.status===200 || response.status===201) deferred.resolve(data) 
                                else deferred.reject(data);                
         });
         
			
          return deferred.promise;
		  };
      		  
               		  
 
    Clear (){    
        this.menuList = {};
        this.pageList = {};
        this.navbar = {};	
        //this.navbarItems = [];
		this.setNavbarItemsWith([])
        this.selectedNavbar=null;
        this.selectedSideMenu=null;
        this.currentNavbarId = null;
        this.selectedSideMenuId =null;
        this.appSelected =false;
       
	 };
        
    
    getMenus (language,type,refresh,appId,version){
      //MIGOR TODO - POSEBNO USERE I GRUPE SKINUTI A MAKNUTI SA GET-MENUS DA SE SMANJI BROJ SEKNDI
      
       var instance = this;
       var deferred = this.$q.defer();
       var l_refresh = refresh;
       if (l_refresh==undefined) l_refresh = false;
       if (appId==undefined) appId = "0";
        if (version==undefined) version = "0";
       var url =  '../api/get-menus/'+language+'/'+type+'/'+appId+'/'+version+'/'
        
        
      if (this.menuList['' + language + type + appId] == undefined || l_refresh){
          
         this.RestApiService.get(url).then(response =>{
                        
                           if (response.status===200)
                           {
                               if (instance.menuList[''+language+type+appId]!=undefined) {
                                 instance.menuList['' + language + type + appId].length = 0;
                                }
                                instance.menuList['' + language + type + appId] = response.data.menus;
                                deferred.resolve(response.data);
                           }
                           else deferred.reject(response.data);
                               
                        
         });
           
      }else {
                deferred.resolve(this.menuList['' + language + type + appId], 200);
      }
	
          return deferred.promise;
	};
           
    
    getMenusExe (menuId,type,version,refresh){
      //MIGOR TODO - POSEBNO USERE I GRUPE SKINUTI A MAKNUTI SA GET-MENUS DA SE SMANJI BROJ SEKNDI
      
       var instance = this;
       var deferred = this.$q.defer();
       var l_refresh = refresh;
       if (l_refresh==undefined) l_refresh = false;
       if (version==undefined) version = "0";
       var url =  '../api/get-menus-exe/'+menuId+'/'+type+'/'+version+'/'
        
        
      if (this.menuList[type+'_'+menuId+'_'+version] == undefined || l_refresh){
          
         this.RestApiService.get(url).then(response =>{
                        
                           if (response.status===200)
                           {
                               if (instance.menuList[type+'_'+menuId+'_'+version]!=undefined) {
                                 instance.menuList[type+'_'+menuId+'_'+version].length = 0;
                                }
                                instance.menuList[type+'_'+menuId+'_'+version] = response.data;
                                deferred.resolve(response.data);
                           }
                           else deferred.reject(response.data);
                               
                        
         });
           
      }else {
                deferred.resolve(this.menuList[type+'_'+menuId+'_'+version], 200);
      }
    
          return deferred.promise;
    };
	
	
 setCurrentMenu ()
 {
   let instance = this
      this.ResourcesDataService.getResourceDefAll(instance.currentApp.menuId).then(
	  function(data) {                
                      instance.currentMenu = data.data;
      },function(error){
                    instance.MessagingService.addMessage(error.msg,'error');  
          });
   }


    resetCurrentMenuWithProps ()
   {    
        this.currentMenu = {Parameters:{}} 
   }

   setCurrentMenuWithProps (type,menuId,version)
   {    
        this.currentMenu = {Parameters:this.menuList[type+'_'+menuId+'_'+version].properties} 
   }
	
	
    getNavbarsFromMenus (language,type,refresh,resourceId,version)
	{
      
        if (resourceId==undefined) resourceId="0";
        if (version==undefined) version="0";
      
      if (this.navbar[type+'_'+resourceId+'_'+version]==undefined || refresh){
     
      var pos=-1;
      
     
       
      if (this.menuList[type+'_'+resourceId+'_'+version]==undefined)
      {
      
        return null;
        
      }
     
       this.navbar[type+'_'+resourceId+'_'+version] = [];
       this.sidemenus[type+'_'+resourceId+'_'+version] = [];
       
      
       for (var i=0;i<this.menuList[type+'_'+resourceId+'_'+version].menus.length;i++)
       {
          if (this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_MenuPosition!=undefined){
               if (this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_MenuPosition.id!=undefined)  
                   this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_MenuPosition = this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_MenuPosition.id //MIGOR - temporary when menuposition is object  
                    
               if (this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_MenuPosition.substr(0,6)=="navbar")
               {
                         
                 pos =this.navbar[type+'_'+resourceId+'_'+version].map(function(e) { return e.s_menus_id; }).indexOf(this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_id);
                 if (pos<0) this.navbar[type+'_'+resourceId+'_'+version].push(this.menuList[type+'_'+resourceId+'_'+version].menus[i]);
              
               
               }else if (this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_MenuPosition=="sidemenu")
               {
               
                  pos =this.sidemenus[type+'_'+resourceId+'_'+version].map(function(e) { return e.s_menus_id; }).indexOf(this.menuList[type+'_'+resourceId+'_'+version].menus[i].s_menus_id);
                  if (pos<0) this.sidemenus[type+'_'+resourceId+'_'+version].push(this.menuList[type+'_'+resourceId+'_'+version].menus[i]);
               
             
               }
           
          }
        }
      
      //get Menus
    
      
    for (var j=0;j<this.navbar[type+'_'+resourceId+'_'+version].length;j++)
    {
         for (var k=0;k<this.sidemenus[type+'_'+resourceId+'_'+version].length;k++)
       {
        if (this.navbar[type+'_'+resourceId+'_'+version][j].s_menus_id==this.sidemenus[type+'_'+resourceId+'_'+version][k].s_menus_Navbar)
        {
          if (this.navbar[type+'_'+resourceId+'_'+version][j].SideMenus ==undefined) this.navbar[type+'_'+resourceId+'_'+version][j].SideMenus = [];
          
          pos =this.navbar[type+'_'+resourceId+'_'+version][j].SideMenus.map(function(e) { return e.s_menus_id; }).indexOf(this.sidemenus[type+'_'+resourceId+'_'+version][k].s_menus_id);
          if (pos<0) this.navbar[type+'_'+resourceId+'_'+version][j].SideMenus.push(this.sidemenus[type+'_'+resourceId+'_'+version][k]);
          
        }
       }
    }
      
 }
      if (this.navbar[type+'_'+resourceId+'_'+version].length==0) this.navbar[type+'_'+resourceId+'_'+version] = null;
      return this.navbar[type+'_'+resourceId+'_'+version];
};

/**********************************************************
	***************** REFRESH MENUS ******************/  
	
   refreshMenus (language,type,appId){
    
    var deferred = this.$q.defer();
    var instance = this;
    
    this.getMenus(language,type,true,appId).then(function (data) {
              
              
            
                  var l_navbar = instance.getNavbarsFromMenus(language,type,true,appId);
                  instance.navbarItems = l_navbar;
                  deferred.resolve(l_navbar);
                                             
                         
                                                           
        });
     return deferred.promise;     
    
    };

      /**********************************************************
	***************** GET NAVBARS FROM MENU LIST ******************
	***********************************************************/  
    getNavbars (language){
      var deferred = this.$q.defer();
      var l_navbar;
      var type=0;
      var appId="0";
      if (this.selectedApp!=undefined)
      {
        appId = this.selectedApp.id;
        if (appId!="0" && appId!=undefined) type=2;
      }
      var instance = this;
      if (!this.editMode)
      {
       
        if (this.navbar[''+language+type+appId]==undefined || this.navbar[''+language+type+appId]==null)
        {
           this.getMenus(language,type,false,appId).then(function (data) {  
                  l_navbar = instance.getNavbarsFromMenus(language,type,false,appId);
                  //instance.navbarItems = l_navbar;
				  instance.setNavbarItemsWith(l_navbar)
                  deferred.resolve(l_navbar);                                     
			}); 
        }
        else {
            l_navbar = this.getNavbarsFromMenus(language,type,false,appId);
            //this.navbarItems = l_navbar;
			this.setNavbarItemsWith(l_navbar)
            deferred.resolve(l_navbar);
        }
      }
      else {     
			type=1;
			if (this.navbar[''+language+type+appId]==undefined || this.navbar[''+language+type+appId]==null) {
			   this.getMenus(language,type,false,appId).then(function (data) {
					  l_navbar = instance.getNavbarsFromMenus(language,type,false,appId);
					  instance.navbarItems = l_navbar;
					  deferred.resolve(l_navbar);
				})
			  
			} else
			{
			   l_navbar = this.getNavbarsFromMenus(language,type,false,appId);	   
			   //this.navbarItems = l_navbar;
			   this.setNavbarItemsWith(l_navbar)
			   deferred.resolve(l_navbar);
			  
			}      
      }
       return deferred.promise;
    };
	
	////////////
   SetPages() {
            var deferred = this.$q.defer();
            var instance = this;
            this.getPagesForUser(this.$rootScope.globals.currentUser.lang).then(function (data) {
                               
                                instance.$rootScope.globals.currentUser.pages=data;
                                instance.$rootScope.globals.currentUser.pagesLoaded=true;
                               
                                return deferred.resolve(true);
            });
            return deferred.promise; 
        }
 /**********************************************************
	***************** SET MENUS ******************
	***********************************************************/
   SetMenus() {
            var deferred = this.$q.defer();
            var instance = this;
            this.getMenus(this.$rootScope.globals.currentUser.lang,0).then(function (data) {
                              
                                instance.$rootScope.globals.currentUser.navBars = instance.getNavbarsFromMenus(instance.$rootScope.globals.currentUser.lang,0);
                                //instance.generateChildrenNavbarRight(0);
                                //instance.buildDropdownMenu(0,0);
                                instance.$rootScope.globals.currentUser.navbarLoaded=true;
                               
                                return deferred.resolve(true);
            });
         return deferred.promise; 
        }
        
  /**********************************************************
	***************** SET MENUS ON AUTH OR PAGE LOAD ******************
	***********************************************************/
  LoadMenus (scope) {
     var deferred = this.$q.defer();
     var instance = this;
     this.SetMenus().then(function(data){
                      if (data)
                      {
                           
                            instance.SetPages().then(function(data){
                              if (data)
                              {
                                  instance.$timeout(function(){
                                       
                                        instance.$rootScope.globals.InterceptorIsActive = true;
                                  });
                                     
                                 // scope.activity.activityMessage = instance.ProcessingService.setActivityMsg(''); ovo odkomentirati kad se migrira ProcessingService ES6
                                  var lang = instance.$rootScope.globals.currentUser.lang;
                                  var stateName = 'app.navbar.home';
                                  var stateTemp = instance.$cookies.getObject('lastToState');
                                  var paramsTemp = instance.$cookies.getObject('lastToParams');
                                  var navbar=instance.getNavbarByTargetName(stateName,lang);
                                  var params = {'navbar_id':navbar};
                                  
                                  var default_page = instance.$rootScope.globals.currentUser.uep?.Params?.DefaultPageUri;
                                  var default_params = instance.$rootScope.globals.currentUser.uep?.Params?.DefaultPageParams;
                        

                                  if (default_page!=undefined) stateName=default_page;
                                  if (default_params!=undefined) params=default_params;
                                  
                                  if (stateTemp!=undefined)
                                  {
                                    if (stateTemp.name!="app.login" && stateTemp.name!="app.sso" && stateTemp.name!="app.logout" && stateTemp.name!="app.otherwise" && stateTemp.name!=undefined)
                                    {
                                      stateName=stateTemp.name;
                                      params = paramsTemp;
                                    }
                                  }
                                  
                                 
                                  instance.$state.go(stateName,params);
                                  deferred.resolve();
                                  
                              }else{ deferred.reject('LoadMenus function -> Set pages error');}
                            });
                           
                                
                      }else{ deferred.reject('LoadMenus function -> Set menus error');}
                      
    });
    return deferred.promise; 
  }
   /**********************************************************
	***************** GENERATE CHILDREN RIGHT SIDE MENU ******************
	***********************************************************/
   
  generateChildrenNavbarRight () {
  
        var parent;
        this.$rootScope.globals.currentUser.cMenus = {};
        if (this.$rootScope.globals.currentUser.navBars!=undefined && this.$rootScope.globals.currentUser.navBars!=null)
        {
            for (var i=0;i<this.$rootScope.globals.currentUser.navBars.length;i++)
            {
              parent = this.$rootScope.globals.currentUser.navBars[i].s_menus_ParentItem;
              if (parent != undefined && parent != null)
              {
          
                if (this.$rootScope.globals.currentUser.cMenus[parent]==undefined){
                  this.$rootScope.globals.currentUser.cMenus[parent]=[];
                }
               
                  this.$rootScope.globals.currentUser.cMenus[parent].push(angular.copy(this.$rootScope.globals.currentUser.navBars[i]));
                
              }
            }
        }
      //return cMenus;
   };
    
 /**********************************************************
	***************** GET MENUS FOR NAVBAR ******************
	***********************************************************/     
  
 getMenusForNavbar (navbarId,language,type,appId){
          
          var menus = [];
          if (type==undefined) type=0;
          if (appId==undefined) appId="0";
          if (navbarId != undefined) {
           
              
              if (this.navbar[''+language+type+appId]!=undefined){
                  for (var j=0;j<this.navbar[''+language+type+appId].length;j++)
                  {	  
                      if (this.navbar[''+language+type+appId][j].s_menus_id == navbarId)
                      {
                        menus = angular.copy(this.navbar[''+language+type+appId][j].SideMenus);
                        break;
                      }
                  }         
              }
          }
      
      return menus;
         
		  };
   
  /**********************************************************
	***************** NEW Sidemenu ******************
	***********************************************************/
      newSideMenu (lang) {
          
          
         var order = 0;
         if (this.selectedNavbar.SideMenus!=undefined) order = this.selectedNavbar.SideMenus.length;
       
         var instance = this;
        
            var  menu_position = 'sidemenu';


            instance.selectedSideMenu = {s_menus_id:null,s_menus_Name:'New menu item',Users:[],Groups:[],s_menus_TargetPage:null,s_menus_AlwaysNew:false,s_menus_Target:null,s_menus_ParentItem:null,s_menus_MenuType:1,s_menus_Params: {},s_menus_Order:order,s_menus_MenuPosition:menu_position,s_menus_Navbar:instance.currentNavbarId};
            // $scope.selectedSideMenu.Params = {pageid:}
            instance.selectedSideMenu.headerLabel="Select users/groups that will have access to this menu item";
            instance.selectedSideMenu.usersLabel="Users";
            instance.selectedSideMenu.groupsLabel="Groups";
            instance.selectedSideMenu.pageDropdownNavbar = {placeholder: 'Select page...', label:'Page',name:'pages', selectedItem:instance.selectedSideMenu.TargetPage ,
                  paddingLeft:true,hideLabel:false,sizeXs:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                
            instance.selectedSideMenu.taskDropdownNavbar = {placeholder: 'Select task...', label:'Task',name:'tasks', selectedItem: instance.selectedSideMenu.TargetTask,
                  paddingLeft:true,hideLabel:false,sizeXs:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
              
            instance.sideMenuSelected = true;
            instance.navbarSelected = false;
             
   
   }
  /**********************************************************
	***************** NEW Navbar ******************
	***********************************************************/     
    newNavbar (lang,position) {
        
       var order = 0;
       if (this.navbar[lang+'10']!=undefined) order=this.navbar[lang+'10'].length+1;
       if (position==undefined) position='navbar';
       var instance = this;
       this.currentNavbarId = 0;
       this.CodesService.getCodesDetailObjectByName('menutypes',position,lang).then(function(data)
        
        {
        var  menu_position = data;
        
        instance.selectedNavbar = {s_menus_Name:'New navbar item',Users:[],Groups:[],s_menus_TargetPage:null,s_menus_AlwaysNew:false,s_menus_Target:null,s_menus_MenuType:2,s_menus_MenuPosition:menu_position,s_menus_ParentItem:null,s_menus_Params: {pageid:  null},s_menus_Order:order,s_menus_Navbar:null};
        instance.selectedNavbar.headerLabel="Select users/groups that will have access to instance navigation bar";
        instance.selectedNavbar.usersLabel="Users";
        instance.selectedNavbar.groupsLabel="Groups";
        instance.selectedNavbar.pageDropdownNavbar = {placeholder: 'Select page...', label:'Page',name:'pages', selectedItem:instance.selectedNavbar.TargetPage ,
                  paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                
        instance.selectedNavbar.taskDropdownNavbar = {placeholder: 'Select task...', label:'Task',name:'tasks', selectedItem: instance.selectedNavbar.TargetTask,
                  paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                            
        instance.selectedNavbar.menuTypeDropdownNavbar = {placeholder:'Select menu position...',label:'Menu position',name:'Menuposition',selectedItem:instance.selectedNavbar.MenuType,
                      paddingLeft:true,hideLabel:false,ropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'}; 
     
        instance.navbarSelected = true;
        instance.sideMenuSelected = false;   
        
        
         },function(error){
                                  
                                 instance.MessagingService.addMessage("Code name for navbar not found",'error');
                                
                              });
       
   }
   
   
    /**********************************************************
	***************** SET SideMenu order ******************
	***********************************************************/  
    setSidemenuOrder (language){
     
          for (var i=0;i<this.navbar[language+'10'].length;i++)
          {	  
              if (this.navbar[language+'10'][i].id == this.currentNavbarId)
              {
                
                this.navbar[language+'10'][i].SideMenus = this.selectedNavbar.SideMenus;
                this.navbarItems[i].SideMenus = this.selectedNavbar.SideMenus;
               
              }
          }
      
      
    };
 
  
    /**********************************************************
	***************** SET SideMenu after save ******************
	***********************************************************/  
    setSidemenu (language){
     
          for (var i=0;i<this.navbar[language+'10'].length;i++)
          {	  
              if (this.navbar[language+'10'][i].s_menus_id == this.currentNavbarId)
              {
                
                this.navbar[language+'10'][i].SideMenus = this.selectedNavbar.SideMenus;
                for (var j=0;j<this.selectedNavbar.SideMenus.length;j++)
                {
                  if (this.selectedNavbar.SideMenus[j].s_menus_id==this.selectedSideMenu.s_menus_id)
                  {
                    this.selectedNavbar.SideMenus[j] = this.selectedSideMenu;
                    
                    
                  }
                }
               
              }
          }
      
      
    };
     /**********************************************************
	***************** SET SideMenu order ******************
	***********************************************************/  
    setNavbarOrder (newNavbar,lang){
          
          this.navbar[language+'10'] = newNavbar
          
          //ovo nije dobro - treba cijeli array razbacati
          for (var j=0;j<this.navbar[language+'00'].length;j++)
          {
          
            for (var i=0;i<this.navbar[language+'10'].length;i++)
            {	  
              if (this.navbar[language+'10'][i].s_menus_id == this.navbar[language+'00'][j].s_menus_id)
              {
                
                this.navbar[language+'00'][j].s_menus_Order = this.navbar[language+'10'][i].s_menus_Order 
               
              }
            }
          
          }
             
     
         
      
      
    };



    /**********************************************************
    ***************** SET SideMenu order ******************
    ***********************************************************/  
    resetNavbar (){ 
        //this.navbarItems = [];
		this.setNavbarItemsWith([])   
    }
    
	/**********************************************************
	***************** GET RESOURCE DEF ID BY PAGEID ******************
	***********************************************************/     
  
 getResourceDefIdByPageId (pageid,language){
          
          if (pageid != undefined) {
           
              
              if (this.pageList[pageid+language]!=undefined){
				  

                return this.pageList[pageid+language].ResourceDefId;
             
            }else{
              return null;
            }
          }
	
         
		  };
	
           
	/**********************************************************
	***************** GET All Pages******************
	***********************************************************/     
  
 GetAllPages (){
       var pages=[];
       for (var key in this.pageList) {
         pages.push(this.pageList[key]);
       }
       
       return pages;
		  };
      
            
     getIfNavbarSelected (){
      
        return  this.navbarSelected;
    
      
      }; 

            
  getIfSideMenuSelected (){
       //debugger
        return  this.sideMenuSelected;
      
  };
	/**********************************************************
	***************** GET MENUS FOR PAGE BY NAME ******************
	***********************************************************/  
    getMenusForPageByName (pagename,language){
      
      var menus=[];
     for (var key in this.pageList) {
       
      if (this.pageList[key].Target==pagename)  menus =  this.pageList[key].Menus;
     
           
		  }
      return menus;
    };
  
 	/**********************************************************
	***************** GET PAGE By Id ******************
	***********************************************************/     
  
 getPageByResourceId (pageid,language){
      
    
      for (var key in this.pageList) {
       if (key.indexOf(language) !== -1)
       {
          if (this.pageList[key].ResourceDefId.id==pageid)  return angular.copy(this.pageList[key]);
       }
           
		  }
      return null;
      
 }
  
    /**********************************************************
	***************** SET NAVBAR BY ID ******************
	***********************************************************/  
    setCurrentNavbarObjectById (language,type){
      
     if (type==undefined) type=0;
     var appId="0";
     if (this.selectedApp!= undefined) appId=this.selectedApp.id;
     if (appId!="0" && appId!=undefined)
     {
      type=2;
     }else if (appId==undefined)
     {
      appId="0";
     }
     if (this.navbar[''+language+type+appId]==undefined)
      {
          this.getNavbarsFromMenus(language,type,false,appId);
      }
      
      
      this.selectedNavbar=null;
      if (this.navbar[''+language+type+appId]!=undefined)
      {
          for (var j=0;j<this.navbar[''+language+type+appId].length;j++)
          {	  
              if (this.navbar[''+language+type+appId][j].s_menus_id == this.currentNavbarId)
              {
                
               
                this.selectedNavbar= angular.copy(this.navbar[''+language+type+appId][j]);
                this.selectedNavbar.headerLabel="Select users/groups that will have access to this navigation bar";
                this.selectedNavbar.usersLabel="Users";
                this.selectedNavbar.groupsLabel="Groups";
                this.selectedNavbar.pageDropdownNavbar = {placeholder: 'Select page...', label:'Page',name:'pages', selectedItem:this.selectedNavbar.TargetPage ,
                  paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                
                this.selectedNavbar.taskDropdownNavbar = {placeholder: 'Select task...', label:'Task',name:'tasks', selectedItem: this.selectedNavbar.TargetTask,
                  paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                            
                this.selectedNavbar.menuTypeDropdownNavbar = {placeholder:'Select menu position...',label:'Menu pos.',name:'Menupositions',selectedItem:this.selectedNavbar.MenuType,
                      paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'}; 
                break;
              }
          }
      }       
     
    };
     /**********************************************************
	***************** SET SIDEMENU BY ID ******************
	***********************************************************/  
    setCurrentSideMenuObjectById (){
      
     
       if (this.selectedNavbar!=undefined)
       {
          if (this.selectedNavbar.SideMenus!=undefined)
          {
            for  (var i=0;i<this.selectedNavbar.SideMenus.length;i++)
            {
          
                if  (this.selectedNavbar.SideMenus[i].s_menus_id==this.selectedSideMenuId)
                {
                   this.selectedSideMenu=angular.copy(this.selectedNavbar.SideMenus[i]);
                   this.selectedSideMenu.headerLabel="Select users/groups that will have access to this menu item";
                   this.selectedSideMenu.usersLabel="Users";
                   this.selectedSideMenu.groupsLabel="Groups";
                   this.selectedSideMenu.pageDropdownNavbar = {placeholder: 'Select page...', label:'Page',name:'pages', selectedItem:this.selectedSideMenu.TargetPage ,
                   paddingLeft:true,hideLabel:false,sizeXs:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                 
                   this.selectedSideMenu.taskDropdownNavbar = {placeholder: 'Select task...', label:'Task',name:'tasks', selectedItem: this.selectedSideMenu.TargetTask,
                   paddingLeft:true,hideLabel:false,sizeXs:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
               
                   break;
                }
             }
          }
      }
           
    };
    
  /**********************************************************
	***************** GET NAVBAR BY ID ******************
	***********************************************************/  
    getNavbarById (language,type,appId){
      
     if (type==undefined) type=0;
     if (appId==undefined) appId=0;
     if (this.navbar[''+language+type+appId]==undefined)
      {
          this.getNavbarsFromMenus(language,type,false,appId);
      }
      
      for (var j=0;j<this.navbar[''+language+type+appId].length;j++)
      {	  
          if (this.navbar[''+language+type+appId][j].s_menus_id == this.currentNavbarId)
          {
            
             this.selectedNavbar=null;
             this.selectedNavbar= this.navbar[language+type][j]
             this.selectedNavbar.headerLabel="Select users/groups that will have access to this navigation bar";
             this.selectedNavbar.usersLabel="Users";
             this.selectedNavbar.groupsLabel="Groups";
             this.selectedNavbar.pageDropdownNavbar = {placeholder:'Select page...',label:'Page',name:'pages',selectedItem: this.selectedNavbar.TargetPage,
                  paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'};
                  
             this.selectedNavbar.menuTypeDropdownNavbar = {placeholder:'Select menu position...',label:'Menu position',name:'Menutypes',selectedItem:this.selectedNavbar.MenuType,
                  paddingLeft:true,hideLabel:false,dropdownSize:'col-lg-16',columnSizeLabel:'col-lg-24'}; 
             
             return  this.selectedNavbar;
            
          }
      }         
     return null;
    };

 
    
  /**********************************************************
	***************** GET SIDEMENU BY ID ******************
	***********************************************************/  
    getSideMenuById (menuId,language,type){
      
      if (type==undefined) type=0;
      for (var j=0;j<this.sidemenus[language+type].length;j++)
      {	  
          if (this.sidemenus[language+type][j].s_menus_id == menuId)
          {s_menus_
           return this.sidemenus[language+type][j];
            
          }
      }         
     return null;
    };    
	/**********************************************************
	***************** GET NAVBAR ******************
	***********************************************************/     
  
 getNavbar (language){
          
          
            var deferred = this.$q.defer();                   
             
            if (this.navbar[language]==undefined){
				  //if  not found - download
               let url = '../api/get-navbar/'+language+'/';
               this.RestApiService.get(url).then(response =>{      
                           if (response.status===200)
                           {
                               this.navbar[language] = response.data;
                               deferred.resolve(this.navbar[language]);
                    
                           }
                           else deferred.reject(response.data);
                               
                        
               });
    			
              } else {
               
                deferred.resolve(this.navbar[language]);
              }
            
            
	
          return deferred.promise;
		  };
		  	/**********************************************************
      ***************** GET NAVBAR BY TARGET NAME ******************
      ***********************************************************/     
  
 getNavbarByTargetName (targetIn,language,type,appId){

       var navbar_id=0;
       var target=null;
       
       if (type==undefined) type=0;
       if (appId==undefined) appId=0;
       if (this.editMode) type=1;
       if (this.menuList[''+language+type+appId]==undefined) return 0;
       for (var i=0;i<this.menuList[''+language+type+appId].length;i++)
       {
        if (this.menuList[''+language+type+appId][i].s_menus_MenuType==1)
        {
          if (this.menuList[''+language+type+appId][i].s_pages_Target!=undefined && this.menuList[''+language+type+appId][i].s_pages_Target!=null)
          {
            target=this.menuList[''+language+type+appId][i].s_pages_Target;
          }
        }
        else
        {
           if (this.menuList[''+language+type+appId][i].TargetTask!=undefined && this.menuList[''+language+type+appId][i].TargetTask!=null)
          {
            target=this.menuList[''+language+type+appId][i].TargetTask.Target;
          }
        }
          if (target!=null)
          {
             if (target==targetIn)
             {
             navbar_id =  this.menuList[''+language+type+appId][i].s_menus_Navbar;
             break;
             }
          }
        }
      return navbar_id;
    };
   /**********************************************************
	***************** FUNCTIONS NOT USED !!!!!!!!!!!! ******************
	***************** ************************************************** 
   ***********************************************************
   ***********************************************************
   ***********************************************************
	***********************************************************/
   
  /**********************************************************
	***************** GET MENUS FOR PAGE ******************
	***********************************************************/     
  
 getMenusForPage (pageid,language){
          
         var instance = this;
         
         if (page != undefined) {
              var deferred = this.$q.defer();                   
              
              if (this.menuList[page+language]==undefined){
				  //if  not found - download
               
               let url = '../api/get-menus-for-page/'+ page+'/'+language+'/';
               this.RestApiService.get(url).then(response =>{
                        
                       
                           if (response.status===200)
                           {
                               for (var i=0;i<response.data.length;i++)
                                 {
                                  instance.menuList[response.data[i].Target+language]=response.data[i];
                                  
                                 }
                                 if (instance.menuList[page+language]!=undefined) deferred.resolve(this.menuList[page+language].Menus);
                                 else deferred.resolve([]);
                                 }
                                 else {
                                   deferred.reject(response.data);
                                 }
                     
                               
                      //  });
                        
               });
      	
            } else {
                deferred.resolve(this.menuList[page+language].Menus, 200);
              }
         };
	
         return deferred.promise;
	};
		  
	/**********************************************************
	***************** GET MENUS FOR USER ******************
	***********************************************************/     
  
 getMenusForUser (page,language){
          
          var menuIndex=-1;
          if (page != undefined) {
              
              var deferred = this.$q.defer();                   
              var posLang = menuList.map(function(e) { return e.lang; }).indexOf(language);
              if (posLang<0){
				  //if  not found - download
			
               let url = '../api/get-menus-for-page/'+ page+'/'+language+'/';
               let instance = this;
               
               this.RestApiService.get(url).then(response =>{
                        
                        
                           if (response.status===200)
                           {
                                var retrivedList = {lang: language, menuList: response.data};
                      

                                 if (posLang>=0)
                                 {
                                   instance.menuList[posLang].menuList= response.data;
                                   menuIndex=posLang;
                                 }else
                                 {
                                   
                                   menuList.push(retrivedList);
                                   menuIndex=menuList.length-1;
                                 }
                      
                                 deferred.resolve(this.menuList[menuIndex].menuList);
                    
                           }
                           else deferred.reject(response.data);
                               
                       // });
                        
               });
         
				
            } else {
                menuIndex=posLang;
                deferred.resolve(this.menuList[menuIndex].menuList);
            }
         };
	
         return deferred.promise;
		};
		  
	/**********************************************************
	***************** GET PAGES FOR USER ***********************
	***********************************************************/     
  
 getPagesForUser (language){
          
             
              var deferred = this.$q.defer();
              var instance = this;
              if (angular.equals(this.pageList,{})){
				  //if  not found - download
                  let url = '../api/get-pages-for-user/'+language+'/';
                  let instance = this;
               
                  this.RestApiService.get(url).then(response =>{

                           if (response.status===200)
                           {
                                for (var i=0;i<response.data.length;i++)
                              {
                                 instance.pageList[response.data[i].id+language]=response.data[i];
                        
                              }
                      
                              deferred.resolve(instance.pageList);
                    
                           }
                           else deferred.reject(response.data);
  
                        
                  });
      
            } else {
                deferred.resolve(instance.pageList);
            }
          
	
          return deferred.promise;
		};
	
  		


		
    getNavbarsFromPageList (language)
	{
      
      if (this.navbar[language])   return this.navbar[language];

      this.navbar[language] = [];
      this.sidemenus[language] = []
      var pos=-1;
	   
	  for (var key in this.pageList) {
      
			for (var i=0;i<this.pageList[key].Menus.length;i++){
			  if (this.pageList[key].Menus[i].s_menus_MenuPosition.substr(0,6)=="navbar")
			  {
			  
				pos =this.navbar[language].map(function(e) { return e.s_menus_id; }).indexOf(this.pageList[key].Menus[i].s_menus_id);
				if (pos<0) this.navbar[language].push(this.pageList[key].Menus[i]);
			  
			 } else if (this.pageList[key].Menus[i].s_menus_MenuPosition=="sidemenu") {
				 pos =this.sidemenus[language].map(function(e) { return e.s_menus_id; }).indexOf(this.pageList[key].Menus[i].s_menus_id);
				 if (pos<0) this.sidemenus[language].push(this.pageList[key].Menus[i]);
			  }
			   
			}
      
	}//for
      
	//get Menus 
	for (var j=0;j<this.navbar[language].length;j++){
		for (var k=0;k<this.sidemenus[language].length;k++){
			if (this.navbar[language][j].s_menus_id==this.sidemenus[language][k].s_menus_Navbar){
			  if (this.navbar[language][j].SideMenus ==undefined) this.navbar[language][j].SideMenus = [];  
			  pos =this.navbar[language][j].SideMenus.map(function(e) { return e.s_menus_id; }).indexOf(this.sidemenus[language][k].s_menus_id);
			  if (pos<0) this.navbar[language][j].SideMenus.push(this.sidemenus[language][k]); 
			}
		}
	}
      

      return this.navbar[language];
	}

 
	/**********************************************************
	***************** GET MENUS FOR PAGE BY ID ******************
	***********************************************************/     
  
 getMenusForPageById (pageid,language){
          
          if (pageid != undefined) {
           
              
              if (this.pageList[pageid+language]!=undefined){
				  

                return this.pageList[pageid+language].Menus;
             
            }else{
              return null ;
            }
          }
	
         
		  };
	
 	/**********************************************************
	***************** GET PAGE NAVBAR ******************
	***********************************************************/     
  
 getPageNavbar (pageid,language){
      
      if (this.pageList[pageid+language]!=undefined)  return this.pageList[pageid+language].Navbar;
      else {
       
         return null;
      }
           
};
		  

 	/**********************************************************
	***************** GET PAGE NAVBAR BY NAME ******************
	***********************************************************/     
  
   getPageNavbarByName (pagename,language){
      
      var navbar=0;
      for (var key in this.pageList) {
       
      if (this.pageList[key].Target==pagename)  navbar =  this.pageList[key].Navbar;
     
           
		}
      return navbar;
    };  
   
}

export {MenuService};

class ClearService{
   constructor(MenuService,$cookies)
   {
      this.MenuService=MenuService;
      this.$cookies=$cookies;
   }
   static $inject = ['MenuService','$cookies'];
 

 
    Clear()
    {
      this.MenuService.Clear();
      this.$cookies.remove('lastToState')
      this.$cookies.remove('lastToParams')
      this.$cookies.remove('currentUser')
      this.$cookies.remove('environment')

    }

 };
 export {ClearService};