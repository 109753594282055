'use strict';

export function vpageCtrl($scope, $rootScope, $location,KatalyoStateManager,ResourcesDataService,UtilityService,MessagingService,AuthenticationService,$timeout,$stateParams,GridsterService,$element,dragularService,MenuService,$state) {
    
    $scope.gridsterType ="page";
    $scope.CTRLNAME='vpageCtrl';

    var page_id=$stateParams.pageid;
    let org_id=$stateParams.orgid;
	
    var lang=$rootScope.globals.currentUser.lang;
    $scope.resourceId = page_id;
    $scope.orgId = org_id;
	$scope.page_version = $stateParams.version;
	
    $scope.localVars={page_state:{}};
	
    if ($stateParams.parameters!=undefined && $stateParams.parameters!="")    $scope.localVars.page_state = {};
         
    $scope.formRenderingCompleted=false;
    $scope.ksm = KatalyoStateManager;
    $scope.gsrvc = GridsterService
    $scope.menu_service = MenuService

    $scope.gsrvc.setFormViewType(2)
    $scope.menu_service.resetPublicPage()

    let pageValueState
    let pageValueStateName 
    let processWidgetsStateName,processWidgetsState
    let menuVisibility= $scope.ksm.addSubject("menuVisibility",$scope.localVars.page_state?.Parameters);
    let menuVisibilityName = menuVisibility.subject.name;

    let page_value_changed = function(data)
    {
        pageValueState.subject.setStateChanges = data;
        pageValueState.subject.notifyObservers("pageValueChanged");
    };
    
    let menu_visibility_changed = function(data)
    {
         menuVisibility.subject.setStateChanges = data;
         menuVisibility.subject.notifyObservers("menuVisibilityChanged");
    };

    let menu_visibility_action = $scope.ksm.addAction(menuVisibilityName,menuVisibilityName+"Changed",menu_visibility_changed);
        
		
    $scope.setPageState = function () 
	{ 
            let action_page_value_changed

            if ($stateParams.parameters.page_state_id!=undefined && $stateParams.parameters.page_state_id.trim()!="" && $stateParams.parameters.use_state) {
                //load state directly from params
                $scope.localVars.page_state.page_state_id = $stateParams.parameters.page_state_id;
                $scope.localVars.page_state.prev_page_state_id = $stateParams.parameters.prev_page_state_id
                $scope.localVars.page_state.previousTask = $stateParams.parameters.prev_task
                pageValueState  = $scope.ksm.addSubject($stateParams.parameters.page_state_id,$scope.localVars.page_state);
                pageValueStateName = pageValueState.subject.name
				
                if (pageValueState.status==='ok') 
					action_page_value_changed = $scope.ksm.addAction(pageValueStateName,"pageValueChanged",page_value_changed);

            } else if ($stateParams.parameters.use_state || $stateParams.parameters?.use_state===undefined && $stateParams.parameters.page_state_id!==undefined)
            {   
                pageValueState  = $scope.ksm.addSubject($stateParams.parameters.page_state_id,null);
                pageValueStateName = pageValueState.subject.name
                if (pageValueState.status==='ok') 
					action_page_value_changed = $scope.ksm.addAction(pageValueStateName,"pageValueChanged",page_value_changed);
                
                if (pageValueState.subject.currentStateIndex>=0){ //load last state
                    $scope.localVars.page_state = pageValueState.subject.getState;
                } else {
                    //create new state
                    //generate guid
                    let uuid = UtilityService.uuidv4();
                    $scope.localVars.page_state.page_state_id = $state.current.name+"-"+page_id+"-"+uuid;
                    $scope.localVars.page_state.prev_page_state_id = $stateParams.parameters.prev_page_state_id;
                    $scope.localVars.page_state.previousTask = $stateParams.parameters.prev_task
                    pageValueState  = $scope.ksm.addSubject($scope.localVars.page_state.page_state_id,null);
                    pageValueStateName = pageValueState.subject.name
               
                    if (pageValueState.status==='ok')
						action_page_value_changed = $scope.ksm.addAction(pageValueStateName,"pageValueChanged",page_value_changed);
                         
                    $scope.ksm.executeAction(pageValueStateName,"pageValueChanged", $scope.localVars.page_state);
                }
            }
            else
            {
                //create new state
                //generate guid
                let uuid = UtilityService.uuidv4();
                $scope.localVars.page_state.page_state_id = $state.current.name+"-"+page_id+"-"+uuid;
                $scope.localVars.page_state.prev_page_state_id = $stateParams.parameters.prev_page_state_id;
                $scope.localVars.page_state.previousTask = $stateParams.parameters.prev_task
                pageValueState  = $scope.ksm.addSubject($scope.localVars.page_state.page_state_id,null)
                pageValueStateName = pageValueState.subject.name
                if (pageValueState.status==='ok') 
					action_page_value_changed = $scope.ksm.addAction(pageValueStateName,"pageValueChanged",page_value_changed);
                
                $scope.ksm.executeAction(pageValueStateName,"pageValueChanged", $scope.localVars.page_state);
            }
            $scope.resourceDefinition.page_state_id=$scope.localVars.page_state.page_state_id
            $scope.resourceDefinition.prev_page_state_id=$scope.localVars.page_state.prev_page_state_id
            $scope.resourceDefinition.pageId= $scope.resourceId
            $scope.resourceDefinition.page_instance_id= $scope.localVars.page_state.page_instance_id
            $scope.resourceDefinition.prev_page_id=$scope.localVars.page_state.previousTask?.pageId
            $scope.resourceDefinition.prev_page_instance_id=$scope.localVars.page_state.previousTask?.page_instance_id
           
            $scope.ksm.executeAction(menuVisibilityName,menuVisibilityName+"Changed", $scope.localVars.page_state.Parameters);
            $scope.setProcessWidgetEvent()
    }//setPageStateFN


    let widgets_processed = function(data)
    {
                processWidgetsState.subject.setStateChanges = data;
                processWidgetsState.subject.notifyObservers("widgets_processed");
                  
    }
             

    $scope.getResourceForm = function () 
	{     
        ResourcesDataService.getResourceForm($scope.resourceId,'i',lang).then(
				function(data) {
                                if (data.data.widgets.length>0){
                                     $scope.dashboard = {widgets:{layout:data.data.widgets},data:{}}
                                } else 
									$scope.dashboard={widgets : {layout:GridsterService.getEmptyTaskForm()},data:{}};          
                                
								$scope.resourceDefinition= data.data.resourceDefinition;
                                $scope.resourceDefinition.formParams = {formViewType:2}

                                $scope.gsrvc.setFormViewType($scope.resourceDefinition.formParams.formViewType)
                                $scope.resourceDefinition.form_definition = false

                                $scope.setPageState();
                                if ($scope.resourceDefinition.Parameters.setBgColor) 
										$scope.gsrvc.setBodyBgColor($scope.resourceDefinition.Parameters.bgColor)
                                else $scope.gsrvc.resetBodyBgColor()
                                $scope.pageLoaded=true
                                
                                if ($scope.resourceDefinition.ParametersLang===undefined) 
										$scope.resourceDefinition.ParametersLang={InvalidFormMessage:'Please correct invalid entries'}
                                else if ($scope.resourceDefinition.ParametersLang.InvalidFormMessage===undefined) 
										$scope.resourceDefinition.ParametersLang.InvalidFormMessage = "Please correct invalid entries"
                                
                                },function(error){
                                  MessagingService.addMessage(error.msg,'error');
                                  $scope.pageLoaded=true
                                    
                             });
    };
	  
    $scope.getResourceDefinition = function () {
          
                ResourcesDataService.getResourceDefAll($scope.resourceId).then(
					function(data) {           
                        $scope.resource = data.data;
                        if ($scope.resource.Parameters==null) { 
                                $scope.resource.Parameters = {navbar:true,sideMenu:true,pageGridItems:[{id:1}],grid:{NoOfRows:null,NoOfColumns:null,RowGap:null,ColumnGap:null}}       
                        }
						
                        $scope.localVars.page_state = $scope.resource;
                        $scope.localVars.page_state.page_instance_id = $scope.page_instance_id
                        $scope.localVars.sideMenus = $scope.resource.ResourceExtended?.page_menu
                        
                        if ($scope.localVars.sideMenus === undefined) $scope.localVars.sideMenus = []
                        if ($scope.localVars.sideMenus.length>0)    {
                                if ($scope.localVars.page_state.Parameters==undefined)	 $scope.localVars.page_state.Parameters={}
                                $scope.localVars.page_state.Parameters.sideMenu = true     
                        }
                        $scope.getResourceForm()
                
                },function(error){  
                              MessagingService.addMessage(error.msg,'error');  
                });
        };


        $scope.setProcessWidgetEvent=function()
        {
            $scope.$on('ProcessWidgets'+$scope.localVars.page_state.page_state_id,  
				function(event,data){      
					let form = $scope['form'+$scope.resourceId]
					form.$setSubmitted()
					$scope.localVars.ProcessWidgets(form,data.action_index);
            });

            processWidgetsStateName= "processWidgetsStateName"+$scope.localVars.page_state.page_state_id;
            processWidgetsState= $scope.ksm.addSubject(processWidgetsStateName,$scope.localVars.page_state.page_state_id);
            let action_widgets_processed = $scope.ksm.addAction(processWidgetsStateName,"widgets_processed",widgets_processed);
        } 

		
        $scope.localVars.ProcessWidgets = function(form,action_idx)
		{
             var lang=$rootScope.globals.currentUser.lang;
             if (form.$valid) {  
				ResourcesDataService.processWidgets($scope.resourceId,$scope.dashboard.data,$scope.localVars.page_state.page_instance_id,$scope.page_version).then(
					function(data) {       
                         if (data?.data?.msg!=="") MessagingService.addMessage(data.data.msg,'success');
                         $rootScope.$broadcast('ProcessWidgetPostProcess'+$scope.localVars.page_state.page_state_id,data.data.ret_value);
                         $scope.ksm.executeAction(processWidgetsStateName,"widgets_processed",{page_id:$scope.resourceId,action:'procwidgets','action_index':action_idx});
                     },function(error){     
                           MessagingService.addMessage(error.msg,'error');
                           $scope.ksm.executeAction(processWidgetsStateName,"widgets_processed",{page_id:$scope.resourceId,action:'procwidgets','action_index':action_idx,'error':true});                                                       
                 });
                   
             } // if (isValid)
             else {
                 $scope.ksm.executeAction(processWidgetsStateName,"widgets_processed",{page_id:$scope.resourceId,action:'procwidgets','action_index':action_idx,'error':true});                    
                 MessagingService.addMessage($scope.resourceDefinition.ParametersLang.InvalidFormMessage,'warning');  
             }
             };  
			 
    /*
    window.addEventListener('popstate', (e) => {  // on browser "Back" click
        e.preventDefault();   
        let page_instance = $location.search().rinstance
        if(page_instance.toString()=="0") { // if initial load 
          //window.history.pushState(null, null, null); // Prevent adding current URL to History API
          window.history.go(-2)
        }else window.history.back()
    
      });
    */
    
            
    $scope.openSecuredPage = function(pageId, pageVersion)
	{   
        let page_instance = $location.search().rinstance

        ResourcesDataService.openSecuredPage(pageId, pageVersion, page_instance).then(
		
			function(data) {       
				//set page instance
				$scope.page_instance_id = data?.data?.instance?.id
				$state.params.rinstance = $scope.page_instance_id
				$scope.resource = data?.data?.params;
				
				if (page_instance?.toString()=="0") // mijenja URL bez zvanja kontrolera
						$state.transitionTo($state.current.name, $state.params, { notify: false,   location:'replace', inherit:true})
				if ($scope.resource.Parameters==null) {  
						$scope.resource.Parameters = {navbar:true,sideMenu:true,pageGridItems:[{id:1}],grid:{NoOfRows:null,NoOfColumns:null,RowGap:null,ColumnGap:null}}       
				}
				
				$scope.localVars.page_state = $scope.resource;
				$scope.localVars.page_state.page_instance_id = $scope.page_instance_id

				// here get page menu exe
				let type = 1
				let menu_id = $scope.resource.ResourceExtended?.rlinks[0]?.Resource2
				let m_ver = $scope.resource.ResourceExtended?.rlinks[0]?.Resource2_Version ?? 0

				$scope.resourceDefinition= $scope.resource //data.data.resourceDefinition;

				$scope.resourceDefinition.formParams = {formViewType:2}

				$scope.gsrvc.setFormViewType($scope.resourceDefinition.formParams.formViewType)
				$scope.resourceDefinition.form_definition = false
                $scope.resourceDefinition.ExeVersion = pageVersion
				if ($scope.resourceDefinition.Parameters.setBgColor) 	$scope.gsrvc.setBodyBgColor($scope.resourceDefinition.Parameters.bgColor)
				else $scope.gsrvc.resetBodyBgColor()
                
                
                if (menu_id!=undefined) {
                    MenuService.getMenusExe(menu_id,type,m_ver).then(
                        function (datam) {                   
                            $scope.menu_service.setSideMenu(menu_id,m_ver,datam.menus,datam.properties)  
                            if ($scope.menu_service.sideMenu.menus.length>0)  {
                                if ($scope.localVars.page_state.Parameters==undefined) $scope.localVars.page_state.Parameters={}
                                $scope.localVars.page_state.Parameters.sideMenu = true
                            }
                            $scope.setPageState()
                            $scope.pageLoaded=true                           
                        })
                } else  {
                   
                    $scope.menu_service.clearSideMenu()
                    $scope.setPageState()
                    $scope.pageLoaded=true
                }

				

				if (data.data.form.length>0)
					 $scope.dashboard = {widgets:{layout:data.data.form},data:{}}
				 else 
					$scope.dashboard={widgets : {layout:GridsterService.getEmptyTaskForm()},data:{}};          
				
				
				
			
				
            if ($scope.resourceDefinition.ParametersLang===undefined) 
					$scope.resourceDefinition.ParametersLang={InvalidFormMessage:'Please correct invalid entries'}
            else if ($scope.resourceDefinition.ParametersLang.InvalidFormMessage===undefined) 
					$scope.resourceDefinition.ParametersLang.InvalidFormMessage = "Please correct invalid entries"
                               
        },function(error){
            $scope.formLoaded=true;
            $scope.unauthorised = true;  
            $scope.pageLoaded=true    
            $state.params.rinstance = undefined

            if (page_instance?.toString()=="0") 
					$state.transitionTo( $state.current.name, $state.params,  { notify: false,   location:'replace', inherit:true})                   
            MessagingService.addMessage(error.msg,'error');                                                 
        });

    }


     $scope.openPublicPage = function(orgId,pageId, pageVersion)
	 {
        
        let page_instance = $location.search().rinstance

        let schemaname = $location.search().env

        if (schemaname) {     
            sessionStorage.setItem("environment", schemaname)
            $rootScope.globals.currentEnv =  schemaname
        } else $state.go("app.otherwise")

        $scope.menu_service.setPublicPage(true)
        ResourcesDataService.openPublicPage(orgId,pageId, pageVersion, page_instance).then(
			function(data) {
            
				//set page instance
				$scope.page_instance_id = data?.data?.instance?.id
				$state.params.rinstance = $scope.page_instance_id
				
				if (page_instance?.toString()=="0") // mijenja URL bez zvanja kontrolera
						$state.transitionTo($state.current.name, $state.params, { notify: false,   location:'replace', inherit:true})
				$scope.resource = data?.data?.params;
				if ($scope.resource.Parameters==null) {  
						$scope.resource.Parameters = {navbar:true,sideMenu:true,pageGridItems:[{id:1}],grid:{NoOfRows:null,NoOfColumns:null,RowGap:null,ColumnGap:null}}       
				}
				
				$scope.localVars.page_state = $scope.resource;
				$scope.localVars.page_state.page_instance_id = $scope.page_instance_id

				$scope.resourceId = $scope.resource.uid
		
				let type = 1
				let menu_id = $scope.resource.ResourceExtended?.rlinks[0]?.Resource2
				let m_ver = $scope.resource.ResourceExtended?.rlinks[0]?.Resource2_Version ?? 0

				AuthenticationService.SetCredentials(data.data.api_user,data.data.api_user?.username, "",false);
			  
			  
				$scope.resourceDefinition= $scope.resource //data.data.resourceDefinition;
				$scope.resourceDefinition.formParams = {formViewType:2}

				$scope.gsrvc.setFormViewType($scope.resourceDefinition.formParams.formViewType)
				$scope.resourceDefinition.form_definition = false

				if ($scope.resourceDefinition.Parameters.setBgColor) 
					$scope.gsrvc.setBodyBgColor($scope.resourceDefinition.Parameters.bgColor)
				else $scope.gsrvc.resetBodyBgColor()

				
				
				if (data.data.form.length>0)
					 $scope.dashboard = {widgets:{layout:data.data.form},data:{}}
				else 
					$scope.dashboard={widgets : {layout:GridsterService.getEmptyTaskForm()},data:{}};          

				$scope.pageLoaded=true
            
				if (menu_id!=undefined) {
					MenuService.getMenusExe(menu_id,type,m_ver).then(
						function (datam) {                 
							$scope.menu_service.setSideMenu(menu_id,m_ver,datam.menus,datam.properties)
							
							if ($scope.menu_service.sideMenu.menus.length>0)  {
								if ($scope.localVars.page_state.Parameters==undefined) $scope.localVars.page_state.Parameters={}
								$scope.localVars.page_state.Parameters.sideMenu = true
							}
                            $scope.setPageState()                        
		  
						})

				} else 
                {
                    $scope.menu_service.clearSideMenu()
                    $scope.setPageState()
                }
           
				if ($scope.resourceDefinition.ParametersLang===undefined) 
					$scope.resourceDefinition.ParametersLang={InvalidFormMessage:'Please correct invalid entries'}
				else if ($scope.resourceDefinition.ParametersLang.InvalidFormMessage===undefined) 
					$scope.resourceDefinition.ParametersLang.InvalidFormMessage = "Please correct invalid entries"						   
     
        },function(error){
            $scope.formLoaded=true;
            $scope.unauthorised = true;  
            $scope.pageLoaded=true    
            $state.params.rinstance = undefined
            if (page_instance?.toString()=="0") 
				$state.transitionTo($state.current.name, $state.params,  { notify: false,   location:'replace', inherit:true})                   
            MessagingService.addMessage(error.msg,'error');                                             
        });

    }

    if ($scope.resourceId != undefined){
        if ($state.current.name=='app.navbar.vpage') $scope.openSecuredPage($scope.resourceId, $scope.page_version);
        else if ($state.current.name=='app.navbar.public-vpage') $scope.openPublicPage($scope.orgId,$scope.resourceId, $scope.page_version);
      } else{
         $scope.formLoaded=true;
         $scope.unauthorised = true;    
    }

    
}
