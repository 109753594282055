//**************************************************************************************************
//*************************************** WidgetDataExchangeService s ****************************
//**************************************************************************************************

'use strict';

class WidgetDataExchangeService{
	
	constructor($rootScope, MessagingService) {
               
                this.$rootScope=$rootScope;
				this.widgetCounter = 0;
				this.maxWidgetId=0;
				this.taskWidgetRegistry={};
        this.widgetRegistryList={};
				this.searchRegistry=[];
				this.taskID=[];
                this.MessagingService=MessagingService;
        
        }
        
        static $inject = ['$rootScope','MessagingService'];

  

   //ovu funkciju poziva widget
	setActionStatus (taskID,widgetID,action,status) {  // Sto radim s taskID ??

	  //u taskWidgetRegistry u widgetid i action stavi status       //  actions: [{name:'PrepareData',status:false}]
	   	this.taskWidgetRegistry[widgetID-1].actions[action]=status 
	
		var finishedCounter=0;
		for (var i=0;i<this.widgetCounter;i++) {
			if (this.taskWidgetRegistry[i].actions['PrepareData']==true) finishedCounter++		
		}
	   
	   //prolazim kroz registry i gledam preparedata da li su svi actioni true za taj taskid 
		if (finishedCounter==this.widgetCounter)  this.$rootScope.$broadcast('PrepareDataAllWidgetsFinished');
				
		this.$rootScope.$broadcast('PrepareDataAllWidgetsFinished');

     }


    registerTask (taskDefId) {
	   var taskId = this.$rootScope.globals.currentUser.userid + taskDefId + Date.now()
		this.taskID.push(taskId);
		//taskWidgetRegistry=[];
		this.$rootScope.$broadcast('InitiateTask',taskId); //MIGOR - obavijesti widgete da sam inicirao task (da se pripreme) - NE RADI!
		return taskId;
}
  registerWidget (widgetDefinition)
  {
      let exists=false;
      let localId='0';
      let pageId = widgetDefinition.parentResourceId

      if (this.taskWidgetRegistry[pageId]==undefined) this.taskWidgetRegistry[pageId]=[]
      if (this.widgetRegistryList[pageId]==undefined) this.widgetRegistryList[pageId]=[]  
			//test -  for existing widgetid   
			if (widgetDefinition.widgetId!=null && widgetDefinition.widgetId!=undefined && pageId!=undefined)
			{
				localId = widgetDefinition.widgetId; 
				
				if (localId!='0')
				{				
					for (var i=0;i<this.taskWidgetRegistry[pageId].length;i++) {
							if (this.taskWidgetRegistry[pageId][i].widgetId==widgetDefinition.widgetId) {
									  exists=true;
									  this.taskWidgetRegistry[pageId][i]=widgetDefinition;//refresh widget data
									  //this.widgetCounter=this.taskWidgetRegistry[i].localWidgetId;
									
									  //console.log('widget sa istim presentationID vec postoji! widgetid=' + widgetDefinition.widgetId);
									  //console.log('widget for UuidRef already exists!');
							}
					}//for
					
				}  

       if (!exists) {
          this.widgetCounter=this.taskWidgetRegistry[pageId].length+1;
           widgetDefinition.localWidgetId = localId;
           widgetDefinition.id=widgetDefinition.widgetId
           widgetDefinition.name = widgetDefinition.widgetLabel+' ('+widgetDefinition.widgetId+')'
		       // widgetDefinition.widgetId = localId; 
          this.taskWidgetRegistry[pageId].push(widgetDefinition);
          this.widgetRegistryList[pageId].push({id:widgetDefinition.id,name:widgetDefinition.name,actions:widgetDefinition.actions})
       }
       
      
    }
     return localId;
  };

  deRegisterWidget (localId,pageId)
  {
    let found=false
    if (pageId!=undefined)
    {
     	for (var i=0;i<this.taskWidgetRegistry[pageId].length;i++)
     	{
      	if (this.taskWidgetRegistry[pageId][i].localWidgetId==localId)
      	{
              found=true
        		this.taskWidgetRegistry[pageId].splice(i,1);
        
      	}
     	}
      if (!found)  this.MessagingService.addMessage('Widget with localId ' + localId + ' not found!','error');  
    }
  }
   
   
 deRegisterAllWidgets (pageId)
	  {
      if (pageId!=undefined)
      {
		    this.taskWidgetRegistry[pageId].splice(0,this.taskWidgetRegistry.length);
		    this.widgetCounter=0;
      }
  }
 

  
getRegisteredWidgets ()  //MIGOR NOVO
  {
   var tmp=angular.copy(this.taskWidgetRegistry);
   return tmp;
  }
 getLocalWidgetId (serverWidgetId,pageId)  //MIGOR - vraca localwidgetid ako mu das serverwidget
	  {
		if (serverWidgetId!=undefined && serverWidgetId!='') {
		  for (var i=0;i<this.taskWidgetRegistry[pageId].length;i++) {
        if (serverWidgetId==this.taskWidgetRegistry[pageId][i].widgetId)  return i;	   
      }
	  }
    else{      
      return 0;
    }
    
 }
 
 getRegisteredInterfaces(widgetID)  //MIGOR NOVO - jos se ne koristi
  {
   //TODO
  if (widgetID!=undefined) {
	  for (var i=0;i<this.widgetCounter;i++) {
		if (widgetID==this.taskWidgetRegistry[i].widgetId)   return this.taskWidgetRegistry[i].DEI		   
	}
  }
  } 
    
  setData (data)
  {
   
  
		   //set data
		  
       var widgetId = data.widgetId; //ipak treba serverski - ima problema s sync async odnosno redoslijedom registracije widgeta
       var pageId = data.parentResourceId;
       var widgetIndex= this.getLocalWidgetId(widgetId,pageId);
      
      
      if (widgetId!='')
       {
          for (var i=0;i<data.DEI.length;i++)
          {
           var name = data.DEI[i].name
           var value = data.DEI[i].value
           
           if  (this.taskWidgetRegistry[pageId][widgetIndex]!=undefined)
           {
              if  (this.taskWidgetRegistry[pageId][widgetIndex].DEI!=undefined)
              {
                 for (var j=0;j<this.taskWidgetRegistry[pageId][widgetIndex].DEI.length;j++)
                 {
                     if (this.taskWidgetRegistry[pageId][widgetIndex].DEI[j].name == name)
                     {
                      this.taskWidgetRegistry[pageId][widgetIndex].DEI[j].value = value;
                     }
              
                 }
              }  
            }
            //ovo je bio bug - prestalo je raditi
           //taskWidgetRegistry[localWidgetId-1].DEI.[name] = value; //TODO  GRESKA - bilo je WidgetCounter umjesto widgetid - vidjeti sto se promjenilo
           
           this.$rootScope.$broadcast('Event'+widgetId+name,value); 
          }
       }
      //broadcast to listeners 
		//console.log('Broadcastam Event '+widgetId+element+' resourcedef='+value.resourceId+' resourceID='+value.resourceRecordId)	;  
     
		  
		   //this.$rootScope.$broadcast('Event'+widgetId+element,value.DEI});  //npr Event234Resourceid,value=3
		
	
    
    }
    getData (widgetId,element,value)
  {
   
   //find data
   return this.taskWidgetRegistry[this.widgetCounter-1][element]
    
  }


getDataByWidgetId (task_exe_id,task_initiate_id,prev_task_id,widgetId,element)
  {
    var taskExeId = "",taskInitId="",prevTaskId="",taskWidget="",name="";
   if (task_exe_id==undefined) task_exe_id="";
    if (prev_task_id==undefined) prev_task_id="";
   if (task_initiate_id==undefined) task_initiate_id="";
   if (widgetId==undefined) widgetId="";
   if (element==undefined) element="";
   var widgetFound=false;
   for (var i=0;i<this.taskWidgetRegistry.length;i++)
   {
     taskExeId = this.taskWidgetRegistry[i].task_execute_id;
     taskInitId = this.taskWidgetRegistry[i].task_initiate_id;
     prevTaskId = this.taskWidgetRegistry[i].prev_task_id;
     if (taskExeId==undefined) {taskExeId="";}
     if (taskInitId==undefined) {taskInitId="";}
     if (prevTaskId==undefined) {prevTaskId="";}
     taskWidget = this.taskWidgetRegistry[i].widgetId;
     //name = this.taskWidgetRegistry[i].name;
     if (taskWidget==undefined) {taskWidget="";}
      widgetFound=false;
    if  (task_exe_id!="")
    {
    
      if (taskExeId.toString() == task_exe_id.toString())
      {
        widgetFound=true;
      }
    }
    if  (task_initiate_id!="")
    {
    
      if (taskInitId.toString() == task_initiate_id.toString())
      {
        widgetFound=true;
      }
    }
    
     if  (prev_task_id!="")
    {
    
      if (prevTaskId.toString() == prev_task_id.toString())
      {
        widgetFound=true;
      }
    }
    
    if(widgetFound && taskWidget.toString()==widgetId.toString())
    {
      for( var j=0;j<this.taskWidgetRegistry[i].DEI.length;j++)
      {
        if (this.taskWidgetRegistry[i].DEI[j].name==element)
        {
          return this.taskWidgetRegistry[i].DEI[j].value;
        }
        
      }
      
    }
    
   }
   //find data
   return 0;
    
  }
  
   getDataByWidgetIdOnly (widgetId,element)
  {
    var  taskWidget="",name="";
   if (widgetId==undefined) widgetId="";
   if (element==undefined) element="";
   var widgetFound=false;
   for (var i=0;i<this.taskWidgetRegistry.length;i++)
   {
    
     taskWidget = this.taskWidgetRegistry[i].widgetId;
     //name = this.taskWidgetRegistry[i].name;
    if (taskWidget==undefined) {taskWidget="";}
    if (taskWidget.toString()==widgetId.toString())
    {
      for( var j=0;j<this.taskWidgetRegistry[i].DEI.length;j++)
      {
        if (this.taskWidgetRegistry[i].DEI[j].name==element)
        {
          return this.taskWidgetRegistry[i].DEI[j].value;
        }
        
      }
      
    }
    
   }
   //find data
   return 0;
    
  };
  
   registerSearch (searchDefinition)
  {
        var exists=false;
       
        for (var i=0;i<this.searchRegistry.length;i++) {
          //test -  ukoliko vec postoji widgetid   
         if (searchDefinition.widgetId!=null && searchDefinition.widgetId!=undefined)
              if (this.searchRegistry[i].widgetId==searchDefinition.widgetId) {
                        exists=true;
                        this.searchRegistry[i]=searchDefinition;//osvježi s novim podacima
                         
         }
      
       }//for
         
       if (!exists) {
    
           this.searchRegistry.push(searchDefinition);
       }
       
       return exists;
  }
    setSearch (widgetId,taskExecuteId)
  {
       
       
        for (var i=0;i<this.searchRegistry.length;i++) {
          //test -  ukoliko vec postoji widgetid   
         if (widgetId!=null && widgetId!=undefined && taskExecuteId!=null && taskExecuteId!=undefined)
              if (this.searchRegistry[i].taskExecuteId==taskExecuteId) {
                        
                        for(var j=0;j<this.searchRegistry[i].childWidgets.length;j++)
                        {
                          if (this.searchRegistry[i].childWidgets[j].widgetId==widgetId)
                          {
                            this.searchRegistry[i].childWidgets[j].processed=true;
                            this.$rootScope.$broadcast('SearchFormReady'+taskExecuteId+this.searchRegistry[i].widgetId,this.searchRegistry[i].childWidgets[j]);
                          }
                        }
                        
                        
         }
      
       }//for
       
  }
  
  getSearch (taskExecuteId,widgetId)
  {
        
        for (var i=0;i<this.searchRegistry.length;i++) {
          //test -  ukoliko vec postoji widgetid   
         if (widgetId!=null && widgetId!=undefined && taskExecuteId!=null && taskExecuteId!=undefined)
              if (this.searchRegistry[i].widgetId==widgetId && this.searchRegistry[i].taskExecuteId==taskExecuteId) {
                    return this.searchRegistry[i];                 
         }
      
       }//for
         
      return null;
  }
    getPrevTaskData (task_exe_id)
  {
	var ret_value=[];
    if (task_exe_id==undefined) return 0;
   
   for (var i=0;i<this.taskWidgetRegistry.length;i++)
   {
     var taskExeId = this.taskWidgetRegistry[i].task_execute_id;
     if (taskExeId==undefined) {taskExeId="";}
     
    if (taskExeId.toString() == task_exe_id.toString())
    {
      
      ret_value.push(this.taskWidgetRegistry[i]);
    }
    
   }
   //find data
   return ret_value;
    
  }
  getPrevResourceData (task_exe_id,resource_id,prev_resource_id)
  {
  var ret_value=[];
  let rid, resourceId,r_type
  if (task_exe_id!=undefined && task_exe_id!=0) 
    {
      rid=task_exe_id
      r_type='task'
    }
  else if (resource_id!=undefined && resource_id!=0) 
  {
    rid=resource_id
    r_type='page'
  }
  else return 0
   
   for (let i=0;i<this.taskWidgetRegistry[prev_resource_id].length;i++)
   {
    if (r_type=='task') resourceId = this.taskWidgetRegistry[prev_resource_id][i].task_execute_id
    else resourceId = this.taskWidgetRegistry[prev_resource_id][i].resource_id
    
    if (resourceId==undefined) resourceId=''
     
    if (resourceId.toString() == rid.toString())
    {
      
      ret_value.push(this.taskWidgetRegistry[prev_resource_id][i]);
    }
    
   }
   //find data
   return ret_value;
    
  }
  getPreviousTaskWidget  (prev_task_id)
    {
      var pTaskData = this.getPrevTaskData(prev_task_id);
            var datasetId=0,datasetRecordId=0;
            if (pTaskData.DEI!=undefined && pTaskData.DEI!=null)
            {
            for (var i=0;i<pTaskData.DEI.length;i++)
            {
              if (pTaskData.DEI[i].name=="datasetListRecordId")
              {
                if (pTaskData.DEI[i].value!=undefined && pTaskData.DEI[i].value!=null)
                {
                  for (var j=0;j<pTaskData.DEI[i].value.length;j++)
                  {
                   if (pTaskData.DEI[i].value[j].name=="resourceId" && pTaskData.DEI[i].value[j].value!=undefined) datasetId=pTaskData.DEI[i].value[j].value;
                   if (pTaskData.DEI[i].value[j].name=="resourceRecordId" && pTaskData.DEI[i].value[j].value!=undefined) datasetRecordId=pTaskData.DEI[i].value[j].value;
                   
                    
                  }
                     
                    
                }
                  
              }
                
              }
            }
    return {'datasetId':datasetId,'datasetRecordId':datasetRecordId};
    };
   getTaskID ()
  {
    
    return this.taskID;
    
  }
};

export default WidgetDataExchangeService;