//**************************************************************************************************
//*************************************** TaskDataService ****************************
//**************************************************************************************************

'use strict';

class TaskDataService{

  constructor($q,$rootScope,MessagingService,RestApiService)
  {
      this.cache = {};
      this.RestApiService = RestApiService;
      this.$q=$q;
      this.$rootScope =$rootScope;
      this.MessagingService =MessagingService;
      
      
        
  }
    static $inject = ['$q','$rootScope','MessagingService','RestApiService'];
 
    
	  GetTaskParameters (taskId) {
       
          var http_method="";
          var url='../api/task-parameters-byresourceid/';
                 
         
          http_method="GET";
          if (taskId!=undefined) {
            url=url+taskId+'/';
          }
            var deferred = this.$q.defer();   
            
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200) {
                                  deferred.resolve(response.data);       
                    } else {
                           deferred.reject({msg:"Data download failed --> GetTaskParameters "+response.status,status:response.status});     
                    }   
            },
            error=>{
                    deferred.reject({msg:"GetTaskParameters:"+error.msg,status:error.status});     
                       
            });

            
            return deferred.promise;
        };  
  
  
  //**********************************************************
	//           SAVE TASK PARAMETRS
  //**********************************************************      
  SaveTaskParameters  (taskId,taskParameters) {
    
          var http_method="POST";
          var taskIdLocal="";
          
          if (taskId!=undefined) {
            //code
            taskIdLocal=taskId+"/";
            http_method="PUT";
          }
          
          
          var url='../api/task-parameters/'+taskIdLocal;
           var deferred = this.$q.defer(); 
            
            this.RestApiService.send(url,http_method,taskParameters).then(response =>{
                
                if (response.status === 200 || response.status === 201) {
                    response.json().then(data=>{
                                  deferred.resolve(data);            
                    });

                } else {  // if not successful, bind errors to error variables
                        let msg_var = ''
                        if (response.msg==undefined) msg_var='Error saving task parameters (SaveTaskParameters)';
                        else msg_var = response.msg
                        deferred.reject({msg:msg_var,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };
 
  
  //**********************************************************
	//           GET OUTCOME DEFINITION
  //**********************************************************      
  GetOutcomeDefinition  (outcomeDefinitionId,lang) {
    
        var http_method="GET";
          
        var url='../api/task-outcome-definition/'+outcomeDefinitionId+'/'+lang+'/';
        var deferred = this.$q.defer(); 
            
        this.RestApiService.send(url,http_method,codesDetailLang).then(response =>{
                response.json().then(data=>{

                    if (response.status === 200) {
                         deferred.resolve(data);            
                    } else { // if not successful, bind errors to error variables
                        deferred.reject({msg:"Data download failed --> GetOutcomeDefinition "+response.status,status:response.status});     
                    }
                });    
        });    
            
        return deferred.promise;
    };
   
  //**********************************************************
	//           SAVE OUTCOME DEFINITION
  //**********************************************************      
  SaveOutcomeDefinition  (outcome_head) {
    
        var id=0;

        if (outcome_head.id!=null && outcome_head.id!=undefined) id = outcome_head.id;
        var url='../api/task-outcome-definition/'+id+'/'+outcome_head.Lang+'/';
        var deferred = this.$q.defer(); 
            
        this.RestApiService.post(url,outcome_head).then(response =>{
                     if (response.status === 200 || response.status === 201) {
                        deferred.resolve({data: response.data, status: response.status});
                    }
                    else {
                        deferred.reject({msg:'Save outcome definition failed !', status:response.status});
                    }    
                        
         });
        
        return deferred.promise;
    };
   
                
 //**********************************************************
	//           GET TASK OUTCOMES DEFINITIONS
  //**********************************************************      
    GetTaskOutcomesDef  (lang) {
    
        var url='../api/get-task-outcomes-def/'+lang+'/';
            
        var deferred = this.$q.defer(); 
        
        this.RestApiService.get(url).then(response =>{

                if (response.status === 200 || response.status === 201) {
                         
                    deferred.resolve({data:response.data,msg:'Task outcomes saved successfully ! '+response.status+' ',status:response.status});
                    
                } else {
                    deferred.reject({msg:'Get task outcomes def failed ! --> GetTaskOutcomesDef',status:response.status});
                }
                        
                        
        });
        
        return deferred.promise;
    };
   
 //**********************************************************
	//           GET TASK OUTCOMES
  //**********************************************************      
  GetTaskOutcomes  (taskId,codeId,lang) {
    
          var http_method="GET";
                 
          var url='../api/get-task-outcomes-details/';
          var taskIdLocal="";
           if (taskId!=undefined) {
            //code
            taskIdLocal=taskId+"/"+codeId+"/"+lang+"/";
            url = url+taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
          
          
        this.RestApiService.get(url).then(response =>{
            
                if (response.status === 200 || response.status === 201) {
                         
                    deferred.resolve({data:response.data,status:response.status});
                    
                } else {
                    deferred.reject({msg:'Get task outcomes failed ! --> GetTaskOutcomes',status:response.status});
                }
        });
            
          
            return deferred.promise;
    };    
 //**********************************************************
	//           SAVE TASK OUTCOMES
  //**********************************************************      
  SaveTaskOutcomes  (taskId,hasOutcomes,outcomes,lang) {
    
          var deferred = this.$q.defer();
          var http_method="POST";

          if (taskId==undefined) 
              deferred.reject({msg:'Save task outcomes failed: taskID undefined', status:500});
          else {
            
          
            var url='../api/save-task-outcomes/'+taskId+'/'+lang+'/';
            
            let post_data = {'outcomes':outcomes,'hasOutcomes':hasOutcomes};     
            this.RestApiService.post(url,post_data).then(response =>{
      
                        if (response.status === 200 || response.status === 201) {
                            deferred.resolve({data: response.data,msg:'Task outcomes saved successfully ! ', status: response.status});
                        }
                        else {
                            deferred.reject({msg:'Save task outcomesoutcome  failed ! --> SaveTaskOutcomes', status:response.status});
                        }

            });
            
           }     
              return deferred.promise;
        };

   //**********************************************************
	//           SAVE TASK STATUSES
  //**********************************************************      
  SaveTaskStatuses  (taskId,codesActions,codeName) {
    
          var http_method="POST";
          var parameters="";
          
          if (taskId!=undefined) {
            //code
            parameters=taskId+"/"+codeName+'/';
           // http_method="POST";
          }
          
          
          var url='../api/save-task-codes-actions/'+parameters;
             var deferred = this.$q.defer();
             
            let post_data = codesActions;     
            this.RestApiService.post(url,post_data).then(response =>{
                     if (response.status === 200 || response.status === 201) {
                        deferred.resolve({data:response.data, msg:'Task statuses saved successfully ! ', status: response.status});
                    }
                    else {
                        deferred.reject({msg:'Save task status  failed ! --> SaveTaskStatuses', status:response.status});
                    }
                  
            });
            
           
            return deferred.promise;
        };
               
  //**********************************************************
	//           SAVE TASK ASSIGNMENTS
  //**********************************************************      
  SaveTaskAssignments  (taskAssignments,taskId,selfAssigned,publicTask,uniqueName,assignType,taskType) {
    
        var http_method="POST";
                 
        var url='../api/save-task-assignments/'+taskId+'/'+assignType+'/';
          
        var deferred = this.$q.defer(); 
          
        let post_data = {'ta':taskAssignments,'SelfAssigned':selfAssigned,'PublicTask':publicTask,'UniqueName':uniqueName,'taskType':taskType};     
        this.RestApiService.post(url,post_data).then(response =>{
                     if (response.status === 200 || response.status === 201) {
                        deferred.resolve({msg:response.data.msg, status: response.status});
                    }
                    else {
                        deferred.reject({msg:response.data.msg, status:response.status});
                    }
                });
          return deferred.promise;
        };
   //**********************************************************
	//           GET TASK EXECUTE DATA
  //**********************************************************      
  GetTaskExecuteData  (taskInstanceId,lang) {
    
          var url='../api/get-task-execute-data/';
          var taskIdLocal="";
          if (taskInstanceId!=undefined) {
            //code
            taskIdLocal=taskInstanceId+"/"+lang+"/";
            url = url+taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
          
          
        this.RestApiService.get(url).then(response =>{
                
                     if (response.status === 200 || response.status === 201) {
             
                        deferred.resolve({data: response.data,status:response.status});
                    }
                    else {
                        deferred.reject({msg:"Get task execute data failed! --> GetTaskExecuteData", status:response.status});
                    }
                // });
        });
        return deferred.promise;
    };
      
  //**********************************************************
	//           GET TASK ASSIGNMENTS
  //**********************************************************      
  GetTaskAssignments  (taskId) {
    
          var http_method="GET";
                 
          var url='../api/task-assignment-byresourceid/';
          var taskIdLocal="";
           if (taskId!=undefined) {
            //code
            taskIdLocal=taskId+"/";
            url = url+taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
           this.RestApiService.get(url).then(response =>{
                
                     if (response.status === 200 || response.status === 201) {
             
                        deferred.resolve({data: response.data,status:response.status});
                    }
                    else {
                        deferred.reject({msg:"Get task assignments failed! --> GetTaskAssignments", status:response.status});
                    }
                 //});
          });
        return deferred.promise;
    };

 //**********************************************************
	//           GET TASK OUTCOMES
  //**********************************************************      
  GetTaskCodesActionsById  (taskId,lang,codeId) {
    
          var http_method="GET";
                 
          var url='../api/get-task-codes-actions-byid/';
          var taskIdLocal="";
           if (taskId!=undefined) {
            //code
            taskIdLocal=taskId+"/"+lang+"/"+codeId+"/";
            url = url+taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
           this.RestApiService.get(url).then(response =>{
               
                     if (response.status === 200 || response.status === 201) {
             
                        deferred.resolve({data: response.data,status:response.status});
                    }
                    else {
                        deferred.reject({msg:"Get task codes actions failed! --> GetTaskCodesActionsById", status:response.status});
                    }
                // });
          });
        return deferred.promise;
    };
//**********************************************************
	//           GET CODES ACTION BY NAME
  //**********************************************************      
  GetTaskCodesActionsByName  (taskId,lang,codeName) {
    
          var http_method="GET";
                 
          var url='../api/get-task-codes-actions-byname/';
          var taskIdLocal="";
           if (taskId!=undefined) {
            //code
            taskIdLocal=taskId+"/"+lang+"/"+codeName+"/";
            url = url + taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
          
          this.RestApiService.get(url).then(
          response =>{
                  if (response.status === 200) {      
                      deferred.resolve({data: response.data,status:response.status});                            
                  } else {
                      deferred.reject({msg: 'Get task codes actions failed ! --> GetTaskCodesActionsByName',status:response.status});     
                  } 
            },
          error=>{
              deferred.reject({msg: 'Get task codes actions failed ! --> GetTaskCodesActionsByName',status:error.status});      
          }
          
            );
          
          
            return deferred.promise;
    };
        
  //**********************************************************
	//           GET INITIATED TASKS
  //**********************************************************      
  getInitiatedTasks  (type,filter) {
    
          var http_method="GET";
          
          var lang=this.$rootScope.globals.currentUser.lang;
          
          var url='../api/get-initiated-tasks/'+type+'/'+filter+'/'+lang+'/';
         
          //console.log(new Date());        
          var deferred = this.$q.defer(); 
          
          this.RestApiService.get(url).then(response =>{
                        
        
                            if (response.status === 200) {
                                // if successful, bind success message to message
                                        
                                deferred.resolve({data: response.data,status:response.status});            
                                               
                            } else {
                                deferred.reject({msg: 'Get initiated tasks failed ! --> getInitiatedTasks',status:response.status});     
                            }
                        //});    
            });
          
        
            return deferred.promise;
    };
  
  //**********************************************************
	//           GET INITIATED TASK PAGINATED
  //**********************************************************      
  getInitiatedTasksPaginated  (type,filter,url_next,url_prev,new_page,page_size) {
    
          var http_method="GET";
          
          var lang=this.$rootScope.globals.currentUser.lang;
          
          var url='../api/get-initiated-tasks/'+type+'/'+filter+'/'+lang+'/';
          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size
            
            }
          }
      
          var deferred = this.$q.defer(); 
          this.RestApiService.get(url).then(response =>{
                        
        
                            if (response.status === 200) {
                                // if successful, bind success message to message
                                        
                                deferred.resolve({data: response.data,status:response.status});            
                                               
                            } else {
                                deferred.reject({msg: 'Get initiated tasks failed ! --> getInitiatedTasksPaginated',status:response.status});     
                            }
                        //});    
            });
          
        
        return deferred.promise;
    };
         //**********************************************************
	//           GET INITIATED TASK PAGINATED FILTERED
  //**********************************************************      
  getInitiatedTasksPaginatedFiltered  (type,filter,url_next,url_prev,new_page,page_size,f_filter) {
    
          var http_method="POST";
          
          var lang=this.$rootScope.globals.currentUser.lang;
          
          var url='../api/get-initiated-tasks-filtered/'+type+'/'+filter+'/'+lang+'/';
          if (new_page!="" && new_page!=undefined)
          {
            url = url+'?page='+new_page
            if (page_size!="" && page_size!=undefined)
            {
            url = url+'&page_size='+page_size
            
            }
          }
      
         var deferred = this.$q.defer(); 
           this.RestApiService.post(url,f_filter).then(response =>{
                       if (response.status === 200) {
  // if successful, bind success message to message
                             deferred.resolve({data: response.data,status:response.status});            
                                             
                          } else {
           
                              deferred.reject({msg: response.data,status:response.status});     
                          }  
          });
          
          
         
          return deferred.promise;
    };
  
   /**********************************************************
	***************** PRE-INITITATE TASK  ******************
	***********************************************************/     
  preInitiateTask  (taskId,prevTask,lang,prevTaskPackage) {
    
          var http_method="POST";
                 
          var url='../api/pre-initiate-task/'+taskId+'/'+prevTask+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
          
          let post_data= {'ptp':prevTaskPackage};
            this.RestApiService.post(url,post_data).then(response =>{
                 if (response.status === 200) {
                        // if successful, bind success message to message
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Pre initiate task failed ! --> preInitiateTask',status:response.status});     
                    }   
            });
            
        
        return deferred.promise;
    };
 /**********************************************************
	***************** INITITATE TASK  ******************
	***********************************************************/     
  InitiateTask  (taskId,transactionId,taskData,potentialOwners,datasetId,datasetRecordId,lang) {
    
          var http_method="POST";
                 
          var url='../api/initiate-task/'+taskId+'/'+transactionId+'/'+datasetId+'/'+datasetRecordId+'/'; 
          var deferred = this.$q.defer(); 
          
          let post_data =  {'taskData':taskData,'potentialOwners':potentialOwners};
          this.RestApiService.post(url,post_data).then(response =>{
               
                    if (response.status === 200) {
                                  deferred.resolve({data: response.data,status:response.status});             
                    } else {
     
                        deferred.reject({msg:'Initiate task error (fn InitiateTask) --> status = '+response.status+' statusText = '+response.statusText+' '+ response.data,status:response.status});     
                        } 
  
            });
          
        return deferred.promise;
    };
  /**********************************************************
	***************** PRE-EXECUTE TASK  ******************
	***********************************************************/     
  preExecuteTask  (taskInitiateId,taskExecuteId,lang) {
    
          var http_method="GET";
                 
          var url='../api/pre-execute-task/'+taskInitiateId+'/'+taskExecuteId+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
          
          
            this.RestApiService.get(url).then(response =>{
                

                    if (response.status === 200) {
                        // if successful, bind success message to message
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {

                        deferred.reject({msg:'Pre-execute task failed ! --> preExecuteTask',status:response.status});     
                    }
               // });    
            });
         
            return deferred.promise;
        };
        //**********************************************************
    //           GET TASK VIEW RESOURCE 
    //**********************************************************      
    GetTaskViewResource  (widgetId,pageId,taskDefId,taskInstanceId,resourceInstanceId,formType,datasetDefId,datasetRecordId,resourceVersion) {
    
          var http_method="GET";
          var url='../api/get-task-view-resource/'+widgetId+'/'+pageId+'/'+taskDefId+'/'+taskInstanceId+'/'+resourceInstanceId+'/'+formType+'/'+datasetDefId+'/'+datasetRecordId+'/'+resourceVersion+'/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get task view resource ! --> GetTaskViewResource' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

        //**********************************************************
    //           GET TASK VIEW RESOURCE 
    //**********************************************************      
    GetTaskUpdateResource  (widgetId,pageId,taskDefId,taskInstanceId,resourceInstanceId,formType,datasetDefId,datasetRecordId,resourceVersion) {
    
          var http_method="GET";
          var url='../api/get-task-update-resource/'+widgetId+'/'+pageId+'/'+taskDefId+'/'+taskInstanceId+'/'+resourceInstanceId+'/'+formType+'/'+datasetDefId+'/'+datasetRecordId+'/'+resourceVersion+'/';
          var deferred = this.$q.defer(); 
 
            this.RestApiService.get(url).then(response =>{
                    if (response.status === 200) {       
                        deferred.resolve({data: response.data,status:response.status});           
                    } else {
                        deferred.reject({msg: 'Get task update resource ! --> GetTaskUpdateResource' + response.data,status:response.status});     
                    }   
            });
            
            return deferred.promise;
        };

  /**********************************************************
	***************** EXECUTE TASK ACTION  ******************
	***********************************************************/     
  ExecuteTaskAction  (taskInitiateId,taskExecuteId,action,lang,forGroup) {
    
          var http_method="POST";
          
          if (forGroup==null || forGroup==undefined || forGroup=="") forGroup=0; 
                 
          var url='../api/task-status-action/'+taskInitiateId+'/'+taskExecuteId+'/'+action+'/'+lang+'/'+forGroup+'/';
          var deferred = this.$q.defer(); 
          
          
          this.RestApiService.post(url).then(response =>{
               if (response.status === 200 || response.status === 201) {
                       deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:response.data.msg,status:response.status});     
                    }   
            });
          
        return deferred.promise;
        };
  /**********************************************************
	***************** EXECUTE TASK  ******************
	***********************************************************/     
  executeTask  (taskId,taskData,outcomeId,lang) {
    
          var http_method="POST";
          
          if (taskId!=undefined)
          {
          var url='../api/execute-task/'+taskId+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
        
            let post_data= {'taskData':taskData,'outcome':outcomeId} ;
            this.RestApiService.post(url,post_data).then(response =>{
                

                 if (response.status === 200 || response.status === 201) {
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {   
                             deferred.reject({msg:'Execute task error (fn executeTask) --> status = '+response.status+' statusText = '+response.statusText+' '+ response.data,status:response.status});              
                    }
 
            });
          
            return deferred.promise;
          }
        };
        
  /**********************************************************
	***************** PROCESS TASK  ******************
	***********************************************************/     
  ProcessTask  (itemId,outcomeId,lang,idType,previousTaskId) {
    
          var http_method="POST";
          
          if (idType==undefined || idType==null || idType=="") idType='m';
          if (previousTaskId==undefined || previousTaskId==null || previousTaskId=="") previousTaskId=0;
                 
          var url='../api/process-task/'+itemId+'/'+outcomeId+'/'+idType+'/'+previousTaskId+'/'+lang+'/';
          
          
          var deferred = this.$q.defer(); 
          this.RestApiService.post(url).then(response =>{
                      if (response.status === 200) {
                             deferred.resolve({data: response.data,status:response.status});            
                       } else {
                            deferred.reject({msg: response.data.msg,status:response.status});     
                          }   
          });
          
        
         return deferred.promise;
    };
  /**********************************************************
	***************** START TASK  ******************
	***********************************************************/     
  StartTask  (taskId,outcomeId,previousTaskId) {
    
          var http_method="GET";
     
          var url='../api/start-task/'+taskId+'/'+outcomeId+'/'+previousTaskId+'/';
          
          
          var deferred = this.$q.defer(); 
       
        this.RestApiService.get(url,false).then(response =>{
                           
            
                                if (response.status === 200) {
                                    // if successful, bind success message to message
                                    if (response.data!=null)
                                    {
                                        deferred.resolve({data: response.data,status:response.status});    
                                    }
                                    else deferred.reject({msg: response.msg,status:response.status});    
                                              
                                                   
                                } else {
                                    deferred.reject({msg: response.msg,status:response.status});     
                                }
                           // });    
        },
        error =>{
            deferred.reject(error.msg);    
        });
         
        
                   return deferred.promise;
        };
   /**********************************************************
	***************** SAVE TASK FORM DATA ******************
	***********************************************************/     
    saveTaskFormData  (taskId,taskData) {
    
        var http_method="POST";
               
        var url='../api/save-task-form-data/'+taskId+'/';
        
        var deferred = this.$q.defer(); 
        this.RestApiService.post(url,taskData).then(response =>{
                      if (response.status === 200) {
                            deferred.resolve({data: response.data,status:response.status});                                
                          } else {
                                deferred.reject({msg: response.data,status:response.status});     
                          }   
          });
         
       
          return deferred.promise;
    };
    //**********************************************************
	//           SAVE TASK FORM BUTTONS 
  //**********************************************************      
  SaveTaskBtns  (taskBtns,taskId,lang) {

          var  http_method="POST";
     
         
          
          var url='../api/save-task-btns/'+taskId+'/'+lang+'/';
             var deferred = this.$q.defer(); 
          
             this.RestApiService.post(url,taskBtns).then(response =>{
                        if (response.status === 200) {
                               deferred.resolve({msg: response.data,status:response.status});                             
                            } else {
                            deferred.reject({msg: response.data,status:response.status});     
                            }   
            });
          
           
        return deferred.promise;
    };
           
   /**********************************************************
	***************** SAVE TASK TO RESOURCE ******************
	***********************************************************/     
  saveTaskToResource  (taskExecuteId,datasetDefId,datasetRecordId,presentationId) {
    
          var http_method="POST";
                 
          var url='../api/save-task-to-resource/'+taskExecuteId+'/'+datasetDefId+'/'+datasetRecordId+'/'+presentationId+'/';
          
          var deferred = this.$q.defer(); 
            this.RestApiService.post(url).then(response =>{
                        if (response.status === 200) {
                               deferred.resolve({data: response.data,status:response.status});            
                                               
                            } else {
             
                              deferred.reject({msg: response.data,status:response.status});     
                            }  
            });
              
        return deferred.promise;
    };
  //**********************************************************
	//           GET WIDGET DEPENDENCIES
  //**********************************************************      
  getWidgetDependencies  (taskDefId,presentationId,formType) {
    
         var http_method="GET";
                 
         var url='../api/get-widget-dependencies/'+taskDefId+'/'+presentationId+'/'+formType+'/';
        
         var deferred = this.$q.defer(); 
         var gotoServer = false;
         if (this.cache['getWidgetDependencies'] !==undefined)
         {
         if(this.cache.getWidgetDependencies[taskDefId+'_'+presentationId+'_'+formType] !==undefined)
         {
            deferred.resolve({data:  this.cache.getWidgetDependencies[taskDefId+'_'+presentationId+'_'+formType],status:200});
         }
         else
         {
           this.cache['getWidgetDependencies'][taskDefId+'_'+presentationId+'_'+formType] = {};
           gotoServer=true; 
         }
      } else
      {
         this.cache['getWidgetDependencies'] = {};
         this.cache['getWidgetDependencies'][taskDefId+'_'+presentationId+'_'+formType] = {};
         gotoServer=true;
      }  
      if (gotoServer)
      {
            var instance = this;

            this.RestApiService.get(url).then(response =>{
                           
                                if (response.status === 200) {
                                    // if successful, bind success message to message
                                    instance.cache.getWidgetDependencies[taskDefId+'_'+presentationId+'_'+formType] = response.data;        
                                    deferred.resolve({data: response.data,status:response.status});                        
                                } else {
                                    // if not successful, bind errors to error variables
                                    deferred.reject({msg: response.data,status:response.status});     
                                }
                           // });    
            });
        
          
      }
      return deferred.promise;
    };

    //**********************************************************
	//           Execute task dataset list
	//**********************************************************      
   ktTaskDatasetList (resourceId,presentationId,parentDefId,uid,version,resourceVersion) {
    
          var http_method="GET";  
          var url='../api/kt-task-dataset-list/'+ resourceId +'/'+presentationId+'/'+parentDefId+'/'+uid+'/'+version+'/'+resourceVersion+'/';
          var deferred = this.$q.defer();
          
           this.RestApiService.get(url).then(response =>{           
                if (response.status === 200) {
                        deferred.resolve({data: response.data,status:response.status});                               
                      } else {
                                deferred.reject({msg: 'kt-task-dataset-list error ! --> ktTaskDatasetList',status:response.status});     
                    }         
            });
         
            return deferred.promise;
        };

         //**********************************************************
    //           Execute task dataset list
    //**********************************************************      
   ktTaskDatasetListFilter (resourceId,presentationId,parentDefId,filter,form_layout,columns_for_query,uid,ver,resourceVersion,child) {
    
          var http_method="GET";  
          var url='../api/kt-task-dataset-list/'+ resourceId +'/'+presentationId+'/'+parentDefId+'/'+uid+'/'+ver+'/'+resourceVersion+'/';
          var deferred = this.$q.defer();
           let post_data = {'filter':filter,'form':form_layout,'columns':columns_for_query};
           if (child!=undefined) post_data.child = child
           this.RestApiService.post(url,post_data).then(response =>{           
                if (response.status === 200) {
                        deferred.resolve({data: response.data,status:response.status});                               
                      } else {
                                deferred.reject({msg: 'kt-task-dataset-list filter error ! --> ktTaskDatasetListFilter',status:response.status});     
                    }         
            });
         
            return deferred.promise;
        };
  
        //**********************************************************
        //           Execute task  view chart
        //**********************************************************      
       ktTaskViewChart (resourceId,presentationId,taskId,formType,lang) {
        
              var http_method="GET";  
              var url='../api/kt-task-view-chart/'+ resourceId +'/'+presentationId+'/'+taskId+'/'+formType+'/'+lang+'/';
              var deferred = this.$q.defer();
              
               this.RestApiService.get(url).then(response =>{           
                    if (response.status === 200) {
                            deferred.resolve({data: response.data,status:response.status});                               
                          } else {
                                    deferred.reject({msg: 'kt-task-view-chart error !',status:response.status});     
                        }         
                });
             
                return deferred.promise;
            };
  
  
      //**********************************************************
	//           Execute task get resource data PANDAS
	//**********************************************************      
   ktGetResourceDataPandas (resourceId,presentationId,taskId,formType,lang) {
    
          var http_method="GET";
          
          var url='../api/get-resource-data-pandas/'+ resourceId +'/'+presentationId+'/'+taskId+'/'+formType+'/'+lang+'/';
          var deferred = this.$q.defer();
           this.RestApiService.get(url).then(response =>{
                            if (response.status === 200) {         
                                deferred.resolve({data: response.data,status:response.status});                           
                            } else { 
                                deferred.reject({msg: response.msg  || 'get-resource-data-pandas error !'  ,status:response.status});     
                            }   
                });
        
      
        return deferred.promise;
    };
        
        
   /**********************************************************
	***************** SET FORM FIELD ******************
	***********************************************************/  
    setField (form,name,value)
     {
     	 if (form!=undefined){
            	for(var i=0;i<form.length;i++) { 
                    if (name in form[i]) {
                      form[i][name] = value;
                     }
                      if ('layout' in form[i])  {      
                         this.setField(form[i].layout,name,value);
                      } else    if ('FormDef' in form[i])  {      
                         this.setField(form[i].FormDef,name,value);
                      }
              }
         }
      }
        
        // getFields - returns specific properties from the form (actually property parent - used as a pointer)

        getFields (form, properties) 
          {
            var result = {}
            for (var x=0; x < properties.length;x++) {
                result[properties[x]] = []  	//var result={ 'Required' : [], 'ReadOnly' : []  }; // result should  be like this
            } 
     	
            //debugger
            var getFieldsRec  = function(form) {            
                  for(var i=0;i < form.length;i++) 
                  {    
                      for(var j=0; j < properties.length;j++)  {
                          if (properties[j] in form[i]) 
                              result[properties[j]].push( form[i] )
                      }   
                      if ('layout' in form[i])   getFieldsRec(form[i].layout);      
                  }           
            }
            if (form!=undefined) getFieldsRec(form)
            return result
        }
        
   /**********************************************************
	***************** SAVE GRID STATE ******************
	***********************************************************/     
  saveGridState  (taskDefId,presentationId,state,lang) {
    
          var http_method="POST";
                 
          var url='../api/save-grid-state/'+taskDefId+'/'+presentationId+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
          
                
                this.RestApiService.post(url,state).then(response =>{
                        if (response.status === 200) {
                               deferred.resolve({data: response.data,status:response.status});            
                                               
                            } else {
                            deferred.reject({msg: response.data,status:response.status});     
                            }   
                });
          
        
        return deferred.promise;
    };
	getCodes (code_id,language){
             var codeIndex=-1;
             if (code_id != undefined) {
              var deferred = this.$q.defer();                   
              var pos = listOfCodesById.map(function(e) { return e.unique_id; }).indexOf(code_id+language);
              if (pos<0){
				  //if  not found - download
                  let url = '../api/get-codes-detail-byidlang/'+ code_id+'/'+language+'/';
                   this.RestApiService.get(url,true).then(response =>{
        
                            if (response.status === 200) {
                                        var retrivedList = {unique_id: code_id+language, id: code_id,lang: language, codeList: response.data};
              
                                        listOfCodesById.push(retrivedList);
                                        codeIndex=listOfCodesById.length-1;
                                        deferred.resolve(listOfCodesById[codeIndex].codeList, response.status);
                                               
                            } else {
                              
                                deferred.reject(response.data);     
                            }   
                    });
    			
              } else {
                codeIndex=pos;
                deferred.resolve(listOfCodesById[codeIndex].codeList, 200);
              }
            };
	
          return deferred.promise;
		  };
		 
}

export default TaskDataService;

export class PublicTaskDataService {
 
 constructor(RestApiService,$q,$rootScope,MessagingService)
  {
      this.cache = {};
      this.RestApiService = RestApiService;
      this.$q=$q;
      this.$rootScope =$rootScope;
      this.MessagingService =MessagingService;
        
  }
    static $inject = ['RestApiService','$q','$rootScope','MessagingService']
 
    
    /**********************************************************
	***************** START TASK  ******************
	***********************************************************/     
   StartTask  (organisationId,taskName,taskId,outcomeId,previousTaskId) {
    
      var http_method="GET";
     
      var url='../api/start-public-task/'+organisationId+'/'+taskName+'/'+taskId+'/'+outcomeId+'/'+previousTaskId+'/';
          
          
      var deferred = this.$q.defer(); 
      
          this.RestApiService.get(url,false).then(response =>{
               

                    if (response.status === 200) {
                        // if successful, bind success message to message
                               
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
     
                        // if not successful, bind errors to error variables
                      
                        deferred.reject({msg:response.msg,status:response.status});     
                    }
                 
            });
    
      return deferred.promise;
   };
    
   GetTaskParameters  (taskId) {
       
          var http_method="";
          var url='../api/task-parameters-byresourceid/';
                 
         
          http_method="GET";
          if (taskId!=undefined) {
            url=url+taskId+'/';
          }
            var deferred = this.$q.defer();   
          
          var deferred = this.$q.defer(); 
            this.RestApiService.get(url).then(
                response =>{
                        if (response.status === 200) {
                             deferred.resolve({data: response.data,status:response.status});            
                        } else {
                            deferred.reject({msg:'Get task parameters failed ! --> GetTaskParameters',status:response.status});     
                        }   
                },  
                error => {
                        deferred.reject({msg:"Data download failed --> GetTaskParameters "+error.status,status:error.status});
               }
            );
            
            
            return deferred.promise;
    };  
  
 //**********************************************************
	//           GET TASK OUTCOMES
  //**********************************************************      
  GetTaskOutcomes  (taskId,codeId,lang) {
    
          var http_method="GET";
                 
          var url='../api/get-task-outcomes-details/';
          var taskIdLocal="";
           if (taskId!=undefined) {
            //code
            taskIdLocal=taskId+"/"+codeId+"/"+lang+"/";
             url = url + taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
            this.RestApiService.get(url).then(response =>{

                    if (response.status === 200) {
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
                      
                        deferred.reject({msg:'Get task outcomes failed ! --> GetTaskOutcomes', status: response.status});     
                    } 
            });
         
      
            return deferred.promise;
    };    

               
   //**********************************************************
	//           GET TASK EXECUTE DATA
  //**********************************************************      
  GetTaskExecuteData  (taskInstanceId,lang) {
    
          var http_method="GET";
                 
          var url='../api/get-task-execute-data/';
          var taskIdLocal="";
           if (taskInstanceId!=undefined) {
            //code
            taskIdLocal=taskInstanceId+"/"+lang+"/";
            url = url + taskIdLocal;
          }
          
          var deferred = this.$q.defer(); 
           this.RestApiService.get(url).then(response =>{

                    if (response.status === 200) {
                                  deferred.resolve({data: response.data,status:response.status});            
                    } else {
 
                        deferred.reject({msg:'Get task execute data failed ! --> GetTaskExecuteData',status:response.status});     
                    }
                });    
         
            return deferred.promise;
        };
      
 
  /**********************************************************
	***************** PRE-INITITATE TASK  ******************
	***********************************************************/     
  preInitiateTask  (taskId,prevTask,lang,prevTaskPackage) {
    
          var http_method="POST";
                 
          var url='../api/pre-initiate-task/'+taskId+'/'+prevTask+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
          
          let post_data= {'ptp':prevTaskPackage};
            this.RestApiService.post(url,post_data).then(response =>{
                 if (response.status === 200) {
                        deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Pre initiate task failed ! --> preInitiateTask',status:response.status});     
                    }    
            });
            
        
        return deferred.promise;
    };
 /**********************************************************
	***************** INITITATE TASK  ******************
	***********************************************************/     
  InitiateTask  (taskId,transactionId,taskData,potentialOwners,datasetId,datasetRecordId,lang) {
    
          var http_method="POST";
                 
          var url='../api/initiate-task/'+taskId+'/'+transactionId+'/'+datasetId+'/'+datasetRecordId+'/'; 
          var deferred = this.$q.defer(); 
          
          let post_data =  {'taskData':taskData,'potentialOwners':potentialOwners};
          this.RestApiService.post(url,post_data).then(response =>{
                if (response.status === 200) {
                         deferred.resolve({data: response.data,status:response.status});            
                    } else { // if not successful, bind errors to error variables
                        var msg=''
                        if (data.msg== undefined) msg = response.data.data;
                        else msg = response.data.data.msg;
                        deferred.reject({msg: msg,status:response.status}); 
                    }
            });
          
        return deferred.promise;
    };
  /**********************************************************
	***************** PRE-EXECUTE TASK  ******************
	***********************************************************/     
  preExecuteTask  (taskInitiateId,taskExecuteId,lang) {
    
          var http_method="GET";
                 
          var url='../api/pre-execute-task/'+taskInitiateId+'/'+taskExecuteId+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
          
          
            this.RestApiService.get(url).then(response =>{

                    if (response.status === 200) {
                        deferred.resolve({data: response.data,status:response.status});            
                    } else {

                        deferred.reject({msg:'Pre-execute task failed ! --> preExecuteTask',status:response.status});     
                    }   
            });
         
            return deferred.promise;
        };
  /**********************************************************
	***************** EXECUTE TASK ACTION  ******************
	***********************************************************/     
  ExecuteTaskAction  (taskInitiateId,taskExecuteId,action,lang,forGroup) {
    
          var http_method="POST";
          
          if (forGroup==null || forGroup==undefined || forGroup=="") forGroup=0; 
                 
          var url='../api/task-status-action/'+taskInitiateId+'/'+taskExecuteId+'/'+action+'/'+lang+'/'+forGroup+'/';
          var deferred = this.$q.defer(); 
          
          
            this.RestApiService.post(url).then(response =>{

                    if (response.status === 200 || response.status === 201) {
                         deferred.resolve({data: response.data,status:response.status});            
                    } else {
                            deferred.reject({msg: response.data.msg, status:response.status});     
                    }   
            });
          
        return deferred.promise;
        };
  /**********************************************************
	***************** EXECUTE TASK  ******************
	***********************************************************/     
  executeTask  (taskId,taskData,outcomeId,lang) {
    
          var http_method="POST";
          
          if (taskId!=undefined)
          {
          var url='../api/execute-task/'+taskId+'/'+lang+'/';
          
          var deferred = this.$q.defer(); 
        
            let post_data= {'taskData':taskData,'outcome':outcomeId} ;
            this.RestApiService.post(url,post_data).then(response =>{
                if (response.status === 200) {
                         deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg:'Execute task failed ! --> executeTask',status:response.status});     
                    }   
            });
          
            return deferred.promise;
          }
        };
  /**********************************************************
	***************** PROCESS TASK  ******************
	***********************************************************/     
  ProcessTask  (itemId,outcomeId,lang,idType,previousTaskId) {
    
          var http_method="POST";
          
          if (idType==undefined || idType==null || idType=="") idType='m';
          if (previousTaskId==undefined || previousTaskId==null || previousTaskId=="") previousTaskId=0;
                 
          var url='../api/process-task/'+itemId+'/'+outcomeId+'/'+idType+'/'+previousTaskId+'/'+lang+'/';
          

             var deferred = this.$q.defer(); 
            
            this.RestApiService.post(url).then(response =>{
                if (response.status === 200) {
                         deferred.resolve({data: response.data,status:response.status});            
                    } else {
                        deferred.reject({msg: response.data.msg, status:response.status});     
                    }   
            });
        return deferred.promise;
  }

}


export class TaskDefinitionService{

 constructor($q, $rootScope,MessagingService,KatalyoStateManager, ResourcesDataService)
  {
      this.$q=$q;
      this.$rootScope =$rootScope;
      this.MessagingService =MessagingService;
      this.ksm =KatalyoStateManager;
      this.rds = ResourcesDataService
      this.selectedTask={};
      this.definitionScope=null;
      this.scope=null;
      this.task=null;
      this.showTaskInitiators = false;
      this.showTaskOwners = false;
      this.showTaskStatus = false;
      this.showTaskForm = "i";
      this.statusTransitionSelected = false;
      this.showActions=[false,false,false];
      //this.lang=this.$rootScope.globals.currentUser.lang;      
      this.lang = 1;
      this.isCached = {
          'taskDefinitionList': false
      }
      this.taskDefinitionList = []
      
       /*this.rds.getResourceDefListByType(this.lang,'task').then(response =>{
                if (response.status === 200) {
                        this.taskDefinitionList = response.data; 
                         this.isCached['taskDefinitionList']=true 
                   
                    } else {
                        //     
                    }   
        });*/
        
      
  }
  
 static $inject = ['$q','$rootScope','MessagingService','KatalyoStateManager','ResourcesDataService']
 
         
     getTaskDefinitionList () {
        
         var deferred = this.$q.defer(); 
         var me = this  
         if (this.isCached['taskDefinitionList'])  
               return this.taskDefinitionList
         
        this.rds.getResourceDefListByType(this.lang,'task').then(            
         	function(response) {
                //debugger
							 me.taskDefinitionList = response.data; 
                              me.isCached['taskDefinitionList']=true 
                              deferred.resolve( me.taskDefinitionList);   
             },function(error){    
                    deferred.resolve({});   
                    MessagingService.addMessage(error.msg,'error');
            }  );
          
            
            return deferred.promise;

    }
    
    
    
    
    SetSelectedTask (task) {
          
        this.selectedTask = task;
        if (this.scope!=undefined) this.scope.$emit('TaskDefinitionLoaded',null);
    };
    
    SetScopeForSelectedTask  (scope) {
          
        this.scope = scope;
    };
    
     SetScopeForDefinition  (scope) {
          
        this.definitionScope = scope;
    };
    
     GetSelectedTask  (task) {
          
        return this.selectedTask;
    };
    
     showTaskOwnersFn (type){
     return this.showTaskOwners;
     };
     showTaskInitiatorsFn (type){
     return this.showTaskInitiators;
     };
     showTaskStatusFn (type){
     return this.showTaskStatus;
     };
 showHideTaskRoles(type){
    
        if (type==0){
          this.showTaskInitiators = true;
          this.showTaskOwners = false;
          this.showTaskStatus = false;
        }
        if (type==1)
        {
          this.showTaskInitiators = false;
          this.showTaskOwners = true;
          this.showTaskStatus = false;
          
        }
         if (type==2)
        {
          this.showTaskInitiators = false;
          this.showTaskStatus = false;
          //ovo vidjeti kako riješiti
          this.definitionScope.clearTaskAssignments();
          this.definitionScope.getTaskAssignments();
          
        }
           if (type==3)
        {
          this.showTaskOwners = false;
          this.showTaskStatus = false;
            
          this.definitionScope.clearTaskAssignments();
         
          this.definitionScope.processTaskAssignments(angular.copy(this.definitionScope.taskAssignmentsOriginal));
          this.task=angular.copy(this.definitionScope.taskOriginal);
          
        }
        this.showTaskForm='';
        this.statusTransitionSelected = false;
        this.showActions.fill(false);
        this.definitionScope.selectedDefinedActions = -1;
  };
  
    showHideTaskStatuses(index,state){
     
     this.showTaskStatus=false;
     this.definitionScope.selectedDefinedActions=index;
     this.showActions.fill(false);
     this.showActions[index]=state;
     this.showTaskStatus=state;
     this.showTaskInitiators = false;
     this.showTaskOwners = false;
     this.definitionScope.statusTransitionSelected = false;
     this.showTaskForm='';
  
    
     
     if (index==0)
     {
      this.getTaskStatusActions("created","created",index,state)
      
     }else{
        if (this.definitionScope.loadingActions.loading==undefined) this.definitionScope.loadingActions={loading:false};
        else this.definitionScope.loadingActions.loading=false;
     
     }
    
  };
  
  showHideTaskForm(formType){ // default form type=e
     
     this.showTaskForm=formType;
     this.showTaskInitiators = false;
     this.showTaskOwners = false;
     this.showTaskStatus=false;
     this.showActions.fill(false);
     this.definitionScope.selectedDefinedActions = -1;
     //this.ksm.executeAction("taskToolBox","toolboxReload",true);
  };
  showTaskFormFn (){
     return this.showTaskForm;
  };
  
  getSelectedActionFn (){
     return this.definitionScope.selectedDefinedActions;
  };

  getSelectedTransition (){
     return this.definitionScope.codesActions.selectedTransition;
  };
    
  getTransitionActionsCount(transition){

    if (this.definitionScope.codesActions.transitionList===undefined || this.definitionScope.codesActions.transitionList[transition]===undefined)  return '';
    else {
        return this.definitionScope.codesActions.transitionList[transition];
       }
    
    };

  getTaskStatusActions(taskStatusFrom,taskStatusTo,transition,state){
    
    //if ($scope.loadingActions.loading==undefined) $scope.loadingActions={loading:true};
    //else $scope.loadingActions.loading = true;
    this.definitionScope.taskStatusFrom = {name:null,id:null,value:null};
    this.definitionScope.taskStatusTo = {name:null,id:null,value:null};
    this.definitionScope.taskStatusFrom.name=taskStatusFrom;
    this.definitionScope.taskStatusTo.name=taskStatusTo;
    this.definitionScope.statusTransitionSelected = true;
    this.definitionScope.codesActions.selectedTransition = transition;
   
    //$scope.codesActions.codesActionsList.length=0;
       
        var pos = this.definitionScope.taskStatuses.map(function(e) { return e.value; }).indexOf(this.definitionScope.taskStatusFrom.name);
        if (pos>-1) {
          this.definitionScope.taskStatusFrom.id = this.definitionScope.taskStatuses[pos].id;
          this.definitionScope.taskStatusFrom.value = this.definitionScope.taskStatuses[pos].name;
        } 
  
        pos = this.definitionScope.taskStatuses.map(function(e) { return e.value; }).indexOf(this.definitionScope.taskStatusTo.name);
        if (pos>-1) {
          this.definitionScope.taskStatusTo.id = this.definitionScope.taskStatuses[pos].id;
          this.definitionScope.taskStatusTo.value = this.definitionScope.taskStatuses[pos].name;
        }
    
        this.showTaskInitiators = false;
        this.showTaskOwners = false;
        this.showTaskStatus=state;
    
        if (!state)  this.task=angular.copy(this.taskOriginal);
    
   };
   

 
 }

export class TaskProcService {
 
 constructor  ($q,$rootScope,MessagingService,TaskDataService) {
    this.$q=$q;
    this.$rootScope =$rootScope;
    this.MessagingService =MessagingService;
    this.TaskDataService =TaskDataService;
      
    this.taskProcOriginal={};
    this.taskDataOriginal={};
 }
 
  static $inject = ['$q','$rootScope','MessagingService','TaskDataService'];
    
    ProcessTask  (menuId,outcomeId,lang,initType,previousTaskId) {
          
         var deferred = this.$q.defer();
         if (initType==undefined || initType==null || initType=="") initType='m';
         if (previousTaskId==undefined || previousTaskId==null || previousTaskId=="") previousTaskId=0;
         
         if (this.taskProcOriginal[menuId+lang+initType+previousTaskId]!=undefined)
         {
            deferred.resolve(angular.copy(this.taskProcOriginal[menuId+lang+initType+previousTaskId])); 
         }
         else
         {
          var instance = this;
            TaskDataService.ProcessTask(menuId,outcomeId,lang,initType,previousTaskId).then(function(data) {
                                
                                 
                                  instance.taskProcOriginal[menuId+lang+initType+previousTaskId] = data;
                                  deferred.resolve(angular.copy(instance.taskProcOriginal[menuId+lang+initType+previousTaskId]));
                                  //deferred.resolve(data); 
                                     
     
                              },function(error){
                                
                              deferred.reject(error);
                                  
                            });
            
        }
      return deferred.promise;
    }
    
     StartTask  (taskId,outcomeId,previousTaskId) {
          
         var deferred = this.$q.defer();
         if (outcomeId==undefined || outcomeId==null || outcomeId=="") outcomeId=0;
         if (previousTaskId==undefined || previousTaskId==null || previousTaskId=="") previousTaskId=0;
         
         if (this.taskDataOriginal[taskId+outcomeId+previousTaskId]!=undefined)
         {
            deferred.resolve(angular.copy(this.taskDataOriginal[taskId+outcomeId+previousTaskId])); 
         }
         else
         {
          var instance = this;
            TaskDataService.StartTask(taskId,outcomeId,previousTaskId).then(function(data) {
                                
                                 
                                  instance.taskDataOriginal[taskId+outcomeId+previousTaskId] = data;
                                  deferred.resolve(angular.copy(instance.taskDataOriginal[taskId+outcomeId+previousTaskId]));
                                  //deferred.resolve(data); 
                                     
     
                              },function(error){
                                
                              deferred.reject(error);
                                  
                            });
            
        }
      return deferred.promise;
    }
    
    
    
    CompleteStartForm  (taskId,taskTransactionId) {
          
        var deferred = this.$q.defer();
        
            
        
      return deferred.promise;
    }
    CompleteTask  (taskExecuteId) {
          
      var deferred = this.$q.defer();
        
            
        
      return deferred.promise;
    }
    
 }